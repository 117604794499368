import * as React from 'react';

import Slide from '@mui/material/Slide';
import { WarningAmberRounded } from '@mui/icons-material';

import { createTheme, ThemeProvider } from '@mui/material/styles';
const softGreen = '#67A46D';
const theme = createTheme({
  palette: {
    success: {
      main: softGreen,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const Denied = () => {
  return (
    <div className="devices_datatable_container">
      <div className="devices_header_container">
        <div className="devices_title_container"></div>
      </div>

      <div className="warnings_container">
        <div className="no_selected_filter">
          <WarningAmberRounded sx={{ height: '88px', width: '98.29px' }} />

          <span>Usted no posee permisos para gestionar los dispositivos</span>
          <div className="add_device_button">
            <ThemeProvider theme={theme}></ThemeProvider>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Denied;

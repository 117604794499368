import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import GraphVoltage from "./GraphVoltage";
import GraphAmps from "./GraphAmps";
import GraphWatts from "./GraphWatts";

export default function TabGraphicVAV({ timeZone }) {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1", marginBottom: "5%" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Watts" value="1" />
            <Tab label="Voltaje" value="2" />
            <Tab label="Amperaje" value="3" />
            {/* <Tab label="Factor de Potencia" value="4" /> */}
          </TabList>
        </Box>
        <TabPanel value="1">
          <GraphWatts timeZone={timeZone} />
        </TabPanel>
        <TabPanel value="2">
          <GraphVoltage timeZone={timeZone} />
        </TabPanel>
        <TabPanel value="3">
          <GraphAmps timeZone={timeZone} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

import React from "react";
import styled from "styled-components";

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: #1c1c1c;
  /* @media (max-width: 768px) {
    grid-template-rows: 10% 30% 55% 5%;
  } */
`;

export const DescriptiontTitle = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 25px;
  @media (max-width: 425px) {
    font-size: 20px;
  }
`;

export const DescriptionText = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
  align-self: center;
  font-size: 16px;
  height: 30%;

  .bold_text {
    font-size: 20px;
  }

  .question {
    display: flex;
    justify-content: center;
    width: 100%;
    font-weight: 500;
    font-size: 20px;
    margin-top: 30px;
  }

  @media (max-width: 425px) {
    font-size: 14px;
    justify-content: flex-start;
  }
`;

import React from "react";
import { last_conection } from "../../utils/helpers.js";
//multilanguage
import { useTranslation } from "react-i18next";

const Controller = (props) => {
  const { t } = useTranslation();

  return (
    <div className="__control_container">
      <div
        className={`__control_container_title ${
          props.item.online ? "__controller_on" : "__controller_off"
        }`}
      >
        <div className="__control_serial">{props.item.name}</div>
        <div className="__control_title">{`${props.item.serial}`}</div>
      </div>
      <div className="__control_container_device_areas">
        <div className="__control_items_name">
          <span>{t("VIEW_CONTROLLERS.AREAS")}</span>
        </div>
        <div className="__control_items_name">
          <span>{t("VIEW_CONTROLLERS.DEVICES")}</span>
        </div>
        <div className="__control_items_device_areas">
          <span>{props.item.num_rooms}</span>
        </div>
        <div className="__control_items_device_areas">
          <span>{props.item.num_devices}</span>
        </div>
      </div>
      <div className="__control_container_last_update">
        <div className="__control_last_update">
          <span>{`${last_conection(props.item.online, t)}`}</span>
        </div>
      </div>
    </div>
  );
};

export default Controller;

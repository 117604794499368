//React Hooks
import React from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../../../context/AuthProvider";

//Material UI Components
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@material-ui/core/IconButton";
import MuiAlert from "@mui/lab/Alert";
import PlaylistAddCircleIcon from "@mui/icons-material/PlaylistAddCircle";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@mui/material/TextField";

//Material UI Styles
import { createTheme, ThemeProvider } from "@mui/material/styles";

//GraphQl Queries
import { useMutation } from "@apollo/client";
import { CREATE_VIRTUAL_CONTROLLER } from "../controller";

const customGreen = "#acf6ac";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createTheme({
  palette: {
    success: {
      main: customGreen,
    },
  },
});

function VirtualControllerCreationButton({ refetch }) {
  const [open, setOpen] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [snackSeverity, setSnackSeverity] = React.useState("success");
  const [nameController, setNameController] = React.useState("");
  const { permissionsRoleUser } = React.useContext(AuthContext);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const location = useLocation();
  const isControllerView = /^\/app\/controllers\/\d+$/.test(location.pathname);
  const [createVirtualController] = useMutation(CREATE_VIRTUAL_CONTROLLER);

  const handleSubmit = async () => {
    if (nameController) {
      try {
        await createVirtualController({
          variables: {
            data: [
              {
                name: nameController,
                projectId: parseInt(localStorage.getItem("id")),
              },
            ],
          },
        });
        setSnackMessage("Creación Exitosa");
        setSnackSeverity("success");
        setOpenSnack(true);
        handleClose();
        if (refetch) {
          refetch();
        }
      } catch (err) {
        setSnackMessage("No fue posible crear el controlador");
        setSnackSeverity("error");
        setOpenSnack(true);
      }
    }
  };

  return (
    <React.Fragment>
      {permissionsRoleUser?.permissionsRole?.CtlCreateVirtual ? (
        <>
          <Snackbar
            open={openSnack}
            autoHideDuration={4000}
            onClose={handleCloseSnack}
          >
            <Alert severity={snackSeverity}>{snackMessage}</Alert>
          </Snackbar>
          {isControllerView && (
            <button
              className="create_controller_button"
              onClick={() => setOpen(true)}
            >
              Crear controlador virtual
              <PlaylistAddCircleIcon />
            </button>
          )}
          <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
              sx: {
                display: "flex",
                borderRadius: "8px",
                width: "20%",
                height: "auto",
                [theme.breakpoints.down("xl")]: {
                  width: "25%",
                },
                [theme.breakpoints.down("lg")]: {
                  width: "35%",
                },
                [theme.breakpoints.down("md")]: {
                  width: "55%",
                },
                [theme.breakpoints.down("sm")]: {
                  width: "90%",
                },
              },
            }}
          >
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#2EB142",
                color: "#FFFFFF",
                lineHeight: "2.6",
                padding: "5px 5px 5px 40px",
                [theme.breakpoints.down("sm")]: {
                  padding: "5px 5px 5px 20px",
                },
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "25%",
                  fontFamily: "Montserrat, sans-serif",
                  fontSize: "auto",
                  fontWeight: "bold",
                }}
              >
                Creación Controlador
              </span>
              <IconButton onClick={handleClose}>
                <CloseIcon sx={{ color: "#ffff" }} />
              </IconButton>
            </DialogTitle>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "25px",
                padding: "20px 24px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "25px",
                  width: "90%",
                  fontFamily: "Montserrat, sans-serif",
                  fontSize: "18px",
                  marginTop: "15px",
                }}
              >
                <TextField
                  onChange={(event) => setNameController(event.target.value)}
                  size="small"
                  InputProps={{
                    sx: { borderRadius: "8px", width: "100%" },
                  }}
                  id="outlined-basic"
                  label="Ingrese nombre del controlador"
                  variant="outlined"
                />
                <button
                  style={{
                    width: "100%",
                    height: "35px",
                    borderRadius: "6px",
                    border: "none",
                    backgroundColor: "#B2E5B7",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "bold",
                    color: "black",
                    border: "solid #67A46D",
                    cursor: "pointer",
                  }}
                  onClick={handleSubmit}
                >
                  Crear controlador
                </button>
              </div>
            </DialogContent>
          </Dialog>
        </>
      ) : null}
    </React.Fragment>
  );
}

export default VirtualControllerCreationButton;

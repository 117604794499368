import { useContext } from "react";
import { GET_PIN_CODES } from "../../../controllers/pinCodesController";
import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useState } from "react";
import ModalDoorLock from "../../modal/ModalDoorLock";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthProvider";
import TableListCodes from "./components/TableListCodes";

function ListCodes() {
  const { setCodesGlobal } = useContext(AuthContext);
  const [codes, setCodes] = useState([]);
  const [open, setOpen] = useState(false);
  const [view, setView] = useState("add");
  const [dataCode, setDataCode] = useState({});

  const { id } = useParams();

  const { data, refetch } = useQuery(GET_PIN_CODES, {
    variables: { DeviceId: parseInt(id) },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data) {
      const newData = [...data.getPinCodes];
      setCodes(newData.sort((a, b) => a.index - b.index));
      setCodesGlobal(newData);
    }
  }, [data]);

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleAddRestriction = (data) => {
    setView("restriction");
    setOpen(true);
    setDataCode(data);
  };

  return (
    <>
      <TableListCodes
        listCodes={codes}
        handleOpenModal={handleOpenModal}
        handleAddRestriction={handleAddRestriction}
      />
      <ModalDoorLock
        title='Crear código'
        open={open}
        handleClose={handleCloseModal}
        view={view}
        setView={setView}
        dataCode={dataCode}
        setDataCode={setDataCode}
        refetchListCode={refetch}
      />
    </>
  );
}

export default ListCodes;

import { useContext, useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_METTERS_PROJECTS_LOGGED_USER } from "../../../controllers/usersControllers";
import { AuthContext } from "../../../context/AuthProvider";

//Material UI components & Styles
import { createTheme, ThemeProvider } from "@mui/material/styles";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

//Components
import TableInfo from "../../../components/tableInformation/TableInfo";
import AutoComplete from "../../../components/tableInformation/components/AutoComplete";
import Status from "../../../components/tableInformation/components/Status";
import SelectInfo from "../../../components/tableInformation/components/SelectInfo";
import AddDeviceButton from "./components/AddDeviceButton";
import ManagementButton from "./components/ManagementButton";
import AddingDeviceProject from "./components/AddingDeviceProject";
import AddNewMetersModal from "./components/AddNewMetersModal";
import Denied from "../../../components/alert/Denied";

//helper
import {
  dataSelector,
  tableDataBuilder,
  useCustomQueries,
  enabledoRDesibaledAddButton,
} from "./helper";

const softGreen = "#67A46D";

const theme = createTheme({
  palette: {
    success: {
      main: softGreen,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const DevicesManagement = () => {
  const {
    selectedDevice,
    setSelectedDevice,
    selectedProject,
    setSelectedProject,
    projectIdStepper,
    permissionsRoleUser,
  } = useContext(AuthContext);
  /*   const { data, loading, error, refetch } = useQuery(
  const { data, loading, error, refetch } = useQuery(
    GET_METTERS_PROJECTS_LOGGED_USER,
    {
      fetchPolicy: "network-only",
      variables: {
        id: parseInt(localStorage.getItem("np")),
      },
    }
  ); */
  const { data, loading, error, refetch } = useCustomQueries(selectedDevice);

  const dataQuery = data?.getMettersProjectsUsers?.data;
  const enabled = data?.getMettersProjectsUsers?.data?.map(
    (element) => element.enabled
  );
  const allNewMettersExists = data?.getMettersProjectsUsers?.data?.length;

  useEffect(() => {
    if (selectedDevice !== 0) {
      refetch();
    }
  }, [selectedDevice, refetch]);
  const projectsForStepper = data?.getAllProjectsByUser?.Projects.map(
    (element) => element.Project
  );

  <Status enabled={enabled} />;

  return (
    <>
      {permissionsRoleUser?.permissionsRole?.GesDispVer ? (
        <div className="devices_datatable_container">
          <div className="devices_header_container">
            <div className="devices_title_container">
              <h1 className="table_title">Gestión de Dispositivos</h1>
              <span className="title_description">
                Aquí podras gestionar tus dispositivos por proyecto
              </span>
            </div>
            <div className="select_input_container">
              <AddingDeviceProject
                enabled={selectedProject === null ? true : false}
                openModal={false}
              />
              <SelectInfo dataSelect={dataSelector} />
              {allNewMettersExists > 0 && selectedDevice > 0 ? (
                <AddingDeviceProject enabled={false} openModal={true} />
              ) : null}
              {allNewMettersExists > 0 && selectedDevice > 0 ? (
                <AutoComplete dataFilter={dataQuery} enabled={selectedDevice} />
              ) : null}
            </div>
          </div>

          <div name="renderViewBySelect">
            {
              /* selectedDevice > 0 &&  */ allNewMettersExists > 0 ? (
                <TableInfo
                  dataTable={dataQuery}
                  amountColumns={tableDataBuilder({
                    Status,
                    AddDeviceButton,
                    ManagementButton,
                  })}
                />
              ) : (
                <div className="warnings_container">
                  <div className="no_selected_filter">
                    <WarningAmberRoundedIcon
                      sx={{ height: "88px", width: "98.29px" }}
                    />
                    {selectedDevice > 0 && allNewMettersExists <= 0 ? (
                      <>
                        <span>
                          Usted no posee ningún proyecto con ese dispositivo.
                          ¿Desea agregar uno?
                        </span>
                        <div className="add_device_filter_and_button">
                          <div className="filter_project_to_add_device">
                            <AutoComplete
                              dataFilter={projectsForStepper}
                              enabled={true}
                            />
                          </div>
                          <AddingDeviceProject
                            enabled={selectedProject === null ? true : false}
                            openModal={false}
                          />
                        </div>
                      </>
                    ) : (
                      <span>
                        No se ha seleccionado ningún elemento del filtro, por
                        favor seleccione un tipo de dispositivo.
                      </span>
                    )}
                  </div>
                </div>
              )
            }
            <AddNewMetersModal data={projectsForStepper} />
          </div>
        </div>
      ) : (
        <Denied />
      )}
    </>
  );
};
export default DevicesManagement;

import React, { useState, useEffect, useContext, useRef } from "react";
import ProjectCardAreaControl from "../../../components/projects/ProjectCardAreaControl";
import HSC from "../../../components/devices/HSC";
import PluguinAula from "../../../components/devices/PluguinAula";
import Weather from "../../../components/devices/Weather";
import { useQuery } from "@apollo/client";
import { GET_ALL_DEVICES } from "../../../controllers/devicesController";
import { useParams } from "react-router";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Occupation from "../../../components/highcharts/Occupation";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Loading from "../../../components/loading/Loading";
import Construction from "../../../components/loading/Construction";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { AuthContext } from "../../../context/AuthProvider";
import MessageInfo from "../../../components/message/MessageInfo";
import MessageSuccess from "../../../components/message/MessageSuccess";
import MessageError from "../../../components/message/MessageError";
import html2canvas from "html2canvas";
import Measurer from "../../../components/highcharts/Measurer";
import Temperature from "../../../components/highcharts/Temperature";
import {
  ExtractTypeGateway,
  calc_device_fails,
  devices_fails,
} from "../../../utils/helpers";
import { useHistory } from "react-router-dom";
import { Hidden } from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import DownloadIcon from "@mui/icons-material/Download";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import HorusMotel from "../../../components/devices/HorusMotel";
//Devices
import SensorElectrico from "../../../components/devices/SensorElectrico";
import Interruptor from "../../../components/devices/Interruptor";
import SensorPuerta from "../../../components/devices/SensorPuerta";
import SensorMovimiento from "../../../components/devices/SensorMovimiento";
import SensorHumo from "../../../components/devices/SensorHumo";
import SensorTemperatura from "../../../components/devices/SensorTemperatura";
import SensorHumedad from "../../../components/devices/SensorHumedad";
import Cerradura from "../../../components/devices/DoorLock/Cerradura";
import Ocupation from "../../../components/reports/Ocupation/Ocupation";
import CurtainSwitch from "../../../components/devices/CurtainSwitch/CurtainSwitch";
import { VRF } from "../../../components/devices/Vrf/VRFG150";
//helperDevices,
import { SocketVRF, IsThereVRF, getSocket } from "./helperDevices";
//multilanguage
import { useTranslation } from "react-i18next";

//utils
import { spliceDevicesData, filterNameDevices } from "./helperDevices";

let client = null;

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#3f9948",
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  graphic: {
    marginTop: 30,
  },
  image: {
    position: "relative",
    height: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
  table: {
    minWidth: 700,
  },
  info: {
    marginBottom: 60,
  },
  tab: {
    marginTop: 60,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const initialStateFilter = {
  all: true,
  on: false,
  off: false,
};

const Devices = () => {
  const { t } = useTranslation();
  const {
    serial,
    setSerial,
    area,
    roomId,
    setRoomId,
    setArea,
    setIdArea,
    setSuccessMessage,
    setErrorMessage,
    setactiveNextBack,
    loadingPdf,
    setLoadingPdf,
    permissionsRoleUser,
    setCalendarDatePicker,
    occupationAreaName,
    setOccupationAreaName,
    nameHeaderOcupation
  } = useContext(AuthContext);
  const [stateFilter, setStateFilter] = useState(initialStateFilter);
  const { id } = useParams();
  const [aire, setAire] = useState([]);
  const [pluguin, setPluguin] = useState([]);
  const [pluginMotel, setPluginMotel] = useState([]);
  const [device, setDevice] = useState([]);
  const [aire_clon, setAire_clon] = useState([]);
  const [pluguin_clon, setPluguin_clon] = useState([]);
  const [device_clon, setDevice_clon] = useState([]);
  const [pluginMotel_clon, setPluginMotel_clon] = useState([]);
  const history = useHistory();
  const childRef = useRef();

  const {
    data: dataDevice,
    loading,
    error,
    refetch,
  } = useQuery(GET_ALL_DEVICES, {
    variables: {
      RoomId: parseInt(id, 10),
      ProjectId: parseInt(localStorage.getItem("id"), 10),
      typeGateway: ExtractTypeGateway(),
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const listMacromeasureOption =
    dataDevice?.getMettersInProjets[0]?.Metters?.length > 0 ||
    dataDevice?.getInfoMetersByProject?.length > 0;

  const [open, setOpen] = React.useState(false);
  const [openMeasurer, setOpenMeasurer] = useState(false);
  const [openTemperature, setOpenTemperature] = useState(false);

  const {
    REACT_APP_SOCKET_PRODUCTION,
    REACT_APP_SOCKET_DEVELOP,
    REACT_APP_SOCKET_STAGING,
  } = process.env;

  const renderDevice = (item, key) => {
    const { category, sub_category } = item?.Category;
    switch (parseInt(category)) {
      case 3:
        if (item?.variables?.Type === "Sensor") {
          return <SensorElectrico {...key} item={item} send={Send} />;
        } else {
          return <Interruptor {...key} item={item} send={Send} />;
        }
      case 4:
        switch (parseInt(sub_category)) {
          case 1:
            console.log("entro aqui")
            return (
              <SensorPuerta
                {...key}
                item={item}
                send={Send}
                areaName={dataDevice?.getAllDevices?.name}
              />
            );
          case 3:
            return (
              <SensorMovimiento
                {...key}
                item={item}
                send={Send}
                areaName={dataDevice?.getAllDevices?.name}
              />
            );
          case 4:
            return <SensorHumo {...key} item={item} send={Send} />;
          default:
            return null;
        }

      case 17:
        return sub_category == 0 ? <SensorTemperatura {...key} item={item} /> : null;

      case 16:
        return sub_category == 0 ? <SensorHumedad {...key} item={item} /> : null;

      case 7:
        return sub_category == 0 ? (
          <Cerradura
            {...key}
            item={item}
            send={Send}
            contId={dataDevice?.getAllDevices?.Controller?.id}
          />
        ) : null;
      case 8:
        return sub_category == 0 ? (
          <CurtainSwitch
            {...key}
            item={item}
            serial={dataDevice?.getAllDevices?.Controller?.serial}
            client={client}
            endPoint={REACT_APP_SOCKET_PRODUCTION}
          />
        ) : null;
      // agrega mas casos segun sea necesario

      default:
        return null;
    }
  };

  const [openReport, setOpenReport] = useState("ocupation");

  const classes = useStyles();
  useEffect(() => {
    const vrf = IsThereVRF(dataDevice?.getAllDevices.Device);
    if (vrf) {
      SocketVRF();
    }
  }, [dataDevice?.getAllDevices]);

  const handleClickOpen = () => {
    if (openReport == "ocupation") {
      const day_by_miliseconds = 86400000;
      // Hora actual
      const actual_hour = new Date().getHours();
      // Hora inicio
      const hotel_hour = Number(localStorage.getItem("startTime").substring(0, 2));

      setCalendarDatePicker(
        actual_hour < hotel_hour
          ? new Date(new Date().getTime() - day_by_miliseconds)
          : new Date()
      );
      setArea(dataDevice?.getAllDevices?.alias);
      setIdArea(dataDevice?.getAllDevices?.id);
      localStorage.setItem("selectedAreaName", dataDevice?.getAllDevices?.name);
      setOccupationAreaName(localStorage.getItem("selectedAreaName"));
      setOpen(true);
    } else if (openReport == "temperature") {
      setOpenTemperature(true);
      setactiveNextBack(true);
    }
  };
  const handleClose = () => {
    // history.push(`/app/devices/${roomId}`)
    setOpen(false);
    setCalendarDatePicker(new Date());
  };

  const handleCloseMeasurer = () => {
    setOpenMeasurer(false);
  };

  const handleCloseTemperature = () => {
    setCalendarDatePicker(new Date());
    setOpenTemperature(false);
  };

  useEffect(() => {
    if (client) {
      client.onerror = client.onopen = client.onclose = null;
      client.close();
    }
    client = new W3CWebSocket(`${REACT_APP_SOCKET_PRODUCTION}`);
    client.onopen = () => {
      if (serial) client.send(JSON.stringify({ channel: serial }));
    };
    client.onclose = () => {
      client = null;
    };
    return () => {
      if (client) client.close();
    };
  }, [serial]);

  useEffect(() => {
    if (client) {
      client.onmessage = (message) => {
        let data = JSON.parse(message.data);
        if (data.message) {
          if (data.message == "Device update") {
            updateState(data);
          } else if (data.message == "Orden enviada y ejecutada correctamente.") {
            setTimeout(() => {
              setSuccessMessage(true);
            }, 1500);
          } else {
            setErrorMessage(true);
          }
        }
      };
    } else {
      console.error("El cliente no está definido");
    }
  }, [device]);

  async function updateState(dev) {
    device?.findIndex((elemento, indice) => {
      if (elemento?.id === dev?.id_device) {
        let tempDevice = [...device];
        tempDevice[indice] = { ...tempDevice[indice] };
        tempDevice[indice].variables = { ...tempDevice[indice].variables };
        if (device[indice].Category.category == 3) {
          if (dev?.action == "Status") {
            tempDevice[indice].variables.Status = dev?.status;
          }
        } else if (
          (device[indice]?.Category?.category == 4 &&
            device[indice]?.Category?.sub_category == 3) ||
          (device[indice]?.Category?.category == 4 &&
            device[indice]?.Category?.sub_category == 1)
        ) {
          if (dev?.action == "Status") {
            tempDevice[indice].variables.Status = dev?.status;
          } else if (dev?.action == "doorLock") {
            tempDevice[indice].variables.doorLock = dev?.status;
          } else if (dev?.action == "BatteryLevel") {
            tempDevice[indice].variables.BatteryLevel = dev?.status;
          } else if (dev?.action == "Tripped") {
            tempDevice[indice].variables.Tripped = dev?.status;
          } else if (dev?.action == "Armed") {
            tempDevice[indice].variables.Armed = dev?.status;
          }
        } else if (
          device[indice].Category.category == 4 &&
          device[indice].Category.sub_category == 4
        ) {
          if (dev?.action == "Tripped") {
            tempDevice[indice].variables.Tripped = dev?.status;
          } else if (dev?.action == "Armed") {
            tempDevice[indice].variables.Armed = dev?.status;
          } else if (dev?.action == "BatteryLevel") {
            tempDevice[indice].variables.BatteryLevel = dev?.status;
          }
        } else if (
          device[indice].Category.category == 7 &&
          device[indice].Category.sub_category == 0
        ) {
          if (dev?.action == "Status") {
            tempDevice[indice].variables.Status = dev?.status;
          }
        } else if (device[indice].Category.category == 5) {
          let tempAire = [...aire];
          aire?.findIndex((elemento, i) => {
            if (elemento?.id == dev?.id_device) {
              tempAire[i] = { ...tempAire[i] };
              tempAire[i].variables = {
                ...tempAire[i].variables,
              };
              if (dev?.action == "ModeStatus") {
                tempAire[i].variables.ModeStatus = dev?.status;
                tempDevice[indice].variables.ModeStatus = dev?.status;
              } else if (dev?.action == "CurrentSetpoint") {
                tempAire[i].variables.CurrentSetpoint = dev?.status;
                tempDevice[indice].variables.CurrentSetpoint = dev?.status;
              } else if (dev?.action == "CurrentTemperature") {
                tempAire[i].variables.CurrentTemperature = dev?.status;
                tempDevice[indice].variables.CurrentTemperature = dev?.status;
              }
            }
          });
          setAire(tempAire);
        } else if (
          (device[indice]?.Category?.category == 600 &&
            device[indice]?.Category?.sub_category == 1) ||
          (device[indice]?.Category?.category == 600 &&
            device[indice]?.Category?.sub_category == 9)
        ) {
          if (dev.action == "EstadoTexto") {
            tempDevice[indice].variables.EstadoTexto = dev?.status;
            let tempPluguin = [...pluguin];
            pluguin?.findIndex((elemento, i) => {
              if (elemento?.id === dev?.id_device) {
                tempPluguin[i] = { ...tempPluguin[i] };
                tempPluguin[i].variables = {
                  ...tempPluguin[i].variables,
                };
                tempPluguin[i].variables.EstadoTexto = dev?.status;
              }
            });
            setPluguin(tempPluguin);
          } else if (dev?.action == "AccionTexto") {
            tempDevice[indice].variables.AccionTexto = dev?.status;
            let tempPluguin = [...pluguin];
            pluguin?.findIndex((elemento, i) => {
              if (elemento?.id === dev?.id_device) {
                tempPluguin[i] = { ...tempPluguin[i] };
                tempPluguin[i].variables = {
                  ...tempPluguin[i].variables,
                };
                tempPluguin[i].variables.AccionTexto = dev?.status;
              }
            });
            setPluguin(tempPluguin);
          } else if (dev?.action == "Modo") {
            tempDevice[indice].variables.AccionTexto = dev?.status;
            let tempPluguin = [...pluguin];
            pluguin?.findIndex((elemento, i) => {
              if (elemento?.id === dev?.id_device) {
                tempPluguin[i] = { ...tempPluguin[i] };
                tempPluguin[i].variables = {
                  ...tempPluguin[i].variables,
                };
                tempPluguin[i].variables.Modo = dev?.status;
              }
            });
            setPluguin(tempPluguin);
          }
        }
        setDevice(tempDevice);
      }
    });
  }

  function Send(device, status) {
    if (device?.Category?.category == 3) {
      client.send(
        JSON.stringify({
          serial: dataDevice?.getAllDevices?.Controller?.serial,
          id_device: device?.id_in_controller,
          variable: "ChangeStatus",
          action: "status",
          value: status ? "1" : "0",
        })
      );
    } else if (
      (device?.Category?.category == 4 && device?.Category?.sub_category == 3) ||
      (device?.Category?.category == 4 && device?.Category?.sub_category == 1) ||
      (device?.Category?.category == 4 && device?.Category?.sub_category == 4)
    ) {
      client.send(
        JSON.stringify({
          serial: dataDevice?.getAllDevices?.Controller?.serial,
          id_device: device?.id_in_controller,
          action: "armed",
          variable: "ChangeStatus",
          value: status ? "0" : "1",
        })
      );
    } else if (device?.Category?.category == 7 && device?.Category?.sub_category == 0) {
      client.send(
        JSON.stringify({
          serial: dataDevice?.getAllDevices?.Controller?.serial,
          id_device: device?.id_in_controller,
          action: "doorLock",
          variable: "ChangeStatus",
          value: status ? "0" : "1",
        })
      );
    }
  }
  function aireAction(device, status, action) {
    if (action == 1 || action == 2) {
      client.send(
        JSON.stringify({
          serial: dataDevice?.getAllDevices?.Controller?.serial,
          id_device: device?.id_in_controller,
          action: "setPoint",
          variable: "ChangeStatus",
          value: status,
        })
      );
    } else {
      client.send(
        JSON.stringify({
          serial: dataDevice?.getAllDevices?.Controller?.serial,
          id_device: device?.id_in_controller,
          action: "modeStatus",
          variable: "ChangeStatus",
          value: status,
        })
      );
    }
  }

  const VRFActions = (device, status, action) => {
    const socket = getSocket();
    if (socket && socket.readyState === WebSocket.OPEN) {
      const message =
        action === 1 || action === 2
          ? JSON.stringify({
              serial: dataDevice?.getAllDevices?.Controller?.serial,
              id_device: device?.variables?.deviceId,
              action: "setpoint",
              variable: "ChangeStatus",
              value: status,
              projectId: parseInt(localStorage.getItem("id")),
            })
          : JSON.stringify({
              serial: dataDevice?.getAllDevices?.Controller?.serial,
              id_device: device?.variables?.deviceId,
              action: "modeStatus",
              variable: "ChangeStatus",
              value: status,
              projectId: parseInt(localStorage.getItem("id")),
            });
      socket.send(message);
    } else {
      console.error("Socket is not open");
    }
  };
  function pluguinAction(device, status, action, subcategory) {
    if (subcategory == 9) {
      client.send(
        JSON.stringify({
          serial: dataDevice?.getAllDevices?.Controller?.serial,
          id_device: device?.id_in_controller,
          action: "pluginAction",
          variable: "ChangeStatus",
          value: status,
        })
      );
    } else {
      if (action == 1 || action == 2) {
        client.send(
          JSON.stringify({
            serial: dataDevice?.getAllDevices?.Controller?.serial,
            id_device: device?.id_in_controller,
            action: "pluginAction",
            variable: "ChangeStatus",
            value: status,
          })
        );
      } else {
        client.send(
          JSON.stringify({
            serial: dataDevice?.getAllDevices?.Controller?.serial,
            id_device: device?.id_in_controller,
            action: "pluginMode",
            variable: "ChangeStatus",
            value: status,
          })
        );
      }
    }
  }

  useEffect(() => {
    if (dataDevice) {
      setSerial(dataDevice?.getAllDevices?.Controller?.serial);
      localStorage.setItem("RoomName", dataDevice?.getAllDevices?.alias);
      setAire([]);
      setPluguin([]);
      setPluginMotel([]);
      setDevice([]);

      for (let i = 0; i < dataDevice?.getAllDevices?.Device?.length; i++) {
        if (dataDevice?.getAllDevices?.Device[i]?.visible) {
          if (dataDevice?.getAllDevices?.Device[i]?.Category?.category == 5) {
            // Aún no se usará
            let helius = dataDevice?.getAllDevices?.Device?.filter(
              (i) => i?.Category?.name == "Termostato"
            );
            setAire(helius);
            setAire_clon([...aire, dataDevice?.getAllDevices?.Device[i]]);
          } else if (
            dataDevice?.getAllDevices?.Device[i]?.Category?.category == 600 &&
            dataDevice?.getAllDevices?.Device[i]?.Category?.sub_category == 1
          ) {
            setPluguin([...pluguin, dataDevice?.getAllDevices?.Device[i]]);
            setPluguin_clon([...pluguin, dataDevice?.getAllDevices?.Device[i]]);
          } else if (
            dataDevice?.getAllDevices?.Device[i]?.Category?.category == 600 &&
            dataDevice?.getAllDevices?.Device[i]?.Category?.sub_category == 9
          ) {
            setPluguin([...pluguin, dataDevice?.getAllDevices?.Device[i]]);
            setPluguin_clon([...pluguin, dataDevice?.getAllDevices?.Device[i]]);
          } else if (
            dataDevice?.getAllDevices?.Device[i]?.Category?.category == 600 &&
            dataDevice?.getAllDevices?.Device[i]?.Category?.sub_category == 3
          ) {
            setPluginMotel([...pluginMotel, dataDevice?.getAllDevices?.Device[i]]);
            setPluginMotel_clon([...pluginMotel, dataDevice?.getAllDevices?.Device[i]]);
          }
          setDevice((device) => [...device, dataDevice?.getAllDevices?.Device[i]]);
          setDevice_clon((device) => [...device, dataDevice?.getAllDevices?.Device[i]]);
        }
      }
    }
  }, [dataDevice]);

  const handleStatusFilter = (data, state) => {
    if (data == "all") {
      if (state.all) {
        return setStateFilter({
          all: true,
          on: false,
          off: false,
        });
      }
      return setStateFilter({
        all: !state.all,
        on: false,
        off: false,
      });
    } else if (data == "on") {
      if (state.on) {
        return setStateFilter({
          all: false,
          on: true,
          off: false,
        });
      }
      return setStateFilter({
        on: !state.on,
        all: false,
        off: false,
      });
    } else if (data == "off") {
      if (state.off) {
        return setStateFilter({
          off: true,
          on: false,
          all: false,
        });
      }
      return setStateFilter({
        off: !state.off,
        on: false,
        all: false,
      });
    }
  };

  const handleOpenReports = (event) => {
    const value = event.target.value;
    setOpenReport(value);
  };

  const handleFilter = (data, state) => {
    const v_plugin_clon = [...pluguin_clon];
    const v_aire_clon = [...aire_clon];
    const v_devices_clon = [...device_clon];
    if (data == "off") {
      const c = v_devices_clon?.filter((i) => i?.variables?.CommFailure == "1");
      const w = v_aire_clon?.filter(
        (i) => i?.visible != true || i?.variables?.CommFailure == "1"
      );
      const p = v_plugin_clon?.filter((i) => i?.visible != true);
      setDevice(c);
      setAire(w);
      setPluguin(p);
      handleStatusFilter(data, state);
    } else if (data == "on") {
      const c = v_devices_clon?.filter(
        (i) => i?.variables?.CommFailure == "0" || i?.variables?.CommFailure == undefined
      );
      const w = v_aire_clon?.filter((i) => i?.visible == true);
      const p = v_plugin_clon?.filter((i) => i?.visible == true);
      setAire(w);
      setPluguin(p);
      setDevice(c);
      handleStatusFilter(data, state);
    } else if (data == "all") {
      setDevice(v_devices_clon);
      setAire(v_aire_clon);
      setPluguin(v_plugin_clon);
      handleStatusFilter(data, state);
    }
  };

  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
    });
  };

  if (loading || !dataDevice) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (error) {
    return <Construction />;
  }

  return (
    <>
      {permissionsRoleUser?.permissionsRole?.AreaDispVer &&
      permissionsRoleUser?.permissionsRole?.AreaDisp ? (
        <div className='_devices_container'>
          {dataDevice && dataDevice?.getAllDevices?.Controller?.online ? (
            <></>
          ) : (
            <div className='_label_offline'>
              <span className='_label_offline_container'>
                <span className='_icon_label_offline'>
                  <i className='fas fa-exclamation-triangle'></i>
                </span>
                <span className='_title_label_offline'>
                  {t("VIEW_AREA.COMPONENT_HEADER_STATUS_NOTIFICATION")}
                </span>
              </span>
            </div>
          )}
          <div className='__devices_container_project_and_filter__'>
            <div className='__devices_container_logo__'>
              <img
                className='__devices_img__'
                src={dataDevice?.getProjectByIdHeader?.img}
                alt='Logo'
                width='180'
                height='180'
              />
            </div>
            <div className='__devices_container_project_filters__'>
              <div className='__devices_container_project__'>
                <ProjectCardAreaControl data={dataDevice?.getProjectByIdHeader} />
              </div>
              <div className='__devices_container_filter__'>
                <div className='__devices_container_name__'>
                  <span className='__devices_name__'>
                    {dataDevice?.getAllDevices?.alias}
                  </span>
                </div>
                <div className='__devices_container_filter_option__'>
                  <div className='__devices_filter_status__'>
                    <div
                      className={`__filter_status_title__ ${
                        stateFilter?.all
                          ? "__filter_status_title_all_true__"
                          : "__filter_status_title_all_false__"
                      }`}
                    >
                      <span
                        onClick={
                          permissionsRoleUser?.permissionsRole?.AreaDispMan
                            ? () => handleFilter("all", stateFilter)
                            : false
                        }
                      >
                        {t("VIEW_AREA.COMPONENT_DEVICES_CONTAINER_FILTER_OPTIONS.ALL")}
                      </span>
                    </div>
                    <div
                      className={`__filter_status_title__ ${
                        stateFilter?.on
                          ? "__filter_status_title_on_true__"
                          : "__filter_status_title_on_false__"
                      }`}
                      onClick={
                        permissionsRoleUser?.permissionsRole?.AreaDispMan
                          ? () => handleFilter("on", stateFilter)
                          : false
                      }
                    >
                      <span>
                        {t("VIEW_AREA.COMPONENT_DEVICES_CONTAINER_FILTER_OPTIONS.ONLINE")}
                      </span>
                    </div>
                    <div
                      className={`__filter_status_title__ ${
                        stateFilter?.off
                          ? "__filter_status_title_off_true__"
                          : "__filter_status_title_off_false__"
                      }`}
                    >
                      <span
                        onClick={
                          permissionsRoleUser?.permissionsRole?.AreaDispMan
                            ? () => handleFilter("off", stateFilter)
                            : false
                        }
                      >
                        {t("VIEW_AREA.COMPONENT_DEVICES_CONTAINER_FILTER_OPTIONS.FAILED")}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='__devices_container_report__'>
                  <div className='_devices_report'>
                    <span className='_report_select'>
                      <select
                        name='report'
                        id=''
                        className='_reprot_select_option'
                        onChange={
                          permissionsRoleUser?.permissionsRole?.AreaDispMan
                            ? (e) => handleOpenReports(e)
                            : false
                        }
                      >
                        <option value='ocupation'>
                          {t("VIEW_AREA.COMPONENT_REPORT_SELECT.OCUPATION")}
                        </option>
                        {listMacromeasureOption ? (
                          <option value='measurer'>
                            {t("VIEW_AREA.COMPONENT_REPORT_SELECT.MEASURERS")}
                          </option>
                        ) : null}
                        <option value='temperature'>
                          {t("VIEW_AREA.COMPONENT_REPORT_SELECT.TEMPERATURE")}
                        </option>
                      </select>
                    </span>
                    <span className='_report_ver_container'>
                      <button
                        type='text'
                        className='_report_ver'
                        onClick={
                          permissionsRoleUser?.permissionsRole?.AreaRoaVer
                            ? () => {
                                handleClickOpen();
                              }
                            : false
                        }
                      >
                        <i class='fas fa-eye'></i>
                      </button>
                    </span>
                  </div>
                </div>
                <div className='__devices_container_count_devices__'>
                  <span className='_count_container'>
                    <span>
                      <i class='fas fa-desktop'></i>
                    </span>{" "}
                    <span className='_count_devices'>
                      {calc_device_fails(dataDevice?.getAllDevices?.Device)}/
                      <span className='_count_devices_fails'>
                        {devices_fails(dataDevice?.getAllDevices?.Device)}
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {dataDevice?.getAllDevices?.Device.length != 0 && (
            <div className='_container_devices_all'>
              <>
                <>
                  {pluguin?.length != 0 && (
                    <>
                      {pluguin[0]?.Category?.sub_category == 1 ? (
                        <HSC
                          data={device[0]}
                          item={pluguin[0]}
                          send={pluguinAction}
                          projectData={dataDevice?.getProjectByIdHeader}
                        />
                      ) : (
                        <PluguinAula
                          data={device[0]}
                          item={pluguin[0]}
                          send={pluguinAction}
                          projectData={dataDevice?.getProjectByIdHeader}
                        />
                      )}
                    </>
                  )}
                </>
                <>
                  {aire?.length !== 0 &&
                    aire
                      ?.filter(
                        (i) => i?.visible && i?.variables?.type !== "thermostatVRF"
                      )
                      ?.map((item, key) => (
                        <Weather
                          key={key}
                          gridNumber={`weather_container${key}`}
                          item={item}
                          pluggin={pluguin?.length}
                          send={aireAction}
                        />
                      ))}
                </>
                {/* Seccion VRF */}
                <>
                  {aire?.length !== 0 &&
                    aire
                      ?.filter((i) => i?.visible && i?.variables?.type == "thermostatVRF")
                      ?.map((item, key) => (
                        <VRF
                          key={key}
                          item={item}
                          pluggin={pluguin?.length}
                          gridNumber={`weather_container${key}`}
                          send={VRFActions}
                        />
                      ))}
                </>
                <>
                  {device?.map(
                    (item, key) =>
                      item?.Category?.category == 600 &&
                      item?.Category?.sub_category == 3 && (
                        <HorusMotel {...key} item={item} send={Send} />
                      )
                  )}
                </>
                {spliceDevicesData(filterNameDevices(device), 2)?.map((element, key) => (
                  <div className='_container_new_devices_all'>
                    {element?.map((secondElement) => renderDevice(secondElement, key))}
                  </div>
                ))}
              </>
            </div>
          )}
          <MessageInfo />
          <MessageError />
          <MessageSuccess />

          <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge='start'
                  color='inherit'
                  onClick={handleClose}
                  aria-label='close'
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant='h6' className={classes.title}>
                  {`${dataDevice?.getProjectByIdHeader?.name} - ${t(
                    "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.HEADER_TITLE"
                  )} ${nameHeaderOcupation}`}
                </Typography>

                {permissionsRoleUser.permissionsRole?.AreaRoaDes ? (
                  <Hidden mdDown>
                    <LoadingButton
                      //disabled={roomPdf.rooms.length == 0 || roomPdf == undefined}
                      onClick={() => childRef.current.occupationPdf()}
                      endIcon={<DownloadIcon />}
                      loading={loadingPdf}
                      loadingPosition='end'
                      color='inherit'
                    >
                      {t(
                        "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_OCUPATION_REPORT_MODAL.DOWNLOAD"
                      )}
                      <Backdrop
                        sx={{
                          color: "#fff",
                          zIndex: (theme) => theme.zIndex.drawer + 100,
                        }}
                        open={loadingPdf}
                        onClick={loadingPdf}
                      >
                        <CircularProgress color='inherit' />
                      </Backdrop>
                    </LoadingButton>
                  </Hidden>
                ) : null}
              </Toolbar>
            </AppBar>
            {/* <Occupation
              RoomId={id}
              project={dataDevice?.getProjectByIdHeader[0]}
            /> */}
            <Ocupation
              ref={childRef}
              RoomId={id}
              roomIdDatepicker={Number(id.split("-")[0])}
              project={dataDevice?.getProjectByIdHeader}
              NewRoomId={dataDevice?.getAllDevices?.NewRoomId}
              typeGateway={dataDevice?.getAllDevices?.Controller?.typeGateway}
            />
          </Dialog>

          <Dialog
            fullScreen
            open={openTemperature}
            onClose={handleCloseTemperature}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge='start'
                  color='inherit'
                  onClick={handleCloseTemperature}
                  aria-label='close'
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant='h6' className={classes.title}>
                  {`${localStorage.getItem("nameProject")} - ${t(
                    "VIEW_AREA.COMPONENT_REPORT_SELECT.COMPONENT_MODAL_TEMPERATURE.MODAL_HEADER_TITLE"
                  )} ${dataDevice?.getAllDevices?.name}`}
                </Typography>
              </Toolbar>
            </AppBar>
            <Temperature
              RoomId={id}
              project={dataDevice?.getProjectByIdHeader}
              NewRoomId={dataDevice?.getAllDevices?.NewRoomId}
              typeGateway={dataDevice?.getAllDevices?.Controller?.typeGateway}
            />
          </Dialog>

          <Dialog
            fullScreen
            open={openMeasurer}
            onClose={handleCloseMeasurer}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge='start'
                  color='inherit'
                  onClick={handleCloseMeasurer}
                  aria-label='close'
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant='h6' className={classes.title}>
                  {dataDevice?.getProjectByIdHeader?.name}
                </Typography>
              </Toolbar>
            </AppBar>
            <Measurer RoomId={id} project={dataDevice?.getProjectByIdHeader} />
          </Dialog>
        </div>
      ) : null}
    </>
  );
};

export default Devices;

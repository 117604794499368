import React, { useContext, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthContext } from '../../../context/AuthProvider';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@material-ui/core/IconButton';
import { AlertComponent } from '../../../components/roleManager/AlertComponent';

//query
import { useQuery, useMutation } from '@apollo/client';
import { UPDATE_PROJECT } from '../../../controllers/projectController';
import { GET_ALL_LOGGED_USER_PROJECT } from '../../../controllers/projectController';

//multilanguage
import { useTranslation } from 'react-i18next';

const customGreen = '#acf6ac';
const customRed = '#f19393';

const colorThemes = createTheme({
  palette: {
    success: {
      main: customGreen,
    },
    error: {
      main: customRed,
    },
  },
});

export const ModalAlertChangeProjectStatus = ({ projects, statusRefetch, openChangeProjectStatusFromToggle, setOpenChangeProjectStatusFromToggle, projectIdd, checkedToggle, setCheckedToggle }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [styleMessage, setStyleMessage] = useState({});
  const { openChangeProjectStatusModal, setOpenChangeProjectStatusModal } = useContext(AuthContext);

  const [editProjectStatus] = useMutation(UPDATE_PROJECT);

  const dataProjects = projects?.map((item) => {
    if (parseInt(projectIdd) === parseInt(item?.id)) {
      return {
        id: parseInt(item?.id),
        enabled: item?.enabled,
      };
    }
  });
 

  const filteredProject = dataProjects?.filter((i) => i !== undefined);
  filteredProject?.map((item, key) => {
    if (item.enabled) {
      item.enabled = false;
    } else {
      item.enabled = true;
    }
    return item.enabled;
  });

  function updateEnableProjects() {
    try {
      setCheckedToggle(!checkedToggle);
      editProjectStatus({
        variables: {
          id: parseInt(projectIdd),
          enabled: filteredProject[0]?.enabled,
        },
      })
        .then((resul) => {})
        .catch((err) => {});
    } catch (err) {}
    statusRefetch();
    setOpenChangeProjectStatusFromToggle(false);
  }

  const handleClose = () => {
    setOpenChangeProjectStatusFromToggle(false);
    setOpenChangeProjectStatusModal(false);
  };
  return (
    <>
      <Dialog
        open={openChangeProjectStatusModal || openChangeProjectStatusFromToggle}
        PaperProps={{
          sx: {
            borderRadius: '8px',
            width: 'auto',
            height: 'auto',
          },
        }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontFamily: 'Montserrat, sans-serif',
            backgroundColor: '#2EB142',
            color: '#FFFFFF',
            fontSize: '20px',
            marginBottom: '15px',
            lineHeight: '2.6',
            padding: '0px',
          }}
        >
          <span
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              width: '100%',
              fontFamily: 'Montserrat, sans-serif',
              fontSize: '22px',
              fontWeight: 'bold',
              paddingLeft: '45px',
            }}
          >
            {t('VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_PROJECT_TABLE.COMPONENT_CHANGE_PROJECT_STATUS.TITLE_MODAL')}
          </span>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: '#ffff' }} />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '35px',
            }}
          >
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                fontFamily: 'Montserrat, sans-serif',
                fontSize: '17px',
              }}
            >
              {t('VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_PROJECT_TABLE.COMPONENT_CHANGE_PROJECT_STATUS.DESCRIPTION')}
            </span>
            <div style={{ display: 'flex', gap: '40px' }}>
              <ThemeProvider theme={colorThemes}>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  sx={{
                    color: '#000',
                    borderRadius: '8px',
                    border: 'solid #89cf90',
                    width: 200,
                    '&:hover': { backgroundColor: '#8ae38a' },
                  }}
                  onClick={() => updateEnableProjects()}
                >
                  {t('VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_PROJECT_TABLE.COMPONENT_CHANGE_PROJECT_STATUS.BUTTON_TEXT')}
                </Button>
              </ThemeProvider>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <AlertComponent
        open={open}
        message={message}
        styleMessage={styleMessage}
      />
    </>
  );
};
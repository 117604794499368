import React, { useState, useEffect, useRef, useContext } from "react";
import { AuthContext } from "../../../../context/AuthProvider";

// Material
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

//multilanguage
import { useTranslation } from "react-i18next";

const customGreen = "#acf6ac";

const theme = createTheme({
  palette: {
    success: {
      main: customGreen,
    },
  },
});

const LightSwitchModal = ({ handleChangeSwitch, close, open }) => {
  const { t } = useTranslation();
  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        PaperProps={{
          sx: {
            display: "flex",
            borderRadius: "8px",
            width: "30%",
            height: "38%",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
            fontFamily: "Montserrat, sans-serif",
            backgroundColor: "#2EB142",
            color: "#FFFFFF",
            fontSize: "20px",
            marginBottom: "15px",
            lineHeight: "2.6",
            padding: "0px",
          }}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
              fontFamily: "Montserrat, sans-serif",
              fontSize: "22px",
              fontWeight: "bold",
              paddingLeft: "45px",
            }}
          >
            {t("VIEW_AREA.COMPONENT_CHNGING_STATUS_DEVICE_MODAL.TITLE")}
          </span>
          <IconButton onClick={close}>
            <CloseIcon sx={{ color: "#ffff" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "25px",
            padding: "20px 24px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              width: "80%",
              fontFamily: "Montserrat, sans-serif",
              fontSize: "17px",
            }}
          >
            {t("VIEW_AREA.COMPONENT_CHNGING_STATUS_DEVICE_MODAL.DESCRIPTION")}
          </span>
          <ThemeProvider theme={theme}>
            <Button
              variant="contained"
              color="success"
              onClick={handleChangeSwitch}
              sx={{
                width: "340px",
                padding: "7px",
                color: "#000",
                borderRadius: "8px",
                lineHeight: "18px",
              }}
            >
              {t("VIEW_AREA.COMPONENT_CHNGING_STATUS_DEVICE_MODAL.BUTTON")}
            </Button>
          </ThemeProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LightSwitchModal;

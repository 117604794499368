import { useState, useEffect, useRef, useContext } from "react";
import Loading_devices from "../../../../../components/loading/Loading_devices";
import fanCool from "../../../../../assets/fant.png";
import { AuthContext } from "../../../../../context/AuthProvider";
import TemperatureModal from "./TemperatureModal";
import GraphConsumptionDetailPerMonth from "../../../../../components/highcharts/MeasurerDetails/GraphConsumptionPerMonth";

//Material UI components
import MessageSuccess from "../../../../../components/message/MessageSuccess";
import MessageError from "../../../../../components/message/MessageError";
import BatteryFullIcon from "@material-ui/icons/BatteryFull";
import Battery90Icon from "@material-ui/icons/Battery90";
import Battery80Icon from "@material-ui/icons/Battery80";
import Battery60Icon from "@material-ui/icons/Battery60";
import Battery50Icon from "@material-ui/icons/Battery50";
import Battery30Icon from "@material-ui/icons/Battery30";
import Battery20Icon from "@material-ui/icons/Battery20";
import BatteryAlertIcon from "@material-ui/icons/BatteryAlert";
import InvertColorsIcon from "@material-ui/icons/InvertColors";
import { ExtractTypeGateway } from "../../../../../utils/helpers";
import GraphBatteryModal from "../../../../../components/devices/GraphBatteryLevel/GraphBatteryModal";
import Battery_Newlevel from "../Battery_Newlevel";
import alert from "../../../../../assets/alert-icon.png";

const Temperature = ({
  areaName,
  allAirs,
  dataSocket,
  sendMessage,
  serial,
  refetch,
  controllerStatus,
}) => {
  const { setSuccessMessage, setErrorMessage, permissionsRoleUser } =
    useContext(AuthContext);
  const [openModal, setOpenModal] = useState(false);
  const [cancel, setCancel] = useState(false);
  const { alias, variables, visible } = allAirs;
  const { items } = variables;
  const [thermostatState, setThermostatState] = useState("");
  const [idThermostat, setIdThermostat] = useState(null);

  const [batteryData, setBatteryData] = useState(
    variables?.items?.filter((i) => i?.name === "battery")
  );

  const [openBatteryReport, setOpenBatteryReport] = useState(false);
  const delay = 3000;
  const handleOpenBatteryReport = () => {
    setOpenBatteryReport(!openBatteryReport);
  };

  const [socketSnackbar, setSocketSnackbar] = useState(null);
  const socketInterval = useRef(null);

  const irDeviceTypeId = [
    "351_10496_28931",
    "351_10496_28929",
    "351_62766_28930",
  ];
  const centralDeviceTypeId = ["351_33026_18742"];
  const fanCoilDeviceTypeId = ["351_2065_20737", "351_2050_20737"];
  const [pointTemperature, setPointTemperature] = useState(
    variables?.items
      ?.find((e) => e?.name === "thermostat_setpoint_cooling")
      .status?.value.toFixed(0)
  );
  const sameGrade = parseInt(
    variables?.items
      ?.find((e) => e?.name === "thermostat_setpoint_cooling")
      .status?.value.toFixed(0)
  );
  const [tem, setTemp] = useState(
    variables?.items?.find((e) => e?.name === "temp")?.status?.value?.toFixed(0)
  );
  const [cool, setCool] = useState(
    variables?.items?.find((e) => (e?.status === "cool" ? true : false))
  );

  const [heats, setHeat] = useState(
    // variables?.items?.find((e) => (e?.status === "heat" ? true : false))
    items?.filter((name) => name.name === "thermostat_mode")[0]?.status ===
      "heat"
      ? true
      : false
  );
  const [fanCoolState, setFanCoolState] = useState(
    variables?.items?.find((e) => e?.name === "thermostat_fan_mode")?.status
  );
  const [fanCooling, setFanCooling] = useState(false);
  const [load, setLoad] = useState(false);
  const [variablesAirs, setVariablesAirs] = useState({
    temId: {
      item_id: items?.find((e) => e?.name === "temp")?.item_id,
      value: items?.find((e) => e?.name === "temp")?.status?.value?.toFixed(0),
    },
    coolId: {
      item_id: items?.find((e) => e?.name === "thermostat_mode")?.item_id,
      value: items?.find((e) => e?.name === "thermostat_mode")?.status,
    },
    pointTemperatureId: {
      item_id: items?.find((e) => e?.name === "thermostat_setpoint_cooling")
        ?.item_id,
      value: items
        ?.find((e) => e?.name === "thermostat_setpoint_cooling")
        ?.status?.value?.toFixed(0),
    },
    fanMode: {
      item_id: items?.find((e) => e?.name === "thermostat_fan_mode")?.item_id,
      value: items?.find((e) => e?.name === "thermostat_fan_mode")?.status,
    },
    humidity: {
      item_id: items?.find((value) => value.name === "humidity")?.item_id,
      value: items?.find((value) => value.name === "humidity")?.status?.value,
    },
  });

  const [previousModeThermostatState, setPreviousModeThermostatState] =
    useState({
      thermostatMode: {
        item_id: variablesAirs?.coolId?.item_id,
        status: variablesAirs?.coolId?.value,
      },
      setpointTemperature: {
        item_id: variablesAirs?.pointTemperatureId?.item_id,
        status: variablesAirs?.pointTemperatureId?.value,
      },
      fanMode: {
        item_id: variablesAirs?.fanMode?.item_id,
        status: variablesAirs?.fanMode?.value,
      },
    });

  {
    /*
    comentado el día 2/07/2024 por posible no uso de eta condición, si de aquí al 2 de agosto no se daña  nada, borrar condición
     if (dataSocket?.itemId === variablesAirs?.fanMode?.item_id) {} 
    */
  }

  const handleThermostatModel = () => {
    if (irDeviceTypeId.includes(variables?.deviceTypeId)) {
      const fanSpeeds = ["low", "medium", "high", "auto_low"];
      return { typeThermostat: "ir", speeds: fanSpeeds };
    } else if (fanCoilDeviceTypeId.includes(variables?.deviceTypeId)) {
      const fanCoilSpeeds = ["low", "medium", "high"];
      return {
        typeThermostat: "fanCoil",
        speeds: fanCoilSpeeds,
      };
    } else if (centralDeviceTypeId.includes(variables?.deviceTypeId)) {
      const centralFanSpeeds = ["low", "auto_low"];
      return {
        typeThermostat: "central",
        speeds: centralFanSpeeds,
      };
    }
  };

  const fanState = (data) => {
    if (handleThermostatModel()?.typeThermostat === "ir") {
      if (data === "medium") {
        return "Medium";
      } else if (data === "high") {
        return "High";
      } else if (data === "low") {
        return "Low";
      } else if (data === "auto_low") {
        return "Auto";
      }
    } else if (handleThermostatModel()?.typeThermostat === "central") {
      if (data === "auto_low") {
        return "Auto";
      } else if (data === "low") {
        return "On";
      }
    } else if (handleThermostatModel()?.typeThermostat === "fanCoil") {
      if (data === "low") {
        return "Low";
      } else if (data === "medium") {
        return "Medium";
      } else if (data === "high") {
        return "High";
      }
    }
  };

  const lastUpdateTemperature = useRef(parseInt(pointTemperature));
  const [fanPosition, setFanPosition] = useState(
    handleThermostatModel()?.speeds?.indexOf(variablesAirs?.fanMode?.value)
  );

  useEffect(() => {
    if (dataSocket?.category === "hvac") {
      const updateTemperature = (itemName, setStateFunction) => {
        const item = variables?.items?.find(
          (e) => e?.item_id === dataSocket?.itemId && e?.name === itemName
        );
        if (item && dataSocket?.state) {
          if (dataSocket?.state !== item?.status?.value) {
            if (typeof dataSocket?.state === "string") {
              if (dataSocket?.state === "cool") {
                setCool(true);
                setHeat(false);
              }
              if (dataSocket.state === "off") {
                setCool(false);
                setHeat(false);
              }
              if (dataSocket.state === "heat") {
                setHeat(true);
                setCool(false);
              }
              if (
                dataSocket?.state === "low" ||
                dataSocket?.state === "medium" ||
                dataSocket?.state === "high" ||
                dataSocket?.state === "auto_low"
              ) {
                setFanCoolState(dataSocket?.state);
                setFanPosition(
                  handleThermostatModel()?.speeds?.indexOf(dataSocket?.state)
                );
              }
            }
            if (typeof dataSocket?.state === "number") {
              setStateFunction(dataSocket?.state?.toFixed(0));
            }
          }
        }
      };
      const idThermostat = variables?.items?.find(
        (e) => e?.item_id === dataSocket?.itemId
      );
      updateTemperature("thermostat_setpoint_cooling", setPointTemperature);
      updateTemperature("thermostat_mode", setCool);
      updateTemperature("thermostat_fan_mode", setFanCoolState);
      updateTemperature("temp", setTemp);
      if (dataSocket?.event_name === "humidity" && idThermostat) {
        setVariablesAirs({
          ...variablesAirs,
          humidity: { value: dataSocket?.state },
        });
      }
    }
  }, [dataSocket]);

  useEffect(() => {
    if (cancel) {
      setPointTemperature(parseInt(sameGrade));
      lastUpdateTemperature.current = sameGrade;
      refetch();
    }
  }, [cancel, sameGrade]);

  useEffect(() => {
    let eventName =
      (dataSocket?.event_name === "thermostat_mode" ||
        dataSocket?.event_name === "thermostat_setpoint_cooling" ||
        dataSocket?.event_name === "thermostat_fan_mode" ||
        dataSocket?.event_name === "thermostat_setpoint_heating") &&
      dataSocket?.event_name !== "temp" &&
      dataSocket?.event_name !== "humidity" &&
      dataSocket?.event_name !== "switch";
    let state = dataSocket?.state;
    if (
      dataSocket?.itemId === variablesAirs?.coolId?.item_id &&
      thermostatState === "cool" &&
      eventName === true &&
      state === "cool"
    ) {
      clearInterval(socketInterval.current);
      setSocketSnackbar(true);
      setPreviousModeThermostatState({
        ...previousModeThermostatState,
        thermostatMode: { item_id: dataSocket?.itemId, status: state },
      });
    } else if (
      dataSocket?.itemId === variablesAirs?.coolId?.item_id &&
      thermostatState === "heat" &&
      eventName === true &&
      state === "heat"
    ) {
      clearInterval(socketInterval.current);
      setSocketSnackbar(true);
      setPreviousModeThermostatState({
        ...previousModeThermostatState,
        thermostatMode: { item_id: dataSocket?.itemId, status: state },
      });
    } else if (
      dataSocket?.itemId === variablesAirs?.coolId?.item_id &&
      thermostatState === "off" &&
      eventName === true &&
      state === "off"
    ) {
      clearInterval(socketInterval.current);
      setSocketSnackbar(true);
      setPreviousModeThermostatState({
        ...previousModeThermostatState,
        thermostatMode: { item_id: dataSocket?.itemId, status: state },
      });
    } else if (
      dataSocket?.itemId === variablesAirs?.pointTemperatureId?.item_id &&
      thermostatState === "increase" &&
      // eventName === true &&
      state === pointTemperature
    ) {
      clearInterval(socketInterval.current);
      setSocketSnackbar(true);
      setPreviousModeThermostatState({
        ...previousModeThermostatState,
        setpointTemperature: { item_id: dataSocket?.itemId, status: state },
      });
    } else if (
      dataSocket?.itemId === variablesAirs?.pointTemperatureId?.item_id &&
      thermostatState === "decrease" &&
      // eventName === true &&
      state === pointTemperature
    ) {
      clearInterval(socketInterval.current);
      setSocketSnackbar(true);
      setPreviousModeThermostatState({
        ...previousModeThermostatState,
        setpointTemperature: { item_id: dataSocket?.itemId, status: state },
      });
    } else if (
      dataSocket?.itemId === variablesAirs?.fanMode?.item_id &&
      thermostatState === "fan" &&
      eventName === true
      // state === handleThermostatModel()?.speeds[fanPosition]
    ) {
      clearInterval(socketInterval.current);
      setSocketSnackbar(true);
      setPreviousModeThermostatState({
        ...previousModeThermostatState,
        fanMode: { item_id: dataSocket?.itemId, status: state },
      });
    }
  }, [dataSocket]);

  useEffect(() => {
    if (socketSnackbar) {
      setSuccessMessage(true);
    } else if (socketSnackbar === false) {
      setErrorMessage(true);

      if (
        previousModeThermostatState?.thermostatMode?.status === "cool" &&
        idThermostat === previousModeThermostatState?.thermostatMode?.item_id
      ) {
        setCool(true);
        setHeat(false);
      } else if (
        previousModeThermostatState?.thermostatMode?.status === "heat" &&
        idThermostat === previousModeThermostatState?.thermostatMode?.item_id
      ) {
        setHeat(true);
        setCool(false);
      } else if (
        previousModeThermostatState?.thermostatMode?.status === "off" &&
        idThermostat === previousModeThermostatState?.thermostatMode?.item_id
      ) {
        setCool(false);
        setHeat(false);
      } else if (
        previousModeThermostatState?.setpointTemperature?.item_id ==
        idThermostat
      ) {
        setPointTemperature(
          previousModeThermostatState?.setpointTemperature?.status
        );
      } else if (
        previousModeThermostatState?.fanMode?.item_id === idThermostat
      ) {
        setFanCoolState(previousModeThermostatState?.fanMode?.status);
        setFanPosition(
          handleThermostatModel()?.speeds?.indexOf(
            previousModeThermostatState.fanMode.status
          )
        );
      }
    }
  }, [socketSnackbar]);

  const SocketTimeValidationResponse = () => {
    clearInterval(socketInterval.current);
    let count = 0;
    if (variables?.onLine === false || controllerStatus === false) {
      setErrorMessage(true);
    } else {
      socketInterval.current = setInterval(() => {
        count++;
        if (count > 14) {
          clearInterval(socketInterval.current);
          setSocketSnackbar(null);
          setSocketSnackbar(false);
        }
      }, 1000);
    }
  };

  const on = async (e) => {
    if (variables?.onLine === true && controllerStatus === true) {
      setIdThermostat(e.target.id);
      // setOpenModal(true); // hace parte de el modal de confirmación
      // setCancel(false); // hace parte de el modal de confirmación
      setThermostatState("cool");
      // setIdThermostat(e.target.id); // hace parte de el modal de confirmación
      // setTimeout(() => { // hace parte de el modal de confirmación
      //   setOpenModal(false);
      // }, 1500);
      sendMessage({
        serial,
        channel: serial,
        id: String(e.target.id),
        eventType: "mode_updated",
        value: "cool",
      });
      SocketTimeValidationResponse();
      setLoad(true);
      setTimeout(() => {
        // if (thermostatState === "cool" && variables?.onLine === true) {
        setCool(true);
        setHeat(false);
        setSocketSnackbar(null);

        // setSuccessMessage(true);
        // } else if (thermostatState === "cool" && variables?.onLine === false) {
        // setErrorMessage(true);
        // }
        setLoad(false);
      }, delay);
    } else {
      SocketTimeValidationResponse();
      setLoad(true);
      setTimeout(() => {
        setSocketSnackbar(null);
        setLoad(false);
      }, delay);
    }
  };

  const heat = (e) => {
    if (variables?.onLine === true && controllerStatus === true) {
      setIdThermostat(e.target.id);
      // setOpenModal(true); // hace parte de el modal de confirmación
      // setCancel(false); // hace parte de el modal de confirmación
      setThermostatState("heat");
      // setIdThermostat(e.target.id);
      // setLoad(true); // hace parte de el modal de confirmación
      // setTimeout(() => { // hace parte de el modal de confirmación
      //   setOpenModal(false);
      // }, 1500);
      sendMessage({
        serial,
        channel: serial,
        id: String(e.target.id),
        eventType: "mode_updated",
        value: "heat",
      });
      setLoad(true);
      SocketTimeValidationResponse();
      setTimeout(() => {
        // if (thermostatState === "heat" && variables?.onLine === true) {
        setHeat(true);
        setCool(false);
        setSocketSnackbar(null);
        // setSuccessMessage(true);
        // } else if (thermostatState === "heat" && variables?.onLine === false) {
        // setErrorMessage(true);
        //}
        setLoad(false);
      }, delay);
    } else {
      SocketTimeValidationResponse();
      setLoad(true);
      setTimeout(() => {
        setSocketSnackbar(null);
        setLoad(false);
      }, delay);
    }
  };

  const offAir = (e) => {
    if (variables?.onLine === true && controllerStatus === true) {
      setIdThermostat(e.target.id);
      // setOpenModal(true); // hace parte de el modal de confirmación
      // setCancel(false); // hace parte de el modal de confirmación
      setThermostatState("off");
      // setIdThermostat(e.target.id);
      // setTimeout(() => { // hace parte de el modal de confirmación
      //   setOpenModal(false);
      // }, 1500);
      sendMessage({
        serial,
        channel: serial,
        id: String(e.target.id),
        eventType: "mode_updated",
        value: "off",
      });
      setLoad(true);
      SocketTimeValidationResponse();
      setTimeout(() => {
        // if (thermostatState === "off" && variables?.onLine === true) {
        setCool(false);
        setHeat(false);
        setSocketSnackbar(null);
        // setSuccessMessage(true);
        // } else if (thermostatState === "off" && variables?.onLine === false) {
        // setErrorMessage(true);
        // }
        setLoad(false);
      }, delay);
    } else {
      SocketTimeValidationResponse();
      setLoad(true);
      setTimeout(() => {
        setSocketSnackbar(null);
        setLoad(false);
      }, delay);
    }
  };

  // const fan = (e) => {
  //   setLoad(true);
  //   sendMessage({
  //     serial,
  //     channel: serial,
  //     id: String(e.target.id),
  //     eventType: "mode_updated",
  //     value: "low",
  //   });
  //   setTimeout(() => {
  //     setHeat(true);
  //     setCool(false);
  //     setLoad(false);
  //   }, delay);
  // };

  const handleUp = (e) => {
    if (variables?.onLine === true && controllerStatus === true) {
      setIdThermostat(e.target.id);
      const newTemp =
        parseInt(pointTemperature) + 1 > 30
          ? 30
          : parseInt(pointTemperature) + 1;
      setPointTemperature(newTemp);
      lastUpdateTemperature.current = newTemp;
      if (variablesAirs?.pointTemperatureId?.item_id === e.target.id) {
        setTimeout(() => {
          setLoad(true);
          // setOpenModal(true); // hace parte de el modal de confirmación
          // setCancel(false); // hace parte de el modal de confirmación
          setThermostatState("increase");
          // setIdThermostat(e.target.id); // hace parte de el modal de confirmación
          setTimeout(() => {
            sendMessage({
              serial,
              channel: serial,
              id: String(e.target.id),
              eventType: "setpoint_updated",
              value: String(lastUpdateTemperature.current),
            });
            setSocketSnackbar(null);
            SocketTimeValidationResponse();
            setLoad(false);
            // if (variables?.onLine && controllerStatus) {
            // setSuccessMessage(true);
            // } else {
            // setErrorMessage(false);
            //}
          }, delay);
        }, 2500);
      } else {
        setPointTemperature(parseInt(variablesAirs?.pointTemperatureId?.value));
        setTimeout(() => {
          setLoad(true);
        }, 2500);
        setTimeout(() => {
          setPointTemperature(
            parseInt(variablesAirs?.pointTemperatureId?.value)
          );
          setLoad(false);
        }, 3500);
      }
    } else {
      setTimeout(() => {
        setLoad(true);
        setTimeout(() => {
          setSocketSnackbar(null);
          SocketTimeValidationResponse();
          setLoad(false);
          // if (variables?.onLine && controllerStatus) {
          // setSuccessMessage(true);
          // } else {
          // setErrorMessage(false);
          //}
        }, delay);
      }, 2000);
    }
  };

  const handleDown = (e) => {
    if (variables?.onLine === true && controllerStatus === true) {
      setIdThermostat(e.target.id);
      const newTemp =
        parseInt(pointTemperature) - 1 < 16
          ? 16
          : parseInt(pointTemperature) - 1;
      setPointTemperature(newTemp);
      lastUpdateTemperature.current = newTemp;
      if (variablesAirs?.pointTemperatureId?.item_id === e.target.id) {
        setTimeout(() => {
          setLoad(true);
          // setOpenModal(true); // hace parte de el modal de confirmación
          // setCancel(false); // hace parte de el modal de confirmación
          setThermostatState("decrease");
          // setIdThermostat(e.target.id); // hace parte de el modal de confirmación
          setTimeout(() => {
            setLoad(false);
            sendMessage({
              serial,
              channel: serial,
              id: String(e.target.id),
              eventType: "setpoint_updated",
              value: String(lastUpdateTemperature.current),
            });
            setSocketSnackbar(null);
            SocketTimeValidationResponse();
            // if (variables?.onLine && controllerStatus) {
            // setSuccessMessage(true);
            // } else {
            // setErrorMessage(false);
            // }
          }, delay);
        }, 2500);
      } else {
        setPointTemperature(parseInt(variablesAirs?.pointTemperatureId?.value));
        setTimeout(() => {
          setLoad(true);
        }, 2500);
        setTimeout(() => {
          setPointTemperature(
            parseInt(variablesAirs?.pointTemperatureId?.value)
          );
          setLoad(false);
        }, 3500);
      }
    } else {
      setTimeout(() => {
        setLoad(true);
        setTimeout(() => {
          setLoad(false);
          setSocketSnackbar(null);
          SocketTimeValidationResponse();
        }, delay);
      }, 2000);
    }
  };

  const changeFan = (e) => {
    if (variables?.onLine === true && controllerStatus === true) {
      setIdThermostat(e.target.id);
      // setOpenModal(true); // hace parte de el modal de confirmación
      // setCancel(false); // hace parte de el modal de confirmación
      setThermostatState("fan");
      // setIdThermostat(e.target.id); // hace parte de el modal de confirmación
      const nextFanPosition =
        (fanPosition + 1) % handleThermostatModel()?.speeds?.length;
      const nextSpeed = handleThermostatModel()?.speeds[nextFanPosition];
      setFanCoolState(nextSpeed);
      setFanPosition(nextFanPosition);
      // setFanCooling(true);
      // setCool(true);
      sendMessage({
        serial,
        channel: serial,
        id: e.target.id,
        eventType: "fan_updated",
        value: nextSpeed,
      });
      SocketTimeValidationResponse();
      setSocketSnackbar(null);
      setLoad(true);
      setTimeout(() => {
        setLoad(false);
      }, delay);
    } else {
      SocketTimeValidationResponse();
      setSocketSnackbar(null);
      setLoad(true);
      setTimeout(() => {
        setLoad(false);
      }, delay);
    }
  };

  //  Función completa y adaptada para funcionar con el modal de confirmación,
  //  mantenerla por si se vuelve a pedir

  // const handleChangeThermostatSates = () => {
  //   if (
  //     thermostatState === "off" ||
  //     thermostatState === "cool" ||
  //     thermostatState === "heat"
  //   ) {
  //     setLoad(true);
  //     setTimeout(() => {
  //       setOpenModal(false);
  //     }, 1500);
  //     sendMessage({
  //       serial,
  //       channel: serial,
  //       id: String(idThermostat),
  //       eventType: "mode_updated",
  //       value: thermostatState,
  //     });
  //     setTimeout(() => {
  //       if (thermostatState === "off" && variables?.onLine === true) {
  //         setCool(false);
  //         setHeat(false);
  //         setLoad(false);
  //         setSuccessMessage(true);
  //       } else if (thermostatState === "cool" && variables?.onLine === true) {
  //         setCool(true);
  //         setHeat(false);
  //         setLoad(false);
  //         setSuccessMessage(true);
  //       } else if (thermostatState === "heat" && variables?.onLine === true) {
  //         setHeat(true);
  //         setCool(false);
  //         setLoad(false);
  //         setSuccessMessage(true);
  //       } else if (
  //         thermostatState === "off" ||
  //         thermostatState === "cool" ||
  //         (thermostatState === "heat" && variables?.onLine === false)
  //       ) {
  //         setErrorMessage(true);
  //       }
  //     }, delay);
  //   } else if (
  //     thermostatState === "increase" ||
  //     thermostatState === "decrease"
  //   ) {
  //     if (variablesAirs?.pointTemperatureId?.item_id === idThermostat) {
  //       setTimeout(() => {
  //         setLoad(true);
  //         setOpenModal(false);
  //         setTimeout(() => {
  //           setLoad(false);
  //           sendMessage({
  //             serial,
  //             channel: serial,
  //             id: idThermostat,
  //             eventType: "setpoint_updated",
  //             value: String(lastUpdateTemperature.current),
  //           });
  //           if (variables?.onLine && controllerStatus) {
  //             setSuccessMessage(true);
  //           } else {
  //             setErrorMessage(false);
  //           }
  //         }, delay);
  //       }, delay);
  //     } else {
  //       setPointTemperature(parseInt(variablesAirs?.pointTemperatureId?.value));
  //       setTimeout(() => {
  //         setLoad(true);
  //       }, delay);
  //       setTimeout(() => {
  //         setPointTemperature(
  //           parseInt(variablesAirs?.pointTemperatureId?.value)
  //         );
  //         setLoad(false);
  //       }, 3500);
  //     }
  //   } else if (thermostatState === "fan") {
  //     if (handleThermostatModel()?.typeThermostat === "ir") {
  //       const nextFanPosition =
  //         (fanPosition + 1) % handleThermostatModel()?.speeds?.length;
  //       const nextSpeed = handleThermostatModel()?.speeds[nextFanPosition];
  //       setFanCoolState(nextSpeed);
  //       setFanPosition(nextFanPosition);
  //       setFanCooling(true);
  //       setCool(true);
  //       setTimeout(() => {
  //         setLoad(true);
  //         setOpenModal(false);
  //         setTimeout(() => {
  //           setLoad(false);
  //           sendMessage({
  //             serial,
  //             channel: serial,
  //             id: idThermostat,
  //             eventType: "fan_updated",
  //             value: nextSpeed,
  //           });
  //         }, delay);
  //       }, delay);
  //     } else if (handleThermostatModel()?.typeThermostat === "central") {
  //       const nextFanPosition =
  //         (fanPosition + 1) % handleThermostatModel()?.speeds?.length;
  //       const nextSpeed = handleThermostatModel()?.speeds[nextFanPosition];
  //       setFanCoolState(nextSpeed);
  //       setFanPosition(nextFanPosition);
  //       setFanCooling(true);
  //       setCool(true);
  //       setTimeout(() => {
  //         setLoad(true);
  //         setOpenModal(false);
  //         setTimeout(() => {
  //           setLoad(false);
  //           sendMessage({
  //             serial,
  //             channel: serial,
  //             id: idThermostat,
  //             eventType: "fan_updated",
  //             value: nextSpeed,
  //           });
  //         }, delay);
  //       }, delay);
  //     } else if (handleThermostatModel()?.typeThermostat === "fanCoil") {
  //       const nextFanPosition =
  //         (fanPosition + 1) % handleThermostatModel()?.speeds?.length;
  //       const nextSpeed = handleThermostatModel()?.speeds[nextFanPosition];
  //       setFanCoolState(nextSpeed);
  //       setFanPosition(nextFanPosition);
  //       setFanCooling(true);
  //       setCool(true);
  //       setTimeout(() => {
  //         setLoad(true);
  //         setOpenModal(false);
  //         setTimeout(() => {
  //           setLoad(false);
  //           sendMessage({
  //             serial,
  //             channel: serial,
  //             id: idThermostat,
  //             eventType: "fan_updated",
  //             value: nextSpeed,
  //           });
  //         }, delay);
  //       }, delay);
  //     }
  //   }
  // };

  if (allAirs) {
    return (
      <>
        <div className={`_weather_container_new`}>
          <div
            className={`_weather_container_title_status_new ${
              variables.onLine && controllerStatus
                ? null
                : "_weather_offline_new"
            }`}
          >
            <div className="_weather__title_new">
              <span>{alias}</span>
            </div>
            {batteryData[0]?.length !== 0 &&
            batteryData[0]?.length === undefined ? (
              <div className="__battery_new" onClick={handleOpenBatteryReport}>
                <div className="__percent_new">
                  {batteryData[0] && <span>{batteryData[0]?.status}%</span>}
                </div>
                <span
                  className={
                    !batteryData[0]?.status
                      ? "__icon_battery_sensor_unknown_new"
                      : batteryData[0]?.status <= 60
                      ? "__icon_battery_sensor_unknown_new"
                      : batteryData[0]?.status <= 10
                      ? "__icon_battery_sensor_alert"
                      : "__icon_battery_sensor_new"
                  }
                >
                  {!batteryData[0]?.status ? null : batteryData[0]?.status >
                    90 ? (
                    <BatteryFullIcon />
                  ) : batteryData[0]?.status > 80 ? (
                    <Battery90Icon />
                  ) : batteryData[0]?.status > 70 ? (
                    <Battery80Icon />
                  ) : batteryData[0]?.status > 50 ? (
                    <Battery60Icon />
                  ) : batteryData[0]?.status > 40 ? (
                    <Battery50Icon />
                  ) : batteryData[0]?.status > 20 ? (
                    <Battery30Icon />
                  ) : batteryData[0]?.status > 10 ? (
                    <Battery20Icon />
                  ) : (
                    <BatteryAlertIcon />
                  )}
                </span>
              </div>
            ) : null}
            <div className="_weather__status_new">
              <span
                className={
                  variables.onLine && controllerStatus
                    ? `${"__icon_wifi_online"}`
                    : `${"__icon_wifi_offline"}`
                }
              >
                <i class="fas fa-wifi"></i>
              </span>
            </div>
          </div>

          <div
            className={`_weather_container_temperature_new ${
              load ? "__loading_weather_other_new" : false
            }`}
          >
            <div
              className={`${
                cool || heats || fanCooling
                  ? ""
                  : "__weather_temperatur_off_new"
              } _weather_temperature_new`}
            >
              <span
                className={`${
                  cool || heats || fanCooling ? "" : "__temperatur_off_new"
                } __temperature_new`}
              >
                {pointTemperature}
                <span
                  style={{
                    display: "flex",
                    fontSize: "35px",
                    alignItems: "start",
                    marginTop: "10px",
                  }}
                >
                  °c
                </span>
              </span>
              <div className="_indoor_weather_new">
                <span className="__indoor_new">
                  <span
                    style={{
                      display: "flex",
                      fontSize: "18px",
                    }}
                  >
                    <InvertColorsIcon />
                    {variablesAirs?.humidity?.value}
                    {"%"}
                  </span>

                  <span
                    style={{
                      display: "flex",
                      fontSize: "20px",
                    }}
                  >
                    {tem}
                    °c
                  </span>
                </span>
              </div>
            </div>

            <div
              className="__container_fan_new"
              id={variablesAirs?.fanMode?.item_id}
            >
              <div className="fan_and_velocity_container">
                <div className="__color_new">
                  <img
                    className={
                      fanCoolState === "high"
                        ? "prueba"
                        : fanCoolState === "medium"
                        ? "prueba2"
                        : "prueba3"
                    }
                    src={fanCool}
                    onClick={
                      permissionsRoleUser?.permissionsRole?.AreaDispMan
                        ? changeFan
                        : false
                    }
                    id={variablesAirs?.fanMode?.item_id}
                    alt="fan"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {fanCoolState === "auto_low" ? (
                    <>
                      {handleThermostatModel()?.typeThermostat === "ir" ? (
                        <>
                          <div
                            className={`icon_bar ${
                              fanCoolState === "auto_low" ||
                              fanCoolState === "medium" ||
                              fanCoolState === "high"
                                ? "low_now"
                                : "bar1"
                            }`}
                          ></div>
                          <div
                            className={`icon_bar ${
                              fanCoolState === "auto_low" ||
                              fanCoolState === "high"
                                ? "medium_now"
                                : "bar2"
                            }`}
                          ></div>
                          <div
                            className={`icon_bar ${
                              fanCoolState === "auto_low" ? "high_now" : "bar3"
                            }`}
                          ></div>
                        </>
                      ) : (
                        <>
                          <div
                            className={`icon_bar ${
                              fanCoolState === "auto_low" ? "low_now" : "bar1"
                            }`}
                          ></div>
                          <div
                            className={`icon_bar ${
                              fanCoolState === "auto_low"
                                ? "medium_now"
                                : "bar2"
                            }`}
                          ></div>
                          <div
                            className={`icon_bar ${
                              fanCoolState === "auto_low" ? "high_now" : "bar3"
                            }`}
                          ></div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {(handleThermostatModel()?.typeThermostat === "ir" ||
                        handleThermostatModel()?.typeThermostat ===
                          "fanCoil") &&
                      thermostatState !== "off" ? (
                        <>
                          <div
                            className={`icon_bar ${
                              fanCoolState === "low" ||
                              fanCoolState === "medium" ||
                              fanCoolState === "high"
                                ? "low"
                                : "bar1"
                            }`}
                          ></div>
                          <div
                            className={`icon_bar ${
                              fanCoolState === "medium" ||
                              fanCoolState === "high"
                                ? "medium"
                                : "bar2"
                            }`}
                          ></div>
                          <div
                            className={`icon_bar ${
                              fanCoolState === "high" ? "high" : "bar3"
                            }`}
                          ></div>
                        </>
                      ) : handleThermostatModel()?.typeThermostat ===
                        "central" ? (
                        <>
                          <div
                            className={`icon_bar ${
                              fanCoolState === "low" ||
                              fanCoolState === "medium" ||
                              fanCoolState === "high"
                                ? "low"
                                : "bar1"
                            }`}
                          ></div>
                          <div
                            className={`icon_bar ${
                              fanCoolState === "low" ||
                              fanCoolState === "medium" ||
                              fanCoolState === "high"
                                ? "medium"
                                : "bar2"
                            }`}
                          ></div>
                          <div
                            className={`icon_bar ${
                              fanCoolState === "low" ||
                              fanCoolState === "medium" ||
                              fanCoolState === "high"
                                ? "high"
                                : "bar3"
                            }`}
                          ></div>
                        </>
                      ) : thermostatState === "off" ? (
                        <>
                          <div
                            className={`icon_bar ${
                              fanCoolState === "low" ||
                              fanCoolState === "medium" ||
                              fanCoolState === "high"
                                ? "bar1"
                                : "low"
                            }`}
                          ></div>
                          <div
                            className={`icon_bar ${
                              fanCoolState === "low" ||
                              fanCoolState === "medium" ||
                              fanCoolState === "high"
                                ? "bar2"
                                : "medium"
                            }`}
                          ></div>
                          <div
                            className={`icon_bar ${
                              fanCoolState === "low" ||
                              fanCoolState === "medium" ||
                              fanCoolState === "high"
                                ? "bar3"
                                : "high"
                            }`}
                          ></div>
                        </>
                      ) : null}
                    </>
                  )}
                </div>
              </div>

              <div
                className={`_indoor_weather_new ${
                  load ? "__loading_weather_other_new" : false
                }`}
              >
                <span className="__indoor_new_name">
                  {fanState(fanCoolState)}
                </span>
              </div>
            </div>
          </div>

          <div className="__loading_weather">
            {load ? <Loading_devices /> : false}
          </div>

          {/* status */}
          <div
            className={`_weather_container_mode_new ${
              load ? "__loading_weather_other_new" : false
            }`}
          >
            <div className="_weather_container_up_down_new">
              <div
                className="_weather_btn_up_down_new"
                id={variablesAirs?.pointTemperatureId?.item_id}
                onClick={(e) =>
                  permissionsRoleUser?.permissionsRole?.AreaDispMan &&
                  heats !== true
                    ? handleUp(e)
                    : false
                }
              >
                <span
                  id={variablesAirs?.pointTemperatureId?.item_id}
                  className="__btn_up_new"
                >
                  <i
                    id={variablesAirs?.pointTemperatureId?.item_id}
                    class="fas fa-chevron-up"
                  ></i>
                </span>
              </div>
              <div
                id={variablesAirs?.pointTemperatureId?.item_id}
                className="_weather_btn_up_down_new"
                onClick={(e) =>
                  permissionsRoleUser?.permissionsRole?.AreaDispMan &&
                  heats !== true
                    ? handleDown(e)
                    : false
                }
              >
                <span
                  id={variablesAirs?.pointTemperatureId?.item_id}
                  className="__btn_down_new"
                >
                  <i
                    id={variablesAirs?.pointTemperatureId?.item_id}
                    class="fas fa-chevron-down"
                  ></i>
                </span>
              </div>
            </div>
            <div className="container_options_new">
              <div
                id={variablesAirs?.coolId?.item_id}
                className={
                  cool ? "_weather_mode_cool_bg_new" : "_weather_mode_cool_new"
                }
                onClick={(event) =>
                  permissionsRoleUser?.permissionsRole?.AreaDispMan
                    ? on(event)
                    : false
                }
              >
                COOL
              </div>
              <div
                id={variablesAirs?.coolId?.item_id}
                className={
                  heats ? "_weather_mode_heat_bg_new" : "_weather_mode_heat_new"
                }
                onClick={(e) =>
                  permissionsRoleUser?.permissionsRole?.AreaDispMan
                    ? heat(e)
                    : false
                }
              >
                HEAT
              </div>
              <div id={""} className={"_weather_mode_auto_new"}>
                AUTO
              </div>
              <div
                id={variablesAirs?.coolId?.item_id}
                className={
                  cool || heats || fanCooling
                    ? "_weather_mode_off_new"
                    : "_weather_mode_off_bg_new"
                }
                onClick={(e) =>
                  permissionsRoleUser?.permissionsRole?.AreaDispMan
                    ? offAir(e)
                    : false
                }
              >
                OFF
              </div>
            </div>
          </div>
        </div>
        {/* 
        Se comenta modal de confirmación, mantenerlo por si lo vuelven a pedir         
        <TemperatureModal
          open={openModal}
          setOpen={setOpenModal}
          setCancel={setCancel}
          ChangeSates={handleChangeThermostatSates}
        /> */}
        <MessageSuccess />
        <MessageError />
        <GraphBatteryModal
          areaName={areaName}
          open={openBatteryReport}
          name={alias}
          handleClose={handleOpenBatteryReport}
          id={batteryData[0]?.item_id}
          typeGateway={ExtractTypeGateway()}
        />
      </>
    );
  }
};

export default Temperature;

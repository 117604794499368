import { useState, useEffect, useContext } from "react";
import { Devices } from "../../../../../constants/devices_types";
import { AuthContext } from "../../../../../context/AuthProvider";
import Loading_devices from "../../../../../components/loading/Loading_devices";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { green } from "@material-ui/core/colors";
import BatteryUnknownIcon from "@material-ui/icons/BatteryUnknown";
import BatteryFullIcon from "@material-ui/icons/BatteryFull";
import Battery90Icon from "@material-ui/icons/Battery90";
import Battery80Icon from "@material-ui/icons/Battery80";
import Battery60Icon from "@material-ui/icons/Battery60";
import Battery50Icon from "@material-ui/icons/Battery50";
import Battery30Icon from "@material-ui/icons/Battery30";
import Battery20Icon from "@material-ui/icons/Battery20";
import BatteryAlertIcon from "@material-ui/icons/BatteryAlert";
import alert from "../../../../../assets/alert-icon.png";
import Battery_Newlevel from "../Battery_Newlevel";

//multilanguage
import { useTranslation } from "react-i18next";

const innerTheme = createTheme({
  palette: {
    secondary: {
      main: green[500],
    },
  },
});

const NewMotionSensor = ({ sensor, serial, dataSocket, controllerStatus }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { alias, variables, Category, name } = sensor;
  const { category, sub_category } = Category;
  const { status, type, items, onLine } = variables;
  const [armed, setArmed] = useState(false);
  const [motionSensor, setMotionSensor] = useState(
    items?.find((e) => e?.name === "motion")?.status
  );
  const [battery, setBattery] = useState({
    itemId_Battery: "",
    percentageBattery: "",
  });
  const { permissionsRoleUser } = useContext(AuthContext);

  useEffect(() => {
    if (
      dataSocket.category === category &&
      sub_category === "motion" &&
      dataSocket.event_name === "motion"
    ) {
      const item = items?.find((e) => e.item_id === dataSocket.itemId);
      if (item) {
        setMotionSensor(dataSocket.state);
      }
    }

    const batteryVariable = variables.items.find(
      (item) => item.name === "battery"
    );
    if (batteryVariable) {
      setBattery({
        itemId_Battery: batteryVariable.item_id,
        percentageBattery: batteryVariable.status,
      });
    }
  }, [dataSocket]);

  const handleChangesArmed = () => {
    setArmed(!armed);
  };

  const handleCloseBattery = () => {
    setOpen(!open);
  };
  return (
    <>
      <div className="_generic_container">
        <div
          className={`_generics_container_title_status ${
            onLine && controllerStatus ? null : "__status_comfailure__"
          }`}
        >
          <div className="_generic__title">
            <span>{alias}</span>
          </div>
          <div
            className={`${
              !battery.percentageBattery
                ? "_generic__status__alert"
                : "__generic_sensor_wifi"
            }`}
          >
            {!alias ? (
              <span className="__icon_alert_sensor">
                <img src={alert} alt="alert" width="22" height="22" />
              </span>
            ) : (
              <>
                {battery.percentageBattery && (
                  <div
                    className="_battery__status"
                    onClick={handleCloseBattery}
                  >
                    <div className="__percent">
                      <span>{battery.percentageBattery}%</span>
                    </div>
                    <div className="__percent">
                      {battery.percentageBattery && (
                        <span>{battery.percentageBattery}%</span>
                      )}
                    </div>
                    <div className="__battery">
                      <span
                        className={
                          !battery.percentageBattery
                            ? "__icon_battery_sensor_unknown"
                            : battery.percentageBattery <= 60
                            ? "__icon_battery_sensor_unknown"
                            : battery.percentageBattery <= 10
                            ? "__icon_battery_sensor_alert"
                            : "__icon_battery_sensor"
                        }
                      >
                        {!battery.percentageBattery ? (
                          <BatteryUnknownIcon />
                        ) : battery.percentageBattery > 90 ? (
                          <BatteryFullIcon />
                        ) : battery.percentageBattery > 80 ? (
                          <Battery90Icon />
                        ) : battery.percentageBattery > 70 ? (
                          <Battery80Icon />
                        ) : battery.percentageBattery > 50 ? (
                          <Battery60Icon />
                        ) : battery.percentageBattery > 40 ? (
                          <Battery50Icon />
                        ) : battery.percentageBattery > 20 ? (
                          <Battery30Icon />
                        ) : battery.percentageBattery > 10 ? (
                          <Battery20Icon />
                        ) : (
                          <BatteryAlertIcon />
                        )}
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="_generic_container_clasification">
          <div
            className={`_generic_clasification ${
              "" ? "__loading_clasification" : false
            }`}
          >
            <span>
              <img
                src={
                  motionSensor
                    ? Devices.MOTION_SENSOR_TRIPPED
                    : Devices.MOTION_SENSOR
                }
                alt="clasification"
                width="60"
                height="60"
              />
            </span>
          </div>
          <div className="__loading_devices">
            {!alias ? <Loading_devices /> : false}
          </div>
          <div
            className={`_generic_toggle row-decoration ${
              !alias ? "__loading_toogle" : false
            }`}
          >
            <div>
              <ThemeProvider theme={innerTheme}>
                <Switch
                  size="medium"
                  checked={armed}
                  onChange={
                    permissionsRoleUser?.permissionsRole?.AreaDispMan
                      ? handleChangesArmed
                      : false
                  }
                />
              </ThemeProvider>
              <br></br>
              {!armed ? (
                <span>
                  {t("VIEW_AREA.COMPONENT_MOVEMENT_SENSOR.ASSEMBLED")}
                </span>
              ) : (
                <span>
                  {t("VIEW_AREA.COMPONENT_MOVEMENT_SENSOR.DISASSEMBLED")}
                </span>
              )}
            </div>
          </div>
        </div>
        <Battery_Newlevel
          id_Battery={battery.itemId_Battery}
          handleCloseBattery={handleCloseBattery}
          name={alias}
          openBattery={open}
        />
      </div>
    </>
  );
};

export default NewMotionSensor;

import React, {useEffect} from "react";

export default function Loading() {

  // useEffect(() => {
  //   window.scroll({
  //     top: 0,
  //   });
  // }, []);

  return (
    <div>
      <lottie-player
        style={{
          marginRight: "auto",
          marginLeft: "auto",
          marginTop: "250px",
          width: "250px"
        }}
        className="_logo"
        src="https://lottie.host/75ed1eab-8061-456e-aa4b-a8fec1db6dbb/DyHOsxKydD.json"
        background="transparent"
        speed="1"
        loop
        autoplay
      ></lottie-player>
    </div>
  );
}

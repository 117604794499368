import { useQuery } from "@apollo/client";
import { GET_ALL_USER_PROJECT } from "../../../../../controllers/projectController";
import { useState } from "react";

export const useProjectUser = () => {
 
  const [dataProject, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useQuery(GET_ALL_USER_PROJECT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setData(data?.getAllProjectsByUser?.Projects);
    },
    onError: (error) => {
      setLoading(false);
      setError(error);
    }
  });
  

  const projectsNameId = dataProject?.map(i => ({
    id: String(i.id),
    name: i.name,
    img : i.img,
    country : i.country,
    state : i.state,
    city : i.city, 
    enabled: i.enabled
  }))

  return { projectsNameId };
};
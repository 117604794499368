import React from "react";
import StepperView from "../../../components/stepperView/stepperView";
import Info from "../../../components/stepperView/components/Info";

const StepperViewMacrometer = () => {
  return (
    <StepperView/>
      )
};

export default StepperViewMacrometer;

import React from 'react'
import { Devices } from '../../constants/devices_types'

//multilanguage
import { useTranslation } from "react-i18next";

const DevicesFails = ({ device }) => {
    const { t } = useTranslation();
    const img_devices_fails = {
        "sensor_de_movimiento": Devices.MOTION_SENSOR,
        "interruptor": Devices.BINARY_LIGHT_OFF,
        "termostato": Devices.THERMOSTAT_DEVICE_DEFAULT,
        "sensor_de_puerta": Devices.DOOR_WINDOW,
        "bloqueo_de_puerta": Devices.DOOR_DESBLOQUEADO,
        "medidor_de_energia": Devices.METER_DEFAULT
    }

    const __img__ = device.Category.name.toLowerCase().replace(/\s/g, "_")

    // console.log(device.Category.name)

    const result_img = img_devices_fails[__img__]

    const translatingDevicesCategories = () => {
        if(device.Category.name === "Sensor De Puerta"){
            return t("VIEW_AREAS.COMPONENT_AREA_CARD.COMPONENT_DEVICE_MODAL_FAILURE.DOOR_SENSOR")
        }else if (device.Category.name === "Termostato"){
            return t("VIEW_AREAS.COMPONENT_AREA_CARD.COMPONENT_DEVICE_MODAL_FAILURE.THERMOSTAT")
        }else if (device.Category.name === "Interruptor"){
            return t("VIEW_AREAS.COMPONENT_AREA_CARD.COMPONENT_DEVICE_MODAL_FAILURE.SWITCH")
        }else if(device.Category.name === "Sensor De Movimiento"){
            return t("VIEW_AREAS.COMPONENT_AREA_CARD.COMPONENT_DEVICE_MODAL_FAILURE.MOVEMENT_SENSOR")
        }else if (device.Category.name === "Bloqueo De Puerta"){
            return t("VIEW_AREAS.COMPONENT_AREA_CARD.COMPONENT_DEVICE_MODAL_FAILURE.LOCK_DOOR")
        }else if (device.Category.name === "Medidor De Energia"){
            return t("VIEW_AREAS.COMPONENT_AREA_CARD.COMPONENT_DEVICE_MODAL_FAILURE.ENERGY_MEASURER")
        }else{
            return null
        }
    }
    
    return (
        <div className="__device_fails__">
            <div className="__img_device_fails__">
                <img
                    src={`${result_img}`}
                    alt="clasification"
                    width="60"
                    height="60"
                />
            </div>
            <div className="__device_fails_names_container__">
                <div className="__device_fails_name__">{translatingDevicesCategories()}</div>
                <div className="__description_device_fails">{device.alias}</div>
            </div>
        </div>
    )
}

export default DevicesFails

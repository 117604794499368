import React, { useContext, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { secondClient } from '../../../index';
import { useQuery } from '@apollo/client';
import { GET_FREQUENCY_TEST } from '../../../controllers/measurersController';
import { AuthContext } from '../../../context/AuthProvider';
import { Dates } from '../MeasurerDetails/helperDate';

//multilanguage
import { useTranslation } from 'react-i18next';

const NewGraphFrequency = ({ serial }) => {
  const { t } = useTranslation();
  const { dateReport, dateMacrometers, dateMacrometersTwo, setSeeGraphic, dateMUI } = useContext(AuthContext);

  const { data } = useQuery(GET_FREQUENCY_TEST, {
    client: secondClient,
    variables: {
      serial: serial,
      starTime: parseInt(Dates(dateMUI).start),
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            zoomType: 'x',
            type: 'spline',
            scrollablePlotArea: {
              minWidth: 600,
              // scrollPositionX: 1,
            },
          },
          title: {
            text: t('VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_TABS_VARIABLES.FREQUENCY_GRAPH_TITLE'),
            align: 'left',
          },
          xAxis: {
            type: 'datetime',
            tickInterval: 3600000,
            min: data?.getFrequencyGraphs?.starpoint,
            max: data?.getFrequencyGraphs?.endpoint,
            labels: {
              format: '{value:%H:%M}',
            },
          },
          yAxis: {
            title: {
              text: t('VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_TABS_VARIABLES.FREQUENCY'),
            },
            max: 60.2,
            min: 59.9,
          },

          plotOptions: {
            spline: {
              lineWidth: 1.5,
              states: {
                hover: {
                  lineWidth: 2.5,
                },
              },
              marker: {
                enabled: false,
              },
            },
          },
          time: {
            timezoneOffset: 300,
          },
          series: data?.getFrequencyGraphs?.ShowData,
          navigation: {
            menuItemStyle: {
              fontSize: '10px',
            },
          },
        }}
      />
    </div>
  );
};

export default NewGraphFrequency;

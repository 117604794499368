import React, { useState, useContext, useEffect } from "react";
import { StyledStepper, StyledOptions } from "../styles/Stepper.style";
import { ROUTER } from "../../../config/routing/router_per_component";
import { Link } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../context/AuthProvider";

const Stepper = () => {
  const history = useHistory();
  const steps = ROUTER.filter((element) => element.step);
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const { validFirstVariables, validSecondVariables } = useContext(AuthContext);

  useEffect(() => {
    if (currentPath) {
      setCurrentPath(window.location.pathname);
    }
  }, [window.location.pathname]);

  const handleChosenPath = (path) => {
    const pathName = window.location.pathname;
    if (
      path !== pathName &&
      parseInt(localStorage.getItem("existNewMeters")) === 1 &&
      validFirstVariables === true
    ) {
      history.push(path);
      localStorage.setItem("showMeters", true);
    } else {
      if (
        path !== pathName &&
        parseInt(localStorage.getItem("existNewMeters")) === 2 &&
        validFirstVariables === true &&
        validSecondVariables === true
      ) {
        history.push(path);
        localStorage.setItem("showMeters", true);
      }
    }
  };

  return (
    <StyledStepper>
      {steps.map((path, index) => (
        <StyledOptions key={index}>
          <div className="stepper_elements_container">
            <Link
              className="steps"
              to={path.path}
              onClick={() => handleChosenPath(path.path)}
            >
              {path.step}
            </Link>
            <i
              className={`${
                currentPath === path.path ? "full_stepper" : "empty_stepper"
              }`}
            ></i>
          </div>
        </StyledOptions>
      ))}
    </StyledStepper>
  );
};

export default Stepper;

import React, { useContext } from "react";
import Switch from "@mui/material/Switch";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import { Typography } from "@mui/material";
import { BlueSkySwitch } from "./GestionPermissions";
import { AuthContext } from "../../context/AuthProvider";

const useStyles = makeStyles((teme) => ({}));

export default function ComponentsProject({ data, state, handleChange }) {
  const classes = useStyles();

  const {
    permissionsRole,
    setPermissionsRoles,
    permissionsCheck,
    setPermissionsCheck,
  } = useContext(AuthContext);

  return (
    <>
      <Tooltip title={data.label}>
        <div className="container_area">
          <Typography variant="inherit" noWrap>
            {data.label}
          </Typography>
          <BlueSkySwitch
            onClick={() => handleChange(data.name)} /*Switches hijos de Área*/
            checked={permissionsRole[data.name]}
          ></BlueSkySwitch>
        </div>
      </Tooltip>
      {state[data.name] &&
        data.action?.map((i, key) => (
          <Tooltip title={i.label}>
            <div className="container_area_actions">
              <Typography variant="inherit" noWrap>
                {i.label}
              </Typography>

              <BlueSkySwitch
                onClick={() => handleChange(i.name)} /*Switches hijos de Área*/
                checked={permissionsRole[i.name]}
              ></BlueSkySwitch> 
            </div>
          </Tooltip>
        ))}
    </>
  );
}

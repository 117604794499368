import React, { useEffect } from "react";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { AuthContext } from "../../context/AuthProvider";

const InputGroup = styled.div`
  position: relative;
`;
const Input = styled.input`
  font-family: Montserrat, sans-serif;
  font-size: 5px;
  padding-left: 10px;
  width: 250px;
  height: 20px;
  font: inherit;
  color: #000; /* Cambiamos el color del texto a negro */
  padding: 10px;
  border: none;
  border-radius: 8px;
  outline: 2px solid #c6c6c6; /* Cambiamos el color del borde a negro */
  background-color: transparent;
  transition: outline-color 500ms;
  &:focus,
  &:valid {
    outline-color: #67a46d;
  }
`;
const Label = styled.label`
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(17px, 10px);
  color: #848080;
  transition: transform 500ms, scale 500ms;
  ${Input}:focus + &,
    ${Input}:valid + & {
    padding-inline: 5px;
    transform: translate(10px, -12px) scale(0.9);
    background-color: #ffffff;
  }
`;

const WrapperIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;
const InputField = ({ label, type, name }) => {
  if (!name) {
    throw new Error("The 'name' prop is required");
  }

  const [showPassword, setShowPassword] = React.useState(false);
  const { inputField, setInputField } = React.useContext(AuthContext);
  const [value, setValue] = React.useState();

  function handleChange(event) {
    const key = event.target.name;
    const value = event.target.value;
    setInputField((prevInputField) => ({
      ...prevInputField,
      [key]: value,
    }));
  }
  useEffect(() => {
    if (inputField?.[name]!== undefined) {
      setValue(inputField[name]);
    }
  }, [inputField?.[name]]);

  return (
    <InputGroup>
      <Input
        type={showPassword ? "text" : `${type}`}
        required
        style={{ fontSize: "15px" }}
        onChange={handleChange}
        name={name}
        value={value}
      />
      <Label>{label}</Label>
      {type === "password" ? (
        <WrapperIcon>
          <IconButton
            size="medium"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </WrapperIcon>
      ) : null}
    </InputGroup>
  );
};

export default InputField;

import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import GTranslateOutlinedIcon from "@mui/icons-material/GTranslateOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import PublicIcon from "@mui/icons-material/Public";
import { AuthContext } from "../../context/AuthProvider";

const options = ["Español", "English"];

const ITEM_HEIGHT = 48;

export default function LanguageSelect(props) {
  const { color, fontSize, iconHeight, iconWidth, borderRadious, border, width } =
    props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const open = Boolean(anchorEl);
  const [userLanguaje, setUserLanguage] = React.useState();

  React.useEffect(() => {
    // comprueba si ya existe un idioma en el localStorage
    const storedLanguage = localStorage.getItem("language");

    if (storedLanguage) {
      setUserLanguage(storedLanguage);
    } else {
      // Si no hay un idioma almacenado, utiliza la lógica del navegador
      const languageCode = navigator.language.split("-")[0];
      if (languageCode === "es") {
        setUserLanguage(languageCode);
        localStorage.setItem("language", languageCode);
      } else {
        setUserLanguage("en");
        localStorage.setItem("language", "en");
      }
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);

    // Mapea la opción seleccionada a los códigos de idioma 'en' o 'es'
    const languageCode = option === "Español" ? "es" : "en";

    // Guarda el nuevo idioma en el localStorage
    localStorage.setItem("language", languageCode);

    // Recarga la página para aplicar el cambio de idioma
    window.location.reload();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showLanguage = (localLanguage) => {
    if (localLanguage === "en") {
      return "English";
    } else if (localLanguage === "es") {
      return "Español";
    }
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          margin: "10px",
          fontSize: "20px",
          color: color,
          fontSize: fontSize,
          gap: "2px",
          borderRadius: borderRadious,
          border: border,
          width: width,
        }}
      >
        <PublicIcon sx={{ height: iconHeight, width: iconWidth }} />
        {selectedOption || showLanguage(localStorage.getItem("language"))}{" "}
        {/* Muestra la opción seleccionada o "Seleccionar" */}
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            selected={option === selectedOption}
            onClick={() => handleOptionClick(option)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

import React from "react";
import styled from "styled-components";

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 18px;
  width: 100%;
  height: 85%;
`;

export const Prev = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 50%;

  a {
    display: flex;
    flex-direction: row-reverse;
    text-decoration: none;
    color: #1c1c1c;

    &:hover {
      color: #a9aaa9;
    }
  }
`;

export const Next = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 50%;
  a {
    display: flex;
    text-decoration: none;
    color: #1c1c1c;

    &:hover {
      color: #a9aaa9;
    }
  }
`;

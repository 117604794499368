import React from "react";
import SENSOR_HUMIDITY from "../../assets/Humedad.png";

const SensorHumedad = ({ item }) => {
 
  return (
    <div className='_generic_container'>
      <div
        className={`_generics_container_title_status ${
          item.variables.CommFailure == "1" ? "__status_comfailure__" : null
        }`}
      >
        <div className='_generic__title'>
          <span>{item.alias}</span>
        </div>
        <div
          className={`${
            item?.variables.CommFailure == "1"
              ? "_generic__status__alert"
              : "_generic__status__wifi"
          }`}
        >
          {item?.variables?.CommFailure == "1" ? (
            <span className='__icon_alert_sensor'>
              <img src={alert} alt='alert' width='22' height='22' />
            </span>
          ) : (
            <span className='__icon_wifi_sensor'>
              <i class='fas fa-wifi'></i>
            </span>
          )}
        </div>
      </div>
      <div className='_generic_container_clasification'>
        <div className='_generic_clasification'>
          <span>
            <img src={SENSOR_HUMIDITY} alt='clasification' width='60' height='60' />
          </span>
        </div>
        <div></div>
        <div className='_generic_toggle'>
          {item.variables.CurrentLevel === undefined ? (
            <span className='_sensor_humedity_text'>{item.variables.status.value} %</span>
          ):(
            <span className='_sensor_humedity_text'>{item.variables.CurrentLevel} %</span>
          )}
          
        </div>
      </div>
    </div>
  );
};

export default SensorHumedad;

import React from "react";
import { Devices } from "../../constants/devices_types";

const IBMFails = ({ stateinitial, stateClone }) => {
  const img_devices_fails = {
    interruptor: Devices.BINARY_LIGHT_OFF,
  };

  const dataSocket = stateinitial.filter((i) => i?.variables?.Status == "1");
  const result_img = img_devices_fails.interruptor;

  return dataSocket.map((i) => (
    <div className='__device_fails__'>
      <div className='__img_device_fails__'>
        <img src={`${result_img}`} alt='clasification' width='60' height='60' />
      </div>
      <div className='__device_fails_names_container__'>
        <div className='__device_fails_name__'>{i.name}</div>
        <div className='__description_device_fails'>Esta zona presenta una novedad</div>
      </div>
    </div>
  ));
};

export default IBMFails;

// Components
import { useState, useContext, useEffect } from "react";
import { DateRange, Calendar } from "react-date-range";
import { AuthContext } from "../../../context/AuthProvider";
import { useTranslation } from "react-i18next";

// Libs
import { addDays } from "date-fns";
import { es, enUS } from "date-fns/locale";

// Utils
import {
  copies,
  format_date_range,
  format_date_calendar,
  is_active_btn_next_day,
  is_hotel_hours,
  handle_status,
} from "./helper.util";

// Style
import "./datepicker.scss";

/*
  @params 

  handleBackDay: func;
  handleNextDay: func;
  handleQueryReport: func;
  type: string; // range : calendar
  selectOption: Obj; // Esto solamente aplica para reporte de ocupación
    -> changeInside: bool;
    -> changeRoomId: func;
    -> stateRoomId: int;
  timerProject: string
*/

const DatePicker = ({
  type,
  back,
  next,
  handleQuery,
  selectOption: { changeInside = false, changeRoomId, RoomId },
  typeGateway,
  timerProject = "00:00",
}) => {
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const {
    rangeDatePicker,
    setRangeDatePicker,
    calendarDatePicker,
    setCalendarDatePicker,
    occupationAreaName,
    setOccupationAreaName, 
    setNameHeaderOcupation,
    idHeaderOcupation
  } = useContext(AuthContext);
  const { t } = useTranslation();
  const { RANGE } = copies;

  useEffect(() => {
    if (innerWidth !== window.innerWidth) {
      setInnerWidth(innerWidth);
    }
  }, [innerWidth, window.innerWidth]);

  const is_start_time_midnight = (time) => {
    const time_now = new Date().getHours();
    if (time_now < 15 && time === "15:00") {
      return -1;
    } else {
      return 0;
    }
  };

  const handleDatePickerSelection = () => {
    handleQuery(); // Se Ejecuta la query
    setIsDropdownActive(false); //Se cierra el modal del datepicker
  };

  const changeLanguage = () => {
    const len = localStorage.getItem("language");
    const keys = {
      es: es,
      en: enUS,
    };
    return keys[len];
  };

  const handleSelectedFormat =
    type === "range"
      ? format_date_range(rangeDatePicker)
      : format_date_calendar(calendarDatePicker);
  return (
    <div className='DatePickerContainer' data-testid='DatePickerContainer'>
      <div
        className='DatePickerContainerButtonsDropdown'
        data-testid='DatePickerContainerButtonsDropdown'
      >
        <div className='DatePickerButtons' data-testid='DatePickerButtons'>
          <div className='buttons_and_select_date_container'>
            {/* Button Back Day */}
            <span
              className='DatePickerButtonBackNext'
              data-testid='DatePickerButtonBack'
              onClick={back}
            >
              <div className='DatePickerButtonBackNextContent'>
                <span className='DatePickerButtonBackNextContent-Icon'>
                  <i className='fas fa-backward'></i>
                </span>
                <span className='DatePickerButtonBackNextContent-Text'>
                  {t("VIEW_DATE_PICKER.BTN_BACK_DAY")}
                </span>
              </div>
            </span>
            {/* Input */}
            <span
              className='DatePickerInput'
              data-testid='DatePickerInput'
              onClick={() => setIsDropdownActive(true)}
            >
              <div className='DatePickerInputContent'>
                <span className='DatePickerInputContent-Icon'>
                  <i className='far fa-calendar-alt'></i>
                </span>
                <span className='DatePickerInputContent-Text'>
                  {handleSelectedFormat}
                </span>
              </div>
            </span>
            {/* Button Next Day */}
            {is_active_btn_next_day(
              type,
              rangeDatePicker,
              calendarDatePicker,
              timerProject
            ) && (
              <>
                <span
                  className='DatePickerButtonBackNext'
                  data-testid='DatePickerButtonNext'
                  onClick={next}
                >
                  <div className='DatePickerButtonBackNextContent'>
                    <span className='DatePickerButtonBackNextContent-Text'>
                      {t("VIEW_DATE_PICKER.BTN_NEXT_DAY")}
                    </span>
                    <span className='DatePickerButtonBackNextContent-Icon'>
                      <i className='fas fa-forward'></i>
                    </span>
                  </div>
                </span>
              </>
            )}
          </div>
          {/* Areas */}
          {changeInside && (
            <div className='DatePickerChangeInside'>
              <select
                className='DatePickerChangeInside-Select'
                onChange={(i) => 
                  changeRoomId(i.target.value)
                }
              >
                {JSON.parse(localStorage.getItem("areas"))
                  ?.filter((i) => i.id == idHeaderOcupation)
                  .map((i, key) => (
                    <option
                      className='DatePickerChangeInside-Select-Option'
                      selected
                      disabled
                      value={i.id}
                      key={key}
                    >
                      {i.name} - {t(`${handle_status(i?.status)}`)}
                      {localStorage.setItem("selectedAreaName", i?.realname)}
                      {setNameHeaderOcupation(i?.realname)}
                      {setOccupationAreaName(localStorage.getItem("selectedAreaName"))}
                    </option>
                  ))
                }

                {JSON.parse(localStorage.getItem("areas"))?.map((i, key) => (
                  <option
                    className='DatePickerChangeInside-Select-Option'
                    key={key}
                    value={i.id}
                  >
                    {i.name} - {t(`${handle_status(i?.status)}`)}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      </div>
      {isDropdownActive && (
        <div className='DatePickerDropDown' data-testid='DatePickerDropDown'>
          <div className='DatePickerDropDownContent'>
            {type === RANGE ? (
              <DateRange
                onChange={(item) => setRangeDatePicker([item?.selection])}
                showSelectionPreview={false}
                moveRangeOnFirstSelection={false}
                maxDate={addDays(new Date(), is_start_time_midnight(timerProject))}
                ranges={rangeDatePicker}
                preventSnapRefocus={true}
                showDateDisplay={false}
                locale={changeLanguage()}
                months={innerWidth <= 850 ? 1 : 2}
                rangeColors={["#4caf50e6"]}
                calendarFocus='backwards'
                direction={innerWidth <= 850 ? "vertical" : "horizontal"}
              />
            ) : (
              <Calendar
                onChange={(item) => {
                  setCalendarDatePicker(item);
                }}
                date={calendarDatePicker}
                showSelectionPreview={false}
                moveRangeOnFirstSelection={false}
                maxDate={addDays(new Date(), is_start_time_midnight(timerProject))}
                preventSnapRefocus={true}
                showDateDisplay={false}
                locale={changeLanguage()}
                months={innerWidth <= 850 ? 1 : 2}
                color={"#4caf50e6"}
                calendarFocus='backwards'
                direction={innerWidth <= 850 ? "vertical" : "horizontal"}
              />
            )}
            <div className='DatePickerDropDownButtons'>
              <div className='DatePickerDropDownButtons-Buttons'>
                <span
                  className='DatePickerDropDownButtons-Buttons-Cancel'
                  onClick={() => setIsDropdownActive(false)}
                >
                  {t("VIEW_DATE_PICKER.BTN_CANCEL_DAY")}
                </span>
                <span
                  className='DatePickerDropDownButtons-Buttons-Success'
                  onClick={handleDatePickerSelection}
                >
                  {t("VIEW_DATE_PICKER.BTN_SUCESS_DAY")}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DatePicker;

import React, { useState, useEffect, useContext } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_INFO_USER_LOGGED } from '../../../controllers/projectController';
import { AuthContext } from '../../../context/AuthProvider';
import Loading from '../../../components/loading/Loading';
import { useHistory } from 'react-router-dom';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Fab } from '@material-ui/core';

//components
import ProjectCard from '../../../components/projects/ProjectCard';
import FilterProject from '../../../components/filterProject/FilterProject';
import { controller_err, room_err, room_all, device_all, device_err } from '../../../utils/helpers';
import Construction from '../../../components/loading/Construction';

const Projects = () => {
  const { user, setUser, projects, setProjects, page, setPage, variablePage, setVariablePage, permissionsRoleUser } = useContext(AuthContext);
  const history = useHistory();
  const [letterMatch, setletterMatch] = useState('');
  const [inputProject, setInputProject] = useState([]);
  const [cantPage, setCantPage] = useState(0);
  const [showPagination, setShowPagination] = useState(true);
  const {
    data: Datacache,
    loading: loadingCache,
    error: errorCache,
    refetch,
  } = useQuery(GET_INFO_USER_LOGGED, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: false,

    variables: {
      records: 5,
      page: variablePage,
    },
  });

  const [getData, { data: queryData, loading: loadingData, error: errorData }] = useLazyQuery(GET_INFO_USER_LOGGED, {
    fetchPolicy: 'no-cache',
  });
  const data = queryData || Datacache;
  const loading = loadingData || loadingCache;
  const error = errorCache || errorData;
  
  
  const handleRight = () => {
    if (page <= cantPage) {
      setVariablePage(variablePage + 1);
      setPage(page + 1);
    }
  };

  const handleLeft = () => {
    if (page > 1) {
      setVariablePage(variablePage - 1)
      setPage(page - 1);
    }
  };

  const handleProjectFilter = (match) => {
    const filter__project = projects.filter((key) => key.name.toLowerCase().includes(match.toLowerCase()));
    setletterMatch(match);
    setInputProject(filter__project);
  };
  
  const _project = () => {
    if (inputProject.length == 0 && letterMatch == '') return projects;
    if (inputProject) {
      return inputProject;
    } else {
      return projects;
    }
  };

  useEffect(() => {
    window.scroll({
      top: 0,
    });
  }, []);

  useEffect(() => {
    if (page) {
      refetch();
    }
  }, [page, refetch]);

  useEffect(() => {
    if (data) {
      if (data.getInfoUserLogged.projects) {
        setProjects([]);
        let cantProy = Math.ceil(data.getInfoUserLogged.allProjects / 5);
        setCantPage(cantProy);
        for (let i = 0; i < data.getInfoUserLogged.projects.length; i++) {
          setProjects((proj) => [
            ...proj,
            {
              id: data.getInfoUserLogged.projects[i].id,
              name: data.getInfoUserLogged.projects[i].name,
              city: data.getInfoUserLogged.projects[i].city,
              img: data.getInfoUserLogged.projects[i].img,
              country: data.getInfoUserLogged.projects[i].country,
              controllers_offline: data.getInfoUserLogged.projects[i].offlineControllers,
              controllers_online: data.getInfoUserLogged.projects[i].onlineControllers,
              numControllers : data.getInfoUserLogged.projects[i].numControllers,
              device_error: data.getInfoUserLogged.projects[i].offlineDevices,
              device_error_free: data.getInfoUserLogged.projects[i].numDevices,
              room_offline: data.getInfoUserLogged.projects[i].offlineRooms,
              room_online:data.getInfoUserLogged.projects[i].numRooms,
              start_time: data.getInfoUserLogged.projects[i].start_time,
              /* start_time:
                data.getInfoUserLogged[0].ProjectUsers[i].Project.start_time,
              end_time:
                data.ggetInfoUserLogged[0].ProjectUsers[i].Project.end_time,
              time_zone:
                data.ggetInfoUserLogged[0].ProjectUsers[i].Project.time_zone,
              kwh_cost:
                data.ggetInfoUserLogged[0].ProjectUsers[i].Project.kwh_cost,
              prefix_currency:
                data.ggetInfoUserLogged[0].ProjectUsers[i].Project
                  ?.prefix_currency,
              currency:
                data.ggetInfoUserLogged[0].ProjectUsers[i].Project?.currency,*/
            },
          ]);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    localStorage.setItem('project', JSON.stringify(projects));
    setUser(localStorage.getItem('name'));
  });

  if (error) {
    if (error == 'Error: Session expired due to inactivity') {
      localStorage.removeItem('Token');
      history.push('/');
    }
    return (
      <div>
        <Construction />
      </div>
    );
  }

  if (loading) {
    return (
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <div className="projects_container">
      <FilterProject
        user={user}
        setProjects={setProjects}
        dataProjects={data}
        projects={data.getAllProjectsName}
        datas={data}
        getData={getData}
        setShowPagination={setShowPagination}
        showPagination={showPagination}
        handleProjectFilter={handleProjectFilter}
        refetch={refetch}
      />
      {permissionsRoleUser?.permissionsRole?.proy
        ? _project().map((item, key) => (
            <ProjectCard
              data={Datacache}
              key={key}
              item={item}
            />
          ))
        : null}

      {permissionsRoleUser?.permissionsRole?.proy && showPagination ? (
        <div className="projects_pagination">
          <Fab
            size="small"
            aria-label="left"
            className="projects_pagination_button"
            onClick={handleLeft}
            disabled={page <= 1}
          >
            <ChevronLeftIcon />
          </Fab>

          <span>
            {page} / {(cantPage)}
          </span>

          <Fab
            size="small"
            aria-label="right"
            className="projects_pagination_button"
            onClick={handleRight}
            disabled={page === cantPage}
          >
            <ChevronRightIcon />
          </Fab>
        </div>
      ) : null}
    </div>
  );
};

export default Projects;

export const listMetter = [
  { label: "Amperio hora", alias: "Ah", unidad: "Ah" },
  { label: "Frecuencia", alias: "FHz", unidad: "Hz" },
  { label: "Corriente en el neutro", alias: "NC", unidad: "A" },
  { label: "Reactiva exportada total", alias: "TExKVarh", unidad: "kVArh"},
  { label: "Lectura Actual", alias: "TImKwh", unidad: "Kwh" },
  { label: "Total activa exportada", alias: "TExKwh", unidad: "kWh" },
  { label: "Total reactiva importada", alias: "TImKVarh", unidad: "kVArh" },
  { label: "Consumo instantaneo", alias: "EI", unidad: "W" },
  { label: "Total activa", alias: "TKWh", unidad: "KWh" },
  { label: "Voltaje fase a fase b", alias: "VFAFB", unidad: "V" },
  { label: "Voltaje fase b fase c", alias: "VFBFC", unidad: "V" },
  { label: "Voltaje fase c fase a", alias: "VFCFA", unidad: "V" },
  { label: "Total energía sistema", alias: "TSE", unidad: "W" },
];

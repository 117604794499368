export const handleEventDate = (date) => {
  const convertDate = new Date(date);
  const day = convertDate.getDate();
  const month = convertDate.getMonth();
  const year = convertDate.getFullYear();
  return `${year}-${month + 1}-${day}`;
};

export const msToTime = (duration) => {
  var seconds = parseInt((duration / 1000) % 60),
    minutes = parseInt((duration / (1000 * 60)) % 60),
    hours = parseInt((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return hours + ":" + minutes;
};

export const round = (num, decimales = 1) => {
  var signo = num >= 0 ? 1 : -1;
  num = num * signo;
  if (decimales === 0)
    //con 0 decimales
    return signo * Math.round(num);
  // round(x * 10 ^ decimales)
  num = num.toString().split("e");
  num = Math.round(+(num[0] + "e" + (num[1] ? +num[1] + decimales : decimales)));
  // x * 10 ^ (-decimales)
  num = num.toString().split("e");
  return signo * (num[0] + "e" + (num[1] ? +num[1] - decimales : -decimales));
};

export const consumoTotal = (data) => {
  var Totales = {
      consumoTotal: 0,
      consumoEficiente: 0,
      consumoIneficiente: 0,
    },
    i = 0;
  for (i = 0; i < data?.length; i++) {
    Totales.consumoTotal += round(data[i].total_consumption);
    Totales.consumoEficiente += round(data[i].efficient_consumption);
    Totales.consumoIneficiente += round(data[i].inefficient_consumption);
  }

  return Totales;
};

export const porcentaje = (valor, valorTotal) => {
  if (valorTotal == 0) {
    return 0;
  }
  return Number.parseFloat((valor / valorTotal) * 100).toFixed(1);
};

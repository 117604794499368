import React, { useContext, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import { AuthContext } from '../../context/AuthProvider';
import { useHistory } from 'react-router-dom';
import { ListItemsAreas } from '../listItems/ListItemsAreas';

//multilanguage
import { useTranslation } from 'react-i18next';

const FilterProject = ({ user, projects, showPagination, refetch, datas, dataProjects }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setProject, setProjectID, permissionsRoleUser } = useContext(AuthContext);
  const [value, setValue] = React.useState();
  const proyectos = projects;
  const [found, setFound] = React.useState();

  useEffect(() => {
    if (value) {
      DatasProjects();
    }
  }, [value]);

  useEffect(() => {
    if (found) {
      navigateProject();
    }
  }, [found]);

  const DatasProjects = () => {
    const resultado = proyectos.find((element) => element.id == value.id);
    setFound(resultado);
  };
  const navigateProject = () => {
    setProjectID(found.id);
    history.push('/app/areas/' + found.id);
  };

  const handleDeleteProject = () => {
    localStorage.setItem('nameProject', '');
    sessionStorage.removeItem("resultadoFinal")
    window.location.reload();
  };
  

  return (
    <div className="filterproject_container">
      <div className="filterproject_title">
        <span>
          {t('VIEW_PROJECT.COMPONENT_PROJECT_FILTER.WELCOME')}, {user}
        </span>
      </div>
      <div className="filterproject_filter_search_container">
        {permissionsRoleUser?.permissionsRole?.proy ? (
          <Autocomplete
            size="small"
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              localStorage.setItem('id', newValue.id);
              localStorage.setItem('project_selected', JSON.stringify(dataProjects.getAllProjects?.find((i) => i.id === newValue.id)));
              setProject([dataProjects.getAllProjects?.find((i) => i.id === newValue.id)]);
            }}
            id="combo-box-demo"
            options={projects}
            getOptionLabel={(option) => option.name}
            style={{ width: 300 }}
            renderInput={(params) => (
              <React.Fragment>
                <TextField
                  sx={{ margiButton: '15px', border: '#21A11D' }}
                  {...params}
                  style={{ height: 30 }}
                  label={t('VIEW_PROJECT.COMPONENT_PROJECT_FILTER.PROJECT_FILTER_PLACEHOLDER')}
                  variant="outlined"
                />
              </React.Fragment>
            )}
          />
        ) : null}

        {!showPagination && (
          <Chip
            style={{ backgroundColor: '#21A11D', color: 'white' }}
            label={localStorage.getItem('nameProject')}
            onDelete={handleDeleteProject}
          />
        )}
      </div>
    </div>
  );
};

export default FilterProject;

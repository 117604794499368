import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Fragment } from "react";
import Loading from "../../../components/loading/Loading";
import { AuthContext } from "../../../context/AuthProvider";

//querys & mutations
import { useQuery } from "@apollo/client";
import { GET_ROLE_PERMISSIONS } from "../../../controllers/rolesControllers";
import { TableRoles } from "../../../components/roleManager/TableRoles";

export default function RoleManager() {
  const [iddRole, setIddRole] = React.useState([]);
  const [prueba, setPrueba] = React.useState(false);

  const { roles, setRoles, permissionsRoleUser } =
    React.useContext(AuthContext);
  const { data, loading, error, refetch } = useQuery(GET_ROLE_PERMISSIONS, {
    fetchPolicy: "network-only",
  });

  const handleSpred = () => {
    setPrueba(!prueba);
  };

  React.useEffect(() => {
    if (data) {
      setRoles(data.getAllRoles);
    }
  }, [data]);

  React.useEffect(() => {
    setIddRole(roles.map((obj) => obj.id));
  }, [roles]);

  if (error) {
    return (
      <div>
        <lottie-player
          style={{
            width: "70%",
            marginLeft: "30%",
            marginRight: "auto",
          }}
          className="_logo"
          src="https://assets1.lottiefiles.com/private_files/lf30_tonsVH.json"
          background="transparent"
          speed="1"
          loop
          autoplay
        ></lottie-player>
      </div>
    );
  }

  if (loading || !roles) {
    return (
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <Fragment>
      {permissionsRoleUser?.permissionsRole?.GesRol ? (
        <TableRoles
          data={data.getAllRoles}
          roleIdd={iddRole}
          refresh={refetch}
        />
      ) : null}
    </Fragment>
  );
}

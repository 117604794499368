// import React from 'react'
import location from '../../assets/location.png';
import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { calc_controler_on_off, color_status_project, weather_convert, getTimeGlobal } from '../../utils/helpers';
import waather_icon from '../../assets/3d.png';
import { AuthContext } from '../../context/AuthProvider';


//multilanguage
import { useTranslation } from 'react-i18next';

const ProjectCard = (props) => {
  const { t } = useTranslation();
  const [wea, setWea] = React.useState(false);
  const { setProject, setProjectID, setMacrometer } = useContext(AuthContext);

  const history = useHistory();
  const { item } = props;
  let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
  let f = new Date();


  var day0 = f.toUTCString().substring(0, 25) + ' GMT';
  var temp0 = new Date(day0).getTime() + item.time_zone * HORA_EN_MILISEGUNDO;
  var dayAct = new Date(temp0);
  // // Set the date to "2018-09-01T16:01:36.386Z"
  // // const utcDate = zonedTimeToUtc("2018-09-01 18:01:36.386", "Europe/Berlin");

  // // Obtain a Date instance that will render the equivalent Berlin time for the UTC date
  // const date = new Date("2018-09-01T16:01:36.386Z");
  // const timeZone = "Europe/Berlin";
  // const zonedDate = utcToZonedTime(date, timeZone);
  // // zonedDate could be used to initialize a date picker or display the formatted local date/time

  // // Set the output to "1.9.2018 18:01:36.386 GMT+02:00 (CEST)"
  // const pattern = "d.M.yyyy HH:mm:ss.SSS 'GMT' XXX (z)";
  // const output = format(zonedDate, pattern, { timeZone: "Europe/Berlin" });
  // console.log("output", output);

  // useEffect(async () => {
  //   return await fetch(`https://api.openweathermap.org/data/2.5/weather?q=${item.city}&appid=47cf75287a5f38a1f022bd34d73f0987`)
  //     .then(response => response.json())
  //     .then(data => setWea(data));
  // }, [item])

  function openProject() {
    setProjectID(item.id);
    localStorage.setItem('id', item.id);
    localStorage.setItem('imgProject', item.img);
    localStorage.setItem('nameProject', item.name);
    localStorage.setItem('startTime', item.start_time);
    localStorage.setItem('endtTime', item.end_time);
    localStorage.setItem('prefix', item.prefix_currency);
    localStorage.setItem('kwhCost', item.kwh_cost);
    localStorage.setItem('project_selected', JSON.stringify(props.item));
    
    let arrayProj = [];
    arrayProj.push(item);
    setProject(arrayProj);
    history.push('/app/areas/' + item.id);
  }
  // useEffect(() => {
  //   const { data, loading, refetch } = useQuery(GET_MACROMETER_IN_PROJECT, {
  //     variables: { ProjectId: parseInt(item?.id) },
  //     fetchPolicy: 'network-only',
  //   });
  //   console.log(data)
  // }, [])
  
  return (
    <div
      className="_pro_car_container"
      onClick={openProject}
    >
      <div className="_pro_car_container_img">
        <div className="_pro_car_img_container">
          <img
            className="_pro_car_img"
            alt="Logo"
            src={item.img ? item.img : 'https://horuspro.s3.amazonaws.com/Private/default/Hotel/hotel_default.png'}
          />
        </div>
      </div>
      <div className="_pro_car_container_info">
        <div className="_pro_car_info_name">
          <span>{item.name}</span>
        </div>
        <div className="_pro_car_info_state">
          <span className={`_pro_car_info_state_color ${color_status_project(item.numControllers, item.controllers_offline)}`}>
            <span>{`${calc_controler_on_off(item.numControllers, item.controllers_offline)}${t('VIEW_PROJECT.COMPONENT_PROJECT_CARD.ONLINE')}`}</span>
          </span>
        </div>
        <div className="_pro_car_info_city_contry">
          <span>
            <span>
              <img
                src={location}
                width="20"
                height="20"
                alt="Icon"
              />
            </span>{' '}
            {item.city} - {item.country}
          </span>
        </div>
      </div>
      <div className="_pro_car_container_cad">
        <div className="_pro_car_info_count_titles">
          <span>{t('VIEW_PROJECT.COMPONENT_PROJECT_CARD.CONTROLLERS')}</span>
        </div>
        <div className="_pro_car_info_count_titles">
          <span>{t('VIEW_PROJECT.COMPONENT_PROJECT_CARD.AREAS')}</span>
        </div>
        <div className="_pro_car_info_count_titles">
          <span>{t('VIEW_PROJECT.COMPONENT_PROJECT_CARD.DEVICES')}</span>
        </div>
        <div className="_pro_car_info_num_count">
          <span>
            <span>{item.numControllers}</span> / <span className={`${item.controllers_offline > 0 ? '_pro_car_info_num_count_off' : false}`}>{item.controllers_offline}</span>
          </span>
        </div>
        <div className="_pro_car_info_num_count">
          <span>
            <span>{item.room_online}</span> / <span className={`${item.room_offline > 0 ? '_pro_car_info_num_count_off' : false}`}>{item.room_offline}</span>
          </span>
        </div>
        <div className="_pro_car_info_num_count">
          <span>
            <span>{item.device_error_free}</span> / <span className={`${item.device_error > 0 ? '_pro_car_info_num_count_off' : false}`}>{item.device_error}</span>
          </span>
        </div>
      </div>
      <div className="_pro_car_container_weather">
        <div className="_pro_car_info_weather_and_clocl">
          <div className="_pro_car_info_weather_">
            {true ? (
              <>
                <div className="_pro_car_info_weather_icon_">
                  {/* <img src={`http://openweathermap.org/img/wn/${wea.weather[0].icon}.png`} alt="Logo" width="60" height="60"/> */}
                  <img
                    src={waather_icon}
                    alt="Logo"
                    width="60"
                    height="60"
                  />
                </div>
                <div className="_pro_car_info_weather_temp_">
                  {/* <span>{weather_convert(wea.main.temp)}<small><sup>°C</sup></small></span> */}
                  <span>
                    23.6
                    <small>
                      <sup>°C</sup>
                    </small>
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;

import React, { useState, useEffect } from "react";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import { listMetter } from "./helperMetter";

//multilanguage
import { useTranslation } from "react-i18next";

export default function CardTotalNew({ measurerData, serial, city }) {
  const { t } = useTranslation();
  const prueba = measurerData;
  const [currentIndex, setCurrentIndex] = useState(4);
  const [dataVariables, setDataVariables] = useState(
    prueba[listMetter[currentIndex]?.alias]
  );
  const [dataAliasNow, setDataAliasNow] = useState(
    listMetter[currentIndex]?.alias
  );
  const [prevAlias, setPrevAlias] = useState(listMetter[3]?.alias);
  const [nextAlias, setNextAlias] = useState(listMetter[5]?.alias);
  const [dataName, setDataName] = useState(listMetter[currentIndex]?.label);
  const [variableAbre, setVariableAbre] = useState(
    listMetter[currentIndex]?.unidad
  );
  const prePage = () => {
    setCurrentIndex((currentIndex - 1 + listMetter.length) % listMetter.length);
    if (currentIndex === 0) {
      setPrevAlias(listMetter[11]?.alias);
      setNextAlias(listMetter[currentIndex]?.alias);
    } else if (currentIndex === 1) {
      setPrevAlias(listMetter[12]?.alias);
      setNextAlias(listMetter[currentIndex]?.alias);
    } else if (currentIndex > 1) {
      setPrevAlias(listMetter[currentIndex - 2]?.alias);
      setNextAlias(listMetter[currentIndex]?.alias);
    }
    if (currentIndex === 0) {
      setDataVariables(prueba[listMetter[12]?.alias]);
      setVariableAbre(listMetter[12]?.unidad);
      setDataName(listMetter[12]?.label);
      setDataAliasNow(listMetter[12]?.alias);
    } else {
      setDataVariables(prueba[listMetter[currentIndex - 1]?.alias]);
      setVariableAbre(listMetter[currentIndex - 1]?.unidad);
      setDataName(listMetter[currentIndex - 1]?.label);
      setDataAliasNow(listMetter[currentIndex - 1]?.alias);
    }
  };

  const nextPage = () => {
    setCurrentIndex((currentIndex + 1 + listMetter.length) % listMetter.length);
    if (currentIndex === 11) {
      setNextAlias(listMetter[0]?.alias);
      setPrevAlias(listMetter[currentIndex]?.alias);
    } else if (currentIndex === 12) {
      setNextAlias(listMetter[1]?.alias);
      setPrevAlias(listMetter[currentIndex]?.alias);
    } else if (currentIndex < 12) {
      setNextAlias(listMetter[currentIndex + 2]?.alias);
      setPrevAlias(listMetter[currentIndex]?.alias);
    }
    if (currentIndex === 12) {
      setDataVariables(prueba[listMetter[0]?.alias]);
      setVariableAbre(listMetter[0]?.unidad);
      setDataName(listMetter[0]?.label);
      setDataAliasNow(listMetter[0]?.alias);
    } else {
      setDataVariables(prueba[listMetter[currentIndex + 1]?.alias]);
      setVariableAbre(listMetter[currentIndex + 1]?.unidad);
      setDataName(listMetter[currentIndex + 1]?.label);
      setDataAliasNow(listMetter[currentIndex + 1]?.alias);
    }
  };

  const translatingCardHeaderTitle = () => {
    if (dataName === "Amperio hora") {
      return t(
        "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_CARD_HEADER_TITLES.AMPERAGE_PER_HOURS"
      );
    } else if (dataName === "Frecuencia") {
      return t(
        "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_CARD_HEADER_TITLES.FREQUENCY"
      );
    } else if (dataName === "Corriente en el neutro") {
      return t(
        "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_CARD_HEADER_TITLES.NEUTRAL_CURRENT"
      );
    } else if (dataName === "Reactiva exportada total") {
      return t(
        "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_CARD_HEADER_TITLES.REACTIVE_ENERGY"
      );
    } else if (dataName === "Lectura Actual") {
      return t(
        "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_CARD_HEADER_TITLES.PRESENT_READING"
      );
    } else if (dataName === "Total activa exportada") {
      return t(
        "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_CARD_HEADER_TITLES.TOTAL_EXPORT_ACTIVE_ENERGY"
      );
    } else if (dataName === "Total reactiva importada") {
      return t(
        "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_CARD_HEADER_TITLES.TOTAL_IMPORT_REACTIVE_ENERGY"
      );
    } else if (dataName === "Consumo instantaneo") {
      return t(
        "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_CARD_HEADER_TITLES.INSTANT_CONSUMPTION"
      );
    } else if (dataName === "Total activa") {
      return t(
        "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_CARD_HEADER_TITLES.TOTAL_ACTIVE"
      );
    } else if (dataName === "Voltaje fase a fase b") {
      return t(
        "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_CARD_HEADER_TITLES.PHASE_A_PHASE_B_VOLTAGE"
      );
    } else if (dataName === "Voltaje fase b fase c") {
      return t(
        "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_CARD_HEADER_TITLES.PHASE_B_PHASE_C_VOLTAGE"
      );
    } else if (dataName === "Voltaje fase c fase a") {
      return t(
        "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_CARD_HEADER_TITLES.PHASE_C_PHASE_A_VOLTAGE"
      );
    } else if (dataName === "Total energía sistema") {
      return t(
        "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_CARD_HEADER_TITLES.TOTAL_SYSTEM_POWER"
      );
    } else {
      return null;
    }
  };

  return (
    <div className="_carTotalNew_ ">
      <div className="__details_measurer_information_card_title__ background_CFT  background_CFA">
        <strong> {translatingCardHeaderTitle().toUpperCase()}</strong>
      </div>
      <div className="__details_measurer_consumption_voltage_variable_value__">
        <span className="container_value_button">{dataVariables}</span>
        <span className="unit_measurer">{variableAbre}</span>
      </div>
      <div className="container_values_songs ">
        <ArrowDropDownCircleIcon
          sx={{
            cursor: "pointer",
            display: "flex",
            transform: "rotate(90deg)",
            color: "#B3B3B3",
            ":hover": { color: "black" },
          }}
          className="button_back"
          onClick={prePage}
        />

        <div className="color_values">{prevAlias}</div>

        <div className={`${"container_value_button"} `}>{dataAliasNow}</div>

        <div className="color_values">{nextAlias}</div>

        <ArrowDropDownCircleIcon
          sx={{
            cursor: "pointer",
            display: "flex",
            transform: "rotate(270deg)",
            color: "#B3B3B3",
            ":hover": { color: "black" },
          }}
          onClick={nextPage}
        />
      </div>
    </div>
  );
}

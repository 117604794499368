import React, { useContext } from "react";
import {
  convert_time_measurergeneral,
  disabledNextDay,
} from "../../utils/helpers";
import { AuthContext } from "../../context/AuthProvider";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import { es } from "date-fns/locale";

//multilanguage
import { useTranslation } from "react-i18next";

const DateCustom = ({ handleQueryReport, back, next }) => {
  const { t } = useTranslation();
  const {
    dateReport,
    setDateReport,
    openDateCustom,
    setOpenDateCustom,
    activeNextBack,
    roomPdf,
    setRoomPdf,
  } = useContext(AuthContext);

  const handleCancelDate = () => {
    setOpenDateCustom(false);
  };

  return (
    <span className="_datecustom_container_input_">
      <div onClick={() => setOpenDateCustom(false)}></div>
      <div
        className="__backbutton_datecustom__"
        onClick={() => setOpenDateCustom(false)}
      >
        {activeNextBack ? (
          <div className="__button_back__" onClick={back}>
            <span className="_input_icon_button_back_">
              <i class="fas fa-backward"></i>
            </span>
            <button className="_button__date_">
              {t(
                "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_DATE_PICKER.BUTTON_BACK"
              )}
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div class="dropdown">
        <div className="__inputs_container_calendar__">
          <span
            className="_input_icon_calendar_"
            onClick={() => setOpenDateCustom(true)}
          >
            <i class="far fa-calendar-alt"></i>
          </span>
          <input
            type="text"
            value={convert_time_measurergeneral(dateReport)}
            className="_inputs__date_"
            onClick={() => setOpenDateCustom(true)}
          />
        </div>
        <div
          class={`${
            openDateCustom ? "dropdown-content-true" : "dropdown-content-false"
          } dropdown-content`}
        >
          <div>
            <DateRange
              onChange={(item) => setDateReport([item.selection])}
              showSelectionPreview={false}
              moveRangeOnFirstSelection={false}
              maxDate={addDays(new Date(), 0)}
              ranges={dateReport}
              preventSnapRefocus={true}
              showDateDisplay={false}
              locale={es}
              months={2}
              rangeColors={["#4caf50e6"]}
              calendarFocus="backwards"
              direction="horizontal"
            />
          </div>
          <div className="_datecustom_container_buttons__">
            <span className="_datecustom_container_button_">
              <button
                onClick={handleQueryReport}
                className="_datecustom_button_"
              >
                {t(
                  "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_DATE_PICKER.GENERATE"
                )}
              </button>
            </span>
            <span className="_datecustom_container_button_cancel_">
              <button
                onClick={() => handleCancelDate()}
                className="_datecustom_button_cancel_"
              >
                {t(
                  "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_DATE_PICKER.CANCEL"
                )}
              </button>
            </span>
          </div>
        </div>
      </div>
      <div
        className="__backbutton_datecustom__"
        onClick={() => setOpenDateCustom(false)}
        // onClick={() => handleCancelDate()}
      >
        {activeNextBack && disabledNextDay(dateReport) ? (
          <div className="__button_next__" onClick={next}>
            <button className="_button__date_">
              {t(
                "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_DATE_PICKER.BUTTON_NEXT"
              )}
            </button>
            <span className="_input_icon_button_next_">
              <i class="fas fa-forward"></i>
            </span>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div onClick={() => setOpenDateCustom(false)}></div>
    </span>
  );
};

export default DateCustom;

import React, { useEffect } from "react";
import locations from "../../assets/location.png";
import PlaylistAddCircleIcon from "@mui/icons-material/PlaylistAddCircle";
import {
  color_status_project,
  calc_controler_on_off,
  getTimeGlobal,
  weather_convert,
} from "../../utils/helpers";
import icon_test from "../../assets/3d.png";
// multilanguage
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const ProjectCardAreaControl = ({ data, AddController }) => {
  const { t } = useTranslation();
  const [wea, setWea] = React.useState();
  const location = useLocation();
  const isControllerView = /^\/app\/controllers\/\d+$/.test(location.pathname);

  useEffect(() => {
    window.scroll({
      top: 0,
    });
  }, []);

  return (
    <>
      <div className="__area_container_info_general__">
        <div
          className={`__area_container_name__ ${
            data?.name?.length >= 32
              ? "__areas_name_max_32__"
              : "__areas_name_min_32__"
          }`}
        >
          {data?.name}
        </div>
        <div className="__area_container_location__">
          <span>
            <img src={locations} alt="location" width="20" height="20" />{" "}
            <span>{`${data?.city}, ${data?.country}`}</span>
          </span>
        </div>
        <div className="__area_container_status__">
          <span
            className={`__area_container_status_number__ ${color_status_project(
              data?.numControllers,
              data?.offlineControllers
            )}`}
          >
            {`${calc_controler_on_off(
              data?.numControllers,
              data?.offlineControllers
            )}${t("VIEW_AREAS.COMPONENT_HEADER_AREAS.ONLINE")}`}
          </span>
          {isControllerView && AddController}
        </div>
      </div>
      <div className="__area_container_info_status__">
        <div className="__area_container_info_data__">
          <span>
            <span>{data?.numControllers}</span>
            {" / "}
            <span
              className={`${
                data?.offlineControllers > 0
                  ? "__area_container_info_data_offline__"
                  : false
              }`}
            >
              {data?.offlineControllers}
            </span>
          </span>
        </div>

        <div className="__area_container_info_data__">
          <span>
            <span>{data?.numRooms}</span>
            {" / "}
            <span
              className={`${
                data?.offlineRooms > 0
                  ? "__area_container_info_data_offline__"
                  : false
              }`}
            >
              {data?.offlineRooms}
            </span>
          </span>
        </div>

        <div className="__area_container_info_data__">
          <span>
            <span>{data?.numDevices}</span>
            {" / "}
            <span
              className={`${
                data?.numDevices > 0
                  ? "__area_container_info_data_offline__"
                  : false
              }`}
            >
              {data?.offlineDevices}
            </span>
          </span>
        </div>
        <div className="__area_container_info_names__">
          {t("VIEW_AREAS.COMPONENT_HEADER_AREAS.CONTROLLERS")}
        </div>
        <div className="__area_container_info_names__">
          {t("VIEW_AREAS.COMPONENT_HEADER_AREAS.AREAS")}
        </div>
        <div className="__area_container_info_names__">
          {t("VIEW_AREAS.COMPONENT_HEADER_AREAS.DEVICES")}
        </div>
      </div>
    </>
  );
};

export default ProjectCardAreaControl;

import React, { createContext, useState, useEffect } from "react";
import { addDays } from "date-fns";
import permissions from "./schema/permission.json";
import { useQuery } from "@apollo/client";
import { GET_USER_ROLE } from "../controllers/usersControllers";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const permissionsDatos = { permissions };
  const PermissionsConfig = {
    config: false,
    proy: false,
    GesUser: false,
    GesUserVer: false,
    GesUserCrear: false,
    GesUserEditar: false,
    GesUserDes: false,
    GesRol: false,
    GesRolVer: false,
    GesRolCrear: false,
    GesRolEditar: false,
    GesRolDes: false,
    GesProject: false,
    GesProjectVer: false,
    GesProjectCrear: false,
    GesProjectEdit: false,
    GesProjectDes: false,
    GesDevices: false,
    GesDevicesVer: false,
    Med: false,
    MedRdf: false,
    MedRpfVer: false,
    MedRpfDes: false,
    MedRmg: false,
    MedRmgVer: false,
    MedRmgDes: false,
    Mac: false,
    MedMacVer: false,
    Area: false,
    AreaCreate: false,
    AreaVer: false,
    AreaRoa: false,
    AreaRoaVer: false,
    AreaRoaDes: false,
    AreaRpta: false,
    AreaRptaVer: false,
    AreaDisp: false,
    AreaDispVer: false,
    AreaDispMan: false,
    AreaRpog: false,
    AreaRpogVer: false,
    AreaRpogDes: false,
    AreaMed: false,
    AreaMedVer: false,
    AreaRpdf: false,
    AreaRpdfver: false,
    Ctl: false,
    CtlVer: false,
    CtlCreateVirtual: false
  };

  const { data, loading, error, refetch } = useQuery(GET_USER_ROLE, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: parseInt(localStorage.getItem("np")),
    },
  });

  //userLenguaje
  const [userLanguaje, setUserLanguage] = useState();

  //Roles access and permits
  const [permissionsRole, setPermissionsRoles] = useState(PermissionsConfig);
  const [newPermissionsRole, setNewPermissionsRole] = useState(permissionsRole);
  const [permissionsRoleUser, setPermissionsRoleUser] = useState();

  //components
  const [inputField, setInputField] = useState();
  const [dateMUI, setDateMUI] = useState(Date.parse(new Date()));

  useEffect(() => {
    setPermissionsRoleUser(data?.getAllUsers[0]?.Role.components[0]);
  }, [data]);

  useEffect(() => {
    // comprueba si ya existe un idioma en el localStorage
    const storedLanguage = localStorage.getItem("language");

    if (storedLanguage) {
      setUserLanguage(storedLanguage);
    } else {
      // Si no hay un idioma almacenado, utiliza la lógica del navegador
      const languageCode = navigator.language.split("-")[0];

      if (languageCode === "es") {
        setUserLanguage(languageCode);
        localStorage.setItem("language", languageCode);
      } else {
        setUserLanguage("en");
        localStorage.setItem("language", "en");
      }
    }
  }, []);
  // Login
  const [user, setUser] = useState("");
  const [np, setNp] = useState();

  // Areas
  const [areas, setAreas] = useState([]);
  const [area, setArea] = useState();
  const [idArea, setIdArea] = useState();
  const [roomId, setRoomId] = useState("");
  const [occupationAreaName, setOccupationAreaName] = useState("");

  //Pdfs
  const [graphic, setGraphic] = useState("");
  const [pie, setPie] = useState("");
  const [roomPdf, setRoomPdf] = useState({ rooms: [] });
  const [startTimes, setStartTimes] = useState("");
  const [finishTimes, setFinishTimes] = useState("");
  const [datePrint, setDatePrint] = useState("");
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [downloadGeneralOcupation, setDownloadGeneralOcupation] = useState();

  //Modal
  const [openModal, setOpenModal] = useState(false);
  const [openModalOcup, setOpenModalOcup] = useState(false);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [openChangeUserStatusModal, setOpenChangeUserStatusModal] =
    useState(false);
  const [openChangeProjectStatusModal, setOpenChangeProjectStatusModal] =
    useState(false);

  //Alerts Form
  const [alertEdit, setAlertEdit] = useState(false);
  const [successfulAlert, setSuccessfulAlert] = useState(false);

  //Message Register
  const [openMessage, setOpenMessage] = useState(false);
  const [openMessage2, setOpenMessage2] = useState(false);

  // Devices
  const [infoMessage, setInfoMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [deviceFailsCount, setDeficeFailsCount] = useState(0);

  //// Devices management view
  const [selectedDevice, setSelectedDevice] = useState(0);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectIdStepper, setProjectIdStepper] = useState(null);
  const [openNewMetersModal, setOpenNewMetersModal] = useState(false);
  const [openCancelInstallation, setOpenCancelInstallation] = useState(false);
  const [validFirstVariables, setValidFirstVariables] = useState(false);
  const [validSecondVariables, setValidSecondVariables] = useState(false);
  const [confirmMettersExists, setConfirmMettersExists] = useState(0);

  //Projects
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState([]);
  const [stateId, setStateId] = useState(0);
  const [page, setPage] = useState(1);
  const [variablePage, setVariablePage] = useState(0);
  const [controllerId, setControllerId] = useState(null);
  const [projectid, setProjectID] = useState();
  const [modalCreateOrEditProject, setModalCreateorEditProject] =
    useState(false);

  //Const report
  const [measurer_data, setMeasurerData] = useState([]);
  const [event, setEvent] = useState(0); // socket

  // User and Roles Status
  const [createRole, setCreateRole] = useState(false);
  const [editRole, setEditRole] = useState(false);
  const [enabledOrDisabledRole, setEnabledOrDisabledRole] = useState(false);
  const [roles, setRoles] = useState([]);
  const [nameRol, setNameRol] = React.useState("");
  const [createOrEditRole, setCreateOrEditRole] = useState("");
  const [roleId, setRoleId] = useState();
  const [roleState, setRoleState] = useState();
  const [enabledOrDisabledUser, setEnabledOrDisabledUser] = useState(true);

  //State of the component CalendarCustom
  const [dateCalendar, setDateCalendar] = useState(new Date());
  const [isOpenCalendarCustom, setIsOpenCalendarCustom] = useState(false);
  const [dateReport, setDateReport] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  const [dateCalendarTemp, setDateCalendarTemp] = useState(new Date());
  const [dateNextTemp, setDateNextTemp] = useState();
  const [dateBackTemp, setDateBackTemp] = useState();

  //DoorLock
  const [codesGlobal, setCodesGlobal] = useState([]);
  const [nameUser, setNameUser] = useState("");
  const [lastNameUser, setlastNameUser] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [numberID, setNumberID] = useState("");
  const [profilePicture, setprofilePicture] = useState("");
  const [userLogin, setUserLogin] = useState("");
  const [roll, setRoll] = useState("");
  const [password, setPassword] = useState("");
  const [passwordTwo, setPasswordTwo] = useState("");
  const [users, setUsers] = useState([]);

  //Measurer
  const [serial, setSerial] = useState("");
  const [zoneP, setZoneP] = useState();
  const [dateMacrometers, setDateMacrometers] = useState(0);
  const [macrometer, setMacrometer] = useState({});
  const [dataMacrometers, setDataMacrometer] = useState();
  const [dataMacrometerVariables, setDataMacrometerVariables] = useState()
  const [dateMacrometersTwo, setDateMacrometersTwo] = useState(0);
  const [valueDatepicker, setValueDatepicker] = useState(
    Date.parse(new Date())
  );
  const [seeGraphic, setSeeGraphic] = useState(true);
  const [datePerDay, setDatePerDay] = useState(new Date());

  //Datecustom prev and next

  const [rangeTable, setRangeTable] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  const [openDateCustom, setOpenDateCustom] = useState(false);
  const [activeNextBack, setactiveNextBack] = useState(false);

  //Bathroom
  const [stateBathroom, setStateBathroom] = useState({
    26128: null,
    26127: null,
    26126: null,
  });

  // Global DatePicker
  const [rangeDatePicker, setRangeDatePicker] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]); // type: range
  const [calendarDatePicker, setCalendarDatePicker] = useState(new Date()); // type: calendar
  const [nameHeaderOcupation, setNameHeaderOcupation] = useState("")
  const [idHeaderOcupation, setIdHeaderOcupation] = useState(null)

  const [socketConection, setSocketConection] = useState(false);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        np,
        setNp,
        serial,
        setSerial,
        areas,
        setAreas,
        area,
        setArea,
        idArea,
        setIdArea,
        roomId,
        setRoomId,
        infoMessage,
        setInfoMessage,
        successMessage,
        setSuccessMessage,
        errorMessage,
        setErrorMessage,
        projects,
        setProjects,
        dateReport,
        setDateReport,
        event,
        setEvent,
        measurer_data,
        setMeasurerData,
        openDateCustom,
        setOpenDateCustom,
        nameUser,
        setNameUser,
        lastNameUser,
        setlastNameUser,
        email,
        setEmail,
        phone,
        setPhone,
        numberID,
        setNumberID,
        profilePicture,
        setprofilePicture,
        userLogin,
        setUserLogin,
        roll,
        setRoll,
        password,
        setPassword,
        passwordTwo,
        setPasswordTwo,
        users,
        setUsers,
        roles,
        setRoles,
        activeNextBack,
        setactiveNextBack,
        rangeTable,
        setRangeTable,
        dateCalendar,
        setDateCalendar,
        isOpenCalendarCustom,
        setIsOpenCalendarCustom,
        downloadGeneralOcupation,
        setDownloadGeneralOcupation,
        graphic,
        setGraphic,
        pie,
        setPie,
        roomPdf,
        setRoomPdf,
        startTimes,
        setStartTimes,
        finishTimes,
        setFinishTimes,
        datePrint,
        setDatePrint,
        openModal,
        setOpenModal,
        loadingPdf,
        setLoadingPdf,
        project,
        setProject,
        page,
        setPage,
        variablePage,
        setVariablePage,
        openModalOcup,
        setOpenModalOcup,
        controllerId,
        setControllerId,
        deviceFailsCount,
        setDeficeFailsCount,
        stateId,
        setStateId,
        openMessage,
        setOpenMessage,
        openMessage2,
        setOpenMessage2,
        codesGlobal,
        setCodesGlobal,
        zoneP,
        setZoneP,
        dateMacrometers,
        setDateMacrometers,
        dateMacrometersTwo,
        setDateMacrometersTwo,
        valueDatepicker,
        setValueDatepicker,
        permissionsRole,
        setPermissionsRoles,
        createOrEditRole,
        setCreateOrEditRole,
        projectid,
        setProjectID,
        seeGraphic,
        setSeeGraphic,
        nameRol,
        setNameRol,
        createRole,
        setCreateRole,
        editRole,
        setEditRole,
        enabledOrDisabledRole,
        setEnabledOrDisabledRole,
        roleId,
        setRoleId,
        roleState,
        setRoleState,
        PermissionsConfig,
        modalCreateOrEditProject,
        setModalCreateorEditProject,
        newPermissionsRole,
        setNewPermissionsRole,
        stateBathroom,
        setStateBathroom,
        permissionsRoleUser,
        alertEdit,
        setAlertEdit,
        successfulAlert,
        setSuccessfulAlert,
        openCreateUserModal,
        setOpenCreateUserModal,
        openEditUserModal,
        setOpenEditUserModal,
        enabledOrDisabledUser,
        setEnabledOrDisabledUser,
        openChangeUserStatusModal,
        setOpenChangeUserStatusModal,
        openChangeProjectStatusModal,
        setOpenChangeProjectStatusModal,
        selectedDevice,
        setSelectedDevice,
        selectedProject,
        setSelectedProject,
        projectIdStepper,
        setProjectIdStepper,
        inputField,
        setInputField,
        setDateCalendarTemp,
        dateCalendarTemp,
        dateNextTemp,
        setDateNextTemp,
        dateBackTemp,
        setDateBackTemp,
        datePerDay,
        setDatePerDay,
        openNewMetersModal,
        setOpenNewMetersModal,
        validFirstVariables,
        setValidFirstVariables,
        validSecondVariables,
        setValidSecondVariables,
        openCancelInstallation,
        setOpenCancelInstallation,
        confirmMettersExists,
        setConfirmMettersExists,
        dateMUI,
        setDateMUI,
        rangeDatePicker,
        setRangeDatePicker,
        calendarDatePicker,
        setCalendarDatePicker,
        occupationAreaName,
        setOccupationAreaName,
        socketConection,
        setSocketConection,
        macrometer,
        setMacrometer,
        dataMacrometers,
        setDataMacrometer,
        setMacrometer,
        dataMacrometerVariables, 
        setDataMacrometerVariables,
        nameHeaderOcupation, 
        setNameHeaderOcupation,
        idHeaderOcupation, 
        setIdHeaderOcupation
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

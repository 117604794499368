import React from "react";
import styled from "styled-components";

export const MainStepperContainer = styled.div`
  display: grid;
  grid-template-rows: 5% 70% 30%;
  row-gap: 25px;
  margin: 0px 3% 30px 3%;
  position: sticky;
  top: 0;
  height: 85vh;
`;

export const Navbar = styled.div`
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: repeat(2, 1fr);

  .modalButton {
    display: none;
    justify-self: flex-end;
    position: fixed;
    align-self: center;
    font-size: 25px;
    color: #f9f9f9;
    background-color: rgba(#1c1c1c, 0.8);
    padding: 10px 14px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: #1c1c1c;
    }

    @media (max-width: 1024px) {
      display: block;
    }
  }
`;

export const InstructionsAndSummaryContainer = styled.div`
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 65% 30%;
  column-gap: 5%;

  @media (max-width: 1024px) {
    grid-template-columns: 100%;
  }
`;

export const InstructionsContainer = styled.div`
  display: grid;
  grid-template-rows: 100%;
  border-radius: 10px;
  padding: 5%;
  box-shadow: rgba(198, 211, 211) 0px 2px 8px;
  background-color: #f8f9f9;

  @media (max-width: 1024px) {
    padding: 5%;
  }
`;

export const SummaryContainer = styled.div`
  display: grid;
  grid-template-rows: 100%;
  border-radius: 10px;
  box-shadow: rgba(198, 211, 211) 0px 2px 8px;
  background-color: #f8f9f9;

  .summary {
    display: flex;
    flex-direction: column;
    gap:10px;
    padding: 10%;
    
    font-family: "Montserrat", sans-serif;
    color: #1c1c1c;
    overflow-x: hidden;
    overflow-y: auto;

    .summary_title {
      justify-self: center;
      align-self: center;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      font-size: 25px;
    }

    .summary_item_container {
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: flex-start;

      .items_labels {
        display: flex;
        flex-direction: row;
        /* gap: 20px; */
        align-items: center;

        .serial_label{
          margin-left: 20px;
        }

      }

      .secondary_labels {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
        margin-left: 18%;
        margin-bottom: 3px;
      }
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const StepperStatusContainer = styled.div`
  display: grid;
  height: 100px;
  grid-template-rows: 100%;
  border-radius: 10px;
  grid-template-columns: 100%;
  box-shadow: rgba(198, 211, 211) 0px 2px 8px;
  background-color: #f8f9f9;
`;

import React from "react";
import {
  DescriptionContainer,
  DescriptiontTitle,
  DescriptionText,
} from "../styles/DescriptionView.style";
import Pagination from "../components/Pagination";
import CancelInstallationButton from "../components/CancelInstallationButton";
import CancelInstallationModal from "../components/CancelInstallationModal";

const DataValidation = ({ info }) => {
  return (
    <DescriptionContainer>
      <DescriptiontTitle>
        Validación de datos
        <CancelInstallationButton />
      </DescriptiontTitle>

      <DescriptionText>{info.description}</DescriptionText>
      <DescriptionText>
        <span className="question">¿Ya se verificaron los datos?</span>
        <div className="text_2">
          De ser así, por favor finalice la instalación haciendo click en el
          botón finalizar
        </div>
      </DescriptionText>
      <Pagination
        labelPreviousButton={"Atrás"}
        previousButtonColor={"success"}
        nextButtonColor={"success"}
        labelNextButton={"Siguiente"}
        prev={info.prev}
        next={info.next}
      />
      <CancelInstallationModal />
    </DescriptionContainer>
  );
};

export default DataValidation;

import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";

// import AutoComplete from '../../../../components/tableInformation/components/AutoComplete';

//Material UI components & Styles
import { createTheme, ThemeProvider } from "@mui/material/styles";

//multilanguage
import { useTranslation } from "react-i18next";

const customGreen = "#acf6ac";

const theme = createTheme({
  palette: {
    success: {
      main: customGreen,
    },
  },
});

const BatteryNoData = ({ open, close, name }) => {
  const { t } = useTranslation();
  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        PaperProps={{
          sx: {
            display: "flex",
            borderRadius: "8px",
            width: "auto",
            height: "20%",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#2EB142",
            color: "#FFFFFF",
            lineHeight: "2.6",
            padding: "5px 5px 5px 15px",
          }}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              fontFamily: "Montserrat, sans-serif",
              fontSize: "auto",
              fontWeight: "bold",
            }}
          >
            {t("VIEW_AREA.COMPONENT_FULL_MODAL_BATTERY.MODAL_HEADER")} - {name}
          </span>
          <IconButton onClick={close}>
            <CloseIcon sx={{ color: "#ffff" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "25px",
            padding: "20px 24px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              width: "60%",
              fontFamily: "Montserrat, sans-serif",
              fontSize: "18px",
            }}
          >
            {t("VIEW_AREA.COMPONENT_FULL_MODAL_BATTERY.NO_DATA")}
          </span>

          <ThemeProvider theme={theme}></ThemeProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BatteryNoData;

import React, { useEffect, useContext } from "react";
import Switch from "@material-ui/core/Switch";
import { Devices } from "../../constants/devices_types";
import { green } from "@material-ui/core/colors";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import BatteryUnknownIcon from "@material-ui/icons/BatteryUnknown";
import BatteryFullIcon from "@material-ui/icons/BatteryFull";
import Battery90Icon from "@material-ui/icons/Battery90";
import Battery80Icon from "@material-ui/icons/Battery80";
import Battery60Icon from "@material-ui/icons/Battery60";
import Battery50Icon from "@material-ui/icons/Battery50";
import Battery30Icon from "@material-ui/icons/Battery30";
import Battery20Icon from "@material-ui/icons/Battery20";
import BatteryAlertIcon from "@material-ui/icons/BatteryAlert";
import { AuthContext } from "../../context/AuthProvider";
import Loading_devices from "../loading/Loading_devices";

//multilanguage
import { useTranslation } from "react-i18next";

const innerTheme = createTheme({
  palette: {
    secondary: {
      main: green[500],
    },
  },
});
const SensorMovimiento = ({ item, send }) => {
  const { t } = useTranslation();
  const {
    infoMessage,
    setInfoMessage,
    successMessage,
    setSuccessMessage,
    errorMessage,
    setErrorMessage,
    permissionsRoleUser,
  } = useContext(AuthContext);
  const [state, setState] = React.useState(
    item.variables.Status == "1" ? true : false
  );
  const [BatteryLevel, setBatteryLevel] = React.useState(
    item.variables.BatteryLevel
  );
  const [load, setLoad] = React.useState(false);
  useEffect(() => {
    setState(item.variables.Status == "1" ? true : false);
    if (item.variables.BatteryLevel) {
      setBatteryLevel(item.variables.BatteryLevel);
    }
    //setLoad(false);
  }, [item]);

  useEffect(() => {
    setLoad(false);
  }, [errorMessage, successMessage]);

  const handleChange = async (event) => {
    setLoad(true);
    await call();
    setState(!state);
  };

  function call() {
    return new Promise(() => {
      setTimeout(() => {
        send(item, !state);
      }, 2000);
    });
  }

  return (
    <div className="_generic_container">
      <div
        className={`_generics_container_title_status ${
          item.variables.CommFailure == "1" ? "__status_comfailure__" : null
        }`}
      >
        <div className="_generic__title">
          <span>{item.alias}</span>
        </div>
        <div
          className={`${
            item.variables.CommFailure == "1"
              ? "_generic__status__alert"
              : "_generic__status__wifi"
          }`}
        >
          {item.variables.CommFailure == "1" ? (
            <span className="__icon_alert_sensor">
              <img src={alert} alt="alert" width="25" height="25" />
            </span>
          ) : (
            <span className="__icon_wifi_sensor">
              <i class="fas fa-wifi"></i>
            </span>
          )}
        </div>
      </div>
      <div className="_generic_container_clasification">
        <div
          className={`_generic_clasification ${
            load ? "__loading_clasification" : false
          }`}
        >
          <span>
            <img
              src={
                state ? Devices.MOTION_SENSOR_TRIPPED : Devices.MOTION_SENSOR
              }
              alt="clasification"
              width="60"
              height="60"
            />
          </span>
        </div>
        <div className="__loading_devices">
          {load ? <Loading_devices /> : false}
        </div>
        <div
          className={`_generic_toggle row-decoration ${
            load ? "__loading_toogle" : false
          }`}
        >
          <div>
            <ThemeProvider theme={innerTheme}>
              <Switch
                size="medium"
                checked={state}
                disabled={true}
                onChange={
                  permissionsRoleUser.permissionsRole?.AreaDispMan
                    ? handleChange
                    : false
                }
              />
            </ThemeProvider>
            <br></br>
            {!state ? (
              <span>
                {t("VIEW_AREA.COMPONENT_ELECTRIC_SENSOR_CARD.DISASSEMBLED")}
              </span>
            ) : (
              <span>
                {t("VIEW_AREA.COMPONENT_ELECTRIC_SENSOR_CARD.ASSEMBLED")}
              </span>
            )}{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SensorMovimiento;

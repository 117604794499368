import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import Logo from '../../assets/logo.svg';
import { LOGIN } from '../../controllers/authenticationController';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { AuthContext } from '../../context/AuthProvider';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Link } from 'react-router-dom';
import { encryptData } from '../../utils/encryptDecrypt';

//multilenguage
import { useTranslation } from 'react-i18next';
import LanguageSelect from '../../components/languageSelect/LanguageSelect';

function Alert(props) {
  return (
    <MuiAlert
      elevation={6}
      variant="filled"
      {...props}
    />
  );
}
const Login = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const { user, setUser, setNp } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const [login] = useMutation(LOGIN);
  const [auth, setValue] = useState({
    user: '',
    password: '',
  });
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const password = process.env.REACT_APP_ENCRYPT_DECRYPT_KEY;

  const { t } = useTranslation();

  const handleClick = () => {
    setOpen(true);
  };
  const handleClick2 = () => {
    setOpen2(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleClose2 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen2(false);
  };

  const __handleSubmit = async () => {
    const encryptedpass = await encryptData(auth.password, password);
    if (auth.user && auth.password) {
      try {
        login({
          variables: {
            user: auth.user,
            password: encryptedpass,
          },
        })
          .then((resul) => {
            if (resul) {
              localStorage.setItem('token', resul.data.Login.token);
              localStorage.setItem('np', resul.data.Login.id);
              localStorage.setItem('email', resul.data.Login.email);
              setUser(resul.data.Login.name);
              localStorage.setItem('name', `${resul.data.Login.name} ${resul.data.Login.last_name}`);
              history.push('/app/projects');
            }
          })
          .catch((err) => {
            handleClick();
          });
      } catch (err) {}
    } else {
      handleClick2();
    }
  };

  const handleInput = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const password = event.target.password;
    setValue({
      ...auth,
      [name]: value,
      [password]: value,
    });
  };
  return (
    <div className="_login_container_principal">
      <div className="principal">
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert severity="error">{t('VIEW_LOGIN.ERROR_ALERT')}</Alert>
        </Snackbar>
        <Snackbar
          open={open2}
          autoHideDuration={6000}
          onClose={handleClose2}
        >
          <Alert severity="warning">{t('VIEW_LOGIN.WARNING_ALERT')}</Alert>
        </Snackbar>
        <div className="_hero">
          <lottie-player
            className="_logo"
            src="https://assets10.lottiefiles.com/private_files/lf30_psn7xxju.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
        </div>
        <div className="_form">
          <div className="_container__logo">
            <img
              className="_logo"
              src={Logo}
              alt="Horus Smart Energy"
              width="130"
              height="130"
            />
          </div>
          <div className="_container__title">
            <span className="_title">{t('VIEW_LOGIN.WELCOME')}</span>
          </div>
          <form
            className="_container_inputs"
            onSubmit={handleSubmit(__handleSubmit)}
          >
            <input
              className="_btn_input"
              name="user"
              ref={register({ required: true })}
              placeholder={t('VIEW_LOGIN.INPUT_USER')}
              onChange={handleInput}
            />
            {errors.name && <span className="">* usuario es requerido</span>}
            <br />
            <div className="_container_password">
              <input
                className="_input_password"
                name="password"
                ref={register({ required: true })}
                placeholder={t('VIEW_LOGIN.INPUT_PASSWORD')}
                type={showPassword ? 'text' : 'password'}
                onChange={handleInput}
              />
              {auth.password !== '' ? (
                <div className="_container_icon">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>{showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}</IconButton>
                </div>
              ) : (
                <></>
              )}
            </div>

            {errors.name && <span className="">* usuario es requerido</span>}
            <br />
            <input
              onClick={__handleSubmit}
              className="_btn_submit"
              type="submit"
              value={t('VIEW_LOGIN.SIGN_IN_BUTTON')}
            />
          </form>

          <div className="_container_info_contact">
            <a
              className="_info_contact"
              TARGET="_blank"
              href="https://www.horus-smartenergy.com/"
            >
              {' '}
              {t('VIEW_LOGIN.CONTACT_US')}
            </a>
          </div>

          <div className="forgot_password">
            <Link to="/forgot_password">{t('VIEW_LOGIN.FORGOT_PASSWORD')}</Link>
          </div>
          <div className="language_select">
            <LanguageSelect
              color={'black'}
              fontSize={'15px'}
              iconHeight={'22px'}
              iconWidth={'22px'}
              border={'none'}
              borderRadious={'20px'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

export async function encryptData(value, password) {
  const pwUtf8 = new TextEncoder().encode(password);
  const pwHash = await window.crypto.subtle.digest("SHA-256", pwUtf8);
  const iv = window.crypto.getRandomValues(new Uint8Array(12));
  const alg = { name: "AES-GCM", iv: iv, tagLength: 128 };
  const key = await window.crypto.subtle.importKey(
    "raw",
    pwHash,
    alg,
    false,
    ["encrypt"]
  );
  const ptUint8 = new TextEncoder().encode(value);
  const ctBuffer = await window.crypto.subtle.encrypt(alg, key, ptUint8);
  const ctArray = Array.from(new Uint8Array(ctBuffer));
  const ctStr = ctArray.map((byte) => String.fromCharCode(byte)).join("");
  const ivHex = Array.from(iv)
    .map((b) => ("00" + b.toString(16)).slice(-2))
    .join("");
  const ctBase64 = btoa(ctStr);
  return ivHex + "," + ctBase64;
}

export async function decryptData(encryptedData, password) {
  const pwUtf8 = new TextEncoder().encode(password);
  const pwHash = await window.crypto.subtle.digest("SHA-256", pwUtf8);

  const [ivHex, ctBase64] = encryptedData.split(",");
  const iv = new Uint8Array(
    ivHex.match(/[\da-f]{2}/gi).map(function (h) {
      return parseInt(h, 16);
    })
  );

  const alg = { name: "AES-GCM", iv: iv, tagLength: 128 };
  const key = await window.crypto.subtle.importKey(
    "raw",
    pwHash,
    alg,
    false,
    ["decrypt"]
  );

  const ctStr = atob(ctBase64);
  const ctUint8 = new Uint8Array(
    ctStr.match(/[\s\S]/g).map(function (ch) {
      return ch.charCodeAt(0);
    })
  );

  const decryptedBuffer = await window.crypto.subtle.decrypt(
    alg,
    key,
    ctUint8
  );
  const decryptedValue = new TextDecoder().decode(decryptedBuffer);

  return decryptedValue;
}
import gql from "graphql-tag";

export const GET_DATA_REPORT = gql`
  query (
    $project_id: Int!
    $RoomId: Int!
    $event_date: Date!
    $start_time: Date!
    $end_time: Date!
  ) {
    getDataReport(
      project_id: $project_id
      RoomId: $RoomId
      event_date: $event_date
      start_time: $start_time
      end_time: $end_time
    )
  }
`;

export const GET_NEW_DATA_OCCUPANCY = gql`
  query (
    $ProjectId: Int
    $NewRoomId: String!
    $event_date: Date!
    $start_time: Date
    $end_time: Date
  ) {
    getNewDataOccupancy(
      ProjectId: $ProjectId
      NewRoomId: $NewRoomId
      event_date: $event_date
      start_time: $start_time
      end_time: $end_time
    )
  }
`;

export const GET_DATA_MEASURER_REPORT = gql`
  query ($RoomId: Int!, $start_time: Date!, $end_time: Date!) {
    getReportMeterZWave(
      RoomId: $RoomId
      start_time: $start_time
      end_time: $end_time
    )
  }
`;

export const GET_DATA_MEASURER_BY_PROJECT = gql`
  query (
    $ProjectId: Int!
    $start_time: Date!
    $end_time: Date!
    $date_range: [Date]
    $time_zone: String
  ) {
    getMetersByProject(
      date_range: $date_range
      ProjectId: $ProjectId
      start_time: $start_time
      end_time: $end_time
      time_zone: $time_zone
    )
  }
`;

export const GET_GENERAL_OCCUPATION_REPORT = gql`
  query ($ProjectId: Int!, $start_time: String!, $end_time: String!) {
    getGeneralOccupationReport(
      ProjectId: $ProjectId
      start_time: $start_time
      end_time: $end_time
    )
  }
`;

export const GET_DEVICE_BY_TEMPERATURE = gql`
  query Query(
    $typeGateway: String!
    $RoomId: Int
    $NewRoomId: String
    $hourStart: Date
    $hourEnd: Date
  ) {
    getDeviceByTemperature(
      typeGateway: $typeGateway
      RoomId: $RoomId
      NewRoomId: $NewRoomId
      hourStart: $hourStart
      hourEnd: $hourEnd
    )
  }
`;

export const GET_DASHBOARD_BY_TEMPERATURE = gql`
  query getDataDashboard($serial: String!) {
    getDataDashboard(serial: $serial)
  }
`;

export const GET_BATTERY_DEVICES = gql`
  query ($DeviceId: Int) {
    getDeviceByBatteryLevel(DeviceId: $DeviceId)
  }
`;

export const GET_METTER_HISTORY_BY_HOUR = gql`
  query {
    getMetterHistoriesByHour(serial: "004000006585")
  }
`;

export const GET_REPORT_FAILS_BY_PROJECT = gql`
  query ($ProjectId: Int!) {
    getFailuresReport(ProjectId: $ProjectId)
  }
`;

export const GET_BATTERY_NEW_DEVICES = gql`
  query ($itemId: String!) {
    getAllNewDeviceByBatteryLevel(itemId: $itemId)
  }
`;

import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Loading from "../../../components/loading/Loading";
import { AuthContext } from "../../../context/AuthProvider";
import { Tooltip } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MuiAlert from "@material-ui/lab/Alert";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import UserToggleSwitch from "../../../components/toggleSwitch/UserToggleSwitch";
import ResponsiveDialog from "../../../components/userManagement/optionsUsers/addUserButton";
import FullScreenDialog from "../../../components/userManagement/editUsers/EditUser";
import EditUser from "../../../components/userManagement/editUsers/EditUser";
import { Fab } from "@material-ui/core";
import { ModalAlertUserStatusChange } from "../../../components/roleManager/ModalAlertUserStatusChange";
import { decryptData } from "../../../utils/encryptDecrypt";

//multilanguage
import { useTranslation } from "react-i18next";

//query & mutation
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_ALL_USER,
  UPDATE_MANY_USERS,
} from "../../../controllers/usersControllers";
import { type } from "os";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: 25,
  },
  contents: {
    alignItems: "center",
    justifyContent: "center",
  },
  options: {
    marginBottom: "2%",
    marginTop: "2%",
    justifyContent: "center",
    alignItems: "center",
  },
});

function RowMenuCell() {
  return (
    <div>
      <IconButton>
        <VisibilityIcon />
      </IconButton>
    </div>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const DataTable = (iddUser) => {
  const { t } = useTranslation();
  const [idd, setIdd] = React.useState("");
  const [worker, setWorker] = React.useState(null);

  const classes = useStyles();
  const {
    users,
    setUsers,
    openModalEditForm,
    setOpenModalEditForm,
    permissionsRoleUser,
    openCreateUserModal,
    setOpenCreateUserModal,
    openEditUserModal,
    setOpenEditUserModal,
    openChangeUserStatusModal,
    setOpenChangeUserStatusModal,
    alertEdit,
  } = React.useContext(AuthContext);

  const [selectedUser, setSelectedUser] = React.useState(null);
  const [searchUser, setSearchUser] = React.useState("");
  const [inputCleared, setInputCleared] = React.useState(false);
  const { data, loading, error, refetch } = useQuery(GET_ALL_USER, {
    fetchPolicy: "network-only",
  });

  const { test, setTest } = React.useState();

  React.useEffect(() => {
    if (data) {
      const filteredUsers = data?.getAllUsers?.filter((user) =>
        user?.user?.toLowerCase().includes(searchUser.toLowerCase())
      );
      setUsers(filteredUsers);
      setCurrentPage(1);
    }
  }, [data, searchUser]);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loadingText = open && options.length === 0;
  const [decryptedUsers, setDecryptedUsers] = React.useState([]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = users.slice(firstIndex, lastIndex);
  const totalPages = Math.ceil(users.length / recordsPerPage);
  const numbers = [...Array(totalPages + 1).keys()].slice(1);

  // const decryptRecords = async () => {
  //   let decrypted = [...data.getAllUsers]; // Copia el array original
  //   for (let i = firstIndex; i < lastIndex; i++) {
  //     decrypted[i].cellPhone = await decryptData(
  //       decrypted[i].cellPhone,
  //       password
  //     );
  //     decrypted[i].email = await decryptData(decrypted[i].email, password);
  //     decrypted[i].identification_number = await decryptData(
  //       decrypted[i].identification_number,
  //       password
  //     );
  //   }
  //   setDecryptedUsers(decrypted);
  // };

  const [DecryptDataUsers, setDecryptDataUsers] = React.useState([]);
  const password = process.env.REACT_APP_ENCRYPT_DECRYPT_KEY;
  const dataUsers = users?.map((e) => {
    if (users?.includes(e.id)) {
      return { id: e.id, enabled: e.enabled };
    }
  });

  const filterUser = dataUsers?.filter((i) => i !== undefined);
  filterUser?.map((e) => {
    if (e.enabled == true) {
      e.enabled = false;
    } else {
      e.enabled = true;
    }
  });

  React.useEffect(() => {
    let active = true;

    if (!loadingText) {
      return undefined;
    }

    (async () => {
      await sleep(1e3);

      if (active) {
        const filteredOptions = users?.filter((users) =>
          users?.user?.toLowerCase().includes(searchUser.toLowerCase())
        );
        setOptions([...filteredOptions]);
      }
    })();
    return () => {
      active = false;
    };
  }, [loadingText, searchUser]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  /*  React.useEffect(() => {
    if (records) {
      decryptRecords();
    }
  }, [records]); */

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenModalEditForm(false);
    // refetch();
  };

  const handleOpenCreateUserModal = () => {
    setOpenCreateUserModal(!openCreateUserModal);
  };

  const handleOpenEditUserModal = (id) => {
    setIdd(id);
    setOpenEditUserModal(!openEditUserModal);
    const auxiliar = users.find((i) => i.id === id);
  };

  // function UpdateEnabledUsers() {
  //   try {
  //     UpdateManyUsers({
  //       variables: { input: filterUser },
  //     })
  //       .then((resul) => {
  //         // refetch();
  //       })
  //       .catch((err) => {});
  //   } catch (err) {}
  // }

  // const handleEnabledOrDisabledUserButton = (id) => {
  //   setOpenChangeUserStatusModal(!openChangeUserStatusModal);
  //   setIdd(id);
  // };

  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changeCurrentPage = (id, searchUser) => {
    setCurrentPage(id);
    setSelectedUser(searchUser);
  };

  const nextPage = () => {
    if (currentPage !== totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleInputChange = (event) => {
    const inputUser = event.target.value;
    const matchingUser = users.find(
      (user) => user?.name?.toLowerCase() === inputUser.toLowerCase()
    );
    if (matchingUser) {
      setSelectedUser(matchingUser);
    } else {
      setSelectedUser(null);
    }
    setSearchUser(inputUser);
    setInputCleared(false);
  };

  const handleAutocompleteChange = (event, value) => {
    if (value) {
      setSelectedUser(value);
      setSearchUser(value.name);
      const pageIndex = Math.ceil(
        users.findIndex((users) => users?.name === value?.name + 1) /
          recordsPerPage
      );
      setCurrentPage(pageIndex);
    } else {
      setSelectedUser(null);
      setSearchUser("");
      setInputCleared(true);
      setCurrentPage(1);
    }
  };

  if (error) {
    return (
      <div>
        <lottie-player
          style={{
            width: "70%",
            marginLeft: "30%",
            marginRight: "auto",
          }}
          className="_logo"
          src="https://assets1.lottiefiles.com/private_files/lf30_tonsVH.json"
          background="transparent"
          speed="1"
          loop
          autoplay
        ></lottie-player>
      </div>
    );
  }

  if (loading || alertEdit) {
    return (
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="user_datatable_container">
        <div className="user_container_header">
          <div className="user_table_title_container">
            <h1 className="user_table_title">
              {t("VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.TABLE_TITLE")}
            </h1>
          </div>
          <div className="container_button_agreger_and_filter">
            <div className="content_header_user_button">
              {permissionsRoleUser?.permissionsRole?.GesRolCrear ? (
                <Tooltip
                  title={t(
                    "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.ADD_USER_BUTTON_TOOLIP"
                  )}
                >
                  <button
                    className="user_button_agreger"
                    onClick={handleOpenCreateUserModal}
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                </Tooltip>
              ) : null}
            </div>
            <div className="user_filter_container">
              <Autocomplete
                id="user_filter"
                sx={{ width: 250 }}
                value={selectedUser}
                inputValue={inputCleared ? "" : searchUser}
                disableClearable={true}
                onChange={handleAutocompleteChange}
                open={open}
                onInput={handleInputChange}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                options={options}
                loading={loadingText}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t(
                      "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.SEARCH_INPUT"
                    )}
                    size="small"
                    value={searchUser}
                    InputProps={{
                      endAdornment: (
                        <React.Fragment>
                          {loadingText ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                      sx: { borderRadius: 2 },
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="user_table_container">
          <table className="user_datatable">
            <thead className="user_container_title_table">
              <tr>
                <th>
                  <h3 className="user_name">
                    {t("VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.NAME")}
                  </h3>
                </th>
                <th>
                  <h3 className="user_lastname">
                    {t(
                      "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.LAST_NAME"
                    )}
                  </h3>
                </th>
                <th>
                  <h3 className="user_username">
                    {t("VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.USER")}
                  </h3>
                </th>
                <th>
                  <h3 className="user_countrycode">
                    {t(
                      "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COUNTRY"
                    )}
                  </h3>
                </th>
                <th>
                  <h3 className="user_cellphone">
                    {t(
                      "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.CELLPHONE"
                    )}
                  </h3>
                </th>
                <th>
                  <h3 className="user_email">
                    {t("VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.EMAIL")}
                  </h3>
                </th>
                <th>
                  <h3 className="user_enabled_disabled">
                    {t(
                      "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.MODIFICATIONS"
                    )}
                  </h3>
                </th>
              </tr>
            </thead>
            {records?.map((item, key) => (
              <tbody key={key} className="user_container_table_body">
                <>
                  <tr>
                    <td className="user_container_name_column">{item.name}</td>
                    <td className="user_container_lastname">
                      {item.last_name}
                    </td>
                    <td className="user_container_username">{item.user}</td>
                    <td className="user_container_countrycode">
                      {item.countryCode === null ? "" : `+${item.countryCode}`}
                    </td>
                    <td className="user_container_cellphone">
                      ************* {/* {item.cellPhone} */}
                    </td>
                    <td className="user_container_email">
                      {" "}
                      ************* {/* {item.email} */}
                    </td>
                    <td className="user_container_acctions">
                      {permissionsRoleUser?.permissionsRole?.GesRolEditar ? (
                        <Tooltip
                          title={t(
                            "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.MODAL_EDIT_TOOLTIP"
                          )}
                        >
                          <ModeEditIcon
                            className="user_edit_button"
                            onClick={() => handleOpenEditUserModal(item.id)}
                          />
                        </Tooltip>
                      ) : null}

                      {permissionsRoleUser?.permissionsRole?.GesRolDes ? (
                        <UserToggleSwitch
                          currentIdUser={item.id}
                          currentUserStatus={item.enabled}
                        />
                      ) : null}
                    </td>
                  </tr>
                </>
              </tbody>
            ))}
          </table>
        </div>
      </div>

      <div className="users_pagination">
        <Fab
          size="small"
          aria-label="left"
          className="user_pagination_button"
          onClick={prePage}
          disabled={currentPage <= 1}
        >
          <ChevronLeftIcon />
        </Fab>
        <span>
          {currentPage} / {totalPages}
        </span>
        <Fab
          size="small"
          aria-label="right"
          className="user_pagination_button"
          onClick={nextPage}
          disabled={currentPage === totalPages || totalPages === 0}
        >
          <ChevronRightIcon />
        </Fab>
      </div>
      <ResponsiveDialog />
      <FullScreenDialog />
      <EditUser iddUser={idd} dataUsers={users} refetch={refetch} />
      <ModalAlertUserStatusChange iddUser={idd} setIdd={setIdd} />
    </>
  );
};

export default DataTable;

import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import { ModalAlertUserStatusChange } from "../roleManager/ModalAlertUserStatusChange";

//components
import { WrapperSwitch, StyledSwitch } from "./style/ToggleSwitch.style";

//multilanguage
import { useTranslation } from "react-i18next";

function UserToggleSwitch({ currentIdUser, currentUserStatus }) {
  const { t } = useTranslation();
  const [openChangeUserStatusFromToggle, setOpenChangeUserStatusFromToggle] =
    useState(false);

  const [roleId, setRoleId] = useState("");
  const [checkedUserToggle, setCheckedUserToggle] = useState(currentUserStatus);

  const handleOpenChangeRoleStatusModal = () => {
    setRoleId(currentIdUser);
    setOpenChangeUserStatusFromToggle(true);
  };

  return (
    <WrapperSwitch>
      <StyledSwitch className="StyledSwitch">
        <Tooltip
          title={
            currentUserStatus === true
              ? t(
                  "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.USER_TOGGLE_TOOLTIP_DISABLE"
                )
              : t(
                  "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.USER_TOGGLE_TOOLTIP_ENABLE"
                )
          }
        >
          <label className="switch">
            <input
              type="checkbox"
              onChange={handleOpenChangeRoleStatusModal}
              checked={currentUserStatus === true}
            />
            <div className="slider round"></div>
          </label>
        </Tooltip>
      </StyledSwitch>
      <ModalAlertUserStatusChange
        openChangeUserStatusFromToggle={openChangeUserStatusFromToggle}
        setOpenChangeUserStatusFromToggle={setOpenChangeUserStatusFromToggle}
        checkedUserToggle={checkedUserToggle}
        setCheckedUserToggle={setCheckedUserToggle}
        roleId={roleId}
      />
    </WrapperSwitch>
  );
}

export default UserToggleSwitch;

import React, { useEffect, useContext, useState } from "react";
import Switch from "@material-ui/core/Switch";
import { Devices } from "../../../constants/devices_types";
import { green } from "@material-ui/core/colors";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import BatteryUnknownIcon from "@material-ui/icons/BatteryUnknown";
import BatteryFullIcon from "@material-ui/icons/BatteryFull";
import Battery90Icon from "@material-ui/icons/Battery90";
import Battery80Icon from "@material-ui/icons/Battery80";
import Battery60Icon from "@material-ui/icons/Battery60";
import Battery50Icon from "@material-ui/icons/Battery50";
import Battery30Icon from "@material-ui/icons/Battery30";
import Battery20Icon from "@material-ui/icons/Battery20";
import BatteryAlertIcon from "@material-ui/icons/BatteryAlert";
import { AuthContext } from "../../../context/AuthProvider";
import Loading_devices from "../../loading/Loading_devices";
import Swal from "sweetalert2";
import alert from "../../../assets/alert-icon.png";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import ModalDoorLock from "../../modal/ModalDoorLock";
import { Link } from "react-router-dom";

const innerTheme = createTheme({
  palette: {
    secondary: {
      main: green[500],
    },
  },
});

const StyledIconButton = styled(IconButton)({
  backgroundColor: "#EEEEEE",
  "&:hover": {
    backgroundColor: "#CCCCCC",
  },
});

const Cerradura = ({ item, send, contId }) => {
  const {
    infoMessage,
    setInfoMessage,
    successMessage,
    setSuccessMessage,
    errorMessage,
    setErrorMessage,
    controllerId,
  } = useContext(AuthContext);

  const [state, setState] = useState(item.variables.Status == "1" ? true : false);
  const [BatteryLevel, setBatteryLevel] = useState(item.variables.BatteryLevel);
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setLoad(false);
  }, [errorMessage, successMessage]);

  useEffect(() => {
    setState(item.variables.Status == "1" ? true : false);
    if (item.variables.BatteryLevel) {
      setBatteryLevel(item.variables.BatteryLevel);
    }
  }, [item]);

  const handleChange = async (event) => {
    await Swal.fire({
      title: "¿Estás seguro?",
      text: "Esta acción cambiará el estado del dispositivo",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, estoy seguro!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoad(true);
        call();
        setState(!state);
      }
    });
  };

  function call() {
    return new Promise(() => {
      setTimeout(() => {
        send(item, state);
      }, 2000);
    });
  }

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <div className='_generic_container'>
      <div
        className={`_generics_container_title_status ${
          item.variables.CommFailure == "1" ? "__status_comfailure__" : null
        }`}
      >
        <div className='_generic__title'>
          <span>{item.alias}</span>
        </div>
        <div
          className={`${
            item.variables.CommFailure == "1"
              ? "_generic__status__alert"
              : "__generic_sensor_wifi"
          }`}
        >
          {item.variables.CommFailure == "1" ? (
            <span className='__icon_alert_sensor'>
              <img src={alert} alt='alert' width='22' height='22' />
            </span>
          ) : (
            <div className='_battery__status'>
              <div className='__percent'>
                {BatteryLevel && <span>{BatteryLevel} %</span>}
              </div>
              <div className='__battery'>
                <span
                  className={
                    !BatteryLevel
                      ? "__icon_battery_sensor_unknown"
                      : BatteryLevel < 10
                      ? "__icon_battery_sensor_alert"
                      : "__icon_battery_sensor"
                  }
                >
                  {!BatteryLevel ? (
                    <BatteryUnknownIcon />
                  ) : BatteryLevel > 90 ? (
                    <BatteryFullIcon />
                  ) : BatteryLevel > 80 ? (
                    <Battery90Icon />
                  ) : BatteryLevel > 70 ? (
                    <Battery80Icon />
                  ) : BatteryLevel > 50 ? (
                    <Battery60Icon />
                  ) : BatteryLevel > 40 ? (
                    <Battery50Icon />
                  ) : BatteryLevel > 20 ? (
                    <Battery30Icon />
                  ) : BatteryLevel > 10 ? (
                    <Battery20Icon />
                  ) : (
                    <BatteryAlertIcon />
                  )}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='_generic_container_clasification_action'>
        <div
          className={`_generic_clasification ${load ? "__loading_clasification" : false}`}
        >
          <span>
            <img
              src={state ? Devices.DOOR_BLOQUEADO : Devices.DOOR_DESBLOQUEADO}
              alt='clasification'
              width='60'
              height='60'
            />
          </span>
        </div>
        <div className='__loading_devices'>{load ? <Loading_devices /> : false}</div>
        <div
          className={`_generic_toggle row-decoration ${
            load ? "__loading_toogle" : false
          }`}
        >
          <div>
            <ThemeProvider theme={innerTheme}>
              <Switch size='medium' checked={state} onChange={handleChange} />
            </ThemeProvider>
            <br></br>
            {state ? <span>Bloqueado</span> : <span>Desbloqueado</span>}{" "}
          </div>
        </div>
        <div className='_generic_clasification'>
          <Link to={`../doorlock/${contId}/${item.zwaveId}`}>
            <StyledIconButton>
              <RoomPreferencesIcon />
            </StyledIconButton>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Cerradura;

import React from "react";
import { Tooltip } from "@mui/material";

//components
import { WrapperSwitch, StyledSwitch } from "./style/ToggleSwitch.style";
import { ModalAlertChangesRoles } from "../../components/roleManager/ModalAlertChangesRoles";

//multilanguage
import { useTranslation } from "react-i18next";

function RolesToggleSwitch({ currentIdRole, currentRoleStatus }) {
  const { t } = useTranslation();
  const [openChangeRoleStatusFromToggle, setOpenChangeRoleStatusFromToggle] =
    React.useState(false);
  const [roleId, setRoleId] = React.useState("");
  const [checkedToggle, setCheckedToggle] = React.useState(currentRoleStatus);

  const handleOpenChangeRoleStatusModal = () => {
    setRoleId(currentIdRole);
    setOpenChangeRoleStatusFromToggle(true);
  };

  return (
    <WrapperSwitch>
      <StyledSwitch>
        <Tooltip
          title={
            currentRoleStatus === true
              ? t(
                  "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_ROLES_TABLE.ROLE_TOGGLE_TOOLTIP_DISABLE"
                )
              : t(
                  "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_ROLES_TABLE.ROLE_TOGGLE_TOOLTIP_ENABLE"
                )
          }
        >
          <label className="switch">
            <input
              type="checkbox"
              onClick={handleOpenChangeRoleStatusModal}
              checked={currentRoleStatus === true}
            />
            <div className="slider round"></div>
          </label>
        </Tooltip>
      </StyledSwitch>
      <ModalAlertChangesRoles
        openChangeRoleStatusFromToggle={openChangeRoleStatusFromToggle}
        setOpenChangeRoleStatusFromToggle={setOpenChangeRoleStatusFromToggle}
        checkedToggle={checkedToggle}
        setCheckedToggle={setCheckedToggle}
        roleIdd={roleId}
      />
    </WrapperSwitch>
  );
}

export default RolesToggleSwitch;

import React from 'react'
import { Link } from "react-router-dom"
import { ListItemIcon, ListItem, ListItemText } from "@material-ui/core";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import ListIcon from "@material-ui/icons/List";

export default function DirectAccess() {
    return (
        <div className='__directaccess_container__'>
            {/* <div className="sidebar_menu">
                <span className="__menu">Acceso rápido</span>
            </div> */}
            {/* <ListItem>
              <ListItemIcon className="_listitem_icon">
                <ListIcon />
              </ListItemIcon>
              <ListItemText
                primary={`Posada guadalajara 1970`}
                className="_listitem_name_projects"
              />
            </ListItem> */}
            <div>
                {/* <Link to={"/app/areas/" + 15} className="_sidebar_item">
                  <ListItem
                    button
                    // onClick={() => change_project_id(15)}
                    className={`${
                      window.location.pathname == "/app/areas/" + 15 ||
                      window.location.pathname.substring(0, 13) +
                        localStorage.getItem("id") ==
                        `/app/devices/${15}`
                        ? "__on_item"
                        : "__off_item"
                    }`}
                  >
                    <ListItemIcon className="__icon_hab_and_control">
                      <HomeWorkIcon />
                    </ListItemIcon>
                    <ListItemText primary="Áreas" className="_sidebar_text" />
                  </ListItem>
                </Link> */}
            </div>
        </div>
    )
}

import React, { useState, useEffect, useContext } from "react";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import {
  Dialog,
  AppBar,
  Typography,
  Slide,
  IconButton,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import {
  calc_percent_watts,
  cacl_color_graph_measurer,
} from "../../utils/helpers";
import CloseIcon from "@material-ui/icons/Close";
import { AuthContext } from "../../context/AuthProvider";
import { addDays } from "date-fns";
import Button from "@material-ui/core/Button";
import html2canvas from "html2canvas";
import { green } from "@material-ui/core/colors";
import MeasurerPhases from "../highcharts/MeasurerPhases";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#3f9948",
    position: "fixed",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const innerTheme = createTheme({
  palette: {
    secondary: {
      main: green[500],
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//Meters-{id proyecto}
const HeavyDuty = ({ data, timeZone, send }) => {
  const {
    event,
    setDateReport,
    setactiveNextBack,
    serial,
    errorMessage,
    successMessage,
  } = useContext(AuthContext);
  const [load, setLoad] = React.useState(false);
  const [openMeasurer, setOpenMeasurer] = useState(false);
  const [state, setState] = useState(data.variables.Status == 1 ? true : false);
  const [watts, setWatts] = useState(data.variables.Watts);
  const [kwh, setKWH] = useState(data.variables.KWH);

  const classes = useStyles();

  useEffect(() => {
    setLoad(false);
  }, [errorMessage, successMessage]);

  const handleClose = () => {
    setOpenMeasurer(false);
    setactiveNextBack(false);
  };

  const handleChange = async () => {
    await Swal.fire({
      title: "¿Estás seguro?",
      text: "Esta acción cambiará el estado del dispositivo",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, estoy seguro!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoad(true);
        call();
        setState(!state);
      }
    });
  };

  function call() {
    return new Promise(() => {
      setTimeout(() => {
        send(data, !state);
      }, 2000);
    });
  }

  const handleOpenReportPhases = () => {
    setOpenMeasurer(true);
    setactiveNextBack(true);
    setDateReport([
      {
        startDate: new Date(),
        endDate: addDays(new Date(), 0),
        key: "selection",
      },
    ]);
  };

  return (
    <>
      <div className="_measurer_container_">
        <div className="_heavyduty_title_container_">
          <div className="_heavyduty__title_">
            <span>{data.alias}</span>
          </div>
          <div className="_heavyduty__title_">
            <ThemeProvider theme={innerTheme}>
              <Switch size="medium" checked={state} onChange={handleChange} />
            </ThemeProvider>
          </div>
        </div>
        <div
          className="_measurer_container_kwh_"
          onClick={handleOpenReportPhases}
        >
          <div className="_measurer_data_container_">
            <span className="_measurer_data_number_">
              {Number(
                `${
                  data.id == event.id_device && event.action == "KWH"
                    ? event.status
                    : data.variables.KWH
                }`
              ).toFixed(1)}
            </span>
            <span className="_measurer_data_kwh_">kWh</span>
          </div>
        </div>
        <div
          className="_measurer_container_watts_"
          onClick={handleOpenReportPhases}
        >
          <SemiCircleProgressBar
            diameter={150}
            stroke={cacl_color_graph_measurer(data.variables.Watts)}
            percentage={`${calc_percent_watts(
              `${
                data.id == event.id_device && event.action == "Watts"
                  ? event.status
                  : data.variables.Watts
              }`
            )}`}
          />
        </div>
        <div
          className="_measurer_container_range_"
          onClick={handleOpenReportPhases}
        >
          <span className="_measurer__range_">0</span>
          <span className="_measurer__range_">15000</span>
        </div>
        <div
          className="_measurer_container_value_"
          onClick={handleOpenReportPhases}
        >
          {Number(
            `${
              data.id == event.id_device && event.action == "Watts"
                ? event.status
                : data.variables.Watts
            }`
          ).toFixed(1) | 0}{" "}
          Watts
        </div>
      </div>
      <Dialog
        fullScreen
        open={openMeasurer}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Reporte de fases del {data.alias}
            </Typography>
          </Toolbar>
        </AppBar>
        <MeasurerPhases
          {...{
            timeZone,
          }}
          measurer={data}
        />
      </Dialog>
    </>
  );
};
export default HeavyDuty;

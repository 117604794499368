import React, { useEffect, useContext } from "react";
import Switch from "@material-ui/core/Switch";
import { Devices } from "../../constants/devices_types";
import { green } from "@material-ui/core/colors";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import BatteryUnknownIcon from "@material-ui/icons/BatteryUnknown";
import BatteryFullIcon from "@material-ui/icons/BatteryFull";
import Battery90Icon from "@material-ui/icons/Battery90";
import Battery80Icon from "@material-ui/icons/Battery80";
import Battery60Icon from "@material-ui/icons/Battery60";
import Battery50Icon from "@material-ui/icons/Battery50";
import Battery30Icon from "@material-ui/icons/Battery30";
import Battery20Icon from "@material-ui/icons/Battery20";
import BatteryAlertIcon from "@material-ui/icons/BatteryAlert";
import { AuthContext } from "../../context/AuthProvider";
import Loading_devices from "../loading/Loading_devices";
import Swal from "sweetalert2";
import alert from "../../assets/alert-icon.png";
import GraphBatteryModal from "./GraphBatteryLevel/GraphBatteryModal";
import { ExtractTypeGateway } from "../../utils/helpers";

//multilanguage
import { useTranslation } from "react-i18next";

const innerTheme = createTheme({
  palette: {
    secondary: {
      main: green[500],
    },
  },
});
const SensorMovimiento = ({ item, send, areaName }) => {
  const typeGateway = ExtractTypeGateway();
  const { t } = useTranslation();
  const {
    infoMessage,
    setInfoMessage,
    successMessage,
    setSuccessMessage,
    errorMessage,
    setErrorMessage,
    permissionsRoleUser,
  } = useContext(AuthContext);
  const [state, setState] = React.useState(
    item?.variables?.Tripped == "1" ? true : false
  );
  const [BatteryLevel, setBatteryLevel] = React.useState(
    item?.variables?.BatteryLevel
  );
  const [armed, setArmed] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleCloseBattery = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setLoad(false);
  }, [errorMessage, successMessage]);

  useEffect(() => {
    setState(item?.variables?.Tripped == "1" ? true : false);
    if (item?.variables?.Armed) {
      setArmed(item?.variables?.Armed == "1" ? true : false);
    }
    if (item?.variables?.BatteryLevel) {
      setBatteryLevel(item?.variables?.BatteryLevel);
    }
  }, [item]);

  const handleChange = async (event) => {
    // await Swal.fire({
    //   title: t('VIEW_AREA.COMPONENT_SWITCH_ALERT_CARD.TITLE'),
    //   text: t('VIEW_AREA.COMPONENT_SWITCH_ALERT_CARD.TEXT'),
    //   icon: 'info',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: t('VIEW_AREA.COMPONENT_SWITCH_ALERT_CARD.CONFIRMBUTTONTEXT'),
    // }).then((result) => {
    // if (result.isConfirmed) {
    setLoad(true);
    call();
    setArmed(!armed);
    //   }
    //  });
  };

  function call() {
    return new Promise(() => {
      setTimeout(() => {
        send(item, armed);
      }, 2000);
    });
  }
  return (
    <div className="_generic_container">
      <div
        className={`_generics_container_title_status ${
          item?.variables?.CommFailure == "1" ? "__status_comfailure__" : null
        }`}
      >
        <div className="_generic__title">
          <span>{item?.alias}</span>
        </div>
        <div
          className={`${
            item?.variables?.CommFailure == "1"
              ? "_generic__status__alert"
              : "__generic_sensor_wifi"
          }`}
        >
          {item?.variables?.CommFailure == "1" ? (
            <>
              <div className="batery_status_off_line">
                <div className="probando">
                  {BatteryLevel && <span>{BatteryLevel} %</span>}
                </div>
                <div className="laBateria" onClick={handleCloseBattery}>
                  <span
                    className={
                      !BatteryLevel
                        ? "__icon_battery_sensor_unknown"
                        : BatteryLevel < 10
                        ? "__icon_battery_sensor_alert"
                        : "__icon_battery_sensor"
                    }
                  >
                    {!BatteryLevel ? (
                      <BatteryUnknownIcon />
                    ) : BatteryLevel > 90 ? (
                      <BatteryFullIcon />
                    ) : BatteryLevel > 80 ? (
                      <Battery90Icon />
                    ) : BatteryLevel > 70 ? (
                      <Battery80Icon />
                    ) : BatteryLevel > 50 ? (
                      <Battery60Icon />
                    ) : BatteryLevel > 40 ? (
                      <Battery50Icon />
                    ) : BatteryLevel > 20 ? (
                      <Battery30Icon />
                    ) : BatteryLevel > 10 ? (
                      <Battery20Icon />
                    ) : (
                      <BatteryAlertIcon />
                    )}
                  </span>
                </div>
                <div className="__icon_alert_sensor">
                  <img src={alert} alt="alert" width="22" height="22" />
                </div>
              </div>
            </>
          ) : (
            <div className="_battery__status" onClick={handleCloseBattery}>
              <div className="__percent">
                {BatteryLevel && <span>{BatteryLevel} %</span>}
              </div>
              <div className="__battery">
                <span
                  className={
                    !BatteryLevel
                      ? "__icon_battery_sensor_unknown"
                      : BatteryLevel < 10
                      ? "__icon_battery_sensor_alert"
                      : "__icon_battery_sensor"
                  }
                >
                  {!BatteryLevel ? (
                    <BatteryUnknownIcon />
                  ) : BatteryLevel > 90 ? (
                    <BatteryFullIcon />
                  ) : BatteryLevel > 80 ? (
                    <Battery90Icon />
                  ) : BatteryLevel > 70 ? (
                    <Battery80Icon />
                  ) : BatteryLevel > 50 ? (
                    <Battery60Icon />
                  ) : BatteryLevel > 40 ? (
                    <Battery50Icon />
                  ) : BatteryLevel > 20 ? (
                    <Battery30Icon />
                  ) : BatteryLevel > 10 ? (
                    <Battery20Icon />
                  ) : (
                    <BatteryAlertIcon />
                  )}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="_generic_container_clasification">
        <div
          className={`_generic_clasification ${
            load ? "__loading_clasification" : false
          }`}
        >
          <span>
            <img
              src={
                state ? Devices.MOTION_SENSOR_TRIPPED : Devices.MOTION_SENSOR
              }
              alt="clasification"
              width="60"
              height="60"
            />
          </span>
        </div>
        <div className="__loading_devices">
          {load ? <Loading_devices /> : false}
        </div>
        <div
          className={`_generic_toggle row-decoration ${
            load ? "__loading_toogle" : false
          }`}
        >
          <div>
            <ThemeProvider theme={innerTheme}>
              <Switch
                size="medium"
                checked={armed}
                onChange={
                  permissionsRoleUser?.permissionsRole?.AreaDispMan
                    ? handleChange
                    : false
                }
              />
            </ThemeProvider>
            <br></br>
            {!armed ? (
              <span>
                {t("VIEW_AREA.COMPONENT_MOVEMENT_SENSOR.DISASSEMBLED")}
              </span>
            ) : (
              <span>{t("VIEW_AREA.COMPONENT_MOVEMENT_SENSOR.ASSEMBLED")}</span>
            )}{" "}
          </div>
        </div>
      </div>
      <GraphBatteryModal
        areaName={areaName}
        open={open}
        name={item?.alias}
        handleClose={handleCloseBattery}
        id={item?.id}
        typeGateway={typeGateway}
      />
    </div>
  );
};

export default SensorMovimiento;

import React, { useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
// import IconButton from "@material-ui/core/IconButton";
// import CloseIcon from "@mui/icons-material/Close";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthContext } from "../../../context/AuthProvider";
import { useHistory } from "react-router-dom";

const customGreen = "#acf6ac";
const customRed = "#f19393";

const theme = createTheme({
  palette: {
    success: {
      main: customGreen,
    },
    error: {
      main: customRed,
    },
  },
});

const CancelInstallationModal = () => {
  const history = useHistory();
  const { openCancelInstallation, setOpenCancelInstallation } =
    useContext(AuthContext);

  const handleClose = () => {
    setOpenCancelInstallation(false);
  };

  const handleCancelInstallation = () => {
    history.push("/app/devicesmanagement");
    setOpenCancelInstallation(false);
    localStorage.setItem("existNewMeters", "0");
    localStorage.setItem("id", "");
    localStorage.setItem("firstSerial", "");
    localStorage.setItem("alias", "");
    localStorage.setItem("maxCurrent", "");
    localStorage.setItem("tcRelation", "");
    localStorage.setItem("powerFactor", "");
    localStorage.setItem("secondAlias", "");
    localStorage.setItem("maxCurrent", "");
    localStorage.setItem("secondMaxCurrent", "");
    localStorage.setItem("tcRelation", "");
    localStorage.setItem("powerFactor", "");
    localStorage.setItem("secondTcRelation", "");
    localStorage.setItem("secondPowerFactor", "");
    localStorage.setItem("firstSerial", "");
    localStorage.setItem("secondSerial", "");
    localStorage.setItem("showMeters", false);
  };

  return (
    <>
      <Dialog
        open={openCancelInstallation}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "8px",
            width: "30%",
            height: "30%",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
            fontFamily: "Montserrat, sans-serif",
            backgroundColor: "#2EB142",
            color: "#FFFFFF",
            fontSize: "20px",
            marginBottom: "15px",
            lineHeight: "2.6",
            padding: "0px",
          }}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
              fontFamily: "Montserrat, sans-serif",
              fontSize: "22px",
              fontWeight: "bold",
            }}
          >
            Cancelación de Instalación
          </span>
        </DialogTitle>

        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "40px",
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                width: "90%",
                fontFamily: "Montserrat, sans-serif",
                fontSize: "17px",
              }}
            >
              La instalación no se ha completado ¿Desea cancelar el proceso?
            </span>
            <div style={{ display: "flex", gap: "40px" }}>
              <ThemeProvider theme={theme}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleClose}
                  sx={{
                    color: "#000",
                    borderRadius: "8px",
                    border: "solid #e76666 ",
                    "&:hover": { backgroundColor: "#ec7979" },
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleCancelInstallation}
                  sx={{
                    color: "#000",
                    borderRadius: "8px",
                    border: "solid #89cf90",
                    "&:hover": { backgroundColor: "#8ae38a" },
                  }}
                >
                  Aceptar
                </Button>
              </ThemeProvider>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CancelInstallationModal;

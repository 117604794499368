import React, { useEffect, useState, useContext } from 'react';
import { Devices } from '../../constants/devices_types';
import { AuthContext } from '../../context/AuthProvider';
import Loading_devices from '../loading/Loading_devices';
import Swal from 'sweetalert2';
import { changeNamePluggin } from '../../utils/helpers';
import BatteryUnknownIcon from '@material-ui/icons/BatteryUnknown';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import Battery90Icon from '@material-ui/icons/Battery90';
import Battery80Icon from '@material-ui/icons/Battery80';
import Battery60Icon from '@material-ui/icons/Battery60';
import Battery50Icon from '@material-ui/icons/Battery50';
import Battery30Icon from '@material-ui/icons/Battery30';
import Battery20Icon from '@material-ui/icons/Battery20';
import BatteryAlertIcon from '@material-ui/icons/BatteryAlert';

//multilanguage
import { useTranslation } from 'react-i18next';

const HSC = ({ item, send, projectData }) => {
  const { t } = useTranslation();
  const [BatteryLevel, setBatteryLevel] = React.useState(item.variables?.BatteryLevel);
  const [libre, setLibre] = useState(false);
  const [manual, setManual] = useState(false);
  const [apagado, setApagado] = useState(false);
  const [auto, setAuto] = useState(false);
  const [load, setLoad] = React.useState(false);
  const { successMessage, errorMessage, permissionsRoleUser } = useContext(AuthContext);
  useEffect(() => {
    switch (item.variables.Modo) {
      case 'Manual':
        setManual(true);
        setApagado(false);
        setAuto(false);
        break;
      case 'Apagado':
        setManual(false);
        setApagado(true);
        setAuto(false);
        break;
      case 'Auto':
        setManual(false);
        setApagado(false);
        setAuto(true);
        break;
      default:
        return false;
    }
    setLibre(item.variables.EstadoTexto === 'Libre' ? true : false);
  }, [item]);

  useEffect(() => {
    setLoad(false);
  }, [errorMessage, successMessage]);

  async function action(event) {
    await Swal.fire({
      title: t('VIEW_AREA.COMPONENT_SWITCH_ALERT_CARD.TITLE'),
      text: t('VIEW_AREA.COMPONENT_SWITCH_ALERT_CARD.TEXT'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('VIEW_AREA.COMPONENT_SWITCH_ALERT_CARD.CONFIRMBUTTONTEXT'),
    }).then((result) => {
      if (result.isConfirmed) {
        setLoad(true);
        switch (event) {
          case 1:
            if (!libre) {
              call('Libre', 1);
              setLibre(!libre);
            }
            break;
          case 2:
            if (libre) {
              call('Ocupado', 2);
              setLibre(false);
            }
            break;
          case 3:
            if (!manual) {
              setManual(true);
              setApagado(false);
              setAuto(false);
              call('Manual', 3);
            }
            break;
          case 4:
            if (!apagado) {
              setManual(false);
              setApagado(true);
              setAuto(false);
              call('Apagado', 4);
            }
            break;
          case 5:
            if (!auto) {
              setManual(false);
              setApagado(false);
              setAuto(true);
              call('Auto', 5);
            }
            break;
          default:
        }
      }
    });
  }

  function call(event, action) {
    return new Promise(() => {
      setTimeout(() => {
        send(item, event, action, item.Category.sub_category);
      }, 2000);
    });
  }

  const translatingPluginStatus = () => {
    if (item.variables.AccionTexto === 'Libre') {
      return t('VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.AVAILABLE');
    } else if (item.variables.AccionTexto === 'Ocupado') {
      return t('VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.BUSY');
    } else if (item.variables.AccionTexto === 'Manual') {
      return t('VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.MANUAL');
    } else if (item.variables.AccionTexto === 'Apagado') {
      return t('VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.OFF');
    } else if (item.variables.AccionTexto === 'Auto') {
      return t('VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.AUTO');
    } else if (item.variables.AccionTexto === 'Puerta Abierta') {
      return t('VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.OPEN_DOOR');
    } else if (item.variables.AccionTexto === 'Scan') {
      return t('VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.SCAN');
    } else {
      return null;
    }
  };

  return (
    <div className="_hsc_container">
      <div className="_hsc_container_title_status">
        <div class="_hsc__title">
          <span>{item.alias}</span>
        </div>
        <div className="__battery">
          <div className="__percent">{BatteryLevel && <span>{BatteryLevel}%</span>}</div>
          <span className={!BatteryLevel ? '__icon_battery_sensor_unknown' : BatteryLevel < 10 ? '__icon_battery_sensor_alert' : '__icon_battery_sensor'}>
            {!BatteryLevel ? null : BatteryLevel > 90 ? (
              <BatteryFullIcon />
            ) : BatteryLevel > 80 ? (
              <Battery90Icon />
            ) : BatteryLevel > 70 ? (
              <Battery80Icon />
            ) : BatteryLevel > 50 ? (
              <Battery60Icon />
            ) : BatteryLevel > 40 ? (
              <Battery50Icon />
            ) : BatteryLevel > 20 ? (
              <Battery30Icon />
            ) : BatteryLevel > 10 ? (
              <Battery20Icon />
            ) : (
              <BatteryAlertIcon />
            )}
          </span>
        </div>

        <div className="_hsc_status">
          <span className="__icon_wifi_hsc">
            <i class="fas fa-wifi"></i>
          </span>
        </div>
      </div>

      {/* status home */}
      <div className={`_hsc_ocupation ${load ? '_loading_hsc_other' : false}`}>
        <div className="_hsc_home_img">
          <span>
            <img
              src={libre ? Devices.DESOCUPADA : Devices.OCUPADA}
              alt="home"
              width="70"
              height="70"
            />
          </span>
        </div>
        <div className="_hsc_home_buttons">
          <div
            className="_free_container"
            onClick={
              permissionsRoleUser.permissionsRole?.AreaDispMan
                ? () => {
                    action(1);
                  }
                : false
            }
          >
            <div className={libre ? '__free_bg' : '__free'}>
              <span>{t('VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.AVAILABLE')}</span>
            </div>
          </div>

          <div
            className="_busy_container"
            onClick={
              permissionsRoleUser.permissionsRole?.AreaDispMan
                ? () => {
                    action(2);
                  }
                : false
            }
          >
            <div className={!libre ? '__busy_bg' : '__busy'}>{t('VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.BUSY')}</div>
          </div>
        </div>
      </div>

      <div className="__loading_hsc">{load ? <Loading_devices /> : false}</div>

      {/* status system */}
      <div className={`_hsc_status_system ${load ? '_loading_hsc_other' : false}`}>
        <div className="_hsc_status_system_title">
          <span>{t('VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.SYSTEM_STATUS')}</span>
        </div>
        <>
          <div className={libre ? '__status_system_free' : '__status_system_busy'}>
            <span className="__status_word">{translatingPluginStatus()}</span>
          </div>
        </>
      </div>

      {/* mode */}
      <div className={`_hsc_mode ${load ? '_loading_hsc_other' : false}`}>
        <div className="_hsc_mode_title">
          <span>{t('VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.MODE')}</span>
        </div>
        <div className="__mode_container">
          <span
            className={manual ? '__mode_item_manual_bg' : '__mode_item_manual'}
            onClick={
              permissionsRoleUser.permissionsRole?.AreaDispMan
                ? () => {
                    action(3);
                  }
                : false
            }
          >
            {t('VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.MANUAL')}
          </span>
          <span
            className={apagado ? '__mode_item_off_bg' : '__mode_item_off'}
            onClick={
              permissionsRoleUser.permissionsRole?.AreaDispMan
                ? () => {
                    action(4);
                  }
                : false
            }
          >
            {changeNamePluggin(projectData.id, t)}
          </span>
          <span
            className={auto ? '__mode_item_auto_bg' : '__mode_item_auto'}
            onClick={
              permissionsRoleUser.permissionsRole?.AreaDispMan
                ? () => {
                    action(5);
                  }
                : false
            }
          >
            {t('VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.AUTO')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default HSC;

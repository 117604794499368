import gql from "graphql-tag";

export const GET_ALL_DEVICES = gql`
  query ($RoomId: Int, $ProjectId: Int!, $typeGateway: String) {
    getProjectByIdHeader(ProjectId: $ProjectId)
    getInfoMetersByProject(ProjectId: $ProjectId)
    getMettersInProjets(ProjectId: $ProjectId)
    getAllDevices(RoomId: $RoomId, typeGateway: $typeGateway) {
      id
      name
      alias
      NewRoomId
      Controller {
        id
        serial
        online
        typeGateway
      }
      Device {
        id
        zwaveId
        id_in_controller
        name
        alias
        maker
        version
        setpoint_temp
        visible
        report_energy
        report_occupation
        report_temp
        watts
        watts_min
        watts_off
        previous_id
        variables
        Category {
          id
          name
          category
          sub_category
        }
        Room {
          id
          name
          floor
        }
        RoomAux {
          id
          name
          floor
        }
      }
    }
  }
`;

import React from "react";

const Status = (enabled) => {
  return (
    <span className={`${enabled.data ? "enabled" : "disabled"}`}>
      {String(enabled.data === false ? "Inactivo" : "Activo")}
    </span>
  );
}

export default Status;
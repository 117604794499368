import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ROUTER } from "../../../config/routing/router_per_component";

const Info = () => {
  return (
    <Switch>
      {ROUTER.map((info, key) => (
        <Route
          key={key}
          path={info.path}
          render={() => <info.component {...{ info }} />}
        />
      ))}
    </Switch>
  );
};

export default Info;

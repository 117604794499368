import React, { useContext, useEffect } from "react";
import {
  DescriptionContainer,
  DescriptiontTitle,
  DescriptionText,
} from "../styles/DescriptionView.style";
import Pagination from "../components/Pagination";
import { AuthContext } from "../../../context/AuthProvider";

const Description = ({ info }) => {
  const { projectIdStepper } = useContext(AuthContext);
  return (
    <>
      <DescriptionContainer>
        <DescriptiontTitle>Instrucciones</DescriptiontTitle>
        <DescriptionText>
          <div className="text_1">{info.description}</div>
          <div className="text_2">
            <b className="bold_text">
              El código del proyecto seleccionado es{" "}
              {localStorage.getItem("id")}
            </b>
            , usted deberá ingresar este código en el comunicador, solo el valor
            numérico, sin comillas.
          </div>
        </DescriptionText>
        <DescriptionText>
          <span className="question">
            ¿Ya ingresaste el codigo en el comunicador?
          </span>
          <div className="text_3">
            De ser asi, por favor finalice la instalación haciendo click en el
            boton finalizar.
          </div>
        </DescriptionText>
        <Pagination
          previousButtonColor={"warning"}
          labelPreviousButton={"Salir"}
          nextButtonColor={"success"}
          labelNextButton={"Siguiente"}
          prev={info.prev}
          next={info.next}
        />
      </DescriptionContainer>
    </>
  );
};

export default Description;

import React from "react";
import { useState, useEffect, useContext } from "react";
import { createTheme } from "@material-ui/core/styles";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { AuthContext } from "../../../context/AuthProvider";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const AutoComplete = (props) => {
  const { dataFilter, enabled } = props;
  const { setSelectedProject, setProjectIdStepper } = useContext(AuthContext);
  const [options, setOptions] = React.useState([]);
  const [open, setOpen] = useState(false);
  const loadingText = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loadingText) {
      return undefined;
    }

    (async () => {
      await sleep(1e3);

      if (active) {
        setOptions([...dataFilter]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingText]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleInputChange = (event, value) => {
    const inputSelection = value?.name;
    const idSelection = value?.id;
    if (inputSelection) {
      setSelectedProject(inputSelection);
      localStorage.setItem("inputSelection", String(inputSelection));
    } else {
      setSelectedProject(null);
      localStorage.setItem("inputSelection", String(""));
    }
    if (idSelection) {
      setProjectIdStepper(idSelection);
      localStorage.setItem("id", idSelection);
    } else {
      setProjectIdStepper(null);
      localStorage.setItem("id", null);
    }
  };

  return (
    <>
      <div className="filter_projects_container">
        <Autocomplete
          id="filter"
          onChange={handleInputChange}
          sx={{
            m: 0,
            width: 335,
            [theme.breakpoints.down("md")]: { width: 250 },
            [theme.breakpoints.down("xs")]: { width: 340 },
          }}
          disabled={enabled > 0 ? false : true}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          getOptionLabel={(option) => option.name}
          options={options}
          loading={loadingText}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"Proyectos"}
              size="small"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loadingText ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
                sx: { borderRadius: 2 },
              }}
            />
          )}
        />
      </div>
    </>
  );
};

export default AutoComplete;

import React, { useContext, useEffect, useState } from "react";
import { ROUTER } from "../../../config/routing/router_per_component";
import { AuthContext } from "../../../context/AuthProvider";
import HomeIcon from "@mui/icons-material/Home";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

const Summary = () => {
  const numOfMetters = JSON.parse(localStorage.getItem("dataMeters"));

  return (
    <div className="summary">
      <span className="summary_title">Resumen</span>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <span>
          <HomeIcon sx={{ height: "30px", width: "30px" }} />
        </span>{" "}
        {localStorage.getItem("inputSelection")
          ? localStorage.getItem("inputSelection")
          : null}
      </div>
      {localStorage.getItem("showMeters") === "true"
        ? numOfMetters.map((element, index) => (
            <>
              <div className="summary_item_container" key={index}>
                <div className="items_labels">
                  <span>
                    <ElectricMeterIcon sx={{ height: "30px", width: "30px" }} />
                  </span>
                  <b className="serial_label">SN:</b> {element.serial}
                </div>
                <div className="secondary_labels">
                  {<b>Alias </b>}
                  {": "}
                  {index === 0
                    ? localStorage.getItem("alias")
                    : localStorage.getItem("secondAlias")}
                </div>
                <div className="secondary_labels">
                  {<b>Fact. Potencia</b>} {": "}
                  {index === 0
                    ? localStorage.getItem("powerFactor")
                    : localStorage.getItem("secondPowerFactor")}
                </div>
                <div className="secondary_labels">
                  {<b>Relación Tc</b>} {": "}
                  {index === 0
                    ? localStorage.getItem("tcRelation")
                    : localStorage.getItem("secondTcRelation")}
                </div>
                <div className="secondary_labels">
                  {<b>Max. Corriente</b>}
                  {": "}
                  {index === 0
                    ? localStorage.getItem("maxCurrent")
                    : localStorage.getItem("secondMaxCurrent")}
                </div>
              </div>
            </>
          ))
        : null}
    </div>
  );
};

export default Summary;

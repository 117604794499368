import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

//multilanguage
import { useTranslation } from "react-i18next";
import { useState } from "react";

const GraphBatteryLevel = ({ data, typeGateway }) => {
  function getDataGraph(typeGateway, data) {
    let dataGraph;
    if (typeGateway === "horus_700") {
      dataGraph = data?.getAllNewDeviceByBatteryLevel?.data;
    } else if (typeGateway === "horus_g150") {
      dataGraph = data?.getDeviceByBatteryLevel?.data;
    }
    return dataGraph;
  }
  getDataGraph(typeGateway, data);

  const { t } = useTranslation();
  return (
    <div className="container_battery">
      {getDataGraph(typeGateway, data)?.length !== 0 ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              zoomType: "x",
              type: "spline",
              scrollablePlotArea: {
                minWidth: 600,
                scrollPositionX: 1,
              },
            },
            title: {
              text: "",
              align: "left",
            },
            subtitle: {
              text: "",
              align: "left",
            },
            xAxis: {
              type: "datetime",
              labels: {
                overflow: "justify",
              },
            },
            yAxis: {
              title: {
                text: t(
                  "VIEW_AREA.COMPONENT_FULL_MODAL_BATTERY.Y_AXIS_DESCRIPTION"
                ),
              },
              minorGridLineWidth: 0,
              gridLineWidth: 0,
              alternateGridColor: null,
              plotBands: [
                {
                  // Light air
                  from: 0.3,
                  to: 1.5,
                  color: "rgba(68, 170, 213, 0.1)",
                },
              ],
            },
            tooltip: {
              valueSuffix: "%",
            },
            plotOptions: {
              spline: {
                lineWidth: 2,
                states: {
                  hover: {
                    lineWidth: 2,
                  },
                },
                marker: {
                  enabled: false,
                },
                pointInterval: 3600000, // one hour
                pointStart: Date.UTC(2023, 3, 15, 0, 0, 0),
              },
            },
            series: [
              {
                name: t("VIEW_AREA.COMPONENT_FULL_MODAL_BATTERY.BATTERY"),
                data: getDataGraph(typeGateway, data)
                  ? getDataGraph(typeGateway, data)
                  : "",
              },
            ],
            navigation: {
              menuItemStyle: {
                fontSize: "5px",
              },
            },
            credits: {
              enabled: false,
            },
          }}
        />
      ) : null}
    </div>
  );
};
export default GraphBatteryLevel;

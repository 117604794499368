import * as React from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import Tooltip from "@mui/material/Tooltip";
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard({ data }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const percentageValue = (value) => {
    if (value.length < 2) {
      return (parseInt(value) / 10) * 100;
      //}else if ( )  {
    } else if (value.length < 3) {
      return (parseInt(value) / 100) * 100;
    } else if (value.length < 4) {
      return (parseInt(value) / 1000) * 100;
    } else if (value.length < 5) {
      return (parseInt(value) / 10000) * 100;
    } else if (value.length < 6) {
      return (parseInt(value) / 100000) * 100;
    } else if (value.length < 7) {
      return (parseInt(value) / 1000000) * 100;
    } else if (value.length < 8) {
      return (parseInt(value) / 10000000) * 100;
    } else if (value.length < 9) {
      return (parseInt(value) / 100000000) * 100;
    }
  };

  return (
    <div className="_cardMeasurers_container_">
      <div className="__details_measurer_consumption_variables_CFA_graphic__">
        <div className="__details_measurer_consumption_variables_CFA_graphic_title__ background_CFC">
          <span className="__details_measurer_consumption_variables_CFA_graphic_title__span">
            Fase C
          </span>
        </div>
        <div className="__details_measurer_consumption_variables_CFA_graphic_image__">
          <SemiCircleProgressBar
            percentage={`${percentageValue(
              data.getMetterAndMetterHistories.dataMetters.variables.EIFC.value.toString()
            )}`}
            strokeWidth={25}
            stroke="#ff2525"
            background="#F2F2F2"
          />
          <span>
            {data.getMetterAndMetterHistories.dataMetters.variables.EIFC.value}{" "}
            Watts
          </span>
        </div>
      </div>
      <div className="__details_measurer_consumption_variables_CFA_value__">
        <div className="__details_measurer_consumption_variables_CFA_value_vfa__ border_color_CFC">
          {/* <div className="__details_measurer_consumption_variables_CFA_value_vfa_title__">
            <strong>Voltaje</strong>
          </div> */}
          <div className="__details_measurer_consumption_variables_CFA_value_vfa_value__">
            <Tooltip title="Voltaje" placement="top" arrow>
              <strong>
                <span>
                  {data.getMetterAndMetterHistories.dataMetters.variables.VFC.value.toFixed(
                    1
                  )}{" "}
                  V
                </span>
              </strong>
            </Tooltip>
          </div>
        </div>
        <div className="__details_measurer_consumption_variables_CFA_value_vfa__ border_color_CFC">
          {/* <div className="__details_measurer_consumption_variables_CFA_value_vfa_title__">
            <strong>Corriente</strong>
          </div> */}
          <div className="__details_measurer_consumption_variables_CFA_value_vfa_value__">
            <Tooltip title="Amperaje" placement="top" arrow>
              <strong>
                <span>
                  {data.getMetterAndMetterHistories.dataMetters.variables.CFC.value.toFixed(
                    1
                  )}{" "}
                  A
                </span>
              </strong>
            </Tooltip>
          </div>
        </div>
        <div className="__details_measurer_consumption_variables_CFA_value_fpa__ border_color_CFC">
          {/* <div className="__details_measurer_consumption_variables_CFA_value_vfa_title__">
            <strong>Factor P.</strong>
          </div> */}
          <div className="__details_measurer_consumption_variables_CFA_value_vfa_value__">
            <Tooltip title="Factor de Potencia" placement="top" arrow>
              <strong>
                <span>
                  {data.getMetterAndMetterHistories.dataMetters.variables.FPC.value.toFixed(
                    2
                  )}{" "}
                  FP
                </span>
              </strong>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useContext, Fragment } from 'react';
import { AuthContext } from '../../context/AuthProvider';
import { useQuery } from '@apollo/client';
import { GET_METTER_AND_METTER_HISTORIES } from '../../controllers/measurersController';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Loading from '../loading/Loading';
//new Design
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
//New Components Design
import CardMacrometerA from '../macrometer/cardphaseA';
import CardMacrometerB from '../macrometer/cardphaseB';
import CardMacrometerC from '../macrometer/cardphaseC';
import CardTotal from '../macrometer/cardTotal';

//Function
import { calculatedMinParameter, calculatedMaxParameter } from '../../utils/helpers';

//components

import TabGraphic from './MeasurerDetails/TabGraphic';
import TabGraphicVAV from './MeasurerDetails/TabGraphicVAV';
import TableConsumptions from './NewMeasurerDetails/TableConsumptions';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const MeasurerDetails = ({ projectID, timeZone, serial }) => {
  const { dateReport, seeGraphic, setSeeGraphic } = useContext(AuthContext);
  const [parameterMax, setParameterMax] = useState(calculatedMaxParameter(dateReport, timeZone));
  const [parameterMin, setParameterMin] = useState(calculatedMinParameter(dateReport, timeZone));
  const [value, setValue] = useState();
  const { data, loading } = useQuery(GET_METTER_AND_METTER_HISTORIES, {
    variables: { serial },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    setSeeGraphic(true);
  }, []);

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <Fragment>
      <Box
        sx={{
          flexGrow: 1,
          marginTop: 5,
          backgroundColor: 'rgba(195, 1, 78, 0.)',
        }}
      >
        <Grid
          container
          spacing={1}
          sx={{ mx: '1%', mt: '40px' }}
        >
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={3}
            xl={3}
          >
            <CardTotal data={data} />
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={3}
            xl={3}
          >
            <CardMacrometerA data={data} />
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={3}
            xl={3}
          >
            <CardMacrometerB data={data} />
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={3}
            xl={3}
          >
            <CardMacrometerC data={data} />
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ height: '550px' }}
          >
            <TabGraphic timeZone={timeZone} />
          </Grid>
          {seeGraphic === true ? (
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ height: '550px' }}
            >
              <TabGraphicVAV timeZone={timeZone} />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Box>
    </Fragment>
  );
};

export default MeasurerDetails;

import * as React from "react";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { Tooltip } from "@mui/material";
import { useHistory } from "react-router-dom";

//components

const AddDeviceButton = () => {
  const history = useHistory();
  const [projectId, setProjectId] = useState(localStorage.getItem("id"));
  const handleMacrometerInstalation = () => {
    history.push("/app/stepperviewmacrometer/description");
  };

  return (
    <>
      <div>
        <Tooltip title={"Agregar Dispositivo"}>
          <IconButton
            sx={{
              width: "35px",
              height: "35px",
            }}
            onClick={handleMacrometerInstalation}
            disabled={true}
          >
            <ControlPointIcon
              sx={{
                width: "35px",
                height: "35px",
                ":hover": { color: "#000000" },
              }}
            />
          </IconButton>
        </Tooltip>
      </div>
    </>
  );
};

export default AddDeviceButton;

export const futureDate =(epoch1, epoch2)=> {
    //convertir los valores epoch a objetos de fecha
    const dateOne = new Date(epoch1);
    const dateTwo = new Date(epoch2);
  
    //comparar año mes y dia 
    const sameYear = dateOne.getFullYear() === dateTwo.getFullYear();
    const sameMonth = dateOne.getMonth() === dateTwo.getMonth();
    const sameDay = dateOne.getDate() === dateTwo.getDate();
  
    //devolver true si son el mismo día, de lo contrario, false
    return sameYear && sameMonth && sameDay;
  }
  
  
import Login from "../../views/login/Login.jsx";
import Dashboard from "../../views/private/dashboard/Principal.jsx";
import Projects from "../../views/private/projects/Projects.jsx";
import Areas from "../../views/private/areas/Areas.jsx";
import Controllers from "../../views/private/controllers/Controllers.jsx";
import Occupation from "../../components/highcharts/Occupation.jsx";
import Devices from "../../views/private/devices/Devices.jsx";
import Measurers from "../../views/private/measurer/Measurers.jsx";
import AddUser from "../../components/userManagement/addUser.js";
import ForgotPassword from "../../views/forgotPassword/forgotPassword.jsx";
import ResetPassword from "../../views/private/resetPassword/ResetPassword.jsx";
import Confirmation from "../../views/private/resetPassword/Confirmation";
/* import ModifyUser from "../../components/userManagement/modifyUsers.js" */
//import UsersManagements from "../../views/private/userManagement/usersManagements";
//import ProjectManagement from "../../views/private/projectsManagement/projectManagement";
import RoleManager from "../../views/private/roleManager/roleManager.jsx";
import PasswordUpdated from "../../views/private/resetPassword/PasswordUpdated.jsx";
//import RoleManager from "../../views/private/roleManager/roleManager.jsx";

export const views = {
  Login,
  Dashboard,
  Projects,
  Areas,
  Occupation,
  Controllers,
  Devices,
  Measurers,
  AddUser,
  ForgotPassword,
  ResetPassword,
  Confirmation,
  PasswordUpdated,
  /*   ModifyUser, */
  // UsersManagements,
  // ProjectManagement,
  //RoleManager,
  /*   ModifyUser, */
  // UsersManagements,
  // ProjectManagement,
  // RoleManager
};

import React from "react";
import { Devices } from "../../constants/devices_types";

const SensorTemperatura = ({ item }) => {

  function selectIcon(temperature) {
    if (temperature >= 25) {
      return Devices.TEMPERATURE_HEAT;
    } else if (temperature < 22) {
      return Devices.TEMPERATURE_COOL;
    } else {
      return Devices.TEMPERATURE_AMBIENT;
    }
  }

  return (
    <>
    
    <div className="_generic_container">
      <div className="_generics_container_title_status">
        <div className="_generic__title">
          <span>{item?.alias}</span>
        </div>
        <div
          className={`${
            item?.variables?.CommFailure == "1"
              ? "_generic__status__alert"
              : "_generic__status__wifi"
          }`}
        >
          {item?.variables?.CommFailure == "1" ? (
            <span className="__icon_alert_sensor">
              <img src={alert} alt="alert" width="22" height="22" />
            </span>
          ) : (
            <span className="__icon_wifi_sensor">
              <i class="fas fa-wifi"></i>
            </span>
          )}
        </div>
      </div>
      <div className="_generic_container_clasification">
        <div className="_generic_clasification">
          {item?.variables?.CurrentTemperature === undefined ? (
            <span>
              <img
                 src={selectIcon(item?.variables?.status?.value)}
                 alt="clasification"
                 width="60"
                 height="60"
            />
          </span>
          ):(
          <span>
            <img
              src={selectIcon(item?.variables?.CurrentTemperature)}
              alt="clasification"
              width="60"
              height="60"
            />
          </span>
          )}
          
        </div>
        <div></div>
        <div className="_generic_toggle">
          {item?.variables?.CurrentTemperature === undefined ? (
            <span>{item?.variables?.status?.value.toFixed(0)} °C</span>
          ) : (
            <span>{item?.variables?.CurrentTemperature} °C</span>
          )}
          
        </div>
      </div>
    </div>
    </>
  );
};

export default SensorTemperatura;

import { AuthContext } from '../../../context/AuthProvider';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { define_time_zone } from '../../../utils/helpers';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const GraphKwhPhases = ({ data, endTime, startTime }) => {
  const { t } = useTranslation();
  const [date_range, setDateRange] = useState([]);
  const [dateMax, setDateMax] = useState();
  const [dateMin, setDateMin] = useState();

  // const variable = data.data.getClampsMeasure.data_watts;
  const endTimeRango = endTime - 61000;

  if (data) {
    return (
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              zoomType: 'x',
              type: 'spline',
            },
            title: {
              text: 'Watts',
            },
            xAxis: {
              type: 'datetime',
              min: startTime,
              max: endTimeRango,
              tickInterval: 3600000,
              labels: {
                format: '{value:%H:%M}',
              },
            },
            yAxis: {
              min: 0,
              title: {
                text: t('VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_WATTS_GRAPH.Y_AXIS_DESCRIPTION'),
              },
            },
            colors: ['#000000'],
            tooltip: {
              xDateFormat: 'Hora: %H:%M:%S',
              valueSuffix: ' watts',
              valueDecimals: 1,
              shared: true,
            },
            plotOptions: {
              column: {
                stacking: 'line',
              },
              spline: {
                lineWidth: 1.5,
                states: {
                  hover: {
                    lineWidth: 2.5,
                  },
                },
                marker: {
                  enabled: false,
                },
              },
              series: {
                turboThreshold: 0,
                marker: {
                  enabled: false,
                  radius: 0.1,
                  symbol: 'circle',
                },
              },
            },
            time: {
              timezoneOffset: 300,
            },
            series: data?.getClampsMeasure.data_watts,
          }}
        />
      </div>
    );
  }
};
export default GraphKwhPhases;

import { useEffect, useState } from 'react';
import { SocketVRF } from '../helperDevices';

export const useDevices = (params) => {
  const [value, setValue] = useState(params);

  useEffect(() => {
    setValue(params);
  }, [params]);

  return { value };
};

export const useExtrationDevices = (devices) => {
  const [airs, setAirs] = useState(devices);
  const [switches, setSwitches] = useState(devices);
  const [sensorsDoor, setSensorsDoor] = useState(devices);
  const [sensorsMotion, setSensorMotion] = useState(devices);
  const [curtainSwitch, setCurtainSwitch ] = useState(devices);
  const [vrf, setVrf] = useState(devices)
  const [plugins, setPlugins] = useState();

  useEffect(() => {
    const allSwitch = devices?.filter((i) => i.Category.category === 'switch');
    setSwitches(allSwitch);

    const allAirs = devices?.filter((i) => i.Category.category === 'hvac' && i.variables.type === 'thermostat' );
    setAirs(allAirs);
    

    const doorSensors = devices?.filter((i) => i.Category.category === 'security_sensor' && i.Category.sub_category === 'door');
    setSensorsDoor(doorSensors);

    const motionSensors = devices?.filter((i) => i.Category.category === 'security_sensor' && i.Category.sub_category === 'motion');
    setSensorMotion(motionSensors);

    const allPugin = devices?.filter((i) => i.Category.category === 'generic_io');
    setPlugins(allPugin);

    const curtainSwitch = devices?.filter((i) => i.Category.category === 'window_cov' && i.Category.sub_category === 'window_cov');
    setCurtainSwitch(curtainSwitch);

    const allVrf = devices?.filter((i) => i.Category.category === 'hvac' && i.variables.type === 'thermostatVRF');
    setVrf(allVrf);
    if (allVrf) {
      SocketVRF();
    }
  }, [devices]);

  

  return {
    switches,
    airs,
    sensorsMotion,
    sensorsDoor,
    plugins,
    curtainSwitch , 
    vrf
  };
};

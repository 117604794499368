import SemiCircleProgressBar from "react-progressbar-semicircle";
import { useQuery } from "@apollo/client";
import { percentage_total } from "../../../utils/helpers";
//multilanguage
import { useTranslation } from "react-i18next";

export const CardPhaseANew = ({ MeasurerDataPhases }) => {
  const { t } = useTranslation();
  const a = parseInt(MeasurerDataPhases?.PAFA);
  return (
    <div className="_cardMeasurers_container_">
      <div className="__details_measurer_consumption_variables_CFA_graphic__">
        <div className="__details_measurer_consumption_variables_CFA_graphic_title__ background_CFA">
          <span className="__details_measurer_consumption_variables_CFA_graphic_title__span">
            {t(
              "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_PHASES_CARD_HEADERS.PHASE_A"
            )}
          </span>
        </div>
        <div className="__details_measurer_consumption_variables_CFA_graphic_image__">
          <SemiCircleProgressBar
            percentage={`${percentage_total(a.toString())}`}
            background="#F2F2F2 "
            strokeWidth={25}
            stroke="#f7ce68"
          />

          <span className="container_watts">
            <strong>{MeasurerDataPhases?.PAFA}</strong> Watts
          </span>
        </div>
      </div>
      <div className="__details_measurer_consumption_variables_CFA_value__">
        <div className=" __details_measurer_consumption_variables_CFA_value_vfa__ border_color_CFA">
          {" "}
          <div className="__details_measurer_consumption_variables_CFA_value_vfa_value__">
            <span className="container_voltaje_data">
              <strong>{MeasurerDataPhases?.VFA}</strong>

              <span className="container_voltaje">
                {t(
                  "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_MINI_CARDS.VOLTAGE"
                )}
              </span>
            </span>
          </div>
        </div>
        <div className="__details_measurer_consumption_variables_CFA_value_vfa__ border_color_CFA">
          <div className="__details_measurer_consumption_variables_CFA_value_vfa_value__">
            <span className="container_voltaje_data">
              {" "}
              <strong>{MeasurerDataPhases?.CFA}</strong>
              <span className="container_voltaje">
                {t(
                  "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_MINI_CARDS.AMPERAGE"
                )}
              </span>
            </span>
          </div>
        </div>
        <div className="__details_measurer_consumption_variables_CFA_value_fpa__ border_color_CFA">
          {" "}
          {/* <div className="__details_measurer_consumption_variables_CFA_value_vfa_title__ ">
            <strong>Factor P.</strong>
          </div> */}
          <div className="__details_measurer_consumption_variables_CFA_value_vfa_value__">
            <span className="container_voltaje_data">
              <strong> {MeasurerDataPhases?.FPFA}</strong>
              <span className="container_voltaje">
                {t(
                  "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_MINI_CARDS.P_FACTOR"
                )}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

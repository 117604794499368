import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { makeStyles } from "@material-ui/core/styles";
import { NewGraphConsumptionDetail } from "./NewGraphConsumptionDetailsDay";
import { NewGraphConsumptionDetailPerMonth } from "./NewGraphConsumptionDetailsMonth";
import { NewGraphConsumptionDetailYear } from "./NewGraphConsumptionDetailsYear";
//multilanguage
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  prueba: {
    bgcolor: "red",
  },
}));

export const TabNewGraphic = ({ serial, city }) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState("1");
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const currentDay = new Date();
  const tabStyle = (tabValue) => ({
    borderColor: "#BEBEBE",
    borderTopLeftRadius: "10px",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 2.6px",
    borderTop: "solid 0.7px #EFEFEF",
    ...(value === tabValue && {
      boxShadow: "rgba(0, 0, 0, 0.35)  2px 5px 15px",
      fontSize: "16px",
      borderColor: "#EFEFEF",
      borderTop: "solid 0.5px #CBCBCB",
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      fontWeight: "bold",
      color: "#3f9948",
    }),
  });

  return (
    <Box sx={{ width: "100%", height: "10px", typography: "body1" }}>
      <TabContext value={value} sx={{ color: "#3f9948" }}>
        <Box sx={{}}>
          <TabList
            indicatorColor="#fff"
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            <Tab
              style={tabStyle("1")}
              label={t(
                "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_TABS.CONSUMPTION_PER_DAY"
              )}
              value="1"
            />
            <Tab
              style={tabStyle("2")}
              label={t(
                "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_TABS.CONSUMTION_PER_MONTH"
              )}
              value="2"
            />
            <Tab
              style={tabStyle("3")}
              label={t(
                "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_TABS.CONSUMPTION_PER_YEAR"
              )}
              value="3"
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <NewGraphConsumptionDetail city={city} serial={serial} />
        </TabPanel>
        <TabPanel value="2">
          <NewGraphConsumptionDetailPerMonth city={city} serial={serial} />
        </TabPanel>
        <TabPanel value="3">
          <NewGraphConsumptionDetailYear city={city} serial={serial} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

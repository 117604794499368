import React from "react";
import styled from "styled-components";

export const MainTableContainer = styled.div`
  @media (width: 100vw) {
    overflow: auto;
    font-size: 15px;
  }

  .data_table {
    border-collapse: collapse;
    margin: 0 1.5%;
    height: auto;
    border-radius: 10px 10px 0 0;
    width: ${(props) => (props.columnLength >= 8 ? "max-content" : "auto")};
    overflow: hidden;

    .container_title_table {
      background: rgba(31, 137, 229, 0.2);
      color: #000;
      width: 94.7%;
      height: 5em;
    }
    .container_table_body {
      align-items: center;
      border: 2px;
      background: rgba(113, 113, 113, 0.091);
      color: black;
      height: 4.5em;
    }
    .rows {
      &:nth-child(even) {
        background-color: rgb(255, 255, 255);
      }
      .td {
        width: 1%;
        height: 65px;
        font-weight: 600;
        text-align: center;
        font-family: "Montserrat", sans-serif;
        font-size: 15px;
        color: #474747;
      }

      .enabled {
        border: 0.2px solid #5cb85c;
        width: auto;
        padding: 2px 3px 5px;
        border-radius: 4px;
        background-color: rgba(34, 169, 92, 0.15);
        border: 1px solid #22a95c;
        color: #22a95c;
        span {
          display: flex;
          align-items: center;
          width: 100%;
          font-weight: 500;
        }
        p {
          font-size: 12px;
          margin: 0px auto;
          color: white;
          display: flex;
          justify-self: center;
          justify-content: center;
        }
      }
      .disabled {
        width: auto;
        padding: 2px 3px 5px;
        border-radius: 4px;
        background-color: rgba(191, 38, 38, 0.15);
        border: 1px solid #bf2626;
        color: #bf2626;
        span {
          display: flex;
          align-items: center;
          font-weight: 500;
        }
        p {
          font-size: 12px;
          margin: 0px auto;
          color: white;
          display: flex;
          justify-self: center;
          justify-content: center;
        }
      }
    }
  }
`;

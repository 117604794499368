import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import reset_password from "../../../assets/logo.svg";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "../../../controllers/authenticationController";

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

export default function ResetPassword() {
  const { token } = useParams();
  const [passwords, setPasswords] = useState({ password: "", new_password: "" });
  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState(false);
  const [error, setError] = useState(null);
  const [error2, setError2] = useState(null);
  const [create_Reg_Password] = useMutation(RESET_PASSWORD);
  const history = useHistory();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseResponse = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleChangePass = (e) => {
    const { name, value } = e.target;
    setPasswords({
      ...passwords,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    const { password, new_password } = passwords;
    if (password === "") setError(true);
    if (new_password === "") setError2(true);
    if (password !== new_password) {
      return setOpen(true);
    }
    if (passwords) {
      try {
        create_Reg_Password({
          variables: {
            password: password,
            token: token,
          },
        })
          .then((result) => {
            if (result.data.create_Reg_Password === "Change Password success") {
              history.push("/password_updated");
            } else {
              setResponse(true);
            }
          })
          .catch((err) => {
            setResponse(true);
          });
      } catch (err) {}
    } else {
      console.error("");
    }
  };

  React.useEffect(() => {
    const { password, new_password } = passwords;
    if (password !== "") setError(false);
    if (new_password !== "") setError2(false);
  }, [passwords]);

  return (
    <div className='resetPassword_container'>
      <Snackbar
        open={open && !error && !error2}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert severity='error'>Las contraseñas no coinciden</Alert>
      </Snackbar>
      <Snackbar open={response} autoHideDuration={3000} onClose={handleCloseResponse}>
        <Alert severity='error'>Algo fallo, contacte con soporte.</Alert>
      </Snackbar>
      <div className='resetPassword_hero'>
        <img className='resetPassword_image' src={reset_password} alt='img' />
      </div>
      <div className='resetPassword_input_container'>
        <div className='resetPassword_input_and_title_container'>
          <div className='resetPassword_input_title_container'>Nueva contraseña</div>
          <input
            className='resetPassword_input'
            type='password'
            placeholder='Nueva contraseña'
            name='password'
            onChange={handleChangePass}
          />
          {error && (
            <div className='resetPassword_span_required_input'>
              * Este campo es requerido
            </div>
          )}
        </div>
      </div>
      <div className='resetPassword_input_container'>
        <div className='resetPassword_input_and_title_container'>
          <div className='resetPassword_input_title_container'>
            Repetir nueva contraseña
          </div>
          <input
            className='resetPassword_input'
            type='password'
            name='new_password'
            placeholder='Repetir nueva contraseña'
            onChange={handleChangePass}
          />
          {error2 && (
            <div className='resetPassword_span_required_input'>
              * Este campo es requerido
            </div>
          )}
        </div>
      </div>
      <div className='resetPassword_submit_container'>
        <button className='resetPassword_submit' onClick={handleSubmit}>
          Guardar
        </button>
      </div>
    </div>
  );
}

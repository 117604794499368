import React, { useContext } from "react";
import { ListItemIcon, ListItem, ListItemText, List } from "@material-ui/core";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthProvider";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import SettingsIcon from "@mui/icons-material/Settings";

//components
import { DeviceManagement } from "../deviceManagement/DeviceManagement";
import Management from "../userManagement/managements";
import ManagementRole from "../roleManager/managementRole";
import ManagementProjets from "../projectsManagements/managementProject";
import { makeStyles } from "@material-ui/core/styles";

//multilanguage
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    margin: "0",
  },
  listItem: {
    padding: "0px",
    marginTop: "0%",
    marginLeft: "0px",
    marginBottom: "0%",
  },
});

const Settings = () => {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const { permissionsRoleUser } = useContext(AuthContext);
  const classes = useStyles();

  const handleClick = () => {
    setOpen(!open);
  };
  const handleRemove = () => {
    localStorage.removeItem("id");
  };

  return (
    <div>
      {permissionsRoleUser?.permissionsRole?.config ? (
        <>
          <ListItem button onClick={handleClick}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText
              className="nameText"
              primary={t("VIEW_PROJECT.COMPONENT_SIDEBAR.SETTINGS")}
            />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          {permissionsRoleUser?.permissionsRole?.GesUser ? (
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" className={classes.listItem}>
                <Link to={"/app/usersmanagements"} className="_sidebar_item">
                  <ListItem
                    button
                    onClick={handleRemove}
                    className={`${
                      window.location.pathname === "/app/usersmanagements"
                        ? "__on_item"
                        : "__off_item"
                    }`}
                  >
                    <Management />
                  </ListItem>
                </Link>
              </List>
            </Collapse>
          ) : null}

          {permissionsRoleUser?.permissionsRole?.GesProject ? (
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" className={classes.listItem}>
                <Link to={"/app/projectmanagement"} className="_sidebar_item">
                  <ListItem
                    button
                    onClick={handleRemove}
                    className={`${
                      window.location.pathname === "/app/projectmanagement"
                        ? "__on_item"
                        : "__off_item"
                    }`}
                  >
                    <ManagementProjets />
                  </ListItem>
                </Link>
              </List>
            </Collapse>
          ) : null}

          {permissionsRoleUser?.permissionsRole?.GesRol ? (
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" className={classes.listItem}>
                <Link to={"/app/rolemanager"} className="_sidebar_item">
                  <ListItem
                    button
                    onClick={handleRemove}
                    className={`${
                      window.location.pathname === "/app/rolemanager"
                        ? "__on_item"
                        : "__off_item"
                    }`}
                  >
                    <ManagementRole />
                  </ListItem>
                </Link>
              </List>
            </Collapse>
          ) : null}

          {permissionsRoleUser?.permissionsRole?.GesDisp ? (
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" className={classes.listItem}>
                <Link to={"/app/devicesmanagement"} className="_sidebar_item">
                  <ListItem
                    button
                    onClick={handleRemove}
                    className={`${
                      window.location.pathname === "/app/devicesmanagement"
                        ? "__on_item"
                        : "__off_item"
                    }`}
                  >
                    <DeviceManagement />
                  </ListItem>
                </Link>
              </List>
            </Collapse>
          ) : null}
        </>
      ) : null}
    </div>
  );
};
export default Settings;

import React from "react";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";
import Avatar from "@mui/material/Avatar";
import { Tooltip } from "@mui/material";

//multilanguage
import { useTranslation } from "react-i18next";

const PluginMode = (props) => {
  const { t } = useTranslation();
  const classRoomState = props?.props?.data?.Device.find(
    (i) =>
      i?.visible &&
      i?.Category?.category === 600 &&
      i?.Category?.sub_category === 9
  );

  const ocupationPlugin = props?.props?.data?.Device.find(
    (i) =>
      i?.visible &&
      i?.Category?.category === 600 &&
      i?.Category?.sub_category === 1
  );

  function changeAvatarContent() {
    if (props) {
      if (
        ocupationPlugin?.variables?.Modo === "Auto" ||
        classRoomState?.variables?.Modo === "Auto"
      ) {
        return (
          <EnergySavingsLeafIcon
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "13px",
              width: "13px",
            }}
          />
        );
      } else if (
        ocupationPlugin?.variables?.Modo === "Manual" ||
        classRoomState?.variables?.Modo === "Manual"
      ) {
        return "M";
      } else if (
        ocupationPlugin?.variables?.Modo === "Apagado" ||
        classRoomState?.variables?.Modo === "Apagado"
      ) {
        return "A";
      } else if (
        ocupationPlugin?.variables?.Modo === undefined ||
        classRoomState?.variables?.Modo === undefined
      ) {
        return false;
      }
    }
  }

  function titleTooltip() {
    if (changeAvatarContent() === "A") {
      return t("VIEW_AREAS.COMPONENT_HEADER_AREAS.OFF");
    } else if (changeAvatarContent() === "M") {
      return t("VIEW_AREAS.COMPONENT_HEADER_AREAS.MANUAL");
    } else {
      return t("VIEW_AREAS.COMPONENT_HEADER_AREAS.AUTO");
    }
  }

  return (
    <>
      {classRoomState || ocupationPlugin ? (
        <Tooltip placement="top-start" title={titleTooltip()}>
          <Avatar
            sx={{
              height: "17px",
              width: "17px",
              fontSize: "12px",
              fontWeight: "bold",
              marginTop: "3px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "#D9D9D9",
              color: "black",
              border: "1px solid black",
              boxShadow: "2px 1px 2px 1px #5a5a5a",
              "&:hover": {
                backgroundColor: "#b9b9b9",
                cursor: "pointer",
              },
            }}
          >
            {changeAvatarContent()}
          </Avatar>
        </Tooltip>
      ) : null}
    </>
  );
};
export default PluginMode;

import styled from 'styled-components';

export const SelectContainer = styled.select`
  padding: 0.5em;
  font-size: 1em;
  border-radius: 8px;
  width: 250px ;
  height: 40px;
  border: 1px solid #ccc;
  position: relative;
  cursor: pointer;
`;
export const SelectInput = styled.select`
  padding: 0.5em;
  font-size: 1em;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

export const OptionContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
`;

export const OptionItem = styled.option`
  padding: 0.5em;
  
`;
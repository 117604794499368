import React from "react";

export default function ConfirmationIcon({ src }) {
  return (
    <lottie-player
      style={{
        width: 100,
      }}
      className='_logo'
      src={src}
      background='transparent'
      speed='1'
      loop
      autoplay
    ></lottie-player>
  );
}

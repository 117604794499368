import gql from "graphql-tag";

export const CREATE_USER = gql`
  mutation CreateUser(
    $name: String!
    $last_name: String!
    $identification_number: String
    $email: String!
    $cellPhone: String
    $countryCode: String
    $user: String!
    $password: String!
    $img: String
    $RoleId: Int!
    $ProjectId: [Int!]
  ) {
    CreateUser(
      name: $name
      last_name: $last_name
      identification_number: $identification_number
      email: $email
      cellPhone: $cellPhone
      countryCode: $countryCode
      user: $user
      password: $password
      img: $img
      RoleId: $RoleId
      ProjectId: $ProjectId
    ) {
      id
      name
      last_name
      identification_number
      email
      cellPhone
      countryCode
      img
    }
  }
`;

export const GET_ROLE = gql`
  query getRole {
    getAllProjects
    getAllRoles {
      id
      name
    }
  }
`;

export const GET_USER_ROLE = gql`
  query ($id: Int) {
    getAllUsers(id: $id) {
      Role {
        id
        name
        components
      }
      Project {
        id
        name
      }
    }
    getAllRoles {
      id
      name
    }
  }
`;
export const GET_ALL_USER = gql`
  query getUsers {
    getAllUsers {
      id
      name
      last_name
      user
      cellPhone
      countryCode
      Project {
        name
        id
      }
      Role {
        id
        name
        components
      }
      identification_number
      email
      enabled
    }
  }
`;

export const UPDATE_ENABLED_USER = gql`
  mutation UpdateEnabledUser($id: Int!, $enabled: Boolean) {
    UpdateEnabledUser(id: $id, enabled: $enabled) {
      id
    }
  }
`;

export const UPDATE_MANY_USERS = gql`
  mutation UpdateManyUsers($input: [ManyUsers]) {
    UpdateManyUsers(input: $input)
  }
`;

export const EDIT_USER = gql`
  query ($id: Int!) {
    getAllUsers(id: $id) {
      id
      name
      last_name
      identification_number
      cellPhone
      countryCode
      email
      user
      enabled
      Role {
        id
        name
      }
      Project {
        name
        id
      }
    }
    getAllProjects
    getAllProjectsByUser
    getAllRoles {
      id
      name
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: Int!
    $name: String
    $last_name: String
    $email: String
    $cellPhone: String
    $countryCode: String
    $user: String
    $RoleId: Int
    $ProjectId: [Int]
    $currentPassword: String
    $newPassword: String
    $confirmPassword: String
  ) {
    UpdateUser(
      id: $id
      name: $name
      last_name: $last_name
      email: $email
      cellPhone: $cellPhone
      countryCode: $countryCode
      user: $user
      RoleId: $RoleId
      ProjectId: $ProjectId
      currentPassword: $currentPassword
      newPassword: $newPassword
      confirmPassword: $confirmPassword
    ) {
      id
    }
  }
`;

export const GET_METTERS_PROJECTS_LOGGED_USER = gql`
  query GetMettersLoggedUser($id: Int!) {
    getMettersProjectsUsers(userId: $id)
    getAllProjectsByUser
  }
`;

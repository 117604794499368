import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { AuthContext } from "../../../../context/AuthProvider";
import AutoComplete from "../../../../components/tableInformation/components/AutoComplete";

//Material UI components & Styles
import { createTheme, ThemeProvider } from "@mui/material/styles";

const customGreen = "#acf6ac";

const theme = createTheme({
  palette: {
    success: {
      main: customGreen,
    },
  },
});

const AddNewMetersModal = ({ data }) => {
  const {
    openNewMetersModal,
    setOpenNewMetersModal,
    selectedProject,
    setSelectedProject,
  } = useContext(AuthContext);
  const history = useHistory();
  const selectedDevice = parseInt(localStorage.getItem("id"));

  const handleNavegateStepper = () => {
    history.push("/app/stepperviewmacrometer/description");
    setOpenNewMetersModal(false);
    setSelectedProject(null);
  };

  const handleClose = () => {
    setOpenNewMetersModal(false);
    setSelectedProject(null);
  };

  return (
    <>
      <Dialog
        open={openNewMetersModal}
        onClose={handleClose}
        PaperProps={{
          sx: {
            display: "flex",
            borderRadius: "8px",
            width: "30%",
            height: "38%",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
            fontFamily: "Montserrat, sans-serif",
            backgroundColor: "#2EB142",
            color: "#FFFFFF",
            fontSize: "20px",
            marginBottom: "15px",
            lineHeight: "2.6",
            padding: "0px",
          }}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
              fontFamily: "Montserrat, sans-serif",
              fontSize: "22px",
              fontWeight: "bold",
              paddingLeft: "45px",
            }}
          >
            Selección de proyecto
          </span>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: "#ffff" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "25px",
            padding: "20px 24px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              width: "80%",
              fontFamily: "Montserrat, sans-serif",
              fontSize: "17px",
            }}
          >
            Seleccione el proyecto a vincular con los dispositivos previamente
            instalados.
          </span>
          <AutoComplete enabled={true} dataFilter={data} />
          <ThemeProvider theme={theme}>
            <Button
              variant="contained"
              color="success"
              disabled={selectedProject !== null ? false : true}
              onClick={() => handleNavegateStepper()}
              sx={{
                width: "340px",
                padding: "7px",
                color: "#000",
                borderRadius: "8px",
                lineHeight: "18px",
              }}
            >
              Aceptar
            </Button>
          </ThemeProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddNewMetersModal;

import React, { useEffect, useContext } from "react";
import Switch from "@material-ui/core/Switch";
import { Devices } from "../../constants/devices_types";
import { green } from "@material-ui/core/colors";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { AuthContext } from "../../context/AuthProvider";
import Loading_devices from "../loading/Loading_devices";
import alert from "../../assets/alert-icon.png";
import Swal from "sweetalert2";

//multilanguage
import { useTranslation } from "react-i18next";

const innerTheme = createTheme({
  palette: {
    secondary: {
      main: green[500],
    },
  },
});

const Device = ({ item, send }) => {
  const { t } = useTranslation();
  const {
    infoMessage,
    setInfoMessage,
    successMessage,
    setSuccessMessage,
    errorMessage,
    setErrorMessage,
    permissionsRoleUser,
  } = useContext(AuthContext);
  const [state, setState] = React.useState(
    item?.variables?.Status == "1" ? true : false
  );
  const [load, setLoad] = React.useState(false);
  useEffect(() => {
    setState(item?.variables?.Status == "1" ? true : false);
  }, [item]);

  useEffect(() => {
    setLoad(false);
  }, [errorMessage, successMessage]);

  const handleChange = async (event) => {
    // // Esto hace parte del modal de confirmación (Swal)
    // await Swal.fire({
    //   title: t("VIEW_AREA.COMPONENT_SWITCH_ALERT_CARD.TITLE"),
    //   text: t("VIEW_AREA.COMPONENT_SWITCH_ALERT_CARD.TEXT"),
    //   icon: "info",
    //   showCancelButton: true,
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "#d33",
    //   confirmButtonText: t(
    //     "VIEW_AREA.COMPONENT_SWITCH_ALERT_CARD.CONFIRMBUTTONTEXT"
    //   ),
    // }).then((result) => {
    // if (result.isConfirmed) {
    setLoad(true);
    call();
    setState(!state);
    //   }
    // });
  };

  function call() {
    return new Promise(() => {
      setTimeout(() => {
        setLoad(false);
        send(item, !state);
      }, 2000);
    });
  }

  return (
    <>
      {" "}
      {item?.name === "button_state" ? null : (
        <div className="_generic_container">
          <div
            className={`_generics_container_title_status ${
              item?.variables?.CommFailure == "1"
                ? "__status_comfailure__"
                : null
            }`}
          >
            <div className="_generic__title">
              <span>{item?.alias}</span>
            </div>
            <div
              className={`${
                item?.variables?.CommFailure == "1"
                  ? "_generic__status__alert"
                  : "_generic__status__wifi"
              }`}
            >
              {item?.variables?.CommFailure == "1" ? (
                <span className="__icon_alert_sensor">
                  <img src={alert} alt="alert" width="25" height="25" />
                </span>
              ) : (
                <span className="__icon_wifi_sensor">
                  <i class="fas fa-wifi"></i>
                </span>
              )}
            </div>
          </div>
          <div className="_generic_container_clasification">
            <div
              className={`_generic_clasification ${
                load ? "__loading_clasification" : false
              }`}
            >
              <span>
                <img
                  src={
                    state ? Devices.BINARY_LIGHT_ON : Devices.BINARY_LIGHT_OFF
                  }
                  alt="clasification"
                  width="60"
                  height="60"
                />
              </span>
            </div>
            <div className="__loading_devices">
              {load ? <Loading_devices /> : false}
            </div>
            <div
              className={`_generic_toggle row-decoration ${
                load ? "__loading_toogle" : false
              }`}
            >
              <div>
                <ThemeProvider theme={innerTheme}>
                  <Switch
                    size="medium"
                    checked={state}
                    onChange={
                      permissionsRoleUser?.permissionsRole?.AreaDispMan
                        ? handleChange
                        : false
                    }
                  />
                </ThemeProvider>
                <br></br>
                {!state ? (
                  <span>{t("VIEW_AREA.COMPONENT_SWITCH_CARD.OFF")}</span>
                ) : (
                  <span>{t("VIEW_AREA.COMPONENT_SWITCH_CARD.ON")}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Device;

import React, { useState, useContext, useEffect } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { sum_of_the_kwh, date_of_the_kwh, total_of_the_kwh } from '../../utils/helpers';
import { TableContainer, Table, TableHead, TableRow, TableBody, Paper, TableCell, Grid, IconButton, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import frLocale from 'date-fns/locale/es';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { green } from '@material-ui/core/colors';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { GET_DATA_MEASURER_REPORT } from '../../controllers/reportController';
import { useLazyQuery } from '@apollo/client';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { AuthContext } from '../../context/AuthProvider';
import Loading from '../loading/Loading';
import Construction from '../loading/Construction';

const innerTheme = createTheme({
  palette: {
    primary: {
      main: green[500],
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#22a374',
    position: 'relative',
  },
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  formControl: {
    minWidth: 120,
    marginLeft: 50,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  graphic: {
    marginTop: 30,
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
  table: {
    minWidth: 700,
  },

  info: {
    marginBottom: 60,
  },
  tab: {
    marginTop: 60,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#4caf50e6',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableCell2 = withStyles((theme) => ({
  head: {
    backgroundColor: '#4caf50e6',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    fontWeight: 'bold',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Measurer = ({ project, RoomId }) => {
  const { idArea, areas, setIdArea } = useContext(AuthContext);

  const [dateMin, setDateMin] = useState();
  const [dateMax, setDateMax] = useState();
  const [parametroMin, setParametroMin] = useState();
  const [parametroMax, setParametroMax] = useState();
  const [nextDay, setNextDay] = useState(true);
  const [dayMilisegundos, setDayMilisegundos] = useState(0);
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState();
  const [changeDay, setChangeDay] = useState(false);

  useEffect(() => {
    //  Si el proyecto no tiene zona horaria
    if (!project.start_time || !project.end_time) {
      let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
      //Obtenemos la fecha actual
      var f = new Date();
      //La configuramos para mostrarla en el calendario
      setSelectedDate(new Date(f.getFullYear() + '-' + (f.getMonth() + 1) + '-' + f.getDate()));

      //Guardamos el dia actual en milisegundos
      setDayMilisegundos(new Date(f.getFullYear() + '-' + (f.getMonth() + 1) + '-' + f.getDate()).getTime());
      var day = new Date();
      // Armamos la hora inicial del proyecto + time zone con el que está configurado
      var day_time_zone = day.toString().substring(0, 16) + ' 00:00:00 GMT' + project.time_zone;
      // Configuramos el parametro que le enviaremos a la query del backend, en formato GMT 0 con la hora inicial
      var param = day.toString().substring(0, 16) + ' 00:00:00 GMT';
      //Parámetro en milisegundos
      var tempPara = new Date(param).getTime() - parseInt(project.time_zone, 10) * HORA_EN_MILISEGUNDO;
      //fecha en milisegundos
      var tmpDate = new Date(day_time_zone).getTime();
      //Parámetro inicial
      setParametroMin(tempPara);
      //Fecha inicial
      setDateMin(tmpDate);
      //parámetro final
      setParametroMax(parseInt(tmpDate, 10) + 86400000 - 1000);
      //Fecha final
      setDateMax(parseInt(tmpDate, 10) + 86400000 - 1000);
      getReportMeterZWave({
        variables: {
          RoomId: parseInt(RoomId, 10),
          start_time: tempPara,
          end_time: parseInt(tmpDate, 10) + 86400000 - 1000,
        },
        fetchPolicy: 'network-only',
      });
    } else {
      var f = new Date();
      //Valor de un día en milisegundos.
      let DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
      //Valor de una hora en milisegundos
      let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
      var date = new Date();
      //Fecha y hora teniendo en cuenta el time_zone del proyecto
      var day2 = date.toString().substring(0, 16) + ' ' + project.start_time + ' GMT ' + project.time_zone;
      //Fecha y hora inicial del proyecto en GMT
      var param2 = day2.substring(0, 16) + ' ' + project.start_time + ' GMT';
      //Fecha y hora inicial en GMT a milisegundos.
      var tempPara = new Date(param2).getTime() - parseInt(project.time_zone, 10) * HORA_EN_MILISEGUNDO;
      //Fecha y hora inicial en GMT -time_zone a milisegundos.
      var tmpDate = new Date(day2).getTime();
      if (f.getTime() < tmpDate) {
        setParametroMin(parseInt(tempPara) - DIA_EN_MILISEGUNDOS);
        setDateMin(parseInt(tmpDate) - DIA_EN_MILISEGUNDOS);
        setParametroMax(parseInt(tempPara, 10) + 86400000 - 1000 - DIA_EN_MILISEGUNDOS);
        setDateMax(parseInt(tmpDate, 10) + 86400000 - 1000 - DIA_EN_MILISEGUNDOS);

        setSelectedDate(new Date(f.getTime() - DIA_EN_MILISEGUNDOS));

        setDayMilisegundos(f.getTime() - DIA_EN_MILISEGUNDOS);

        getReportMeterZWave({
          variables: {
            RoomId: parseInt(RoomId, 10),
            start_time: parseInt(tempPara) - DIA_EN_MILISEGUNDOS,
            end_time: parseInt(tempPara, 10) + 86400000 - 1000 - DIA_EN_MILISEGUNDOS,
          },
          fetchPolicy: 'network-only',
        });
      } else {
        setParametroMin(parseInt(tempPara));
        setDateMin(parseInt(tmpDate));
        setParametroMax(parseInt(tempPara, 10) + 86400000 - 1000);
        setDateMax(parseInt(tmpDate, 10) + 86400000 - 1000);
        setSelectedDate(new Date(f.getTime()));

        setDayMilisegundos(f.getTime());

        getReportMeterZWave({
          variables: {
            RoomId: parseInt(RoomId, 10),
            start_time: parseInt(tempPara),
            end_time: parseInt(tempPara, 10) + 86400000 - 1000,
          },
          fetchPolicy: 'network-only',
        });
      }
    }
  }, []);

  const [getReportMeterZWave, { data, loading, error }] = useLazyQuery(GET_DATA_MEASURER_REPORT);

  useEffect(() => {
    if (data) {
      setChangeDay(true);
    }
  }, [data]);

  const before = (date) => {
    setChangeDay(false);
    setNextDay(false);
    let DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
    let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
    let date2 = date.getTime();
    setSelectedDate(new Date(date2 - DIA_EN_MILISEGUNDOS));
    var paramteroDate = new Date(date2 - DIA_EN_MILISEGUNDOS);

    if (!project.start_time || !project.end_time) {
      var dayTemp = new Date(date2 - DIA_EN_MILISEGUNDOS);
      var day2 = dayTemp.toString().substring(0, 16) + ' 00:00:00 GMT ' + project.time_zone;
      var param2 = day2.substring(0, 16) + ' 00:00:00 GMT';

      var tempPara = new Date(param2).getTime() - parseInt(project.time_zone, 10) * HORA_EN_MILISEGUNDO;
      var tmpDate = new Date(day2).getTime();
      setParametroMin(parseInt(tempPara));
      setDateMin(parseInt(tmpDate));
      setParametroMax(parseInt(tempPara, 10) + 86400000 - 1000);
      setDateMax(parseInt(tmpDate, 10) + 86400000 - 1000);

      getReportMeterZWave({
        variables: {
          RoomId: parseInt(idArea, 10),
          start_time: parseInt(tempPara),
          end_time: parseInt(tempPara, 10) + 86400000 - 1000,
        },
        fetchPolicy: 'network-only',
      });
    } else {
      var dayTemp = new Date(date2 - DIA_EN_MILISEGUNDOS);
      var day2 = dayTemp.toString().substring(0, 16) + ' ' + project.start_time + ' GMT ' + project.time_zone;
      var param2 = day2.substring(0, 16) + ' ' + project.start_time + ' GMT';

      var tempPara = new Date(param2).getTime() - parseInt(project.time_zone, 10) * HORA_EN_MILISEGUNDO;
      var tmpDate = new Date(day2).getTime();

      setParametroMin(parseInt(tempPara));
      setDateMin(parseInt(tmpDate));
      setParametroMax(parseInt(tempPara, 10) + 86400000 - 1000);
      setDateMax(parseInt(tmpDate, 10) + 86400000 - 1000);

      getReportMeterZWave({
        variables: {
          RoomId: parseInt(idArea, 10),
          start_time: parseInt(tempPara),
          end_time: parseInt(tempPara, 10) + 86400000 - 1000,
        },
        fetchPolicy: 'network-only',
      });
    }
  };

  const after = (date) => {
    setChangeDay(false);
    let DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
    let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
    let date2 = date.getTime() + DIA_EN_MILISEGUNDOS;

    if (date2 <= dayMilisegundos) {
      setSelectedDate(new Date(date2));
      var paramteroDate = new Date(date2);

      let dateNext = date2 + DIA_EN_MILISEGUNDOS;
      if (dateNext > dayMilisegundos) {
        setNextDay(true);
      }

      if (!project.start_time || !project.end_time) {
        var dayTemp = new Date(date2);
        var day2 = dayTemp.toString().substring(0, 16) + ' 00:00:00 GMT ' + project.time_zone;
        var param2 = day2.substring(0, 16) + ' 00:00:00 GMT';

        var tempPara = new Date(param2).getTime() - parseInt(project.time_zone, 10) * HORA_EN_MILISEGUNDO;
        var tmpDate = new Date(day2).getTime();

        setParametroMin(parseInt(tempPara));
        setDateMin(parseInt(tmpDate));
        setParametroMax(parseInt(tempPara, 10) + 86400000 - 1000);
        setDateMax(parseInt(tmpDate, 10) + 86400000 - 1000);

        getReportMeterZWave({
          variables: {
            RoomId: parseInt(idArea, 10),
            start_time: parseInt(tempPara),
            end_time: parseInt(tempPara, 10) + 86400000 - 1000,
          },
          fetchPolicy: 'network-only',
        });
      } else {
        var dayTemp = new Date(date2);
        var day2 = dayTemp.toString().substring(0, 16) + ' ' + project.start_time + ' GMT ' + project.time_zone;
        var param2 = day2.substring(0, 16) + ' ' + project.start_time + ' GMT';

        var tempPara = new Date(param2).getTime() - parseInt(project.time_zone, 10) * HORA_EN_MILISEGUNDO;
        var tmpDate = new Date(day2).getTime();

        setParametroMin(parseInt(tempPara));
        setDateMin(parseInt(tmpDate));
        setParametroMax(parseInt(tempPara, 10) + 86400000 - 1000);
        setDateMax(parseInt(tmpDate, 10) + 86400000 - 1000);

        getReportMeterZWave({
          variables: {
            RoomId: parseInt(idArea, 10),
            start_time: parseInt(tempPara),
            end_time: parseInt(tempPara, 10) + 86400000 - 1000,
          },
          fetchPolicy: 'network-only',
        });
      }
    } else {
      setNextDay(true);
    }
  };

  const handleDateChange = (date) => {
    setChangeDay(false);
    let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;

    setSelectedDate(date);

    if (!project.start_time || !project.end_time) {
      var day2 = date.toString().substring(0, 16) + ' 00:00:00 GMT ' + project.time_zone;
      var param2 = day2.substring(0, 16) + ' 00:00:00 GMT';
      var tempPara = new Date(param2).getTime() - parseInt(project.time_zone, 10) * HORA_EN_MILISEGUNDO;
      var tmpDate = new Date(day2).getTime();

      if (tmpDate > dayMilisegundos) {
        setNextDay(true);
      } else {
        setNextDay(false);
      }
      setParametroMin(parseInt(tempPara));
      setDateMin(parseInt(tmpDate));
      setParametroMax(parseInt(tempPara, 10) + 86400000 - 1000);
      setDateMax(parseInt(tmpDate, 10) + 86400000 - 1000);

      getReportMeterZWave({
        variables: {
          RoomId: parseInt(idArea, 10),
          start_time: parseInt(tempPara),
          end_time: parseInt(tempPara, 10) + 86400000 - 1000,
        },
        fetchPolicy: 'network-only',
      });
    } else {
      var day2 = date.toString().substring(0, 16) + ' ' + project.start_time + ' GMT ' + project.time_zone;
      var param2 = day2.substring(0, 16) + ' ' + project.start_time + ' GMT';

      var tempPara = new Date(param2).getTime() - parseInt(project.time_zone, 10) * HORA_EN_MILISEGUNDO;
      var tmpDate = new Date(day2).getTime();

      if (tmpDate > dayMilisegundos) {
        setNextDay(true);
      } else {
        setNextDay(false);
      }

      setParametroMin(parseInt(tempPara));
      setDateMin(parseInt(tmpDate));
      setParametroMax(parseInt(tempPara, 10) + 86400000 - 1000);
      setDateMax(parseInt(tmpDate, 10) + 86400000 - 1000);

      getReportMeterZWave({
        variables: {
          RoomId: parseInt(idArea, 10),
          start_time: parseInt(tempPara),
          end_time: parseInt(tempPara, 10) + 86400000 - 1000,
        },
        fetchPolicy: 'network-only',
      });
    }
    //refetch();
  };

  const handleChange = (event) => {
    setIdArea(event.target.value);
    getReportMeterZWave({
      variables: {
        RoomId: parseInt(event.target.value, 10),
        start_time: parametroMin,
        end_time: parametroMax,
      },
      fetchPolicy: 'network-only',
    });
  };

  if (error) {
    return <Construction />;
  }

  if (loading || !data || !changeDay) {
    return <Loading />;
  }

  return (
    <div className="__report_measurer_container__">
      <div className="__report_measurer_set_date__">
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          locale={frLocale}
        >
          <Grid
            container
            justify="center"
          >
            <IconButton
              color="grey"
              onClick={() => {
                before(selectedDate);
              }}
            >
              <ChevronLeft />
            </IconButton>
            <ThemeProvider theme={innerTheme}>
              <DatePicker
                okLabel="seleccionar"
                showTodayButton={true}
                todayLabel="hoy"
                id="date-picker-dialog"
                label="Fecha"
                format="eeee, dd MMMM"
                value={selectedDate}
                onChange={handleDateChange}
                disableFuture
                color="primary"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </ThemeProvider>
            <IconButton
              color="grey"
              disabled={nextDay}
              onClick={() => {
                after(selectedDate);
              }}
            >
              <ChevronRight />
            </IconButton>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-helper-label">Área</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={idArea}
                onChange={handleChange}
              >
                {areas.map((item, key) => (
                  <MenuItem
                    key={key}
                    value={item.id}
                  >
                    {item.alias}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </MuiPickersUtilsProvider>
      </div>
      <div className="__report_measurer_graph_columns__">
        {data.getReportMeterZWave ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: 'column',
              },
              title: {
                text: 'Consumo en kWh',
              },
              xAxis: {
                type: 'datetime',
                min: dateMin,
                max: dateMax,
                tickInterval: 3600000,
                labels: {
                  format: '{value:%H:%M}',
                },
              },
              yAxis: {
                min: 0,
                max: data.getReportMeterZWave.length,
                tickAmount: data.getReportMeterZWave.length + 1,
                title: {
                  text: 'Kilovatios/hora (kWh)',
                },
              },
              colors: ['#04b431', '#013adf', '#df01a5', '#DF2653', '#615C5D', '#D5DF03'],
              plotOptions: {
                column: {
                  stacking: 'stream',
                },
              },
              time: {
                timezoneOffset: -(project.time_zone * 60),
              },
              tooltip: {
                xDateFormat: '%H:%M:%S',
                headerFormat: '<b>{point.key}</b><br>',
              },
              series: data.getReportMeterZWave.series_kwh,
            }}
          />
        ) : (
          <></>
        )}
      </div>

      <div className="__report_measurer_graph_line__">
        {data.getReportMeterZWave ? (
          <>
            {dateMax && (
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: 'spline',
                  },
                  title: {
                    text: 'Watts',
                  },
                  // legend: {
                  //     align: "right",
                  //     verticalAlign: "top",
                  //     layout: "vertical",
                  //     x: 0,
                  //     y: 0,
                  // },
                  xAxis: {
                    type: 'datetime',
                    min: dateMin,
                    max: dateMax,
                    tickInterval: 3600000,
                    labels: {
                      format: '{value:%H:%M}',
                    },
                  },
                  yAxis: {
                    min: 0,
                    title: {
                      text: 'Vatios(W)',
                    },
                  },
                  colors: ['#04b431', '#013adf', '#df01a5'],
                  tooltip: {
                    pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
                    shared: true,
                  },
                  plotOptions: {
                    column: {
                      stacking: 'line',
                    },
                  },
                  time: {
                    timezoneOffset: -(project.time_zone * 60),
                  },
                  series: data.getReportMeterZWave.series_watts,
                }}
              />
            )}
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="__report_measurer_table_total__">
        <TableContainer component={Paper}>
          <div className="__report_measurer_table_total_title__">Resumen total</div>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">#</StyledTableCell>
                <StyledTableCell align="center">Áreas</StyledTableCell>
                <StyledTableCell align="center">Total</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.getReportMeterZWave.series_kwh
                ? data.getReportMeterZWave.series_kwh.map((i, key) => (
                    <StyledTableRow>
                      <StyledTableCell align="center">{key + 1}</StyledTableCell>
                      <StyledTableCell align="center">{i.name}</StyledTableCell>
                      <StyledTableCell align="center">{sum_of_the_kwh(i.data)} KWh</StyledTableCell>
                    </StyledTableRow>
                  ))
                : false}
              <StyledTableRow>
                <StyledTableCell2
                  component="th"
                  scope="row"
                  align="center"
                >
                  Total del día
                </StyledTableCell2>
                <StyledTableCell2
                  component="th"
                  scope="row"
                ></StyledTableCell2>
                <StyledTableCell2
                  component="th"
                  scope="row"
                  align="center"
                >
                  {total_of_the_kwh(data.getReportMeterZWave.series_kwh)} KWh
                </StyledTableCell2>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {data.getReportMeterZWave.series_kwh ? (
        data.getReportMeterZWave.series_kwh.map((j) => (
          <div className="__report_measurer_rooms__">
            <TableContainer component={Paper}>
              <div className="__report_measurer_by_room__">Resumen total {j.name}</div>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">#</StyledTableCell>
                    <StyledTableCell align="center">Date</StyledTableCell>
                    <StyledTableCell align="center">Total</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {j.data.map((i, key) => (
                    <StyledTableRow>
                      <StyledTableCell align="center">{key + 1}</StyledTableCell>
                      <StyledTableCell align="center">{date_of_the_kwh(i.x)}</StyledTableCell>
                      <StyledTableCell align="center">{i.y} KWh</StyledTableCell>
                    </StyledTableRow>
                  ))}
                  <StyledTableRow>
                    <StyledTableCell2
                      component="th"
                      scope="row"
                      align="center"
                    >
                      Total del día
                    </StyledTableCell2>
                    <StyledTableCell2
                      component="th"
                      scope="row"
                    ></StyledTableCell2>
                    <StyledTableCell2
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {sum_of_the_kwh(j.data)} KWh
                    </StyledTableCell2>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
};

export default Measurer;

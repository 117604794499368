import gql from "graphql-tag";

export const GET_ROLE_PERMISSIONS = gql`
  query getRoleAndPermission {
    getAllRoles {
      id
      name
      components
      enabled
    }
  }
`;

export const DELETE_ROLES = gql`
  mutation DeleteRole($id: [Int!]) {
    DeleteRole(id: $id)
  }
`;

export const CREATE_ROLE_PERMISSION = gql`
  mutation CreateRoleAndPerm($nameRole: String!, $components: [JSON]) {
    CreateRoleAndPermission(nameRole: $nameRole, components: $components)
  }
`;

export const EDIT_ROLE_PERMISSION = gql`
  mutation updateRoleAndPermission(
    $name: String!
    $id: Int!
    $components: [JSON]
    $enabled: Boolean
  ) {
    updateRoleAndPermission(
      name: $name
      id: $id
      enabled: $enabled
      components: $components
    )
  }
`;

export const DISABLE_ROLE_PERMISSION = gql`
  mutation updateRoleAndPermission($id: Int!, $enabled: Boolean) {
    updateRoleAndPermission(id: $id, enabled: $enabled)
  }
`;

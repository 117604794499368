import React, { useEffect, useContext } from "react";
import ControllerCard from "../../../components/controllers/Controller";
import ProjectCardAreaControl from "../../../components/projects/ProjectCardAreaControl";
import { useQuery } from "@apollo/client";
import { GET_ALL_CONTROLLERS } from "../../../controllers/controllerControllers";
import { useParams } from "react-router";
import Loading from "../../../components/loading/Loading";
import { AuthContext } from "../../../context/AuthProvider";
import VirtualControllerCreationButton from "./components/VirtualControllerCreationButton";

const Controllers = () => {
  const { id } = useParams();
  const { permissionsRoleUser } = useContext(AuthContext);
  const {
    data: dataController,
    loading,
    refetch,
  } = useQuery(GET_ALL_CONTROLLERS, {
    variables: { ProjectId: parseInt(id, 10) },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  if (loading) {
    return (
      <div style={{}}>
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="_control_container">
        {permissionsRoleUser?.permissionsRole?.CtlVer ? (
          <>
            <div className="__controller_container__">
              <div className="__controller_container_logo__">
                <img
                  src={dataController?.getProjectByIdHeader.img}
                  className="__controller__img"
                  alt="Logo"
                  width="180"
                  height="180"
                />
              </div>
              <div className="_control_container_projects">
                <ProjectCardAreaControl
                  data={dataController?.getProjectByIdHeader}
                  AddController={<VirtualControllerCreationButton  refetch={refetch} />}
                
                />
              </div>
            </div>
            <div className="_control_container_controllers_all">
              {dataController?.getAllControllers.map((item, key) => (
                <ControllerCard {...key} item={item} />
              ))}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default Controllers;

export const Devices = {
  BINARY_LIGHT_OFF: "https://horuspro.s3.amazonaws.com/Public/binary_light_off.png",
  BINARY_LIGHT_ON: "https://horuspro.s3.amazonaws.com/Public/binary_light_on.png",
  BUTTON_HOME_SELECTED:
    "https://horuspro.s3.amazonaws.com/Public/button_home_selected.png",
  BUTTON_HOME: "https://horuspro.s3.amazonaws.com/Public/button_home.png",
  DESOCUPADA: "https://horuspro.s3.amazonaws.com/Public/desocupada.png",
  DIMMABLE_LIGHT_10: "https://horuspro.s3.amazonaws.com/Public/dimmable_light_10.png",
  DIMMABLE_LIGHT_20: "https://horuspro.s3.amazonaws.com/Public/dimmable_light_20.png",
  DIMMABLE_LIGHT_30: "https://horuspro.s3.amazonaws.com/Public/dimmable_light_30.png",
  DIMMABLE_LIGHT_40: "https://horuspro.s3.amazonaws.com/Public/dimmable_light_40.png",
  DIMMABLE_LIGHT_50: "https://horuspro.s3.amazonaws.com/Public/dimmable_light_50.png",
  DIMMABLE_LIGHT_60: "https://horuspro.s3.amazonaws.com/Public/dimmable_light_60.png",
  DIMMABLE_LIGHT_70: "https://horuspro.s3.amazonaws.com/Public/dimmable_light_70.png",
  DIMMABLE_LIGHT_80: "https://horuspro.s3.amazonaws.com/Public/dimmable_light_80.png",
  DIMMABLE_LIGHT_90: "https://horuspro.s3.amazonaws.com/Public/dimmable_light_90.png",
  DIMMABLE_LIGHT_100: "https://horuspro.s3.amazonaws.com/Public/dimmable_light_100.png",
  DIMMABLE_LIGHT_OFF: "https://horuspro.s3.amazonaws.com/Public/dimmable_light_off.png",
  DOOR_WINDOW_TRIPPED: "https://horuspro.s3.amazonaws.com/Public/door_window_tripped.png",
  DOOR_WINDOW: "https://horuspro.s3.amazonaws.com/Public/door_window.png",
  METER_DEFAULT: "https://horuspro.s3.amazonaws.com/Public/meter_default.png",
  MOTION_SENSOR_TRIPPED:
    "https://horuspro.s3.amazonaws.com/Public/motion_sensor_tripped.png",
  MOTION_SENSOR: "https://horuspro.s3.amazonaws.com/Public/motion_sensor.png",
  OCUPADA: "https://horuspro.s3.amazonaws.com/Public/ocupada.png",
  SIREN_OFF: "https://horuspro.s3.amazonaws.com/Public/siren_off.png",
  SIREN_ON: "https://horuspro.s3.amazonaws.com/Public/siren_on.png",
  TEMPERATURE_AMBIENT: "https://horuspro.s3.amazonaws.com/Public/temperature_ambient.png",
  TEMPERATURE_COOL: "https://horuspro.s3.amazonaws.com/Public/temperature_cool.png",
  TEMPERATURE_DEFAULT: "https://horuspro.s3.amazonaws.com/Public/temperature_default.png",
  TEMPERATURE_HEAT: "https://horuspro.s3.amazonaws.com/Public/temperature_heat.png",
  TEMPERATURE_ICON: "https://horuspro.s3.amazonaws.com/Public/Temperature_icon.png",
  TEMPERATURE_SENSOR_DEFAULT:
    "https://horuspro.s3.amazonaws.com/Public/temperature_sensor_default.png",
  TERMOMETRO: "https://horuspro.s3.amazonaws.com/Public/termometro3.png",
  THERMOSTAT_DEVICE_DEFAULT:
    "https://horuspro.s3.amazonaws.com/Public/thermostat_device_default.png",
  THERMOSTAT_MODE_AUTO:
    "https://horuspro.s3.amazonaws.com/Public/thermostat_mode_auto.png",
  THERMOSTAT_MODE_COOLING:
    "https://horuspro.s3.amazonaws.com/Public/thermostat_mode_cooling.png",
  THERMOSTAT_MODE_HEATING:
    "https://horuspro.s3.amazonaws.com/Public/thermostat_mode_heating.png",
  THERMOSTAT_MODE_OFF: "https://horuspro.s3.amazonaws.com/Public/thermostat_mode_off.png",
  WINDOW_COVERING_10: "https://horuspro.s3.amazonaws.com/Public/window_covering_10.png",
  WINDOW_COVERING_20: "https://horuspro.s3.amazonaws.com/Public/window_covering_20.png",
  WINDOW_COVERING_30: "https://horuspro.s3.amazonaws.com/Public/window_covering_30.png",
  WINDOW_COVERING_40: "https://horuspro.s3.amazonaws.com/Public/window_covering_40.png",
  WINDOW_COVERING_50: "https://horuspro.s3.amazonaws.com/Public/window_covering_50.png",
  WINDOW_COVERING_60: "https://horuspro.s3.amazonaws.com/Public/window_covering_60.png",
  WINDOW_COVERING_70: "https://horuspro.s3.amazonaws.com/Public/window_covering_70.png",
  WINDOW_COVERING_80: "https://horuspro.s3.amazonaws.com/Public/window_covering_80.png",
  WINDOW_COVERING_90: "https://horuspro.s3.amazonaws.com/Public/window_covering_90.png",
  WINDOW_COVERING_100: "https://horuspro.s3.amazonaws.com/Public/window_covering_100.png",
  WINDOW_COVERING_CLOSE:
    "https://horuspro.s3.amazonaws.com/Public/window_covering_close.png",
  WINDOW_COVERING_OPEN:
    "https://horuspro.s3.amazonaws.com/Public/window_covering_open.png",
  ZWAVE_DEFAULT: "https://horuspro.s3.amazonaws.com/Public/zwave_default.png",
  CO_SENSOR_TRIPPED: "https://horuspro.s3.amazonaws.com/Public/co_sensor_tripped.png",
  CO_SENSOR_UNTRIPPED: "https://horuspro.s3.amazonaws.com/Public/co_sensor_untripped.png",
  SMOKE_SENSOR_DEFAULT:
    "https://horuspro.s3.amazonaws.com/Public/smoke_sensor_default.png",
  SMOKE_SENSOR_TRIPPED:
    "https://horuspro.s3.amazonaws.com/Public/smoke_sensor_tripped.png",
  HUMADITY_SENSOR_DEFAULT:
    "https://horuspro.s3.amazonaws.com/Public/humidity_sensor_default.png",
  LIGHT_SENSOR_DEFAULT:
    "https://horuspro.s3.amazonaws.com/Public/light_sensor_default.png",
  DOORBELL_STATIC: "https://horuspro.s3.amazonaws.com/Public/doorbell_static.png",
  SIREN_OFF: "https://horuspro.s3.amazonaws.com/Public/siren_off.png",
  SIREN_ON: "https://horuspro.s3.amazonaws.com/Public/siren_on.png",
  DOOR_BLOQUEADO: "https://horuspro.s3.amazonaws.com/Public/door_lock_locked.png",
  DOOR_DESBLOQUEADO: "https://horuspro.s3.amazonaws.com/Public/door_lock_unlocked.png",
};

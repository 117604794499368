import * as React from "react";
import Button from "@mui/material/Button";
import LoginIcon from "@mui/icons-material/Login";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useHistory } from "react-router-dom";

const customGreen = "#acf6ac";

const theme = createTheme({
  palette: {
    success: {
      main: customGreen,
    },
  },
});

const ManagementButton = () => {
  return (
    <ThemeProvider theme={theme}>
      <Button
        variant="contained"
        endIcon={<LoginIcon />}
        color="success"
        disabled={true}
        sx={{
          color: "black",
          borderRadius: "8px",
        }}
      >
        Ingresar
      </Button>
    </ThemeProvider>
  );
};

export default ManagementButton;

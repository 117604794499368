import React, { useState, useContext } from "react";
import { AuthContext } from "../../context/AuthProvider";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "../../controllers/usersControllers";
import { useHistory } from "react-router-dom";
import { decryptData, encryptData } from "../../utils/encryptDecrypt";
const password = process.env.REACT_APP_ENCRYPT_DECRYPT_KEY;

export default function ConfirmationDialog(props) {
  const history = useHistory();
  const [update] = useMutation(UPDATE_USER);
  const { onClose, value: valueProp, /* open, */ ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef(null);
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [menssage, setMenssage] = useState();
  //emailInput
  const [errormess, setErrormess] = useState("");
  const [color, setColor] = useState("success");
  //emailConfirm
  const [errormessconf, setErrormessconf] = useState("");
  const [colorconfirm, setColorconfirm] = useState("success");
  //No match email
  const [match, setMatch] = useState(false);

  const [datauser, setDatauser] = React.useState({
    email: "",
    emailConf: "",
  });

  React.useEffect(() => {
    if (localStorage.getItem("email") === "") {
      setOpen(true);
    } else {
    }
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    openAlert(false);
  };

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const validation = () => {
    if (datauser.email.includes("@" && ".") === true) {
      setColor("success");
      setErrormess("");
    } else if (datauser.email.includes("@" && ".") === false) {
      setColor("error");
      setErrormess("Correo inválido");
    }
  };
  const validationConfirm = () => {
    if (datauser.emailConf.includes("@" && ".") === true) {
      setColorconfirm("success");
      setErrormessconf("");
    } else if (datauser.emailConf.includes("@" && ".") === false) {
      setColorconfirm("error");
      setErrormessconf("Correo inválido");
    }
    if (datauser.emailConf === datauser.email) {
      setMatch(false);
    }
    if (datauser.emailConf !== datauser.email) {
      setMatch(true);
    }
  };

  const handleChange = (event) => {
    validation();
    const value = event.target.value;
    const name = event.target.name;
    setDatauser({
      ...datauser,
      [name]: value,
    });
  };

  const handleChangeConfirm = (event) => {
    validationConfirm();
    const value = event.target.value;
    const name = event.target.name;
    setDatauser({
      ...datauser,
      [name]: value,
    });
  };

  function handleCloseModal() {
    setDatauser({ email: "", emailConf: "" });
    setTimeout(function () {
      localStorage.removeItem("token");
      window.location.reload();
      history.push("/");
      setOpen(false);
    }, 4000); // 1000 milisegundos = 1 segundo
  }

  const updateUser = async () => {
    const value = await encryptData(datauser.email, password);
    try {
      update({
        variables: {
          id: parseInt(localStorage.getItem("np")),
          email: value,
        },
      })
        .then((resul) => {
          setOpenAlert(true);
          handleCloseModal();
          //action a realizar si el cambio se ejecuta correctamente
        })
        .catch((err) => {
          //action a realizar si el cambio no se ejecuta
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "80%",
          maxHeight: 500,
          borderRadius: "8px",
        },
      }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle
        sx={{
          background: "#3f9948",
          color: "white",
          textAlign: "center",
          fontFamily: "Montserrat, sans serif",
        }}
      >
        <b>¡Actualiza tus datos!</b>
      </DialogTitle>
      <DialogContent>
        <p
          style={{
            fontFamily: "Montserrat, sans serif",
            textAlign: "justify",
            color: "black",
          }}
        >
          {" "}
          Estamos trabajando día a día para mejorar tu expriencia. Por tu
          seguridad y para hacer uso de nuevas y futuras funcionalidades,
          necesitamos que actualices tus datos.
        </p>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <TextField
            helperText={errormess}
            onChange={handleChange}
            name="email"
            color={color}
            fullWidth
            label="Correo electrónico"
            type="email"
            size="small"
            InputProps={{ sx: { borderRadius: "8px" } }}
          />
          <TextField
            helperText={errormessconf}
            onChange={handleChangeConfirm}
            name="emailConf"
            color={colorconfirm}
            fullWidth
            label="Confirmar correo electrónico"
            type="email"
            size="small"
            InputProps={{ sx: { borderRadius: "8px" } }}
          />
        </div>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "0px 24px 20px",
        }}
      >
        <Button
          disabled={
            datauser.email !== "" && datauser.emailConf !== ""
              ? datauser.email === datauser.emailConf
                ? false
                : true
              : true
          }
          fullWidth
          sx={{
            borderRadius: "8px",
            marginBottom: "5px",
            backgroundColor: "#2EB142",
            color: "white",
            fontFamily: "Montserrat, sans serif ",
            "&:hover": {
              backgroundColor: "#336535",
            },
          }}
          onClick={updateUser}
        >
          <b>Guardar cambios</b>
        </Button>
      </DialogActions>

      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity="success">
          Correo registrado correctamente!, tu sesion cerrará, por favor vuelve
          a ingresar
        </Alert>
      </Snackbar>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

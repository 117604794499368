import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/AuthProvider";
import { Collapse, FormControlLabel, Switch } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import GestionPermissions from "./GestionPermissions";
import ProjectPermissions from "./ProjectPermissions";

const BlueSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "rgba(88, 84, 219, 0.93)",
    "&:hover": {
      backgroundColor: alpha("rgba(88, 84, 219, 0.93)", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "rgba(88, 84, 219, 0.93)",
  },
}));

const PrincipalConfigAndGestionRol = ({ label, name, action, component }) => {
  const { permissionsRole, setPermissionsRoles } = useContext(AuthContext);

  const handleChange = (name) => {
    setPermissionsRoles({
      ...permissionsRole,
      [name]: !permissionsRole[name],
    });
  };

  return (
    <>
      <div className="prueba">
        {label}
        <BlueSwitch
          onClick={() => handleChange(name)} /* Switches padre */
          checked={permissionsRole[name]}
        ></BlueSwitch>
      </div>

      <div className="container_gestion">
        {permissionsRole[name] &&
          action?.map((i) => (
            <GestionPermissions
              data={i}
              handleChange={handleChange}
              state={permissionsRole}
            />
          ))}

        {permissionsRole[name] &&
          component?.map((j) => (
            <ProjectPermissions
              data={j}
              handleChange={handleChange}
              state={permissionsRole}
            />
          ))}
      </div>
    </>
  );
};
export default PrincipalConfigAndGestionRol;

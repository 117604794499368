import gql from "graphql-tag";

export const LOGIN = gql`
  mutation ($user: String, $password: String) {
    Login(user: $user, password: $password) {
      id
      name
      last_name
      token
      email
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ($user: String, $email: String) {
    send_Email(user: $user, email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ($token: String, $password: String) {
    create_Reg_Password(token: $token, password: $password)
  }
`;

export const LOGOUT = gql`
  mutation Mutation {
    logout
}`

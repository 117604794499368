import React, { useEffect, useContext } from "react";
import { GET_REPORT_FAILS_BY_PROJECT } from "../../controllers/reportController";
import { AuthContext } from "../../context/AuthProvider";
import Loading from "../loading/Loading";
import { useQuery } from "@apollo/client";

//components
import DeviceFails from "./DeviceFails";

//multilanguage
import { useTranslation } from "react-i18next";

const ModalReportFailsByProject = ({ projectId }) => {
  const { t } = useTranslation();
  const [filterData, setFilterData] = React.useState(null);
  const { deviceFailsCount, setDeficeFailsCount } = useContext(AuthContext);

  const { data, loading } = useQuery(GET_REPORT_FAILS_BY_PROJECT, {
    variables: {
      ProjectId: projectId,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!loading) {
      setDeficeFailsCount(data?.getFailuresReport.length);
    }
  }, []);

  const __title__ = (lng) => {
    return lng > 1
      ? `${t(
          "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.FAIL_REPORT_VIEW.DEVICES_FAILING_DESCRIPTION"
        )} ${lng}`
      : `${t(
          "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.FAIL_REPORT_VIEW.DEVICE_FAILING"
        )}`;
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (value === "all") return setFilterData(null);
    const newData = [];
    newData.push(data?.getFailuresReport.filter((i) => i.name == value));
    return setFilterData(newData[0]);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="__DeviceFailsByProjectContainer__">
      {!data?.getFailuresReport || data?.getFailuresReport.length === 0 ? (
        <div className="_empty_data_">
          {t(
            "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.FAIL_REPORT_VIEW.NO_DATA"
          )}
        </div>
      ) : (
        <>
          <div className="__DFPC_Title_Filter__">
            <div className="__DFPC_Title__">
              {__title__(
                data?.getFailuresReport
                  .map((i) => i.Device.length)
                  .reduce((a, b) => a + b)
              )}
            </div>
            <div className="__DFPC_Select__">
              <label>
                {t(
                  "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.FAIL_REPORT_VIEW.FILTER_LABEL"
                )}{" "}
              </label>
              <select name="filter_project" onChange={handleChange}>
                <option value={"all"}>
                  {t(
                    "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.FAIL_REPORT_VIEW.ALL"
                  )}
                </option>
                {data?.getFailuresReport.map((i, key) => (
                  <option key={key} value={i.name}>
                    {i.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {filterData === null ? (
            <div className="__DFPC_DeviceFailsContainer__">
              {data?.getFailuresReport.map((i) => (
                <DeviceFails data={i} />
              ))}
            </div>
          ) : (
            <div className="__DFPC_DeviceFailsContainer__">
              {filterData.map((i) => (
                <DeviceFails data={i} />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ModalReportFailsByProject;

import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { views } from './views';

//paths
import {
  LOGIN,
  DASHBOARD,
  PROJECT,
  FORGOT,
  RESET_PASSWORD,
  SEND_CONFIRMATION_EMAIL,
  PASSWORD_UPDATED,
} from '../../constants/paths';

const Router_Per_View = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path={LOGIN}
          render={(props) =>
            !localStorage.getItem('token') ? (
              <views.Login {...props} />
            ) : (
              <Redirect
                from="/app/projects"
                to={PROJECT}
              />
            )
          }
        />
        <Route
          path={DASHBOARD}
          render={(props) =>
            localStorage.getItem('token') ? (
              <views.Dashboard {...props} />
            ) : (
              <Redirect
                from="/"
                to={LOGIN}
              />
            )
          }
        />
        <Route
          path={FORGOT}
          render={(props) =>
            !localStorage.getItem('token') ? (
              <views.ForgotPassword {...props} />
            ) : (
              <Redirect
                from="/"
                to={LOGIN}
              />
            )
          }
        />
        <Route
          path={SEND_CONFIRMATION_EMAIL}
          render={(props) =>
            !localStorage.getItem('token') ? (
              <views.Confirmation {...props} />
            ) : (
              <Redirect
                from="/"
                to={LOGIN}
              />
            )
          }
        />
        <Route
          path={PASSWORD_UPDATED}
          render={(props) =>
            !localStorage.getItem('token') ? (
              <views.PasswordUpdated {...props} />
            ) : (
              <Redirect
                from="/"
                to={LOGIN}
              />
            )
          }
        />
        <Route
          path={RESET_PASSWORD}
          render={(props) =>
            !localStorage.getItem('token') ? (
              <views.ResetPassword {...props} />
            ) : (
              <Redirect
                from="/"
                to={LOGIN}
              />
            )
          }
        />
        <Redirect
          from="/"
          to={LOGIN}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default Router_Per_View;

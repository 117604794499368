import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// components
import Role from '../../views/private/roleManager/roleManager';
import DevicesManagement from '../../views/private/devicesManagement/DevicesManagement';
import ProjectManagement from '../../views/private/projectsManagement/projectManagement';
import UsersManagements from '../../views/private/userManagement/usersManagements';
import Projects from '../../views/private/projects/Projects.jsx';
import Areas from '../../views/private/areas/Areas.jsx';
import Devices from '../../views/private/devices/Devices.jsx';
import NewDevices from '../../views/private/devices/NewDevices';
import Controllers from '../../views/private/controllers/Controllers.jsx';
import Measurers from '../../views/private/measurer/Measurers.jsx';
import { LOGIN } from '../../constants/paths';
import RoleManager from '../../views/private/roleManager/roleManager';
import StepperViewMacrometer from '../../views/private/stepperViewMacrometer/stepperViewMacrometer';
import ListCodes from '../../components/devices/DoorLock/ListCodes.jsx';
import Description from '../../components/stepperView/pages/Description';
import ErrorMacrometerInstallation from '../../components/stepperView/pages/ErrorMacrometerInstallation';
import MacrometerVariables from '../../components/stepperView/pages/MacrometerVariables';
import DataValidation from '../../components/stepperView/pages/DataValidation';
import Finalization from '../../components/stepperView/pages/Finalization';

//Material Icons
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';

export const ROUTER = [
  {
    path: '/app/stepperviewmacrometer/description/macrometervariables/macrometerdatavalidation/finalization',
    name: '/app/stepperviewmacrometer/description/macrometervariables/macrometerdatavalidation/finalization',
    nameState: 'finalization',
    component: Finalization,
    layout: '/app',
    code: 'final',
    description: 'Se ha completado el formulario de manera exitosa, si desea terminar la instalación con los datos actualmente registrados, por favor hacer click en el botón de finalizar',
    visible: false,
    next: '/app/devicesmanagement',
    step: 'Finalización',
    prev: null,
  },
  {
    path: '/app/stepperviewmacrometer/description/macrometervariables/macrometerdatavalidation',
    name: '/app/stepperviewmacrometer/description/macrometervariables/macrometerdatavalidation',
    nameState: 'datavalidation',
    component: DataValidation,
    layout: '/app',
    code: 'validation',
    description:
      'Usted ya ha completado los pasos, por favor verifique los datos ingresados, puede hacerlo yendo hacia atrás, revisando el cuadro de resumen que se encuantra a su derecha, o en su defecto si se encuentra desde un dispositivo móvil pulsando el botón resumen que se encuantra en la parte superior derecha',
    visible: false,
    next: '/app/stepperviewmacrometer/description/macrometervariables/macrometerdatavalidation/finalization',
    step: 'Validación de datos',
    prev: '/app/stepperviewmacrometer/description/macrometervariables',
  },
  {
    path: '/app/stepperviewmacrometer/description/macrometervariables',
    name: '/app/stepperviewmacrometer/description/macrometervariables',
    nameState: 'macrometervariables',
    component: MacrometerVariables,
    layout: '/app',
    code: 'variables',
    description: 'Estas son las variables con las que se configuró el medidor por defecto, si deseas cambiar alguna de las variables, usted debe modificar los valores.',
    visible: false,
    next: '/app/stepperviewmacrometer/description/macrometervariables/macrometerdatavalidation',
    step: 'Modificación de variables',
    prev: '/app/stepperviewmacrometer/description',
    icon: ElectricMeterIcon,
  },
  {
    path: '/app/stepperviewmacrometer/description/errormacrometerinstallation',
    name: '/app/stepperviewmacrometer/description/errormacrometerinstallation',
    nameState: 'intallationerror',
    component: ErrorMacrometerInstallation,
    layout: '/app',
    code: 'error',
    description: 'El proyecto no contiene medidores a instalar asociados, por favor restablezca de fabrica el comunicador e inicie nuevamente la configuración.',
    visible: false,
    next: null,
    prev: '/app/devicesmanagement',
  },
  {
    path: '/app/stepperviewmacrometer/description',
    name: '/app/stepperviewmacrometer/description',
    nameState: 'beginning',
    component: Description,
    layout: '/app',
    code: '',
    description: 'A continuación encontrarás un formulario el cual deberas llenar paso a paso para hacer la correcta instalación del dispositivo. También, podrás personalizar metricas de este.',
    visible: false,
    next: '/app/stepperviewmacrometer/description/macrometervariables',
    step: 'ID del proyecto',
    prev: '/app/devicesmanagement',
  },
  {
    path: '/stepperviewmacrometer',
    name: '/stepperviewmacrometer',
    component: StepperViewMacrometer,
    layout: '/app',
  },
  {
    path: '/devicesmanagement',
    name: '/devicesmanagement',
    component: DevicesManagement,
    layout: '/app',
  },

  {
    path: '/rolemanager',
    name: '/rolemanager',
    component: RoleManager,
    layout: '/app',
  },
  {
    path: '/projectmanagement',
    name: '/projectmanagement',
    component: ProjectManagement,
    layout: '/app',
  },
  {
    path: '/usersmanagements',
    name: '/usersmanagements',
    component: UsersManagements,
    layout: '/app',
  },
  {
    path: '/projects',
    name: 'projects',
    component: Projects,
    layout: '/app',
  },
  {
    path: '/areas/:id',
    name: 'areas',
    component: Areas,
    layout: '/app',
  },
  {
    path: '/devices/:id',
    name: 'devices',
    component: Devices,
    layout: '/app',
  },
  {
    path: '/newdevices/:id',
    name: 'newdevices',
    component: NewDevices,
    layout: '/app',
  },
  {
    path: '/controllers/:id',
    name: 'controllers',
    component: Controllers,
    layout: '/app',
  },
  {
    path: '/measurers/:id',
    name: 'measurers',
    component: Measurers,
    layout: '/app',
  },
  {
    path: '/doorlock/:controllerId/:id',
    name: 'device',
    component: ListCodes,
    layout: '/app',
  },
];

const Router_per_Components = () => {
  return ROUTER.map((prop, key) => {
    if (prop.layout === '/app') {
      return (
        <Route
          path={prop.layout + prop.path}
          component={
            localStorage.getItem('token') ? (
              prop.component
            ) : (
              <Redirect
                from="/"
                to={LOGIN}
              />
            )
          }
          key={key}
        />
      );
    } else {
      return null;
    }
  });
};

export default Router_per_Components;

import React, { useContext } from "react";
import IconButton from "@mui/material/IconButton";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { CancelInstallationButtonContainer } from "../styles/MacrometerVariables.style";
import { Tooltip } from "@mui/material";
import { AuthContext } from "../../../context/AuthProvider";

const CancelInstallationButton = () => {
  const { setOpenCancelInstallation } = useContext(AuthContext);
  const handleAbortInstallation = () => {
    setOpenCancelInstallation(true);
  };

  return (
    <CancelInstallationButtonContainer>
      <Tooltip title={"Cancelar Instalación"}>
        <IconButton color="warning" onClick={() => handleAbortInstallation()}>
          <CancelOutlinedIcon />
        </IconButton>
      </Tooltip>
    </CancelInstallationButtonContainer>
  );
};

export default CancelInstallationButton;

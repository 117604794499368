import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { useLazyQuery } from "@apollo/client";
import {
  GET_BATTERY_DEVICES,
  GET_BATTERY_NEW_DEVICES,
} from "../../controllers/reportController";
import Battery20Icon from "@material-ui/icons/Battery20";
import Battery40Icon from "@material-ui/icons/Battery30";
import Battery60Icon from "@material-ui/icons/Battery60";
import Battery80Icon from "@material-ui/icons/Battery80";
import Battery90Icon from "@material-ui/icons/Battery90";
import Loading from "../loading/Loading";
import Slide from "@material-ui/core/Slide";
import {
  formatDateLocal,
  changeColorIconBatteryLevel,
} from "../../utils/helpers";
import { t } from "i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBarDeviceFails: {
    position: "fixed",
    width: 600,
    zIndex: 10,
    height: 60,
    marginBottom: 100,
    boxShadow:
      "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px",
    backgroundColor: "#3f9948",
  },
  titleConainer: {
    marginLeft: theme.spacing(1),
    color: "white",
    paddingTop: 12,
    flex: 1,
  },
  title: {
    fontSize: 15,
  },
  spacingClose: {
    marginRight: 30,
    padding: "10px 15px",
    cursor: "pointer",
    borderRadius: 50,
    "&:hover": {
      backgroundColor: "#069C08",
    },
  },
}));

const BatteryLevel = ({
  openBattery,
  handleCloseBattery,
  name,
  id,
  typeGateway,
}) => {
  const [getDataBatteryReport, { data: dataBattery150, loading: loading150 }] =
    useLazyQuery(GET_BATTERY_DEVICES);

  const [
    getDataNewBatteryReport,
    { data: dataBattery700, loading: loading700 },
  ] = useLazyQuery(GET_BATTERY_NEW_DEVICES);

  const queryHorus150 = {
    variables: {
      DeviceId: parseInt(id, 10),
    },
  };

  const queryHorus700 = {
    variables: {
      itemId: id,
    },
  };

  useEffect(() => {
    if (typeGateway === "horus_700") {
      getDataNewBatteryReport(queryHorus700);
    } else if (typeGateway === "horus_g150") {
      getDataBatteryReport(queryHorus150);
    }
  }, []);

  const batteryIcon = (lv) => {
    if (lv >= 1 && lv <= 20) {
      return <Battery20Icon />;
    } else if (lv > 20 && lv <= 40) {
      return <Battery40Icon />;
    } else if (lv > 40 && lv <= 60) {
      return <Battery60Icon />;
    } else if (lv > 60 && lv <= 80) {
      return <Battery80Icon />;
    } else {
      return <Battery90Icon />;
    }
  };

  if (typeGateway === "horus_g150" && loading150) {
    return <Loading />;
  } else if (typeGateway === "horus_700" && loading700) {
    return <Loading />;
  }

  const handleTypeGatewayDataBattery = () => {
    if (typeGateway === "horus_g150") {
      return {
        data: dataBattery150?.getDeviceByBatteryLevel?.data,
        length: dataBattery150?.getDeviceByBatteryLevel?.data?.length,
      };
    } else if (typeGateway === "horus_700") {
      return {
        data: dataBattery700?.getAllNewDeviceByBatteryLevel?.data,
        length: dataBattery700?.getAllNewDeviceByBatteryLevel?.data?.length,
      };
    }
  };

  return (
    <>
      {/* <div className={classes.appBarDeviceFails}>
        <Typography
          variant="h6"
          className={classes.titleConainer}
        >
          <span
            className={classes.spacingClose}
            onClick={handleCloseBattery}
          >
            X
          </span>
          <span
            title={`Reporte de bateria - ${localStorage.getItem('nameProject')} - ${name}`}
            className={classes.title}
          >
            Reporte de bateria - {`${localStorage.getItem('nameProject').substring(0, 25)}...`} - {name}
          </span>
        </Typography>
      </div> */}
      <div className="battery_devices">
        {handleTypeGatewayDataBattery().length === 0 ||
        handleTypeGatewayDataBattery().length === undefined ? (
          <div className="battery_device_empty">
            {t("VIEW_AREA.COMPONENT_FULL_MODAL_BATTERY.NO_DATA")}
          </div>
        ) : (
          <>
            <div className="battery_devices_title">
              {t("VIEW_AREA.COMPONENT_FULL_MODAL_BATTERY.BATTERY_STATUS")}
            </div>
            <div className="battery_device_title_container">
              <div className="battery_device_title_date">
                {t("VIEW_AREA.COMPONENT_FULL_MODAL_BATTERY.DATE")}
              </div>
              <div className="battery_device_title_level">
                {t("VIEW_AREA.COMPONENT_FULL_MODAL_BATTERY.LEVEL")}
              </div>
            </div>
            {[...handleTypeGatewayDataBattery().data].reverse().map(
              (i) =>
                formatDateLocal(i.x) && (
                  <>
                    <div className="battery_device_container">
                      <div className="battery_device_date">
                        {formatDateLocal(i.x)}
                      </div>
                      <div className="battery_device_level">
                        <span className="battery_device_averange">{i.y}%</span>
                        <span
                          className={`battery_device_level_icon ${changeColorIconBatteryLevel(
                            i.y
                          )}`}
                        >
                          {batteryIcon(i.y)}
                        </span>
                      </div>
                    </div>
                  </>
                )
            )}
          </>
        )}
      </div>
    </>
  );
};

export default BatteryLevel;

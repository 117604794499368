import { useQuery } from "@apollo/client";
import { GET_METER_HISTORY_VARIABLES } from "../../../controllers/measurersController";
import { secondClient } from "../../..";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthProvider";



const codigosCiudades = {
    Bogotá: 'BG',
    Barranquilla: 'BQ',
    default: 'BQ' 
  };
 Object.freeze(codigosCiudades);

export const useGetDataMacrometersVariables = (seriales,city) => {
    
    const { data, loading,error, refetch } = useQuery(GET_METER_HISTORY_VARIABLES, {
        client: secondClient,
        variables: { serial: seriales,    location: codigosCiudades[city] || codigosCiudades.default, },
        fetchPolicy: 'no-cache',
      });
      return { data, loading, error, refetch}
}
import React from "react";
import ConfirmationIcon from "../../../components/loading/ConfirmationIcon";
import { Link } from "react-router-dom";

export default function Confirmation() {
  return (
    <div className='confirmation_container'>
      <div className='confirmation_icon_text'>
        <span>
          <ConfirmationIcon src='https://assets7.lottiefiles.com/packages/lf20_b8am0qif.json' />
        </span>
      </div>
      <div className='confirmation_icon_text size_text'>
        <span>Haz recibido un email con instrucciones para recuperar tu contraseña.</span>
      </div>
      <div className='confirmation_back_login_container'>
        <span className='confirmation_back_login'>
          <Link to='/'>🡠 Regresar al LogIn</Link>
        </span>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";

export default function RecipeReviewCard({ data }) {
  const [expanded, setExpanded] = React.useState(false);
  const [actual, setActual] = React.useState("");
  const [alias, setAlias] = React.useState();
  const opcionesFormateo = {
    minimumFractionDigits: 1, // Fijar el número mínimo de dígitos fraccionarios en 1
    maximumFractionDigits: 1, // Fijar el número máximo de dígitos fraccionarios en 1
    useGrouping: true, // Habilitar el agrupamiento de miles
  };

  const [valuesVariables, setValuesVariables] = React.useState(
    data.getMetterAndMetterHistories.lecturaActual.toFixed(2)
  );

  useEffect(() => {
    const numero = data.getMetterAndMetterHistories.lecturaActual.toFixed(2);
    const opcionesFormateo = {
      minimumFractionDigits: 1, // Fijar el número mínimo de dígitos fraccionarios en 1
      maximumFractionDigits: 1, // Fijar el número máximo de dígitos fraccionarios en 1
      useGrouping: true, // Habilitar el agrupamiento de miles
    };
    const numeroFormateado = numero.toLocaleString("es-ES", opcionesFormateo);

  }, [data]);

  const nameVariables = [
    { name: "EI", EI: "Energía instantánea" },
    { name: "EAE", EAE: "Energía activa entregada" },
    { name: "ERR", ERR: "Energía reactiva recibida" },
    { name: "ERE", ERE: "Energía reactiva entregada" },
    { name: "EAR", EAR: "" },
  ];

  const handleNameValue = (e) => {
    let name = e.target.outerText;
    const objecValue = data?.getMetterAndMetterHistories.dataMetters.variables;
    setAlias(name);
    for (const i in objecValue) {
      if (i === name) {
        setValuesVariables(objecValue[i].value);
        nameVariables.map((l) => {
          if (l[i] !== undefined) {
            setActual(l[i]);
          }
        });
      }
    }
  };

  return (
    <div className="_carTotalNew_ ">
      <div className="__details_measurer_information_card_title__ background_CFT  background_CFA">
        <strong>LECTURA ACTUAL {String(actual?.toUpperCase())}</strong>
      </div>
      <div className="__details_measurer_consumption_voltage_variable_value__">
        <span>{parseInt(valuesVariables).toFixed(2)}</span>
      </div>
      <div className="container_values_songs ">
        <Tooltip title="" placement="top" arrow>
          <div
            className={`${
              alias === nameVariables[0].name
                ? "container_value_button"
                : "container_value_button_normal"
            }  `}
            onClick={handleNameValue}
          >
            {nameVariables[0].name}
          </div>
        </Tooltip>
        <Tooltip title="" placement="top" arrow>
          <div
            className={`${
              alias === nameVariables[1].name
                ? "container_value_button"
                : "container_value_button_normal"
            }  `}
            onClick={handleNameValue}
          >
            {nameVariables[1].name}
          </div>
        </Tooltip>
        <Tooltip title="" placement="top" arrow>
          <div
            className={`${
              alias === nameVariables[2].name
                ? "container_value_button"
                : "container_value_button_normal"
            }  `}
            onClick={handleNameValue}
          >
            {nameVariables[2].name}
          </div>
        </Tooltip>
        <Tooltip title="" placement="top" arrow>
          <div
            className={`${
              alias === nameVariables[3].name
                ? "container_value_button"
                : "container_value_button_normal"
            }  `}
            onClick={handleNameValue}
          >
            {nameVariables[3].name}
          </div>
        </Tooltip>
        <div
          className={`${
            alias === nameVariables[4].name
              ? "container_value_button"
              : "container_value_button_normal"
          }  `}
          onClick={handleNameValue}
        >
          {nameVariables[4].name}
        </div>
      </div>
    </div>
  );
}

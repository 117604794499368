import React, { useContext, useState } from "react";
import { Tooltip } from "@mui/material";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../../context/AuthProvider";

const AddingDeviceProject = (props) => {
  const { enabled, openModal,  onButtonClick } = props;
  const { setSelectedProject, setOpenNewMetersModal } = useContext(AuthContext);
  const history = useHistory();

  const handleNavegate = () => {
    history.push("/app/stepperviewmacrometer/description");
    setSelectedProject(null);
  };

  const handleOpenAddNewMetersModal = () => {
    setOpenNewMetersModal(true);
  };

  return (
    <div className="add_device_button_container">
      <Tooltip title="Agregar Nuevo Dispositivo">
        <button
          className="add_device_button_project"
          onClick={
            onButtonClick
            //openModal ? handleOpenAddNewMetersModal : () => handleNavegate()
          }
          disabled={enabled}
        >
          <i className="fas fa-plus"></i>
        </button>
      </Tooltip>
    </div>
  );
};

export default AddingDeviceProject;

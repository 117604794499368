import { useContext, useState, useEffect } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useQuery } from '@apollo/client';
import { GET_HISTORY_TWO } from '../../../controllers/measurersController';
import { secondClient } from '../../..';
import Select from '../../macrometer/selectMonth/selectMonth';
import { timeConvertMacromedidor } from '../../../utils/helpers';
import { AuthContext } from '../../../context/AuthProvider';

//multilanguage
import { useTranslation } from 'react-i18next';

export const NewGraphConsumptionDetailPerMonth = ({ serial,city }) => {
  const codigosCiudades = {
    Bogotá: 'BG',
    Barranquilla: 'BQ',
    default: 'BQ' 
  };
  Object.freeze(codigosCiudades);
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(new Date().getMonth() + 1);

  const [year, setYear] = useState(timeConvertMacromedidor().year.toString());
  const [month, setMonth] = useState(timeConvertMacromedidor().month);
  const [datas, setDatas] = useState(0);
  const { setSeeGraphic } = useContext(AuthContext);

  const { data } = useQuery(GET_HISTORY_TWO, {
    client: secondClient,
    variables: {
      serial: serial,
      month: parseInt(month),
      location: codigosCiudades[city] || codigosCiudades.default,
      year: new Date().getFullYear() % 100,
    },
    fetchPolicy: 'no-cache',
    fetchPolicy: 'no-cache',
  });

  const options = [
    { label: 'Enero', value: 1 },
    { label: 'Febrero', value: 2 },
    { label: 'Marzo', value: 3 },
    { label: 'Abril', value: 4 },
    { label: 'Mayo', value: 5 },
    { label: 'Junio', value: 6 },
    { label: 'Julio', value: 7 },
    { label: 'Agosto', value: 8 },
    { label: 'Septiembre', value: 9 },
    { label: 'Octubre', value: 10 },
    { label: 'Noviembre', value: 11 },
    { label: 'Diciembre', value: 12 },
    { label: 'Enero', value: 1 },
    { label: 'Febrero', value: 2 },
    { label: 'Marzo', value: 3 },
    { label: 'Abril', value: 4 },
    { label: 'Mayo', value: 5 },
    { label: 'Junio', value: 6 },
    { label: 'Julio', value: 7 },
    { label: 'Agosto', value: 8 },
    { label: 'Septiembre', value: 9 },
    { label: 'Octubre', value: 10 },
    { label: 'Noviembre', value: 11 },
    { label: 'Diciembre', value: 12 },
  ];

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    setMonth(event.target.value);
  };
  useEffect(() => {
    setSeeGraphic(false);
  }, []);

  return (
    <div className="__details_measurer_consumption_graphic__">
      <div className="calendar_content">
        <Select
          options={options}
          value={selectedOption}
          onChange={handleSelectChange}
        />
      </div>
      {data?.getConsumptionHistory.length === 0 ? (
        <></>
      ) : (
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              zooming: {
                mouseWheel: false,
              },
              type: 'column',
              type: 'column',
            },
            colors: ['#04b431'],
            colors: ['#04b431'],

            title: {
              align: 'left',
              text: `${t('VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_TABS.CONSUMPTION_GRAPH_TITLE')} ${
                data?.getConsumptionHistory.currentConsumption.activeEnergy ? data?.getConsumptionHistory.currentConsumption.activeEnergy : datas
              } kWh `,
            },

            subtitle: {
              align: 'right',
  
              style: {
                color: '#000',
                fontSize: '16px',
              },
              y: 40,
            },
            accessibility: {
              announceNewData: {
                enabled: true,
              },
            },
            xAxis: {
              type: 'category',
              type: 'category',
            },
            yAxis: {
              title: {
                text: '',
                text: '',
              },
            },
            
  
            plotOptions: {
              column: {
                grouping: false,
                shadow: false,
                borderRadius: 4,
                borderWidth: 2,
                borderColor: 'black',
              },
              series: {
                dataLabels: {
                  enabled: true,
                  format: '{point.y:.1f}',
                  format: '{point.y:.1f}',
                },
              },
            },
            legend: {
              shadow: false,
            },
            tooltip: {
              shared: true,
              // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
              pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.2f}</b> kWh<br/>',
            },

            series: data?.getConsumptionHistory?.series,
          }}
        />
      )}
    </div>
  );
};

import { useContext, useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_CLAMPS_MEASURE } from '../../../controllers/measurersController';
import { calculatedMinParameter, calculatedMaxParameter } from '../../../utils/helpers';
import { AuthContext } from '../../../context/AuthProvider';
import GraphMeasurerPhases from './GraphMeasurerPhases';
import GraphKwhPhases from './GraphKwhPhases';
import TabConsumptionPhases from './TabConsumptionPhases';
import { useTranslation } from 'react-i18next';
import { NewDates } from '../MeasurerDetails/helperDate';
import DatePicker from '../../globals/datepicker/datepicker';
import DateCustom from '../../dates/DateCustom';
import Loading from '../../loading/Loading';
import { Button } from '@material-ui/core';

const NewMeasurerPhases = ({ measurer, timeZone }) => {
  const { dateReport, setCalendarDatePicker, calendarDatePicker } = useContext(AuthContext);
  const startTime = calculatedMinParameter(dateReport, timeZone);
  const endTime = calculatedMaxParameter(dateReport, timeZone) + 61000;
  const [timeStart, setTimeStart] = useState(startTime);
  const [timeEnd, setTimeEnd] = useState(endTime);
  const deviceId = measurer.deviceId;
  const { data, loading } = useQuery(GET_CLAMPS_MEASURE, {
    variables: { timeZone: String(Intl.DateTimeFormat().resolvedOptions().timeZone), deviceId: deviceId, startTime: timeStart, endTime: timeEnd },
    fetchPolicy: 'no-cache',
  });

  const handleReport = () => {
    setTimeStart(calendarDatePicker.getTime());
    setTimeEnd(calendarDatePicker.getTime() + 86460000);
  };

  const handlePrevius = () => {
    let back = timeStart - 86400000;
    const newToday = new Date(back);
    setTimeStart(back);
    setCalendarDatePicker(newToday);
    let next = timeEnd - 86400000;
    setTimeEnd(next);
  };

  const handleNext = () => {
    let back = timeStart + 86400000;
    setTimeStart(back);
    let next = timeEnd + 86400000;
    setTimeEnd(next);
    const newToday = new Date(back);
    setCalendarDatePicker(newToday);
  };

  if (loading) {
    return <Loading />;
  }
  if (data) {
    return (
      <>
        <div className="DatePickerContainer">
          <DatePicker
            type="calendar"
            handleQuery={handleReport}
            back={handlePrevius}
            next={handleNext}
            selectOption={{ changeInside: false, changeRoomId: '', stateRoomId: '' }}
          />
        </div>

        <GraphMeasurerPhases
          data={data}
          endTime={timeEnd}
          startTime={timeStart}
        />
        <GraphKwhPhases
          data={data}
          endTime={timeEnd}
          startTime={timeStart}
        />
        <TabConsumptionPhases data={data} />
      </>
    );
  }
};
export default NewMeasurerPhases;

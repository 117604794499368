// Modal.js
import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@material-ui/core/IconButton";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const ModalNavbar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2eb142;
  border-radius: 8px 8px 0 0;
  padding: 0 1rem;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
`;
const TittleNavbar = styled.h3`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: 44px;
  font-family: "Montserrat", sans-serif;
`;

const CloseButton = styled.button`
  width: 10%;
  background: none;
  border: none;
  cursor: pointer;
  color: white;

  @media (max-width: 500px) {
    width: 15%;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const scaleIn = keyframes`
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const scaleOut = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0;
  }
`;

const ModalBackground = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${({ render }) => (render ? fadeIn : fadeOut)} 0.3s forwards;
`;

const ModalContent = styled.div`
  padding-bottom: 300px;
  min-height: ${({ height }) => height || '450px'}; 
  width: 80%;
  max-width: 540px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  animation: ${({ render }) => (render ? scaleIn : scaleOut)} 0.3s forwards;
`;

const Modal = ({ isOpen, onClose, children, tittle, height }) => {
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setRender(true);
    } else {
      setTimeout(() => {
        setRender(false);
      }, 300);
    }
  }, [isOpen]);

  const onAnimationEnd = () => {
    if (!isOpen) {
      setRender(false);
    }
  };

  if (!render && !isOpen) return null;

  return (
    <ModalBackground
      render={isOpen}
      onClick={onClose}
      onAnimationEnd={onAnimationEnd}
    >
      <ModalContent  height={height} render={isOpen} onClick={(e) => e.stopPropagation()}>
        <ModalNavbar>
          <TittleNavbar>{tittle}</TittleNavbar>
          <CloseButton>
            <IconButton
              color="inherit"
              onClick={onClose}
              sx={{
                display: "flex",
                position: "absolute",
              }}
            >
              <CloseIcon />
            </IconButton>
          </CloseButton>
        </ModalNavbar>
        {children}
      </ModalContent>
    </ModalBackground>
  );
};

export default Modal;

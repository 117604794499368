import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";

//Components
import ModalReportFailsByProject from "./ModalReportFailsByProject";

//multilanguage
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReportFailsByProject = ({
  projectData,
  openReportFailsByProject,
  handleReportFailsByProject,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={openReportFailsByProject}
      onClose={handleReportFailsByProject}
      TransitionComponent={Transition}
    >
      <div className="_ReportFailsByProjec_container_">
        <div className="_ReportFailsByProjec_container_close_">
          <span onClick={handleReportFailsByProject}>
            <i class="fas fa-times"></i>
          </span>
        </div>
        <div className="_ReportFailsByProjec_container_name_">
          <span>
            {`${projectData?.name} - ${t(
              "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.FAIL_REPORT_VIEW.MODAL_HEADER_TITLE"
            )}`}
          </span>
        </div>
        <div className="_ReportFailsByProjec_container_download_">
          {/* <span>
            Descargar <i class='fas fa-arrow-down'></i>
          </span> */}
        </div>
      </div>
      <ModalReportFailsByProject projectId={projectData.id} />
    </Dialog>
  );
};

export default ReportFailsByProject;

export const Dates = (epoch) => {
  const currentDate = new Date(epoch);
  currentDate.setHours(0, 0, 0, 0); // establece a las 00:00

  const nextDate = new Date(epoch);
  nextDate.setDate(nextDate.getDate() + 1); //  dia siguiente
  nextDate.setHours(0, 0, 0, 0); // establece las horas a medianoche

  const startEpoch = Math.floor(currentDate.getTime() / 1000); // epoch en segundos
  const endEpoch = Math.floor(nextDate.getTime() / 1000 + 60); // epoch en segundos

  return { start: startEpoch, end: endEpoch };
};

export const NewDates = (date) => {
  const dates = {
    dd: date.getDate(),
    mm: date.getMonth() + 1,
    yy: date.getFullYear().toString(),
    yyyy: date.getFullYear(),
  };

  const day = String(dates.dd);
  const month = String(dates.mm);
  const year = String(dates.yyyy);
  const startTime = '00:00:00';

  let a = '';
  if (day.length < 2 && month.length < 2) {
    return (a = `0${month} 0${day} ${year} ${startTime}`);
  } else if (day.length === 2 && month.length < 2) {
    return (a = `0${month} ${day} ${year} ${startTime}`);
  } else if (day.length < 2 && month.length === 2) {
    return (a = `${month} 0${day} ${year} ${startTime}`);
  }
  return a;
};

import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthProvider";
import { createTheme } from "@material-ui/core/styles";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const SelectInfo = (props) => {
  const { dataSelect } = props;
  const [selectItem, setSelectItem] = React.useState(0);
  const { selectedDevice, setSelectedDevice } = useContext(AuthContext);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "auto",
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectItem(typeof value === "string" ? value.split(",") : value);
    setSelectedDevice(event.target.value);
   
  };

  return (
    <>
      <div className="select_container">
        <FormControl
          sx={{
            m: 0,
            width: 320,
            [theme.breakpoints.down("md")]: { width: 250 },
            [theme.breakpoints.down("xs")]: { width: 340 },
          }}
          size="small"
        >
          <InputLabel id="demo-multiple-checkbox-label">
            Dispositivos
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            sx={{ borderRadius: 2, height: "40px" }}
            value={selectItem}
            onChange={handleChange}
            input={<OutlinedInput label="Dispositivos..." />}
            MenuProps={MenuProps}
          >
            {dataSelect.map((select) => (
              <MenuItem key={select.id} value={select.id}>
                <ListItemText primary={select.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
};

export default SelectInfo;

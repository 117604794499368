import { useContext, useState, useEffect } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { useQuery } from "@apollo/client";
import { GET_HISTORY_TWO } from "../../../controllers/measurersController";
import { secondClient } from "../../..";
import { timeConvertMacromedidor } from "../../../utils/helpers";
import { AuthContext } from "../../../context/AuthProvider";
import Select from "../../macrometer/selectMonth/selectMonth";

//multilanguage
import { useTranslation } from "react-i18next";

export const NewGraphConsumptionDetailYear = ({ serial, city }) => {
  const { t } = useTranslation();
  const { setSeeGraphic } = useContext(AuthContext);
  const [selectedOption, setSelectedOption] = useState("");
  const [year, setYear] = useState(timeConvertMacromedidor().year);
  const [datas, setDatas] = useState(0);
  const codigosCiudades = {
    Bogotá: 'BG',
    Barranquilla: 'BQ',
    default: 'BQ' 
  };
  const { data } = useQuery(GET_HISTORY_TWO, {
    client: secondClient,
    variables: { serial: serial, year: parseInt(year),  location: codigosCiudades[city] || codigosCiudades.default },
    fetchPolicy: "no-cache",
    
  });
  useEffect(() => {
    setSeeGraphic(false);
  }, []);

  const options = [
    { label: "2024", value: 2024 },
    { label: "2023", value: 2023 },
    { label: "2022", value: 2022 },
    { label: "2024", value: 2024 },
    { label: "2023", value: 2023 },
    { label: "2022", value: 2022 },
  ];

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    setYear(event.target.value);
  };

  return (
    <div className="__details_measurer_consumption_graphic__">
      <div className="calendar_content">
        <Select
          options={options}
          value={selectedOption}
          onChange={handleSelectChange}
        />
      </div>
      {data?.getConsumptionHistory.length === 0 ? (
        <></>
      ) : (
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              zooming: {
                mouseWheel: false,
              },
              type: "column",
              type: "column",
            },
            colors: ["#04b431"],
            colors: ["#04b431"],

            title: {
              align: "left",
              text: `${t(
                "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_TABS.CONSUMPTION_GRAPH_TITLE"
              )} ${
                data?.getConsumptionHistory.res
                  ? data?.getConsumptionHistory?.currentConsumption?.toFixed(2)
                  : datas
              } kWh `,
            },

            subtitle: {
              align: "left",
              text: "" /* "Click the columns to view details. " */,
              align: "left",
              text: "" /* "Click the columns to view details. " */,
            },
            accessibility: {
              announceNewData: {
                enabled: true,
              },
            },
            xAxis: {
              type: "category",
              type: "category",
            },
            yAxis: {
              title: {
                text: "",
                text: "",
              },
            },
            legend: {
              enabled: false,
            },
            plotOptions: {
              series: {
                borderWidth: 0,
                dataLabels: {
                  enabled: true,
                  format: "{point.y:.1f}",
                  format: "{point.y:.1f}",
                },
              },
            },

            tooltip: {
              headerFormat:
                '<span style="font-size:11px">{series.name}</span><br>',
              pointFormat:
                '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b> kWh<br/>',
              headerFormat:
                '<span style="font-size:11px">{series.name}</span><br>',
              pointFormat:
                '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b> kWh<br/>',
            },

            series: [
              {
                name: "Horas",
                name: "Horas",
                colorByPoint: true,
                data: data?.getConsumptionHistory?.res
                  ? data?.getConsumptionHistory?.res
                  : "",
                data: data?.getConsumptionHistory?.res
                  ? data?.getConsumptionHistory?.res
                  : "",
              },
            ],
          }}
        />
      )}
    </div>
  );
};

import gql from "graphql-tag";

export const CREATE_PIN_CODE = gql`
    mutation (
        $ControllerId: Int!
        $DeviceId: Float!
        $name: String!
        $pin: String!
        $enabled: Boolean
        $type: String
    ) {
        createPinCode(
            ControllerId: $ControllerId
            DeviceId: $DeviceId
            name: $name
            pin: $pin
            enabled: $enabled
            type: $type
        )
    }
`;

export const ADD_RESTRICTION = gql`
    mutation (
        $ControllerId: Int
        $DeviceId: Float
        $index: Int
        $type: String
        $from: String
        $to: String
        $days: String
        ) {
            addRestriction(
                ControllerId: $ControllerId
                DeviceId: $DeviceId
                index: $index
                type: $type
                from: $from
                to: $to
                days: $days
            )
        }`

export const GET_PIN_CODES = gql`
    query(
        $id: Int
        $index: Int
        $name: String
        $pin: String
        $enabled: Boolean
        $type: String
        $from: String
        $to: String
        $days: String
        $DeviceId: Float
    ){
        getPinCodes(
            id: $id
            index: $index
            name: $name
            pin: $pin
            enabled: $enabled
            type: $type
            from: $from
            to: $to
            days: $days
            DeviceId: $DeviceId
        ){
            id
            index
            name
            pin
            enabled
            type
            from
            to
            days
            DeviceId
        }
    }
`
import React from "react";
import styled from "styled-components";

export const StyledStepper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;

  @media (max-width: 425px) {
    grid-template-columns: 100%;
  }
`;

export const StyledOptions = styled.div`
  justify-self: center;
  align-self: center;

  .empty_title {
    color: #1c1c1c;
  }

  .__full_title {
    color: #4caf50e6;
  }

  .stepper_elements_container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 5px;

    .steps {
      font-family: "Montserrat", sans-serif;
      color: #1c1c1c;
      text-decoration: none;

      &:hover {
        color: #a9aaa9;
        cursor: pointer;
      }
    }

    .empty_stepper {
      padding: 8px 8px;
      font-size: 10px;
      border-radius: 50%;
      color: #f9f9f9;
      background-color: rgba(177, 174, 174, 1);
      margin-right: 5px;
    }

    .full_stepper {
      padding: 8px 8px;
      font-size: 10px;
      border-radius: 50%;
      color: #f9f9f9;
      background-color: rgba(178, 229, 183, 1);
      margin-right: 5px;
    }

    @media (max-width: 425px) {
      justify-self: flex-start;
      padding-left: 30px;
    }
  }
`;

import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../context/AuthProvider';
import { useQuery } from '@apollo/client';
import { GET_HISTORY } from '../../../controllers/measurersController';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { TableRow, TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
//import components
import DatesMacrometer from '../../../components/macrometer/datesMacrometer';
import DateMUI from "../../dateMUI/DateMUI"

//Function
import { timeConvertMacromedidor } from '../../../utils/helpers';
import {Dates }from "./helperDate"

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#4caf50e6',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableCell2 = withStyles((theme) => ({
  head: {
    backgroundColor: '#4caf50e6',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    fontWeight: 'bold',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const GraphConsumptionDetail = ({ projectID, timeZone }) => {
  const {serial, setSeeGraphic, dateMUI } = useContext(AuthContext);

  useEffect(() => {
    setSeeGraphic(true);
  }, []);

  const [value, setValue] = useState();
  const [datas, setDatas] = useState(0);

  useEffect(() => {
    setSeeGraphic(true);
  }, []);

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const { data, loading } = useQuery(GET_HISTORY, {
    variables: {
      serial: serial,
      start_time: Dates(dateMUI).start,
      end_time: Dates(dateMUI).end,
      time_zone: timeZone
    },
    fetchPolicy: 'no-cache',
  });

  const [x, setX] = useState([]);

  if (loading || !data) {
    return <></> /* <LoadingBar /> */;
  }

  return (
    <div className="__details_measurer_consumption_graphic__">
      <div className="calendar_content">
      <DateMUI data={data} />
      </div>

      {data.getHistory.length === 0 ? (
        <></>
      ) : (
        /* <div className="date_vacio_report_measurer">
          <span>
            <i class="fas fa-exclamation-triangle"></i> No hay datos
          </span>
        </div> */
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              type: 'column',
            },
            colors: ['#04b431'],

            title: {
              align: 'left',
              text: `Consumo total de ${
                data.getHistory.res ? data.getHistory.currentConsumption.toFixed(2) : datas
              } kWh `,
            },
            subtitle: {
              align: 'left',
              text: '' /* "Click the columns to view details. " */,
            },
            accessibility: {
              announceNewData: {
                enabled: true,
              },
            },
            xAxis: {
              type: 'category',
            },
            yAxis: {
              title: {
                text: '',
              },
            },
            legend: {
              enabled: false,
            },
            plotOptions: {
              series: {
                borderWidth: 0,
                dataLabels: {
                  enabled: true,
                  format: '{point.y:.1f}',
                },
              },
            },

            tooltip: {
              headerFormat: '<span style="font-size:12px">{series.name}</span><br>',
              pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b> kWh<br/>',
            },

            series: [
              {
                name: 'Horas',
                colorByPoint: true,
                data: data?.getHistory.res,
              },
            ],
          }}
        />
      )}
    </div>
  );
};

export default GraphConsumptionDetail;

import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import { AuthContext } from "../../../context/AuthProvider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useHistory } from "react-router-dom";
import ModalProfile from "../../modal/modalProfile/ModalProfile";
import ModalChangePasswordModal from "../../modal/modalChangePassword/ModalChangePassword";
import EditForm from "../../userManagement/editUsers/editForm";
import SyncLockIcon from "@mui/icons-material/SyncLock";
import ChangesPasswordForm from "../../userManagement/editUsers/chagesPassword/changesPasswordForm";
import { useMutation } from "@apollo/client";
import { LOGOUT } from "../../../controllers/authenticationController";

//helpersFunctions
import { ClearLocalStorageExcept } from "../../../utils/helpers";

//multilanguage
import { useTranslation } from "react-i18next";

export default function AccountMenu(setEditAlert) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalResetPass, setModalResetPass] = useState(false);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showEditModifications, setNewShownModifications] = useState(false);
  const { alertEdit, setAlertEdit, setInputField } = useContext(AuthContext);
  const edit = true;
  const [logoutSession] = useMutation(LOGOUT);
  const open = Boolean(anchorEl);
  const handleModalClose = () => {
    setTimeout(() => {
      setIsModalOpen(false);
      setAlertEdit(false);
    }, 500);
  };

  const handleModalCloseResetPass = () => {
    setTimeout(() => {
      setModalResetPass(false);
      setAlertEdit(false);
      setInputField();
    }, 500);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    try {
      logoutSession()
        .then((response) => {
          console.log("success", response);
          ClearLocalStorageExcept("language");
          history.push("/");
          window.location.reload();
        })
        .catch((err) => {
          console.log("Error de logout", err);
          ClearLocalStorageExcept("language");
          history.push("/");
          window.location.reload();
        });
    } catch (err) {
      console.error("Excepción:", err);
    }
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              sx={{
                width: 32,
                height: 32,
                border: "solid ",
                borderColor: "white",
              }}
            >
              {localStorage.getItem("name").slice(0, 1).toUpperCase()}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "8px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => setIsModalOpen(true)}>
          <Avatar /> {t("VIEW_PROJECT.COMPONENT_AVATAR.ACCOUNT")}
        </MenuItem>
        <MenuItem onClick={() => setModalResetPass(true)}>
          <ListItemIcon>
            <SyncLockIcon fontSize="medium" />
          </ListItemIcon>
          {t("VIEW_PROJECT.COMPONENT_AVATAR.PASSWORD_CHANGING")}
        </MenuItem>
        <Divider />{" "}
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="medium" />
          </ListItemIcon>
          {t("VIEW_PROJECT.COMPONENT_AVATAR.LOGOUT")}
        </MenuItem>
      </Menu>

      <div>
        <Snackbar
          open={alertEdit}
          autoHideDuration={2500}
          onClose={handleModalClose}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        >
          <Alert severity="success">
            {t(
              "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_USER_EDIT_MODAL.SUCCESSFUL_ALERT"
            )}
          </Alert>
        </Snackbar>
      </div>
      <ModalProfile
        tittle={t("VIEW_PROJECT.COMPONENT_AVATAR.HEADER_MODAl_TITLE")}
        isOpen={isModalOpen}
        onClose={handleModalClose}
      >
        <EditForm
          editing={true}
          iddUser={parseInt(localStorage.getItem("np"))}
        />
      </ModalProfile>
      <ModalChangePasswordModal
        height="300px"
        tittle={t("VIEW_PROJECT.COMPONENT_MODAL_RESET_PASSWORD.TITLE")}
        isOpen={modalResetPass}
        onClose={handleModalCloseResetPass}
      >
        <ChangesPasswordForm />
      </ModalChangePasswordModal>
    </React.Fragment>
  );
}

import * as React from "react";
import { useState, useEffect } from "react";

//styled components
import { MainTableContainer } from "./style/TableInfo.style";

const TableInfo = (props) => {
  const { amountColumns, dataTable } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  // const records = users.slice(firstIndex, lastIndex);

  return (
    <>
      <MainTableContainer
        amountColumns={amountColumns}
        columnLength={amountColumns.length}
      >
        <table className="data_table">
          <thead className="container_title_table">
            <tr className="tr">
              {amountColumns?.map((columnName, index) => (
                <th key={index}>
                  <h3>{columnName.label}</h3>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="container_table_body">
            {dataTable?.map((rowData, rowIndex) => (
              <tr className="rows" key={rowIndex}>
                {amountColumns?.map((column, colIndex) => (
                  <td className="td" key={colIndex}>
                    {column.component ? (
                      <column.component data={rowData[column.dataKey]} />
                    ) : (
                      `${rowData[column.dataKey]}`
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </MainTableContainer>
    </>
  );
};
export default TableInfo;

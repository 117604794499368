import { useContext } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import PasswordIcon from "@mui/icons-material/Password";
import { useMutation } from "@apollo/client";
import { CREATE_PIN_CODE } from "../../../controllers/pinCodesController";
import { useParams } from "react-router-dom";
import FormHelperText from "@mui/material/FormHelperText";
import { AuthContext } from "../../../context/AuthProvider";

function AddCode({ setTypeMessage, setView, setDataCode }) {
  const { codesGlobal } = useContext(AuthContext);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const { controllerId, id } = useParams();

  const [createPinCode] = useMutation(CREATE_PIN_CODE);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeName = (event) => setName(event.target.value);
  const handleChangeCode = (event) => setCode(event.target.value);

  const handleSubmit = async () => {
    if (valideForm()) {
      setTypeMessage("loading");

      let variables = {
        name,
        pin: code,
        enabled: true,
        type: "a",
        ControllerId: parseInt(controllerId),
        DeviceId: parseInt(id),
      };

      const codeCreated = await createPinCode({ variables });
      if (codeCreated.data.createPinCode.success) {
        variables.index = codeCreated.data.createPinCode.data.index;
        setTimeout(() => {
          setTypeMessage(null);
          setDataCode(variables);
          setView("type");
        }, 60000);
      } else {
        setTypeMessage("error");
      }
    }
  };

  const valideKey = (evt) => {
    var code = evt.which ? evt.which : evt.keyCode;

    if (code == 8) {
      // backspace.
      return true;
    } else if (code >= 48 && code <= 57) {
      // is a number.
      return true;
    } else {
      // other keys.
      evt.preventDefault();
      return false;
    }
  };

  const valideForm = () => {
    if (codesGlobal.map((i) => i.name).includes(name.trim())) {
      setErrors((prev) => ({ ...prev, name: "Esta nombre ya existe" }));
      return false;
    }
    if (name.trim().length === 0) {
      setErrors((prev) => ({ ...prev, name: "Ingrese un nombre válido" }));
      return false;
    } else {
      setErrors((prev) => ({ ...prev, name: "" }));
    }
    if (codesGlobal.map((i) => i.pin).includes(code.trim())) {
      setErrors((prev) => ({ ...prev, code: "Esta contraseña ya está en uso." }));
      return false;
    }
    if (code.trim().length === 0 || code.trim().length < 6) {
      setErrors((prev) => ({ ...prev, code: "Debe tener mínimo 6 dígitos." }));
      return false;
    } else {
      setErrors((prev) => ({ ...prev, code: "" }));
    }

    return true;
  };

  const setRandomCode = () => {
    let random = String(Math.random()).split(".")[1].substring(0, 6);
    setCode(random);
  };

  return (
    <Box sx={{ display: "flex", p: 8 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "120px",
            height: "120px",
            backgroundColor: "rgb(56, 142, 60, 0.08)",
            borderRadius: "50%",
          }}
        >
          <PasswordIcon color='primary' sx={{ fontSize: "50px" }} />
        </Box>
      </Box>
      <Box sx={{ width: "300px", px: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              size='small'
              label='Nombre'
              variant='outlined'
              name='name'
              value={name}
              onChange={handleChangeName}
              fullWidth
              error={errors?.name ? true : false}
              helperText={errors?.name}
            />
          </Grid>
          <Grid item xs={9}>
            <FormControl
              variant='outlined'
              size='small'
              fullWidth
              error={errors?.code ? true : false}
            >
              <InputLabel htmlFor='doorlock-code'>Código</InputLabel>
              <OutlinedInput
                id='doorlock-code'
                type={showPassword ? "text" : "password"}
                inputProps={{
                  maxLength: "8",
                }}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                value={code}
                onChange={handleChangeCode}
                onKeyPress={(event) => valideKey(event)}
                onPaste={(event) => event.preventDefault()}
                label='Código'
              />
              <FormHelperText>{errors?.code}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant='contained'
              color='inherit'
              disableElevation
              onClick={setRandomCode}
            >
              <ShuffleIcon />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant='contained'
              fullWidth
              disableElevation
              onClick={() => handleSubmit()}
            >
              Crear
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default AddCode;

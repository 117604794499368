import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const WrapperSwitch = styled.div`
  color: rgb(109, 105, 112);
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  gap: 0.5rem;
`;
export const StyledSwitch = styled.div`
  .switch {
    display: flex;
    height: 24px;
    position: relative;
    width: 40px;
  }

  .switch input {
    display: none;
  }

  .slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
  }

  .slider:before {
    background-color: #fff;
    bottom: 3px;
    content: "";
    height: 18px;
    left: 3px;
    position: absolute;
    transition: 0.4s;
    width: 18px;
  }

  input:checked + .slider {
    background-color: #66bb6a;
  }

  input:checked + .slider:before {
    transform: translateX(16px);
  }

  .slider.round {
    border-radius: 24px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

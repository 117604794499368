import React, { useEffect, useState } from "react";
import { MONTHS_NUMBER } from "../../../../utils/helpers";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

const RangeCodesList = ({ data }) => {
  const splitTo = data.to.split(" ");
  const splitFrom = data.from.split(" ");
  const getHourTo = splitTo[1];
  const getHourFrom = splitFrom[1];
  const getYearTo = splitTo[0].split("-")[0];
  const getDayTo = splitTo[0].split("-")[2];
  const getMountTo = splitTo[0].split("-")[1];
  const getYearFrom = splitFrom[0].split("-")[0];
  const getDayFrom = splitFrom[0].split("-")[2];
  const getMountFrom = splitFrom[0].split("-")[1];

  return (
    <div className="__content_range_restriction">
      <div className="___content_range_content">
        <div className="____title_range_restrict">Desde</div>
        <div className="____date_range_restrict">{`${getDayFrom} ${
          MONTHS_NUMBER[Number(getMountFrom)]
        } ${getYearFrom}`}</div>
        <div className="____hour_range_restrict">{getHourFrom}</div>
      </div>
      <div className="___content_range_content">
        <div className="____title_range_restrict">Hasta</div>
        <div className="____date_range_restrict">{`${getDayTo} ${
          MONTHS_NUMBER[Number(getMountTo)]
        } ${getYearTo}`}</div>
        <div className="____hour_range_restrict">{getHourTo}</div>
      </div>
    </div>
  );
};

const LabelDaysCodeList = ({ days }) => {
  const [labels, setLabels] = useState([
    { key: 1, label: "L" },
    { key: 2, label: "M" },
    { key: 3, label: "X" },
    { key: 4, label: "J" },
    { key: 5, label: "V" },
    { key: 6, label: "S" },
    { key: 7, label: "D" },
  ]);

  useEffect(() => {
    days.split("").map((chart) => {
      setLabels((prev) =>
        prev.map((label) => {
          if (label.key == chart) {
            return { ...label, color: "primary" };
          }
          return label;
        })
      );
    });
  }, [days]);
  return (
    <Stack direction="row" spacing={1}>
      {labels.map((day) => (
        <Chip {...day} size="small" />
      ))}
    </Stack>
  );
};

const TableListCodes = ({
  listCodes,
  handleOpenModal,
  handleAddRestriction,
}) => {
  const [nameFilter, setNameFilter] = useState("");
  const [statusFitler, setStatusFilter] = useState("all");
  const orderCodes = listCodes.sort((a, b) => b.id - a.id);
  const restrictionLabel = {
    a: "Siempre",
    t: "Rango",
    w: "Personalizado",
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setNameFilter(value);
  };

  const handleChangeStatus = (e) => {
    const { value } = e.target;
    setStatusFilter(value);
  };

  const filtersByNameStatus = (data) => {
    if (nameFilter != "") {
      return data.filter((i) => i.name.toLowerCase().includes(nameFilter));
    }
    if (statusFitler === "all") {
      return data;
    }
    if (statusFitler === "enable") {
      return data.filter((i) => i.enabled);
    }
    if (statusFitler === "disabled") {
      return data.filter((i) => !i.enabled);
    }
    return data;
  };

  return (
    <div className="tablelistcodes_container">
      <div className="tablelistcodes_content_header">
        <div className="__content_header_title">
          <h1>Lista de códigos</h1>
          <span>Aquí podrás ver y gestionar todos tus códigos</span>
        </div>
        <div className="__content_header_filters">
          <div className="__content_header_filters_container">
            <span className="__content_header_filters__title">
              Filtrar por nombres
            </span>
          </div>
          <div className="__content_header_filters_container">
            <input
              type="text"
              className="__content_header_filters_input"
              placeholder="Ingresa un nombre"
              name="filter_name_codes"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="__content_header_filters_status">
          <div className="__content_header_filters_container_status">
            <span className="__content_header_filters_status_title">
              Filtrar por status
            </span>
          </div>
          <div className="__content_header_filters_container_status">
            <select
              name="filter_status"
              className="__content_header_filters_status_input"
              onChange={(e) => handleChangeStatus(e)}
            >
              <option value="all">Todos</option>
              <option value="enable">Activos</option>
              <option value="disabled">Inactivos</option>
            </select>
          </div>
        </div>
        <div className="__content_header_button">
          <button onClick={() => handleOpenModal()}>
            <i class="fas fa-plus"></i> Agregar código
          </button>
        </div>
      </div>
      <div className="tablelistcodes_content_header_table">
        <span>No:</span>
        <span>Nombre</span>
        <span>Restricción</span>
        <span>Tipo</span>
        <span>Estado</span>
        <span>Acciones</span>
      </div>
      <>
        {filtersByNameStatus(orderCodes).map((i, index) => (
          <div
            className={`tablelistcodes_content_header_codes ${
              index % 2 !== 0 ? "" : "tablelistcodes_content_header_codes_bg"
            }`}
          >
            <div className="__div__">{`${index + 1}`}</div>
            <div className="__content_codes_name_pass">
              <div className="___content_name">{i.name}</div>
              <div className="___content_pin">{i.pin}</div>
            </div>
            <div className="__content_code_add_restriction">
              {i.type === "a" ? (
                <div className="___content_add_text">Sin restricción</div>
              ) : (
                <></>
              )}
              {i.type === "w" && i.days ? (
                <LabelDaysCodeList days={i.days} />
              ) : (
                <></>
              )}
              {i.type === "t" && i.to && i.from ? (
                <RangeCodesList data={i} />
              ) : (
                <></>
              )}
            </div>
            <div className="__div__">{`${restrictionLabel[i.type]}`}</div>
            <div
              className={`${
                i.enabled ? "__codes_status_activa" : "__codes_status_inactiva"
              }`}
            >{`${i.enabled ? "Activa" : "Inactiva"}`}</div>
            <div
              className="__acciones_codelist"
              onClick={() => handleAddRestriction(i)}
            >
              <i title="Agregar restricción" className="fas fa-ellipsis-v"></i>
            </div>
          </div>
        ))}
      </>
    </div>
  );
};

export default TableListCodes;

import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import { AuthContext } from "../../context/AuthProvider";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import { GET_DATA_MEASURER_BY_PROJECT } from "../../controllers/reportController";
import { PDF_MEASURE_GENERAL } from "../../controllers/pdfControllers";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Loading from "../loading/Loading";
import { addDays } from "date-fns";
import html2canvas from "html2canvas";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  TableCell,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

//Function
import {
  sum_of_the_kwh,
  date_of_the_kwh,
  round_digits,
  convert_time_measurergeneral,
  calc_date_range,
  date_last,
  define_time_zone,
  depuration_data_measurergeneral_report,
  handleTime,
  defice_toolTip_date,
  dateRangeButton,
  calculatedMinParameter,
  calculatedMaxParameter,
  roundTotalBy,
  sumTotalBy,
  formatDateLocal,
} from "../../utils/helpers";

//components
import DatePicker from "../globals/datepicker/datepicker";
import DateCustom from "../dates/DateCustom";

//multilanguage
import { useTranslation } from "react-i18next";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#4caf50e6",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableCell2 = withStyles((theme) => ({
  head: {
    backgroundColor: "#4caf50e6",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    fontWeight: "bold",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const MeasurerGeneral = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [pdfGenerator] = useMutation(PDF_MEASURE_GENERAL);
  const {
    dateReport,
    setOpenDateCustom,
    openDateCustom,
    setDateReport,
    rangeTable,
    setRangeTable,
    graphic,
    setGraphic,
    setLoadingPdf,
    setPie,
    pie,
  } = useContext(AuthContext);
  const [measurer_data, setMeasurerData] = useState([]);
  const [project, setProject] = useState(-5);
  const [temp, setTemp] = useState(false);
  const printRef = React.useRef();
  const printRefP = React.useRef();
  const { projectID, timeZone } = props;
  const [basePdf, setBasePdf] = React.useState();

  const [parameterMax, setParameterMax] = useState(
    calculatedMaxParameter(dateReport, timeZone)
  );
  const [parameterMin, setParameterMin] = useState(
    calculatedMinParameter(dateReport, timeZone)
  );
  const [date_range, setDateRange] = useState([]);
  const [dayActual, setDayActual] = useState(new Date().getTime());
  const [dateMax, setDateMax] = useState();
  const [dateMin, setDateMin] = useState();

  const { data, loading } = useQuery(GET_DATA_MEASURER_BY_PROJECT, {
    variables: {
      date_range: date_range,
      ProjectId: parseInt(projectID, 10),
      start_time: parameterMin,
      end_time: parameterMax,
      time_zone: String(timeZone),
    },
    fetchPolicy: "no-cache",
  });

  useEffect(async () => {
    // setMeasurerData(data)
    let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
    let day_time_zone =
      dateReport[0]?.startDate?.toString().substring(0, 16) +
      "00:00:00 GMT" +
      timeZone;

    let day_time_end_zone =
      dateReport[0]?.endDate?.toString().substring(0, 16) +
      "23:59:59 GMT" +
      timeZone;
    let param =
      dateReport[0]?.startDate?.toString().substring(0, 16) + "00:00:00 GMT";

    let tempPara =
      new Date(param).getTime() - parseInt(timeZone, 10) * HORA_EN_MILISEGUNDO;
    let tmpDate = new Date(day_time_zone).getTime();

    const temEndDate = new Date(day_time_end_zone).getTime();

    //Parámetro inicial
    setParameterMin(tempPara);
    //Fecha inicial
    setDateMin(tmpDate);
    //parámetro final
    setParameterMax(temEndDate);
    //Fecha final
    setDateMax(temEndDate);
    //date ranges
    setDateRange(date_last(calc_date_range(dateReport)));
    setRangeTable(dateReport);
  }, []);

  const handleQueryReport = (ref) => {
    let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
    let day_time_zone =
      dateReport[0]?.startDate?.toString().substring(0, 16) +
      "00:00:00 GMT" +
      timeZone;

    let day_time_end_zone =
      dateReport[0]?.endDate?.toString().substring(0, 16) +
      "23:59:59 GMT" +
      timeZone;
    let param =
      dateReport[0]?.startDate?.toString().substring(0, 16) + "00:00:00 GMT";

    let tempPara =
      new Date(param).getTime() - parseInt(timeZone, 10) * HORA_EN_MILISEGUNDO;
    let tmpDate = new Date(day_time_zone).getTime();

    const temEndDate = new Date(day_time_end_zone).getTime();

    //openDateCusom
    setOpenDateCustom(false);
    //Parámetro inicial
    setParameterMin(tempPara);
    //Fecha inicial
    setDateMin(tmpDate);
    //parámetro final
    setParameterMax(temEndDate);
    //Fecha final
    setDateMax(temEndDate);
    //date ranges
    setDateRange(date_last(calc_date_range(dateReport)));
    setRangeTable(dateReport);
    setDayActual(dateReport[0].startDate.getTime());
  };

  useEffect(() => {
    if (data) {
      setMeasurerData(data);
      setTemp(true);
    }
  }, [data]);

  useEffect(async () => {
    // setMeasurerData(data)
    let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
    let day_time_zone =
      dateReport[0]?.startDate?.toString().substring(0, 16) +
      "00:00:00 GMT" +
      timeZone;

    let day_time_end_zone =
      dateReport[0]?.endDate?.toString().substring(0, 16) +
      "23:59:59 GMT" +
      timeZone;
    let param =
      dateReport[0]?.startDate?.toString().substring(0, 16) + "00:00:00 GMT";

    let tempPara =
      new Date(param).getTime() - parseInt(timeZone, 10) * HORA_EN_MILISEGUNDO;
    let tmpDate = new Date(day_time_zone).getTime();

    const temEndDate = new Date(day_time_end_zone).getTime();

    //Parámetro inicial
    setParameterMin(tempPara);
    //Fecha inicial
    setDateMin(tmpDate);
    //parámetro final
    setParameterMax(temEndDate);
    //Fecha final
    setDateMax(temEndDate);
    //date ranges
    setDateRange(date_last(calc_date_range(dateReport)));
    setRangeTable(dateReport);
  }, []);

  //   const handleQueryReport = () => {
  //     let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
  //     let day_time_zone =
  //       dateReport[0].startDate.toString().substring(0, 16) +
  //       "00:00:00 GMT" +
  //       timeZone;

  //     let day_time_end_zone =
  //       dateReport[0].endDate.toString().substring(0, 16) +
  //       "23:59:59 GMT" +
  //       timeZone;
  //     let param =
  //       dateReport[0].startDate.toString().substring(0, 16) + "00:00:00 GMT";

  //     let tempPara =
  //       new Date(param).getTime() - parseInt(timeZone, 10) * HORA_EN_MILISEGUNDO;
  //     let tmpDate = new Date(day_time_zone).getTime();

  //     const temEndDate = new Date(day_time_end_zone).getTime();

  //     //openDateCusom
  //     setOpenDateCustom(false);
  //     //Parámetro inicial
  //     setParameterMin(tempPara);
  //     //Fecha inicial
  //     setDateMin(tmpDate);
  //     //parámetro final
  //     setParameterMax(temEndDate);
  //     //Fecha final
  //     setDateMax(temEndDate);
  //     //date ranges
  //     setDateRange(date_last(calc_date_range(dateReport)));
  //     setRangeTable(dateReport);
  //     setDayActual(dateReport[0].startDate.getTime());
  //   };

  const back = () => {
    const backday = dayActual - 86400000;
    let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
    const new_backday = new Date(backday);
    let day_time_zone =
      new Date(new_backday).toString().substring(0, 16) +
      "00:00:00 GMT" +
      timeZone;

    let day_time_end_zone =
      new Date(new_backday).toString().substring(0, 16) +
      "23:59:59 GMT" +
      timeZone;
    let param =
      new Date(new_backday).toString().substring(0, 16) + "00:00:00 GMT";

    let tempPara = new Date(param).getTime() - parseInt(timeZone, 10) * HORA_EN_MILISEGUNDO;
    let tmpDate = new Date(day_time_zone).getTime();

    const temEndDate = new Date(day_time_end_zone).getTime();

    setDateReport([
      {
        startDate: new_backday,
        endDate: new_backday,
        key: "selection",
      },
    ]);

    //openDateCusom
    setOpenDateCustom(false);
    //Parámetro inicial
    setParameterMin(tempPara);
    //Fecha inicial
    setDateMin(tmpDate);
    //parámetro final
    setParameterMax(temEndDate);
    //Fecha final
    setDateMax(temEndDate);
    //date ranges
    setDateRange(dateRangeButton(new_backday));
    setRangeTable([
      {
        startDate: new_backday,
        endDate: new_backday,
        key: "selection",
      },
    ]);

    setDayActual(backday);
  };

  const next = () => {
    const nextday = dayActual + 86400000;
    let HORA_EN_MILISEGUNDO = 60 * 60 * 1000;
    const new_nextday = new Date(nextday);
    let day_time_zone =
      new Date(new_nextday).toString().substring(0, 16) +
      "00:00:00 GMT" +
      timeZone;

    let day_time_end_zone =
      new Date(new_nextday).toString().substring(0, 16) +
      "23:59:59 GMT" +
      timeZone;
    let param =
      new Date(new_nextday).toString().substring(0, 16) + "00:00:00 GMT";

    let tempPara = new Date(param).getTime() - parseInt(timeZone, 10) * HORA_EN_MILISEGUNDO;
    let tmpDate = new Date(day_time_zone).getTime();

    const temEndDate = new Date(day_time_end_zone).getTime();

    setDateReport([
      {
        startDate: new_nextday,
        endDate: new_nextday,
        key: "selection",
      },
    ]);

    //openDateCusom
    setOpenDateCustom(false);
    //Parámetro inicial
    setParameterMin(tempPara);
    //Fecha inicial
    setDateMin(tmpDate);
    //parámetro final
    setParameterMax(temEndDate);
    //Fecha final
    setDateMax(temEndDate);
    //date ranges
    setDateRange(dateRangeButton(new_nextday));
    setRangeTable([
      {
        startDate: new_nextday,
        endDate: new_nextday,
        key: "selection",
      },
    ]);
    setDayActual(nextday);
  };

  useEffect(() => {
    if (graphic) {
      if (date_range.length < 1 || date_range.length == 1) {
        imagePie();
      }
      if (date_range.length > 1) {
        dataPdf();
      }
    }
  }, [graphic]);

  useEffect(() => {
    if (pie) {
      dataPdf();
    }
  }, [pie]);

  useEffect(() => {
    if (basePdf) {
      decodePdf();
    }
  }, [basePdf]);

  const decodePdf = () => {
    const linkSource = `data:application/pdf;base64,${basePdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "Informe general de medición.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    /* let pdfWindow = window.open("");
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(basePdf) +
        "'></iframe>"
    ); */
    setLoadingPdf(false);
    setGraphic("");
    setPie("");
    setBasePdf("");
  };

  const imageGraphic = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/jpg");
    setGraphic(data.slice(22));
  };

  const imagePie = async () => {
    const elementTwo = printRefP.current;
    const canvas = await html2canvas(elementTwo);
    const data = canvas.toDataURL("image/jpg");
    setPie(data.slice(22));
  };

  const seeData = () => {
    setLoadingPdf(false);
    setGraphic("");
    setPie("");
  };

  useImperativeHandle(ref, () => ({
    async measuresGeneralPdf() {
      //seeData();
      setLoadingPdf(true);
      await imageGraphic();
    },
  }));

  function dataPdf() {
    try {
      pdfGenerator({
        variables: {
          image: [graphic, pie],
          data: data.getMetersByProject,
          date_range: date_range,
          startTime: formatDateLocal(parameterMin),
          finishTime: formatDateLocal(parameterMax),
          nameProject: localStorage.getItem("nameProject"),
          userName: localStorage.getItem("name"),
          imageProject: localStorage.getItem("imgProject"),
          datePrint: localStorage.getItem("localTime"),
        },
      })
        .then((resul) => {
          setBasePdf(resul.data.pdfMeasureGeneral);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  }

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <div className="_measurergeneral_container_dates_">
      {/* <DatePicker type='range' handleQuery={handleQueryReport} back={back} next={next} /> */}
      <DateCustom
        handleQueryReport={handleQueryReport}
        back={back}
        next={next}
      />
      {
        <>
          {data?.getMetersByProject &&
          !(data?.getMetersByProject?.data_kwh?.length === 0) ? (
            <>
              {dateMax && (
                <div ref={printRef}>
                  <div
                    className="_measurergeneral_container_graphics_"
                    onClick={() => setOpenDateCustom(false)}
                  >
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={{
                        chart: {
                          type: "column",
                        },
                        title: {
                          text: t(
                            "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_MEASUREMENT_MODAL.KILOWATTS_CONSUMPTION_GRAPH_TITLE"
                          ),
                        },
                        xAxis:
                          date_range?.length <= 1
                            ? {
                                type: "datetime",
                                min: parameterMin,
                                max: parameterMax - 3600000,
                                tickInterval: 3600000,
                                labels: {
                                  format: "{value:%H:%M}",
                                },
                              }
                            : {
                                type: "datetime",
                                tickInterval: 86400000,
                              },
                        yAxis: {
                          min: 0,
                          max: data?.getMetersByProject?.length,
                          tickAmount: data?.getMetersByProject?.length + 1,
                          title: {
                            text: t(
                              "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_MEASUREMENT_MODAL.Y_AXIS_KILOWATTS_DESCRIPTION"
                            ),
                          },
                          stackLabels: {
                            enabled: true,
                            formatter: function () {
                              return this.total?.toFixed(1);
                            },
                          },
                        },
                        colors: [
                          "#04b431",
                          "#013adf",
                          "#df01a5",
                          "#DF2653",
                          "#615C5D",
                          "#D5DF03",
                          "#1C1C1C",
                          "#ee0f0f",
                          "#FFAF0E",
                          "#1f89e5e6",
                          "#1f89e566",
                        ],
                        plotOptions: {
                          column: {
                            stacking: "stream",
                            maxPointWidth: 50,
                          },
                        },
                        time: {
                          timezoneOffset: define_time_zone(
                            date_range,
                            timeZone
                          ),
                        },
                        tooltip: {
                          headerFormat: "<b>{point.key}</b><br>",
                          valueDecimals: 1,
                          xDateFormat: defice_toolTip_date(date_range),
                          valueSuffix: " kW h",
                        },
                        series: data?.getMetersByProject?.data_kwh,
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="date_vacio_report_measurer">
                <span>
                  <i class="fas fa-exclamation-triangle"></i>{" "}
                  {t(
                    "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_MEASUREMENT_MODAL.NO_DATA"
                  )}
                </span>
              </div>
            </>
          )}
          <div ref={printRefP}>
            <div className="_measurergeneral_container_graphics_">
              {data?.getMetersByProject &&
              !(data?.getMetersByProject?.data_watts == undefined) ? (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      zoomType: "x",
                      type: "spline",
                    },
                    title: {
                      text: "Watts",
                    },

                    xAxis: {
                      type: "datetime",
                      tickInterval: 3600000,
                      min: parameterMin,
                      max: parameterMax,
                      labels: {
                        format: "{value:%H:%M}",
                      },
                    },
                    yAxis: {
                      min: 0,
                      title: {
                        text: t(
                          "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_MEASUREMENT_MODAL.Y_AXIS_WATSS_DESCRIPTION"
                        ),
                      },
                    },
                    colors: [
                      "#04b431",
                      "#013adf",
                      "#df01a5",
                      "#DF2653",
                      "#615C5D",
                      "#D5DF03",
                      "#1C1C1C",
                      "#ee0f0f",
                      "#FFAF0E",
                      "#1f89e5e6",
                      "#1f89e566",
                    ],
                    tooltip: {
                      headerFormat: "<b>{series.name}</b><br>",
                      pointFormat:
                        "<b>{point.y} watts </b><br><b>{point.key}</b>",
                      // valueSuffix: " watts",
                      xDateFormat: "Hora: %H:%M:%S",
                      valueDecimals: 1,
                      shared: false,
                      // followTouchMove: false,
                      useHTML: true,
                    },
                    plotOptions: {
                      column: {
                        stacking: "line",
                      },
                      series: {
                        turboThreshold: 0,
                        marker: {
                          enabled: false,
                          radius: 0.1,
                          symbol: "circle",
                        },
                      },
                    },
                    time: {
                      timezoneOffset: define_time_zone(date_range, timeZone),
                    },
                    series: data?.getMetersByProject?.data_watts,
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="_measurergeneral_container_tables_">
            <TableContainer component={Paper}>
              <div className="__report_measurer_table_total_title__">
                {t(
                  "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_MEASUREMENT_MODAL.TOTAL_SUMMARY"
                )}
                ({convert_time_measurergeneral(rangeTable)})
              </div>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">
                      {t(
                        "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_MEASUREMENT_MODAL.MEASUREMENT_NUMBER"
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {t(
                        "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_MEASUREMENT_MODAL.AREA"
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {t(
                        "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_MEASUREMENT_MODAL.INITIAL_MEASUREMENT"
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {t(
                        "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_MEASUREMENT_MODAL.FINAL_MEASUREMENT"
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">Total</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.getMetersByProject?.totals
                    ? data?.getMetersByProject?.totals?.map((i, key) => (
                        <StyledTableRow>
                          <StyledTableCell align="center">
                            {key + 1}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {i?.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {i?.accumulated
                              ? `${i?.accumulated?.initial.toFixed(1)} kW h`
                              : "-"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {i.accumulated
                              ? `${i?.accumulated?.final.toFixed(1)} kW h`
                              : "-"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {round_digits(i?.value)} kW h
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    : false}
                  <StyledTableRow>
                    <StyledTableCell2 component="th" scope="row" align="center">
                      Total
                    </StyledTableCell2>
                    <StyledTableCell2
                      component="th"
                      scope="row"
                    ></StyledTableCell2>
                    <StyledTableCell2
                      component="th"
                      scope="row"
                    ></StyledTableCell2>
                    <StyledTableCell2
                      component="th"
                      scope="row"
                    ></StyledTableCell2>
                    <StyledTableCell2 component="th" scope="row" align="center">
                      {round_digits(data?.getMetersByProject?.total_range)} kW h
                    </StyledTableCell2>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className="__measurergeneral_grid_title_tables__">
            <span>
              <hr />
            </span>
            <span>
              {t(
                "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_MEASUREMENT_MODAL.TOTAL_SUMMARY_PER_MEASUREMENT"
              )}
            </span>
            <span>
              <hr />
            </span>
          </div>

          <div className="_measurergeneral_grid_tables_">
            {data?.getMetersByProject?.data_kwh ? (
              data?.getMetersByProject?.data_kwh?.map((j) => (
                <div className="__report_measurer_rooms__">
                  <TableContainer component={Paper}>
                    <div className="__report_measurer_by_room__">{j?.name}</div>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">
                            {t(
                              "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_MEASUREMENT_MODAL.HOUR"
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Total
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {j?.data?.map((i, key) => (
                          <StyledTableRow>
                            <StyledTableCell align="center">
                              {date_of_the_kwh(i?.x, date_range, key)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {i?.y?.toFixed(1)} kW h
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                        <StyledTableRow>
                          <StyledTableCell2
                            component="th"
                            scope="row"
                            align="center"
                          >
                            Total
                          </StyledTableCell2>
                          <StyledTableCell2
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {round_digits(sum_of_the_kwh(j?.data))} kW h
                          </StyledTableCell2>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ))
            ) : (
              <></>
            )}
            {data?.getMetersByProject?.total_by ? (
              <div className="__report_measurer_rooms__">
                <TableContainer component={Paper}>
                  <div className="__report_measurer_by_room__">
                    {t(
                      "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_MEASUREMENT_MODAL.TOTAL_DAY_HOUR"
                    )}
                  </div>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">
                          {t(
                            "VIEW_AREAS.COMPONENT_HEADER_AREAS.COMPONENT_CONTAINER_FILTERS.COMPONENT_REPORTS_FILTER.GENERAL_MEASUREMENT_MODAL.HOUR"
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">Total</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.getMetersByProject?.total_by?.map((i) => (
                        <StyledTableRow>
                          <StyledTableCell align="center">
                            {roundTotalBy(i?.x)}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {i === undefined || i?.length === 0
                              ? "-----"
                              : `${i?.y?.toFixed(1)}`}
                            kW h
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                      <StyledTableRow>
                        <StyledTableCell2
                          component="th"
                          scope="row"
                          align="center"
                        >
                          Total
                        </StyledTableCell2>
                        <StyledTableCell2
                          component="th"
                          scope="row"
                          align="center"
                        >
                          {data?.getMetersByProject?.total_by.length !== 0
                            ? sumTotalBy(data?.getMetersByProject?.total_by)
                            : "-----"}{" "}
                          kW h
                        </StyledTableCell2>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : (
              <></>
            )}
          </div>
        </>
      }
    </div>
  );
});

export default MeasurerGeneral;

import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import AddCode from "../devices/DoorLock/AddCode";
import Restriction from "../devices/DoorLock/Restriction";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import LoadingDoorLock from "../loading/LoadingDoorLock";

const theme = createTheme({
  palette: {
    primary: {
      main: green[700],
    },
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          "&": {
            textTransform: "none",
          },
          "&.Mui-selected": {
            backgroundColor: "rgba(56, 142, 60, 0.3)",
            color: green[700],
            "&:hover": {
              backgroundColor: "rgba(56, 142, 60, 0.35)",
            },
          },
          "&:hover": {
            backgroundColor: green[100],
          },
        },
      },
    },
  },
});

function Loading() {
  const msgStatus = [
    "Iniciando configuración...",
    "Intercambiando credenciales...",
    "Encriptando código de seguridad...",
    "Finalizando proceso...",
  ];
  const [stateLoading, setStateLoading] = useState(0);
  setTimeout(() => {
    stateLoading == 3 ? setStateLoading(0) : setStateLoading(stateLoading + 1);
  }, 15000);
  clearTimeout();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        position: "absolute",
        backgroundColor: "#FFFFFF",
        zIndex: 100,
      }}
    >
      <div className='__container_doorLock__'>
        <div className='__loading_text_container_doorlock__'>
          <LoadingDoorLock />
        </div>
        <div className='__loading_text_container_doorlock__'>
          <span className='descrptcion_doorlock_loading'>{msgStatus[stateLoading]}</span>
        </div>
      </div>
    </Box>
  );
}

function SuccessMessage() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        position: "absolute",
        backgroundColor: "#d0e9d6",
        zIndex: 100,
      }}
    >
      <CheckCircleIcon fontSize='large' color='success' />
      <span className='__message_doorLock__'>Información registrada correctamente.</span>
    </Box>
  );
}

function ErrorMessage() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        position: "absolute",
        backgroundColor: "#e9d0d0",
        zIndex: 100,
      }}
    >
      <CancelIcon fontSize='large' color='error' />
      <span className='__message_doorLock__'>
        Ocurrió un error al procesar información.
      </span>
    </Box>
  );
}

function ModalDoorLock({
  open,
  handleClose,
  title,
  view,
  setView,
  dataCode,
  setDataCode,
  refetchListCode,
}) {
  const [typeMessage, setTypeMessage] = useState(null);

  const handleCloseModal = () => {
    handleClose();
    setView("add");
    setTypeMessage(null);
    refetchListCode()
  };

  const selectMessage = () => {
    switch (typeMessage) {
      case "loading":
        return <Loading />;
      case "success":
        return <SuccessMessage />;
      case "error":
        return <ErrorMessage />;

      default:
        break;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} onClose={handleCloseModal} fullWidth>
        <DialogTitle id='alert-dialog-title' className="header_modal">
          <span>{title}</span>
          <span className='_close_modal_doorlock' onClick={handleCloseModal}>
            <i class="fas fa-times"></i>
          </span>{" "}
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ position: "relative", p: 0 }}>
          {typeMessage && selectMessage()}
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {view === "add" ? (
              <AddCode
                setTypeMessage={setTypeMessage}
                setView={setView}
                setDataCode={setDataCode}
              />
            ) : (
              <Restriction
                dataCode={dataCode}
                setTypeMessage={setTypeMessage}
                refetchListCode={refetchListCode}
              />
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}

export default ModalDoorLock;

import React from "react";
import { ListItemIcon, ListItem, ListItemText, List } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import PersonAddIcon from '@material-ui/icons/PersonAdd';

export const AddUser = () => {
    return (
        <ListItem button>
            <ListItemIcon >
                <PersonAddIcon />
            </ListItemIcon>
            <ListItemText
                primary="Crear Usuarios"               
            />
        </ListItem>
    )
}
export default AddUser;
import React from "react";
import { Route } from "react-router-dom";
import {
  MainStepperContainer,
  Navbar,
  InstructionsAndSummaryContainer,
  InstructionsContainer,
  SummaryContainer,
  StepperStatusContainer,
} from "./styles/stepperView.style";
import Info from "./components/Info";
import Stepper from "./components/Stepper";
import Summary from "./components/Summary";

const StepperView = (props) => {
  return (
    <Route
      render={({ match }) => (
        <MainStepperContainer>
          <Navbar>
            <div className="modalButton"></div>
          </Navbar>
          <InstructionsAndSummaryContainer>
            <InstructionsContainer>
              <Info match={match} />
            </InstructionsContainer>
            <SummaryContainer>
              <Summary />
            </SummaryContainer>
          </InstructionsAndSummaryContainer>
          <StepperStatusContainer>
            <Stepper />
          </StepperStatusContainer>
        </MainStepperContainer>
      )}
    ></Route>
  );
};

export default StepperView;

import { Alert, AlertTitle, Snackbar } from "@mui/material";

export const AlertComponent = ({ open, message, styleMessage }) => {
  return (
    <>
      <Snackbar open={open} autoHideDuration={open}>
        <Alert severity={styleMessage}>
          <AlertTitle> {message}</AlertTitle>
        </Alert>
      </Snackbar>
    </>
  );
};

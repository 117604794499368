import React from "react";

export default function LoadingDoorLock() {
  return (
    <div>
      <lottie-player
        style={{
          marginRight: "auto",
          marginLeft: "auto",
          width: "40%",
        }}
        className='_logo'
        src='https://lottie.host/676d6909-e4d6-4273-a75f-ccf894540af1/TdWWNBkotQ.json'
        background='transparent'
        speed='1'
        loop
        autoplay
      ></lottie-player>
    </div>
  );
}

import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../context/AuthProvider";
import { useQuery } from "@apollo/client";
import { GET_TWELVE_HOUR_VOLTAGE } from "../../../controllers/measurersController";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";



//Function
import { Dates } from "./helperDate";

const GraphVoltage = ({ projectID, timeZone }) => {
  const {serial, dateMUI } = useContext(AuthContext);

  const { data, loading } = useQuery(GET_TWELVE_HOUR_VOLTAGE, {
    variables: {
      serial: serial,
      start_time: Dates(dateMUI).start,
      end_time: Dates(dateMUI).end,
      time_zone: timeZone,
    },
    fetchPolicy: "no-cache",
  });

  if (loading || !data) {
    return <></> /* <LoadingVoltage /> */;
  }

  return (
    <div className="__details_measurer_consumption_voltage_graphic__">
      {data.getTwelveHourVoltage.length === 0 ? (
        <div className="date_vacio_report_measurer">
          <span>
            <i class="fas fa-exclamation-triangle"></i> No hay datos
          </span>
        </div>
      ) : (
        <div>
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: "spline",
                scrollablePlotArea: {
                  minWidth: 600,
                  scrollPositionX: 1,
                },
              },
              title: {
                text: "Voltaje últimas 24 horas",
                align: "left",
              },
              subtitle: {
                text: "",
                align: "left",
              },
              xAxis: {
                type: "datetime",
                tickInterval: 3600000,
                min: parseInt(`${data.getTwelveHourVoltage.startPoint}000`),
                max: parseInt(`${data.getTwelveHourVoltage.endPoint}000`),
                labels: {
                  format: "{value:%H:%M}",
                },
              },
              yAxis: {
                //min: 122,
                title: {
                  text: "Voltios",
                },
              },
              tooltip: {
                valueSuffix: " V",
              },
              plotOptions: {
                spline: {
                  lineWidth: 1.5,
                  states: {
                    hover: {
                      lineWidth: 2.5,
                    },
                  },
                  marker: {
                    enabled: false,
                  },
                },
              },
              time: {
                timezoneOffset: 300,
              },
              series: data.getTwelveHourVoltage.show_data,
              navigation: {
                menuItemStyle: {
                  fontSize: "10px",
                },
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

export default GraphVoltage;

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { define_time_zone } from '../../../utils/helpers';
import { useTranslation } from 'react-i18next';

const GraphMeasurerPhases = ({ data, endTime, startTime }) => {
  const endTimeRango = endTime - 61000;

  const { t } = useTranslation();
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            zooming: {
              mouseWheel: false,
            },
            type: 'column',
          },
          colors: ['#04b431'],

          title: {
            align: 'left',
            text: `Consumo total de ${''} kWh `,
          },

          subtitle: {
            align: 'left',
            text: '' /* "Click the columns to view details. " */,
          },
          accessibility: {
            announceNewData: {
              enabled: true,
            },
          },
          xAxis: {
            type: 'datetime',
            min: startTime,
            max: endTimeRango,
            tickInterval: 3600000,
            labels: {
              format: '{value:%H:%M}',
            },
          },
          yAxis: {
            title: {
              text: '',
            },
            min: 0,
          },
          legend: {
            enabled: false,
          },
          plotOptions: {
            series: {
              borderWidth: 0,
              dataLabels: {
                enabled: true,
                format: '{point.y:.1f}',
              },
            },
          },

          tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b> kWh<br/>',
          },
          time: {
            timezoneOffset: 300,
          },
          series: [
            {
              name: '{value:%H:%M}',
              colorByPoint: true,
              data: data?.getClampsMeasure?.data_kwh[0]?.data,
            },
          ],
        }}
      />
    </div>
  );
};
export default GraphMeasurerPhases;

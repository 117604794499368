import React, { useContext, useState } from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import { AuthContext } from "../../../context/AuthProvider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import EditForm from "./editForm";
//multilanguage

import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  root: {
    backgroundColor: "#3F9948",
  },
  headerRegister: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '5px',
    marginTop: '10px',
  },
  logo: {
    height: '80px',
    margin: '5px',
    marginTop: '10px',
  },
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});
export default function FullScreenDialog({
  openMod,
  setOpenSet,
  iddUser,
  dataUsers,
  refetch,
}) {
  const { t } = useTranslation();
  const getUsers = dataUsers;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { alertEdit, openEditUserModal, setOpenEditUserModal } = useContext(AuthContext);
  const [editError, setEditError] = useState(false);
  const handleCloseEditModal = () => {
    setOpenEditUserModal(false);
  };
  const handleCloseAlert = () => {
    setEditError(false);
  };
  
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={openEditUserModal ? true : false}
        onClose={handleCloseEditModal}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: { width: '534px', height: '590px', borderRadius: '8px' },
        }}
      >
        <AppBar sx={{ position: 'relative', backgroundColor: '#2EB142' }}>
          <Toolbar>
            <Typography
              sx={{ display: "flex", justifyContent: "center", flex: 2 }}
              variant="h6"
              component="div"
            >
              {t(
                "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_USER_EDIT_MODAL.TITLE_MODAL"
              )}
            </Typography>
            <IconButton
              color="inherit"
              onClick={handleCloseEditModal}
              edge="end"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <br />
        <br />
        <DialogContent>
          <EditForm
            iddUser={iddUser}
            setEditError={setEditError}
            dataUsers={getUsers}
            refetchFromUser={refetch}
          />
        </DialogContent>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <Snackbar
            open={alertEdit}
            autoHideDuration={2000}
            onClose={handleCloseAlert}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          >
            <Alert severity="success">
              {t(
                "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_USER_EDIT_MODAL.SUCCESSFUL_ALERT"
              )}
            </Alert>
          </Snackbar>
          <Snackbar
            open={editError}
            autoHideDuration={2000}
            onClose={handleCloseAlert}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          >
            <Alert severity="error">
              {t(
                "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_USER_EDIT_MODAL.ERROR_ALERT"
              )}
            </Alert>
          </Snackbar>
        </div>
      </Dialog>
    </div>
  );
}

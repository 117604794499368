import React from "react";
import { ListItemIcon, ListItem, ListItemText, List } from "@material-ui/core";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { makeStyles } from "@material-ui/core/styles";

//multilanguage
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    margin: "0",
  },
  listItem: {
    padding: "0px",
    marginTop: "0%",
    marginLeft: "25px",
    marginBottom: "0%",
  },
});

export const ManagementRole = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <ListItem className={classes.listItem}>
      <ListItemIcon>
        <AdminPanelSettingsIcon />
      </ListItemIcon>
      <ListItemText primary={t("VIEW_PROJECT.COMPONENT_SIDEBAR.ROL_MANAGEMENT")}/>
    </ListItem>
  );
};
export default ManagementRole;

import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { NewGraphVoltage } from './NewGraphVoltage';
import { NewGraphWatts } from './NewGraphWatts';
import { NewGraphCurrent } from './NewGraphCurrent';
import { NewGraphPowerFactor } from './NewGraphFDPotencia';
import NewGraphFrequency from './NewGraphFrequency';
import NewGraphArmonicCurrent from './NewGraphArmonicCurrent';
import NewGraphArmonicVoltage from './NewGraphArmonicVoltage';

//multilanguage
import { useTranslation } from 'react-i18next';

export default function TabNewGraphicVAV({ serial }) {
  const { t } = useTranslation();
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const currentDay = new Date();

  const tabStyle = (tabValue) => ({
    borderColor: '#BEBEBE',
    borderTopLeftRadius: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 2.6px',
    borderTop: 'solid 0.7px #EFEFEF',

    ...(value === tabValue && {
      boxShadow: 'rgba(0, 0, 0, 0.35)  2px 5px 15px',
      fontSize: '16px',
      borderColor: '#EFEFEF',
      borderTop: 'solid 0.5px #CBCBCB',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      fontWeight: 'bold',
      color: '#3f9948',
    }),
  });
  return (
    <Box sx={{ width: '100%', typography: 'body1', marginBottom: '5%' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: '#DCE0E5' }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{ color: '#DCE0E5' }}
            indicatorColor="#fff"
          >
            <Tab
              style={tabStyle('1')}
              label="Watts"
              value="1"
            />
            <Tab
              style={tabStyle('2')}
              label={t('VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_TABS_VARIABLES.VOLTAGE')}
              value="2"
            />
            <Tab
              style={tabStyle('3')}
              label={t('VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_TABS_VARIABLES.AMPERAGE')}
              value="3"
            />
            <Tab
              style={tabStyle('4')}
              label={t('VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_TABS_VARIABLES.P_FACTOR')}
              value="4"
            />
            <Tab
              style={tabStyle('5')}
              label={t('VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_TABS_VARIABLES.FREQUENCY')}
              value="5"
            />
            <Tab
              style={tabStyle('6')}
              label={t('VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_TABS_VARIABLES.ARMONIC_CURRENT')}
              value="6"
            />
            <Tab
              style={tabStyle('7')}
              label={t('VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_TABS_VARIABLES.ARMONIC_VOLTAGE')}
              value="7"
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <NewGraphWatts serial={serial} />
        </TabPanel>
        <TabPanel value="2">
          <NewGraphVoltage serial={serial} />
        </TabPanel>
        <TabPanel value="3">
          <NewGraphCurrent serial={serial} />
        </TabPanel>
        <TabPanel value="4">
          <NewGraphPowerFactor serial={serial} />
        </TabPanel>
        <TabPanel value="5">
          <NewGraphFrequency serial={serial} />
        </TabPanel>
        <TabPanel value="6">
          <NewGraphArmonicCurrent serial={serial} />
        </TabPanel>
        <TabPanel value="7">
          <NewGraphArmonicVoltage serial={serial} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

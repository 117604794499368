import React, { useState, useEffect, useContext } from "react";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import {
  MainContainerMacrometerVariables,
  MacrometerVariablesTitle,
  MacrometerVariablesSecondaryContainer,
} from "../styles/MacrometerVariables.style";
import Pagination from "../components/Pagination";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CancelInstallationButton from "../components/CancelInstallationButton";
import { AuthContext } from "../../../context/AuthProvider";
import CancelInstallationModal from "../components/CancelInstallationModal";

const secondaryGreen = "#67A46D";
const theme = createTheme({
  palette: {
    success: {
      main: secondaryGreen,
    },
  },
});

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const MacrometerVariables = ({ info }) => {
  const allMetersVariables = JSON.parse(localStorage.getItem("dataMeters"));
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const existMeters = parseInt(localStorage.getItem("existNewMeters"));
  const [validFirstAlias, setValidFirstAlias] = useState(true);
  const [validFirstTcRelation, setValidFirstTcRelation] = useState(true);
  const [validFirstMaxCurrent, setValidFirstMaxCurrent] = useState(true);
  const [validSecondAlias, setValidSecondAlias] = useState(true);
  const [validSecondTcRelation, setValidSecondTcRelation] = useState(true);
  const [validSecondMaxCurrent, setValidSecondMaxCurrent] = useState(true);
  const { setValidFirstVariables, setValidSecondVariables } =
    useContext(AuthContext);

  const [firstVariables, setFirstVariables] = useState({
    alias: localStorage.getItem("alias")
      ? localStorage.getItem("alias")
      : allMetersVariables[0]?.alias,
    powerFactor: localStorage.getItem("powerFactor")
      ? localStorage.getItem("powerFactor")
      : allMetersVariables[0]?.powerFactor,
    tcRelation: localStorage.getItem("tcRelation")
      ? localStorage.getItem("tcRelation")
      : allMetersVariables[0]?.relationTc,
    maxCurrent: localStorage.getItem("maxCurrent")
      ? localStorage.getItem("maxCurrent")
      : allMetersVariables[0]?.maximumCurrent,
  });

  const [secondVariables, setSecondVariables] = useState({
    alias: localStorage.getItem("secondAlias")
      ? localStorage.getItem("secondAlias")
      : allMetersVariables[1]?.alias,
    powerFactor: localStorage.getItem("secondPowerFactor")
      ? localStorage.getItem("secondPowerFactor")
      : allMetersVariables[1]?.powerFactor,
    tcRelation: localStorage.getItem("secondTcRelation")
      ? localStorage.getItem("secondTcRelation")
      : allMetersVariables[1]?.relationTc,
    maxCurrent: localStorage.getItem("secondMaxCurrent")
      ? localStorage.getItem("secondMaxCurrent")
      : allMetersVariables[1]?.maximumCurrent,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleInputChangeCharacters = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-ZñÑáÁéÉíÍóÓúÚ.0-9\s]*$/;
    if (!regex.test(value)) {
      return;
    } else {
      setFirstVariables({
        ...firstVariables,
        alias: value,
      });
      setValidFirstAlias(value.length >= 3);
    }
  };
  useEffect(() => {
    localStorage.setItem("alias", firstVariables.alias);
  }, [firstVariables.alias]);

  const handleInputChangeCharacters2 = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-ZñÑáÁéÉíÍóÓúÚ.0-9\s]*$/;
    if (!regex.test(value)) {
      return;
    } else {
      setSecondVariables({
        ...secondVariables,
        alias: value,
      });
      setValidSecondAlias(value.length >= 3);
    }
  };
  useEffect(() => {
    localStorage.setItem("secondAlias", secondVariables.alias);
  }, [secondVariables.alias]);

  const handleInputMaximumCurrent = (event) => {
    const value = event.target.value;
    const regex = /^[0-9\s]*$/;
    if (!regex.test(value)) {
      return;
    } else {
      setFirstVariables({
        ...firstVariables,
        maxCurrent: value,
      });
      setValidFirstMaxCurrent(
        parseInt(value) >= 100 && parseInt(value) <= 5000
      );
    }
  };
  useEffect(() => {
    localStorage.setItem("maxCurrent", firstVariables.maxCurrent);
  }, [firstVariables.maxCurrent]);

  const handleInputMaximumCurrent2 = (event) => {
    const value = event.target.value;
    const regex = /^[0-9\s]*$/;
    if (!regex.test(value)) {
      return;
    } else {
      setSecondVariables({
        ...secondVariables,
        maxCurrent: value,
      });
      setValidSecondMaxCurrent(
        parseInt(value) >= 100 && parseInt(value) <= 5000
      );
    }
  };
  useEffect(() => {
    localStorage.setItem("secondMaxCurrent", secondVariables.maxCurrent);
  }, [secondVariables.maxCurrent]);

  const handleInputTcRelation = (event) => {
    const value = event.target.value;
    const regex = /^[0-9/\s]*$/;
    if (!regex.test(value)) {
      return;
    } else {
      const variablesOperation = value.split("/");
      const outcome = variablesOperation[0] / variablesOperation[1];

      setFirstVariables({
        ...firstVariables,
        tcRelation: value,
        powerFactor: outcome,
      });

      setValidFirstTcRelation(value.includes("/") && Number.isInteger(outcome));
    }
  };
  useEffect(() => {
    localStorage.setItem("tcRelation", firstVariables.tcRelation);
    localStorage.setItem("powerFactor", firstVariables.powerFactor);
  }, [firstVariables.tcRelation]);

  const handleInputTcRelation2 = (event) => {
    const value = event.target.value;
    const regex = /^[0-9/\s]*$/;
    if (!regex.test(value)) {
      return;
    } else {
      const variablesOperation = value.split("/");
      const outcome = variablesOperation[0] / variablesOperation[1];
      setSecondVariables({
        ...secondVariables,
        tcRelation: value,
        powerFactor: outcome,
      });
      setValidSecondTcRelation(
        value.includes("/") && Number.isInteger(outcome)
      );
    }
  };
  useEffect(() => {
    localStorage.setItem("secondTcRelation", secondVariables.tcRelation);
    localStorage.setItem("secondPowerFactor", secondVariables.powerFactor);
  }, [secondVariables.tcRelation]);

  const validFirstMetersVariables =
    validFirstAlias === true &&
    validFirstTcRelation === true &&
    validFirstMaxCurrent === true;

  const validSecondMetersVariables =
    validSecondAlias === true &&
    validSecondTcRelation === true &&
    validSecondMaxCurrent === true;

  useEffect(() => {
    if (parseInt(localStorage.getItem("existNewMeters")) === 1) {
      setValidFirstVariables(validFirstMetersVariables);
    } else {
      if (parseInt(localStorage.getItem("existNewMeters")) === 2) {
        setValidFirstVariables(validFirstMetersVariables);
        setValidSecondVariables(validSecondMetersVariables);
      }
    }
  }, [validFirstMetersVariables, validSecondMetersVariables]);

  return (
    <MainContainerMacrometerVariables>
      <MacrometerVariablesTitle>
        <span className="variables_title">
          {"Variables de/los medidor(es)"}
        </span>
        <CancelInstallationButton />
      </MacrometerVariablesTitle>
      <MacrometerVariablesSecondaryContainer>
        <div className="text_description">{info.description}</div>
        <div className="page_n">
          {"Page: "}
          {value + 1}
        </div>
      </MacrometerVariablesSecondaryContainer>
      <MacrometerVariablesSecondaryContainer>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Macromedidor 1" {...a11yProps(0)} />
              <Tab
                label="Macromedidor 2"
                {...a11yProps(1)}
                disabled={existMeters > 1 ? false : true}
              />
            </Tabs>
          </Box>

          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <CustomTabPanel value={value} index={0} dir={theme.direction}>
              <div className="first_input_group">
                <ThemeProvider theme={theme}>
                  <TextField
                    sx={{}}
                    size="small"
                    variant="outlined"
                    label={"Alias (nombre)"}
                    InputProps={{ sx: { borderRadius: 2 } }}
                    color={validFirstAlias ? "success" : "error"}
                    disabled={existMeters > 0 ? false : true}
                    value={firstVariables.alias}
                    onChange={handleInputChangeCharacters}
                  />
                  <TextField
                    sx={{}}
                    size="small"
                    variant="outlined"
                    label="Relación TC"
                    InputProps={{ sx: { borderRadius: 2 } }}
                    color={validFirstTcRelation ? "success" : "error"}
                    disabled={existMeters > 0 ? false : true}
                    value={firstVariables.tcRelation}
                    onChange={handleInputTcRelation}
                  />
                </ThemeProvider>
              </div>
              <div className="second_input_group">
                <ThemeProvider theme={theme}>
                  <TextField
                    sx={{}}
                    size="small"
                    variant="outlined"
                    label={"Factor de potencia"}
                    InputProps={{ sx: { borderRadius: 2 } }}
                    color="success"
                    disabled={true}
                    value={firstVariables.powerFactor}
                    // onChange={handleInputPowerFactor}
                  />
                  <TextField
                    sx={{}}
                    size="small"
                    variant="outlined"
                    label="Maxima Corriente"
                    InputProps={{ sx: { borderRadius: 2 } }}
                    color={validFirstMaxCurrent ? "success" : "error"}
                    disabled={existMeters > 0 ? false : true}
                    value={firstVariables.maxCurrent}
                    onChange={handleInputMaximumCurrent}
                  />
                </ThemeProvider>
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1} dir={theme.direction}>
              <div className="first_input_group">
                <ThemeProvider theme={theme}>
                  <TextField
                    sx={{}}
                    size="small"
                    variant="outlined"
                    label="Alias (nombre)"
                    InputProps={{ sx: { borderRadius: 2 } }}
                    color={validSecondAlias ? "success" : "error"}
                    value={secondVariables.alias}
                    disabled={existMeters > 1 ? false : true}
                    onChange={handleInputChangeCharacters2}
                  />
                  <TextField
                    sx={{}}
                    size="small"
                    variant="outlined"
                    label="Relación TC"
                    InputProps={{ sx: { borderRadius: 2 } }}
                    color={validSecondTcRelation ? "success" : "error"}
                    value={secondVariables.tcRelation}
                    disabled={existMeters > 1 ? false : true}
                    onChange={handleInputTcRelation2}
                  />
                </ThemeProvider>
              </div>
              <div className="second_input_group">
                <ThemeProvider theme={theme}>
                  <TextField
                    sx={{}}
                    size="small"
                    variant="outlined"
                    label="Factor de potencia"
                    InputProps={{ sx: { borderRadius: 2 } }}
                    color="success"
                    value={secondVariables.powerFactor}
                    disabled={true}
                    // onChange={handleInputPowerFactor2}
                  />
                  <TextField
                    sx={{}}
                    size="small"
                    variant="outlined"
                    label="Maxima Corriente"
                    InputProps={{ sx: { borderRadius: 2 } }}
                    color={validSecondMaxCurrent ? "success" : "error"}
                    value={secondVariables.maxCurrent}
                    disabled={existMeters > 1 ? false : true}
                    onChange={handleInputMaximumCurrent2}
                  />
                </ThemeProvider>
              </div>
            </CustomTabPanel>
          </SwipeableViews>
        </Box>
      </MacrometerVariablesSecondaryContainer>
      <Pagination
        labelPreviousButton={"Atrás"}
        previousButtonColor={"success"}
        nextButtonColor={"success"}
        labelNextButton={"Siguiente"}
        enabled={
          existMeters === 2 &&
          validFirstMetersVariables === true &&
          validSecondMetersVariables === true
            ? false
            : existMeters === 1 &&
              validFirstMetersVariables === true &&
              validSecondMetersVariables === true
            ? false
            : true
        }
        prev={info.prev}
        next={info.next}
      />
      <CancelInstallationModal />
    </MainContainerMacrometerVariables>
  );
};

export default MacrometerVariables;

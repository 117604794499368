import React, { useContext, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { useTheme, createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthContext } from "../../context/AuthProvider";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { AlertComponent } from "./AlertComponent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";

//query
import { useQuery, useMutation } from "@apollo/client";
import {
  UPDATE_MANY_USERS,
  GET_ALL_USER,
} from "../../controllers/usersControllers";

//multilanguage
import { useTranslation } from "react-i18next";

const customGreen = "#acf6ac";
const customRed = "#f19393";

const theme = createTheme({
  palette: {
    success: {
      main: customGreen,
    },
    error: {
      main: customRed,
    },
  },
});

export const ModalAlertUserStatusChange = ({
  roleId,
  setCheckedUserToggle,
  checkedUserToggle,
  openChangeUserStatusFromToggle,
  setOpenChangeUserStatusFromToggle,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [styleMessage, setStyleMessage] = useState({});
  const { users, openChangeUserStatusModal, setOpenChangeUserStatusModal } =
    useContext(AuthContext);

  const [activateOrDesactivateUser] = useMutation(UPDATE_MANY_USERS);
  const { refetch } = useQuery(GET_ALL_USER, {
    fetchPolicy: "network-only",
  });

  const dataUsers = users?.map((item) => {
    if (roleId === item.id) {
      return { id: item.id, enabled: item.enabled };
    }
  });

  const filteredUser = dataUsers?.filter((i) => i !== undefined);
  filteredUser?.map((item, key) => {
    if (item.enabled === true) {
      item.enabled = false;
    } else {
      item.enabled = true;
    }
    return item.enabled;
  });

  function updateEnableUsers() {
    try {
      setCheckedUserToggle(!checkedUserToggle);
      activateOrDesactivateUser({
        variables: { input: filteredUser },
      })
        .then((resul) => {})
        .catch((err) => {});
    } catch (err) {}
    setOpenChangeUserStatusFromToggle(false);
    refetch();
  }

  const handleClose = () => {
    setOpenChangeUserStatusModal(false);
    setOpenChangeUserStatusFromToggle(false);
  };

  return (
    <>
      <Dialog
        open={openChangeUserStatusModal || openChangeUserStatusFromToggle}
        PaperProps={{
          sx: {
            borderRadius: "8px",
            width: "30%",
            height: "30%",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
            fontFamily: "Montserrat, sans-serif",
            backgroundColor: "#2EB142",
            color: "#FFFFFF",
            fontSize: "20px",
            marginBottom: "15px",
            lineHeight: "2.6",
            padding: "0px",
          }}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
              fontFamily: "Montserrat, sans-serif",
              fontSize: "22px",
              fontWeight: "bold",
              paddingLeft: "45px",
            }}
          >
            {t(
              "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CHANGE_USER_STATUS.TITLE_MODAL"
            )}
          </span>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: "#ffff" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "40px",
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                fontFamily: "Montserrat, sans-serif",
                fontSize: "17px",
              }}
            >
              {t(
                "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CHANGE_USER_STATUS.DESCRIPTION"
              )}
            </span>
            <div style={{ display: "flex", gap: "40px" }}>
              <ThemeProvider theme={theme}>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  sx={{
                    color: "#000",
                    borderRadius: "8px",
                    border: "solid #89cf90",
                    width: 200,
                    "&:hover": { backgroundColor: "#8ae38a" },
                  }}
                  onClick={() => {
                    updateEnableUsers();
                    handleClose();
                  }}
                >
                  {t(
                    "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_CHANGE_USER_STATUS.BUTTON_TEXT"
                  )}
                </Button>
              </ThemeProvider>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <AlertComponent
        open={open}
        message={message}
        styleMessage={styleMessage}
      />
    </>
  );
};

import styled from 'styled-components';

export const WrapperIcon = styled.div`
  height: ${props => props.size ? `${props.size}px` : '60px'};
  width: ${props => props.size ? `${props.size}px` : '60px'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


export const WindowWrapper = styled.div`
  width: 75%;
  height: 90%;
  border: solid 3px #6d6969;
  border-bottom: none;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WindowLevel = styled.div`
  width: 100%;
  //height: ${props => props?.level >= 0 ? `${props?.level}%` : '100%'};
  height: ${props=> 100 - props.level}%;
  border-radius: 5px 5px 0px 0px;
  background: repeating-linear-gradient(
    180deg,
    #6d6969,
    #6d6969 2.5px,
    transparent 2.5px,
    transparent 5px
  );
  transition: height 0.3s ease;
`;


export const BaseCortain = styled.span`
  width: 100%;
  height: 5px;
  background-color: #6d6969;
`;

import React from "react";
import {
  DescriptionContainer,
  DescriptiontTitle,
  DescriptionText,
} from "../styles/DescriptionView.style";
import Pagination from "../components/Pagination";
import CancelInstallationButton from "../components/CancelInstallationButton";
import CancelInstallationModal from "../components/CancelInstallationModal";

const Finalization = ({ info }) => {
  return (
    <DescriptionContainer>
      <DescriptiontTitle>
        Finalizar
        <CancelInstallationButton />
      </DescriptiontTitle>
      <DescriptionText>{info.description}</DescriptionText>
      <Pagination
        labelNextButton={"Finalizar"}
        nextButtonColor={"success"}
        next={info.next}
      />
      <CancelInstallationModal />
    </DescriptionContainer>
  );
};

export default Finalization;

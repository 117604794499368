import React, { useState, useContext } from "react";
import AccordionsPrincipal from "./AccordionsPrincipal";
import info from "../../context/schema/permission.json";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../context/AuthProvider";
import {
  Dialog,
  AppBar,
  IconButton,
  Typography,
  TextField,
  Container,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { stringify } from "querystring";

const useStyles = makeStyles((theme) => ({
  containerIcons: {
    display: "flex",
    justifyContent: "space-between",
  },
  contentCreate: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },
  nameRole: {
    marginTop: "7em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nameField: {
    width: "100%",
  },
  center: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
}));

export const ModalRole = ({ refresh, data }) => {
  const {
    PermissionsConfig,
    roleId,
    permissionsRole,
    setPermissionsRoles,
    createOrEditRole,
    nameRol,
    setNameRol,
    createRole,
    setCreateRole,
    setEnabledOrDisabledRole,
    newPermissionsRole,
    setNewPermissionsRole,
  } = useContext(AuthContext);

  const [open, setOpen] = useState(false);

  const classes = useStyles();

  // Función para manejar el cambio en el campo de entrada del TexField
  const handleChange = (e) => {
    const name = e.target.value.trim();
    const regex = /[^a-zA-Z0-9]/g;
    const filteredValue = name.replace(regex, "");
    if (filteredValue !== name && name !== nameRol) {
    } else {
      setNameRol(name);
    }
  };

  const handleChangeClose = () => {
    setCreateRole(false);
    setPermissionsRoles(PermissionsConfig);
    setOpen(false);
  };

  const handleCloseAlert = () => {
    setTimeout(function () {
      setOpen(false);
    }, 3000);
  };

  const handleModalAlertChangeRoles = () => {
    setEnabledOrDisabledRole(true);
  };

  function Equeal() {
    const newRole = data?.find((i) => i.id === roleId);
    const nameNewRole = newRole?.name;

    if (nameNewRole !== nameRol) {
      return false;
    } else {
      const areEqual =
        JSON.stringify(permissionsRole) === JSON.stringify(newPermissionsRole);
      return areEqual;
    }
  }

  return (
    <>
      <Dialog fullScreen open={createRole} onClose={() => handleChangeClose()}>
        <AppBar sx={{ backgroundColor: "#3f9948", height: "4em" }}>
          <div className={classes.containerIcons}>
            <IconButton
              sx={{
                width: "60px",
                height: "60px",
                display: "flex",

                justifyContent: "center",
                color: "#fff",
              }}
            >
              <CloseOutlinedIcon
                onClick={() => handleChangeClose()}
                sx={{ width: "100%" }}
              />
            </IconButton>
            <Typography
              className={classes.textTitle}
              variant={"h4"}
              sx={{ marginTop: "0.3em" }}
            >
              {createOrEditRole}
            </Typography>

            <IconButton
              sx={{ width: "60px", height: "60px" }}
              disabled={Equeal()}
              onClick={handleModalAlertChangeRoles}
            >
              <SaveOutlinedIcon
                className={
                  Equeal() === true
                    ? "__color_button_disabled"
                    : "__color_button_save"
                }
                sx={{ width: "100%", height: "80%" }}
                onClick={handleModalAlertChangeRoles}
              />
            </IconButton>
          </div>
        </AppBar>

        <Container
          maxWidth="lg"
          className={classes.contentCreate}
          onClose={() => handleChangeClose()}
        >
          <div className={classes.nameRole}>
            <TextField
              className={classes.nameField}
              color="success"
              variant="outlined"
              label="Nombre rol"
              name="nameRol"
              id="nameRol"
              onChange={(e) => handleChange(e)}
              value={nameRol}
            ></TextField>
          </div>

          <div className={classes.position}>
            <h3>Accesos y permisos</h3>
          </div>
          {info.permissions?.map((item) => (
            <AccordionsPrincipal key={item.id} {...item} data={item} />
          ))}
        </Container>
      </Dialog>
    </>
  );
};

import { TableContainer, Table, TableHead, TableRow, TableBody, Paper, TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { roundTotalBy } from '../../../utils/helpers';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#4caf50e6',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 18,
  },
}))(TableCell);

const StyledTableCell2 = withStyles((theme) => ({
  head: {
    backgroundColor: '#4caf50e6',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    fontWeight: 'bold',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const TabConsumptionPhases = (data) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="_measurergeneral_container_tables_new">
        <TableContainer component={Paper}>
          <div className="hola">{t('VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY.TITLE')}</div>
          <Table arial-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">{t('VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY_PER_HOURS.HOUR')}</StyledTableCell>
                <StyledTableCell align="center">{t('VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY_PER_HOURS.INITIAL_MEASURE')}</StyledTableCell>
                <StyledTableCell align="center">{t('VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY_PER_HOURS.FINAL_MEASURE')}</StyledTableCell>
                <StyledTableCell align="center">{t('VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY_PER_HOURS.TOTAL')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.data?.getClampsMeasure?.totals.map((i) => (
                <StyledTableRow>
                  <StyledTableCell align="center">00:00</StyledTableCell>
                  <StyledTableCell align="center">{`${i?.accumulated.initial.toFixed(1)} kwh`}</StyledTableCell>
                  <StyledTableCell align="center">{`${i?.accumulated.final.toFixed(1)} kwh`}</StyledTableCell>
                  <StyledTableCell align="center">{`${i?.value.toFixed(1)} kwh`}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="__measurergeneral_grid_title_tables__new">
        <span>
          <hr></hr>
        </span>
        <span>{t('VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY_PER_HOURS.TITLE')}</span>
        <span>
          <hr />
        </span>
      </div>
      <div className="_measurergeneral_container_tables_new">
        <TableContainer component={Paper}>
          <div className="__report_measurer_by_room__new">{t('VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY_PER_HOURS.DESCRIPTION')}</div>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">{t('VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY_PER_HOURS.HOUR')}</StyledTableCell>
                <StyledTableCell align="center">{t('VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY_PER_HOURS.INITIAL_MEASURE')}</StyledTableCell>
                <StyledTableCell align="center">{t('VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY_PER_HOURS.FINAL_MEASURE')}</StyledTableCell>
                <StyledTableCell align="center">{t('VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY_PER_HOURS.ACCUMULATED')}</StyledTableCell>
                <StyledTableCell align="center">{t('VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_TOAL_SUMMARY_PER_HOURS.TOTAL')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {data?.data?.getClampsMeasure?.total_by?.map((i) => (
                <StyledTableRow>
                  <StyledTableCell align="center">{String(roundTotalBy(i.x))}</StyledTableCell>
                  <StyledTableCell align="center">{`${i?.initialValue} kwh`}</StyledTableCell>
                  <StyledTableCell align="center"> {`${i?.finalValue} kwh`}</StyledTableCell>
                  <StyledTableCell align="center"> {`${i?.dailyAccumulated} kwh`}</StyledTableCell>
                  <StyledTableCell align="center">{i?.dailyAccumulated} kwh</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
export default TabConsumptionPhases;

import { useQuery } from '@apollo/client';
import { GET_METTERS_PROJECTS_LOGGED_USER, GET_PROJECTS_BY_USERS } from "../../../controllers/usersControllers";


export const dataSelector = [
  { id: 1, name: 'Macromedidor' }, { id: 2, name: 'Flujo de Refrigerante Variable(VRF)' }
]
export const tableDataBuilder = (dataBuilder) => {
  const { Status, AddDeviceButton, ManagementButton } = dataBuilder
  return [
    { label: 'Nombre', dataKey: 'name' },
    {
      label: 'Estado del Proyecto',
      dataKey: 'enabled',
      component: Status,
    },
    { label: 'País', dataKey: 'country' },
    { label: 'Ciudad', dataKey: 'city' },
    { label: 'Agregar', component: AddDeviceButton },
    { label: 'Gestionar', component: ManagementButton },
  ]
}

export const enabledoRDesibaledAddButton = (data) => {
  if (data.length > 0) {
    return false
  } else {
    return true
  }
}

export const useCustomQueries = (variable) => {
  const id = parseInt(localStorage.getItem("np"));
  let query = null;
  let skipCondition = false;

  switch (variable) {
    case 1:
      query = GET_METTERS_PROJECTS_LOGGED_USER;
      skipCondition = false;
      break;

    default:
      skipCondition = true;
  }

  const { data, loading, error, refetch } = useQuery(GET_METTERS_PROJECTS_LOGGED_USER, {
    fetchPolicy: "network-only",
    variables: { id },
    skip: skipCondition,
  });

/*   console.log("dataCustomQuery: ", { data, loading, error, refetch })
 */
  return { data, loading, error, refetch };
};

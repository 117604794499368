import React from 'react'

export default function Construction() {
    return (
        <lottie-player
            style={{
            width: "70%",
            marginLeft: "25%",
            marginRight: "auto",
            }}
            className="_logo"
            src="https://assets7.lottiefiles.com/packages/lf20_8uHQ7s.json"
            background="transparent"
            speed="1"
            loop
            autoplay
        ></lottie-player>
    )
}

import React, { useEffect, useState, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import { Fragment } from "react";
import { AuthContext } from "../../../context/AuthProvider";
import { partnerCountries } from "../../../utils/dataCountries.json";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useForm } from "react-hook-form";
//query & mutation
import { useQuery, useMutation } from "@apollo/client";
import {
  UPDATE_USER,
  EDIT_USER,
  GET_USER_ROLE,
} from "../../../controllers/usersControllers";
//components
import Loading from "../../loading/Loading";
import { decryptData, encryptData } from "../../../utils/encryptDecrypt";

//multilanguage
import { useTranslation } from "react-i18next";

const password = process.env.REACT_APP_ENCRYPT_DECRYPT_KEY;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  forms: {
    display: "flex",
    justifyContent: "space-between",
  },
  tittle: {
    padding: "0px",
    marginLeft: "10px",
    marginTop: "-4rem",
    marginBottom: "10px",
  },
  input: {
    marginLeft: "10px",
    marginTop: "10px",
  },
  tittleTwo: {
    marginBottom: "0px",
    marginTop: "0px",
  },
  tittleThree: {
    color: "black",
    marginTop: "17px",
    marginLeft: "10px",
    marginBottom: "10px",
  },
  butreg: {
    width: "470px",
    backgroundColor: "#2EB142",
    display: "flex",
    top: "35px",
    left: "7px",
    height: "35px",
    color: "white",
    borderRadius: "7px",
    "&:hover": {
      backgroundColor: "#336535",
    },
  },
  formsUserLogin: {
    display: "flex",
    width: "100%",
  },
  error: {
    backgroundColor: "#FC2121",
    fontSize: "16px",
    borderRadius: "5px",
    zIndex: "2",
    position: "relative",
    with: "100px",
  },
  userlog: {
    width: "100ch",
    userlog: {
      width: "100ch",
    },
    formsUserLogin: {
      display: "flex",
      width: "100px",
    },
  },
  formProjects: {
    width: "20px",
    height: "60px",
  },
}));

const EditForm = ({ closeModal, iddUser, editing, setEditError }) => {
  const { t } = useTranslation();
  const { setOpenModalEditForm } = React.useContext(AuthContext);
  const [open, setOpen] = React.useState(false);
  const [noFilledTextfield, setNoFilledTextfield] = useState("error");
  const [colorTextFields, setColorTextFields] = useState("success");
  const [validateName, setValidateName] = useState(true);
  const [validateLastName, setValidateLastName] = useState(true);
  const [validatePhoneNumber, setValidatePhoneNumber] = useState(true);
  const [validateEmail, setValidateEmail] = useState(true);
  const [validateUser, setValidateUser] = useState(true);
  const [validateSelectedRole, setValidateSelectedRole] = useState(true);
  const [validateSelectedProject, setValidateSelectedProject] = useState(true);
  const [validateCountryCode, setValidateCountryCode] = useState(true);
  const { alertEdit, setAlertEdit, np } = useContext(AuthContext);

  const [edit] = useMutation(UPDATE_USER);
  const [datauser, setDatauser] = useState({
    userName: "",
    lastName: "",
    cellPhone: "",
    countryCode: "",
    email: "",
    userLogin: "",
    allRoles: "",
    ProjectId: [],
  });
  const [defaultValue, setDefaultValue] = useState(true);
  const { data, loading, error, refetch } = useQuery(EDIT_USER, {
    variables: {
      id: iddUser,
    },
    // onCompleted: (data) => {},
  });

  const editingProject = data?.getAllUsers[0]?.Project;

  const loggedProjects = data?.getAllProjects?.map(
    (element) => element
  );

  const hasId = (array, id) => array.some((element) => element.id === id);

  const mergedProjects = loggedProjects?.filter(
    (project) => !hasId(editingProject, project.id)
  );

  const result = editingProject?.concat(mergedProjects);

  const {
    register: userData,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    refetch();
  }, []);
  useEffect(() => {
    const fetchDefaultValue = async () => {
      try {
        const resultCellphone = await decryptData(
          data?.getAllUsers[0]?.cellPhone,
          password
        );
        const resultEmail = await decryptData(
          data?.getAllUsers[0]?.email,
          password
        );
        setDefaultValue(false);
        setTimeout(() => {
          setDatauser({
            ...datauser,
            userName: data?.getAllUsers[0]?.name,
            lastName: data?.getAllUsers[0]?.last_name,
            countryCode: data?.getAllUsers[0]?.countryCode,
            cellPhone:
              resultCellphone === "null" || resultCellphone === "String"
                ? ""
                : resultCellphone,
            email: resultEmail,
            userLogin: data?.getAllUsers[0]?.user,
            ProjectId: data?.getAllUsers[0]?.Project.map((i) => i.id),
            allRoles: data?.getAllUsers[0]?.Role?.id,
          });
        }, 50);
      } catch (error) {
        console.error("Error fetching default value:", error);
      }
    };
    fetchDefaultValue();
  }, [data]);

  const classes = useStyles();

  if (error) {
    return (
      <div>
        <lottie-player
          style={{
            width: "70%",
            marginLeft: "30%",
            marginRight: "auto",
          }}
          className="_logo"
          src="https://assets1.lottiefiles.com/private_files/lf30_tonsVH.json"
          background="transparent"
          speed="1"
          loop
          autoplay
        ></lottie-player>
      </div>
    );
  }
  if (loading) {
    return (
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </div>
    );
  }
  if (defaultValue) {
    return (
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </div>
    );
  }
  const handleRegName = (event) => {
    const fieldName = event.target.name;
    const value = event.target.value;
    const regex = /^[a-zA-Z ]+$/;
    if (value === "" || !regex.test(value)) {
      setValidateName(false);
      event.target.value = "";
    } else {
      setDatauser({
        ...datauser,
        userName: value,
      });
    }
    if (value.length < 3) {
      setValidateName(false);
    } else {
      setValidateName(true);
    }
  };

  const handleRegLastName = (event) => {
    const fieldName = event.target.name;
    const value = event.target.value;
    const regex = /^[a-zA-Z ]+$/;
    if (value === "" || !regex.test(value)) {
      setValidateLastName(false);
      event.target.value = "";
    } else {
      setDatauser({
        ...datauser,
        lastName: value,
      });
    }
    if (value.length < 3) {
      setValidateLastName(false);
    } else {
      setValidateLastName(true);
    }
  };

  const handleRegPhoneNumber = (event) => {
    const value = event.target.value;
    const regex = /[^0-9]/g;
    const numericValue = value.replace(regex, "");

    if (numericValue !== value) {
      setValidatePhoneNumber(false);
    } else {
      setDatauser({
        ...datauser,
        cellPhone: value,
      });
    }
    if (value.length < 9 || value.length > 11) {
      setValidatePhoneNumber(false);
    } else {
      setValidatePhoneNumber(true);
    }
  };

  const validateUpdatedEmail = (email) => {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return regex.test(email);
  };

  const handleEmailChange = (event) => {
    const fieldName = event.target.name;
    const email = event.target.value;
    const isValidEmail = validateUpdatedEmail(email);
    setDatauser({
      ...datauser,
      email: email,
    });
    setValidateEmail(isValidEmail);
  };

  const handleRegUserName = (event) => {
    const fieldName = event.target.name;
    const value = event.target.value;
    const regex = /^[a-zA-Z]+$/;
    if (value === "" || !regex.test(value)) {
      setValidateUser(false);
      event.target.value = "";
    } else {
      setDatauser({
        ...datauser,
        userLogin: value,
      });
    }
    if (value.length < 3) {
      setValidateUser(false);
    } else {
      setValidateUser(true);
    }
  };

  const handleRegRoleSelect = (event) => {
    const value = event.target.value;
    if (value === "") {
      setValidateSelectedRole(false);
    } else {
      setDatauser({
        ...datauser,
        allRoles: value,
      });
      setValidateSelectedRole(true);
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;

    setDatauser((prevState) => ({
      ...prevState,
      ProjectId: value,
    }));

    if (value.length === 0) {
      setValidateSelectedProject(false);
    } else {
      if (value.length > 0) {
        setValidateSelectedProject(true);
      }
    }
  };

  const handleselectCountry = (code) => {
    const aux = code.replace(/\D/g, "");

    if (aux === "" || aux === null) {
      setValidateCountryCode(false);
    } else {
      setDatauser({
        ...datauser,
        countryCode: aux,
      });
      setValidateCountryCode(true);
    }
  };

  const closeDialog = () => {
    setTimeout(() => {
      setOpenModalEditForm(false);
    }, 3000);
  };

  async function userEdit() {
    if (
      validateName === false ||
      validateLastName === false ||
      validatePhoneNumber === false ||
      validateEmail === false ||
      validateUser === false ||
      validateSelectedRole === false ||
      validateSelectedProject === false ||
      validateCountryCode === false
    ) {
    } else {
      try {
        edit({
          variables: {
            id: iddUser,
            name: datauser.userName,
            last_name: datauser.lastName,
            email: await encryptData(datauser.email, password),
            cellPhone: await encryptData(datauser.cellPhone, password),
            countryCode: datauser.countryCode,
            user: datauser.userLogin,
            ProjectId: datauser.ProjectId,
            RoleId: parseInt(datauser.allRoles),
          },
        })
          .then((response) => {
            if (response.data && response.data.error === "Validation error") {
              console.error("Error de validación:", response.data.message);
            } else {
              setAlertEdit(true);
              refetch();
            }
          })
          .catch((err) => {
            console.log("Error", error);
            setEditError(true);
          });
      } catch (err) {
        console.error("Excepción:", err);
      }
    }
  }

  return (
    <Fragment>
      <form
        className={classes.root}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(userEdit)}
      >
        <div className={classes.forms}>
          <TextField
            InputProps={{ sx: { borderRadius: "8px" } }}
            sx={{ width: "226.17px" }}
            placeholder="Nombre(s)"
            defaultValue={data?.getAllUsers[0]?.name}
            id="userName"
            name="name"
            type="text"
            variant="outlined"
            size="small"
            label={t(
              "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_USER_EDIT_MODAL.NAME"
            )}
            onChange={handleRegName}
            color={validateName === false ? noFilledTextfield : colorTextFields}
          />
          <TextField
            sx={{ width: "226.17px" }}
            InputProps={{ sx: { borderRadius: "8px" } }}
            placeholder="Apellido(s)"
            id="last_name"
            defaultValue={data?.getAllUsers[0]?.last_name}
            name="last_name"
            variant="outlined"
            size="small"
            label={t(
              "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_USER_EDIT_MODAL.LAST_NAME"
            )}
            onChange={handleRegLastName}
            color={
              validateLastName === false ? noFilledTextfield : colorTextFields
            }
          />
        </div>
        <div className={classes.forms}>
          <Autocomplete
            sx={{ width: "226.17px" }}
            id="country-select-demo"
            size="small"
            options={partnerCountries}
            onChange={(e) => handleselectCountry(e.target.textContent)}
            value={datauser.countryCode}
            autoHighlight
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{
                  borderRadius: "50px",
                  "& > img": { mr: 2, flexShrink: 0 },
                }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                ({option.name}) +{option.phone}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                sx={{ width: "226.17px" }}
                {...params}
                label={t(
                  "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_USER_EDIT_MODAL.COUNTRY"
                )}
                color={
                  validateCountryCode === false
                    ? noFilledTextfield
                    : colorTextFields
                }
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
              >
                {partnerCountries.map((countryInitial) => (
                  <MenuItem
                    key={countryInitial.code}
                    value={countryInitial.code}
                  >
                    {countryInitial.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <TextField
            sx={{ width: "226.17px" }}
            InputProps={{ sx: { borderRadius: "8px" } }}
            label={t(
              "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_USER_EDIT_MODAL.CELLPHONE"
            )}
            id="cellPhone"
            value={datauser.cellPhone}
            //defaultValue={datauser.cellPhone}
            variant="outlined"
            size="small"
            onChange={handleRegPhoneNumber}
            color={
              validatePhoneNumber === false
                ? noFilledTextfield
                : colorTextFields
            }
          />
        </div>
        <div className={classes.forms}>
          <TextField
            InputProps={{ sx: { borderRadius: "8px", width: "100%" } }}
            sx={{
              width: 470,
              maxWidth: "100%",
            }}
            className={classes.textField}
            label={t(
              "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_USER_EDIT_MODAL.EMAIL"
            )}
            type="email"
            id="email"
            value={datauser.email}
            name="email"
            variant="outlined"
            size="small"
            onChange={handleEmailChange}
            color={
              validateEmail === false ? noFilledTextfield : colorTextFields
            }
          />
        </div>
        <div>
          <h4 className={classes.tittleThree}>
            {t(
              "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_USER_EDIT_MODAL.DATA_ACCESS_LABEL"
            )}
          </h4>
        </div>
        <div className={classes.formsUserLogin}>
          <TextField
            sx={{ width: "226.17px", display: "flex", top: "24px" }}
            InputProps={{ sx: { borderRadius: "8px" } }}
            placeholder="User"
            id="user"
            name="user"
            defaultValue={data?.getAllUsers[0]?.user}
            variant="outlined"
            size="small"
            label={t(
              "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_USER_EDIT_MODAL.USER"
            )}
            onChange={handleRegUserName}
            className={classes.userlog}
            color={validateUser === false ? noFilledTextfield : colorTextFields}
          />
          {editing ? null : (
            <TextField
              sx={{ width: "226.17px", display: "flex", top: "24px" }}
              InputProps={{ sx: { borderRadius: "8px" } }}
              id="RoleId"
              name="RoleId"
              select={<multiple />}
              size="small"
              label={t(
                "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_USER_EDIT_MODAL.ROLE"
              )}
              defaultValue={data?.getAllUsers[0]?.Role?.id}
              onChange={(e) => handleRegRoleSelect(e)}
              variant="outlined"
              color={
                validateSelectedRole === false
                  ? noFilledTextfield
                  : colorTextFields
              }
            >
              {data?.getAllRoles.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </div>
        {editing ? null : (
          <div>
            <FormControl
              sx={{
                width: "470px",
                position: "relative",
                marginLeft: "0.5rem",
                marginTop: "3.5rem",
              }}
            >
              <InputLabel
                sx={{
                  marginTop: -1,
                }}
                id="ProjectId"
              >
                {t(
                  "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_USER_EDIT_MODAL.PROJECTS"
                )}
              </InputLabel>
              <Select
                defaultValue={data?.getAllUsers[0]?.Project?.map((i) => i.id)}
                multiple
                sx={{ borderRadius: "8px" }}
                id="ProjectId"
                name="ProjectId"
                input={<OutlinedInput label="Proyectos" />}
                onChange={(e) => handleChange(e)}
                size="small"
                variant="outlined"
                color={
                  validateSelectedProject === false
                    ? noFilledTextfield
                    : colorTextFields
                }
                MenuProps={{ sx: { width: "350px" } }}
              >
                {result.map((element) => (
                  <MenuItem key={element.id} value={element.id}>
                    {element.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        <div>
          <Button
            className={classes.butreg}
            disabled={
              validateName === false ||
              validateLastName === false ||
              validatePhoneNumber === false ||
              validateEmail === false ||
              validateUser === false ||
              validateSelectedRole === false ||
              validateSelectedProject === false ||
              validateCountryCode === false
            }
            type="submit"
          >
            <h3>
              {t(
                "VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_USER_TABLE.COMPONENT_USER_EDIT_MODAL.BUTTON_TEXT"
              )}
            </h3>
          </Button>
        </div>
      </form>
    </Fragment>
  );
};
export default EditForm;

import React from 'react';
import styled from 'styled-components';
import {SelectContainer, OptionContainer, OptionItem }from "./styledSelect"

export default function selectMonth({ options, value, onChange }) {
  return (
    <SelectContainer value={value} onChange={onChange}>
    {options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </SelectContainer>
  )
}

// Componente Select



import { useContext, useState, useEffect } from "react";
import { TableHead } from "@material-ui/core";
import { Table } from "@material-ui/core";
import {
  TableContainer,
  TableRow,
  TableBody,
  Paper,
  TableCell,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { GET_HISTORY_TWO } from "../../../controllers/measurersController";
import { secondClient } from "../../..";
import { AuthContext } from "../../../context/AuthProvider";
import DateMUI from "../../dateMUI/DateMUI";
import { Dates } from "../MeasurerDetails/helperDate";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#4caf50e6",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    width: "95%",
    marginLeft: "50px",
  },
  celda: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "10px",
  },
}));
const StyledTableCell2 = withStyles((theme) => ({
  head: {
    backgroundColor: "#4caf50e6",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    fontWeight: "bold",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const TableConsumptions = ({ serial, city, dataConsumptionTable }) => {
  const codigosCiudades = {
    Bogotá: "BG",
    Barranquilla: "BQ",
    default: "BQ",
  };
  Object.freeze(codigosCiudades);
  const {
    dateReport,
    dateMacrometers,
    dateMacrometersTwo,
    setSeeGraphic,
    dateMUI,
  } = useContext(AuthContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const { data } = useQuery(GET_HISTORY_TWO, {
    client: secondClient,
    variables: {
      serial: serial,
      location: codigosCiudades[city] || codigosCiudades.default,
      starTime: parseInt(Dates(dateMUI).start),
      endTime: parseInt(Dates(dateMUI).end),
    },
    fetchPolicy: "no-cache",
  });

  dataConsumptionTable(data);

  return (
    <>
      <Paper className={classes.container}>
        <TableContainer align="center">
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell aling="center">
                  {t(
                    "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_SUMMARY_MEASURMENT_TABLE.HOUR"
                  )}
                </StyledTableCell>
                <StyledTableCell aling="center">
                  {t(
                    "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_SUMMARY_MEASURMENT_TABLE.INITIAL_MEASURE"
                  )}
                </StyledTableCell>
                <StyledTableCell aling="center">
                  {t(
                    "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_SUMMARY_MEASURMENT_TABLE.FINAL_MEASURE"
                  )}
                </StyledTableCell>
                <StyledTableCell aling="center">
                  {t(
                    "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_SUMMARY_MEASURMENT_TABLE.TOTAL"
                  )}
                </StyledTableCell>
                <StyledTableCell aling="center">
                  {t(
                    "VIEW_MEASURERS.COMPONENT_MEASURER_CARDS.COMPONENT_MEASURER_FULL_MODAL.COMPONENT_NEW_MACROMETER_GRAPH.COMPONENT_SUMMARY_MEASURMENT_TABLE.ACCUMULATED"
                  )}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.getConsumptionHistory?.totalBy?.activeEnergy.map(
                (i, key) => (
                  <StyledTableRow>
                    <StyledTableCell>{i.x}</StyledTableCell>
                    <StyledTableCell aling="center">
                      {i?.initialValue} kWh
                    </StyledTableCell>
                    <StyledTableCell aling="center">
                      {i?.finalValue} kWh
                    </StyledTableCell>
                    <StyledTableCell aling="center">{i?.y} kWh</StyledTableCell>
                    <StyledTableCell aling="center">
                      {i?.accumulated} kWh
                    </StyledTableCell>
                  </StyledTableRow>
                )
              )}

              <StyledTableRow>
                <StyledTableCell2>Total</StyledTableCell2>
                <StyledTableCell2 component="th" scope="row"></StyledTableCell2>
                <StyledTableCell2 component="th" scope="row"></StyledTableCell2>
                <StyledTableCell2 component="th" scope="row">
                  {" "}
                  {
                    data?.getConsumptionHistory?.currentConsumption.activeEnergy
                  }{" "}
                  kWh
                </StyledTableCell2>

                <StyledTableCell2
                  component="th"
                  scope="row"
                  align="center"
                ></StyledTableCell2>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
export default TableConsumptions;

import React from "react";
import { ListItemIcon, ListItem, ListItemText, List } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import PeopleIcon from "@material-ui/icons/People";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { makeStyles } from "@material-ui/core/styles";

//multilanguage
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    margin: "0",
  },
  listItem: {
    padding: "0px",
    marginTop: "0%",
    marginLeft: "25px",
    marginBottom: "0%",
  },
});

export const ManagementProject = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <ListItem className={classes.listItem}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText
        primary={t("VIEW_PROJECT.COMPONENT_SIDEBAR.PROJECT_MANAGEMENT")}
      />
    </ListItem>
  );
};
export default ManagementProject;

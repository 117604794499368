import React, { useContext, useEffect } from "react";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/es";
import Grid from "@material-ui/core/Grid";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { AuthContext } from "../../context/AuthProvider";
import { addDays, format } from "date-fns";
// helper
import { futureDate } from "./helper";

const innerTheme = createTheme({
  palette: {
    primary: {
      main: green[500],
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

function DateMUI({data}) {
  const { dateMUI, setDateMUI } = useContext(AuthContext);
  const [actualDate, setActualDate] = React.useState(Date.parse(new Date()));

  useEffect(() => { 
    if (data === undefined) { 
      setDateMUI(Date.parse(new Date()));
     } 
    }, [data]);

  const Next = () => {
    const nextDate = addDays(dateMUI, 1);
    setDateMUI(nextDate);
  };

  const Prev = () => {
    const prevDate = addDays(dateMUI, -1);
    setDateMUI(prevDate);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
      <Grid container justify="center">
        <IconButton color="grey" onClick={Prev}>
          <ChevronLeft />
        </IconButton>
        <ThemeProvider theme={innerTheme}>
          <DatePicker
            okLabel="seleccionar"
            showTodayButton={true}
            todayLabel="hoy"
            id="date-picker-dialog"
            label="Fecha"
            format="eeee, dd MMMM"
            value={dateMUI}
            onChange={(event) => setDateMUI(Date.parse(event))}
            disableFuture
            color="primary"
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </ThemeProvider>
        {futureDate(dateMUI, actualDate) ? (
          <IconButton color="grey" disabled={true}>
            <ChevronRight />
          </IconButton>
        ) : (
          <IconButton color="grey" onClick={Next}>
            <ChevronRight />
          </IconButton>
        )}
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

export default DateMUI;

import React from "react";
import {
  DescriptionContainer,
  DescriptiontTitle,
  DescriptionText,
} from "../styles/DescriptionView.style";
import Pagination from "../components/Pagination";

const ErrorMacrometerInstallation = ({ info }) => {
  return (
    <DescriptionContainer>
      <DescriptiontTitle>No se encontraron medidores.</DescriptiontTitle>
      <DescriptionText>{info.description}</DescriptionText>
      <Pagination
        previousButtonColor={"success"}
        labelPreviousButton={"Finalizar"}
        prev={info.prev}
        next={info.next}
      />
    </DescriptionContainer>
  );
};

export default ErrorMacrometerInstallation;

import { useEffect, useState, useContext } from "react";
import { Devices } from "../../../../../constants/devices_types";
import Loading_devices from "../../../../../components/loading/Loading_devices";
import { AuthContext } from "../../../../../context/AuthProvider";
import { changeNamePluggin } from "../../../../../utils/helpers";

//Multilanguage
import { useTranslation } from "react-i18next";

const NewHSC = ({
  hsc,
  project,
  serial,
  dataSocket,
  sendMessage,
  controllerStatus,
}) => {
  const { t } = useTranslation();
  const { alias, variables, Category, name } = hsc;
  const { category, sub_category } = Category;
  const { item_id, status, type, AccionTexto, Modo, EstadoTexto, onLine } =
    variables;
  const { AccionTextoId, ModoId } = item_id;
  const [libre, setLibre] = useState(EstadoTexto === "Libre" ? true : false);
  const [accionTexto, setAccionTexto] = useState(AccionTexto);
  const [estadoTexto, setEstadoTexto] = useState(EstadoTexto);
  const [auto, setAuto] = useState(Modo === "Auto" ? true : false);
  const [manual, setManual] = useState(Modo === "Manual" ? true : false);
  const [apagado, setApagado] = useState(Modo === "Apagado" ? true : false);
  const { permissionsRoleUser } = useContext(AuthContext);

  if (dataSocket?.category === "generic_io") {
  }
  useEffect(() => {
    if (
      dataSocket.category === "generic_io" &&
      dataSocket.state !== AccionTexto &&
      dataSocket.event_name === "AccionTexto"
    ) {
      setAccionTexto(dataSocket.state);
      setLibre(dataSocket.state);
    }

    if (
      dataSocket.category === "generic_io" &&
      dataSocket.event_name === "Modo"
    ) {
      if (dataSocket.state === "Auto") {
        setAuto(dataSocket.State);
        setApagado(false);
        setManual(false);
      }
      if (dataSocket.state === "Apagado") {
        setApagado(dataSocket.state);
        setManual(false);
        setAuto(false);
      }
      if (dataSocket.state === "Manual") {
        setManual(dataSocket.state);
        setAuto(false);
        setApagado(false);
      }
    }
  }, [dataSocket]);

  const handleChangesOptions = (e) => {
    setAccionTexto(dataSocket.state);
    setEstadoTexto(dataSocket.state);
    sendMessage({
      serial,
      channel: serial,
      id: AccionTextoId,
      eventType: "plugin_mode",
      value: String(e.target.textContent),
    });
  };

  const handleChangesMode = (e) => {
    if (e.target.textContent === "Auto") {
      setAuto(true);
      setApagado(false);
      setManual(false);
    }
    if (e.target.textContent === "Apagar" ?? "Apagado") {
      setApagado(true);
      setManual(false);
      setAuto(false);
    }
    if (e.target.textContent === "Manual") {
      setManual(true);
      setAuto(false);
      setApagado(false);
    }
    sendMessage({
      serial,
      channel: serial,
      id: ModoId,
      eventType: "plugin_mode",
      value: String(e.target.textContent),
    });
  };

  const translatingHeaderCardPlugin = (alias) => {
    if (alias === "Plugin ocupacion") {
      return t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.PLUGIN_OCCUPATION");
    } else if (alias === "Aula inteligente") {
      return t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.PLUGIN_SMART");
    } else {
      return null;
    }
  };

  const translatingAccionTexto = (accionTexto) => {
    if (accionTexto === "Libre") {
      return t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.AVAILABLE");
    } else if (accionTexto === "Scan") {
      return t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.SCAN");
    } else if (accionTexto === "Puerta Abierta") {
      return t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.OPEN_DOOR");
    } else if (accionTexto === "Ocupado") {
      return t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.BUSY");
    }
  };

  return (
    <>
      <div className="_hsc_container">
        <div
          className={`_hsc_container_title_status ${
            onLine && controllerStatus ? null : "_offline_hsc"
          }`}
        >
          <div class="_hsc__title">
            <span>{translatingHeaderCardPlugin(alias)}</span>
          </div>
          <div className="_hsc_status">
            <span
              className={`${
                onLine && controllerStatus
                  ? "__icon_wifi_hsc"
                  : "__icon_wifi_offline_hsc"
              }`}
            >
              <i class="fas fa-wifi"></i>
            </span>
          </div>
        </div>

        {/* status home */}
        <div
          className={`_hsc_ocupation ${
            variables.online ? "_loading_hsc_other" : false
          }`}
        >
          <div className="_hsc_home_img">
            <span>
              <img
                src={variables.online ? Devices.DESOCUPADA : Devices.OCUPADA}
                alt="home"
                width="70"
                height="70"
              />
            </span>
          </div>
          <div className="_hsc_home_buttons">
            <div
              className="_free_container"
              onClick={
                permissionsRoleUser.permissionsRole?.AreaDispMan
                  ? (e) => {
                      handleChangesOptions(e);
                    }
                  : false
              }
            >
              <div className={accionTexto === "Libre" ? "__free_bg" : "__free"}>
                <span
                  onClick={
                    permissionsRoleUser.permissionsRole?.AreaDispMan
                      ? (e) => {
                          handleChangesOptions(e);
                        }
                      : false
                  }
                >
                  {t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.AVAILABLE")}
                </span>
              </div>
            </div>

            <div className="_busy_container">
              <div className={libre === "Ocupado" ? "__busy_bg" : "__busy"}>
                {t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.BUSY")}
              </div>
            </div>
          </div>
        </div>

        <div className="__loading_hsc">
          {variables.online ? <Loading_devices /> : false}
        </div>

        {/* status system */}
        <div
          className={`_hsc_status_system ${"" ? "_loading_hsc_other" : false}`}
        >
          <div className="_hsc_status_system_title">
            <span>
              {t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.SYSTEM_STATUS")}
            </span>
          </div>
          <>
            <div
              className={
                accionTexto === "Libre"
                  ? "__status_system_free"
                  : "__status_system_busy"
              }
            >
              <span className="__status_word">
                {translatingAccionTexto(accionTexto)}
              </span>
            </div>
          </>
        </div>

        <div
          className={`_hsc_mode ${
            variables.online ? "_loading_hsc_other" : false
          }`}
        >
          <div className="_hsc_mode_title">
            <span>{t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.MODE")}</span>
          </div>
          <div className="__mode_container">
            <span
              className={
                manual ? "__mode_item_manual_bg" : "__mode_item_manual"
              }
              onClick={
                permissionsRoleUser.permissionsRole?.AreaDispMan
                  ? (e) => {
                      handleChangesMode(e);
                    }
                  : false
              }
            >
              {t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.MANUAL")}
            </span>
            <span
              className={apagado ? "__mode_item_off_bg" : "__mode_item_off"}
              onClick={
                permissionsRoleUser.permissionsRole?.AreaDispMan
                  ? (e) => {
                      handleChangesMode(e);
                    }
                  : false
              }
            >
              {t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.OFF")}
              {/* {changeNamePluggin(project.id)} */}
            </span>
            <span
              className={auto ? "__mode_item_auto_bg" : "__mode_item_auto"}
              onClick={
                permissionsRoleUser.permissionsRole?.AreaDispMan
                  ? (e) => {
                      handleChangesMode(e);
                    }
                  : false
              }
            >
              {t("VIEW_AREA.COMPONENT_OCCUPATION_PLUGIN_CARD.AUTO")}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewHSC;

import React from 'react';
import { Tooltip } from '@mui/material';

//componets
import { WrapperSwitch, StyledSwitch } from './style/ToggleSwitch.style';
import { ModalAlertChangeProjectStatus } from '../../views/private/projectsManagement/ChangeProjectStatusModal';

//multilanguage
import { useTranslation } from 'react-i18next';

function ToggleSwitch({ projects, currentIdProject, currentProjectStatus, statusRefetch }) {
  const { t } = useTranslation();
  const [openChangeProjectStatusFromToggle, setOpenChangeProjectStatusFromToggle] = React.useState(false);

  const [projectIdd, setProjectIdd] = React.useState('');
  const [checkedToggle, setCheckedToggle] = React.useState(currentProjectStatus);
  

  const handleOpenChangeProjectStatusModal = () => {
    setProjectIdd(currentIdProject);
    setOpenChangeProjectStatusFromToggle(true);
  };
  return (
    <WrapperSwitch>
      <StyledSwitch className="StyledSwitch">
        <Tooltip title={currentProjectStatus === true ? t('VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_PROJECT_TABLE.PROJECT_TOGGLE_TOOLTIP_DISABLE') : t('VIEW_PROJECT.COMPONENT_SIDEBAR.VIEW_PROJECT_TABLE.PROJECT_TOGGLE_TOOLTIP_ENABLE')}>
          <label className="switch">
            <input
              type="checkbox"
              onChange={handleOpenChangeProjectStatusModal}
              checked={currentProjectStatus === true}
            />
            <div className="slider round"></div>
          </label>
        </Tooltip>
      </StyledSwitch>
      <ModalAlertChangeProjectStatus
        statusRefetch={statusRefetch}
        projects={projects}
        openChangeProjectStatusFromToggle={openChangeProjectStatusFromToggle}
        setOpenChangeProjectStatusFromToggle={setOpenChangeProjectStatusFromToggle}
        checkedToggle={checkedToggle}
        setCheckedToggle={setCheckedToggle}
        projectIdd={projectIdd}
      />
    </WrapperSwitch>
  );
}
export default ToggleSwitch;
import * as React from "react";
import { pink } from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import CleaningServicesOutlinedIcon from "@mui/icons-material/CleaningServicesOutlined";
import { makeStyles } from "@mui/styles";
//import ModalPlugin from "../PluginBathroom/ModalPlugin"


const useStyles = makeStyles({
  avatar: {
    height: "24px",
    width: "24px",
    animation: "$blink 1.5s linear infinite",
    backgroundColor: pink[500],
    cursor: "pointer",
    boxShadow: "0 0 10px 0 rgba(255, 0, 0, 0.4)",
  },
  "@keyframes blink": {
    "0%": {
      boxShadow: "0 0 10px 0 rgba(255, 0, 0, 0.2)",
      backgroundColor: "rgba(255, 0, 0, 0.2)",
    },
    "50%": {
      boxShadow: "0 0 10px 0 rgba(255, 0, 0, 0.8)",
      backgroundColor: "rgba(255, 0, 0, 0.8)",
    },
    "100%": {
      boxShadow: "0 0 10px 0 rgba(255, 0, 0, 0.2)",
      backgroundColor: "rgba(255, 0, 0, 0.2)",
    },
  },
  appBarDeviceFails: {
    backgroundColor: "#FFAF0E",
    position: "relative",
  },
  appBar: {
    backgroundColor: "#3f9948",
    position: "relative",
  },
});

export default function IconAvatars() {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Stack direction="row" spacing={2}>
        <Avatar
          onClick={handleOpen}
          sizes="small"
          className={classes.avatar}
          sx={{ width: "23px", height: "23px", marginTop: "2px" }}
        >
          <CleaningServicesOutlinedIcon sx={{ fontSize: "15px" }} />
        </Avatar>
      </Stack>
{/*      < ModalPlugin openback={open} />
 */}    </React.Fragment>
  );
}

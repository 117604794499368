import React from "react";
import logo from "../../assets/logo_lr.svg";
import { Link } from "react-router-dom";
import { ListItemIcon, ListItem, ListItemText, List } from "@material-ui/core";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
//components
import ListItems from "../../components/listItems/listItems";
import DirectAccess from "../access/DirectAccess";
import ListItemAdmin from "../listItemsAdmin/listItemsAdmin";
import Settings from "../../components/settings/Settings";
import { ListItemsAreas } from "../listItems/ListItemsAreas";

//multilanguage
import { useTranslation } from "react-i18next";

const SideBar = ({ handleOpen }) => {
  const { t } = useTranslation();

  return (
    <div className="sidebar_container">
      <div className="sidebar_logo">
        <img className="sidebar_img_logo" src={logo} alt="Horus" />
      </div>
      <div>
        {window.location.pathname !== "/app/projects" ? (
          <DirectAccess />
        ) : (
          <></>
        )}
      </div>
      <div className="sidebar_menu">
        <span className="__menu">
          {t("VIEW_PROJECT.COMPONENT_SIDEBAR.MENU")}
        </span>
      </div>

      <ListItemsAreas {...{ handleOpen }} />

      <Settings />
    </div>
  );
};
export default SideBar;

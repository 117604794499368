import React from "react";
import PropTypes from "prop-types";
import {
  WrapperIcon,
  WindowWrapper,
  WindowLevel,
  BaseCortain,
} from "./IconCortain.style";

const IconCortain = (props) => {
  return (
    <WrapperIcon size={props.size}>
      <WindowWrapper>
        <WindowLevel level={props.level} />
        {/* <PointWindow /> */}
      </WindowWrapper>
      <BaseCortain />
    </WrapperIcon>
  );
};

IconCortain.propTypes = {
  level: PropTypes.number.isRequired,
  size: PropTypes.string,
};

export default IconCortain;

import React from "react";
import { ListItemIcon, ListItem, ListItemText, List } from "@material-ui/core";
import SettingsRemoteOutlinedIcon from '@mui/icons-material/SettingsRemoteOutlined';
import { makeStyles } from "@material-ui/core/styles";

//multilanguage
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    margin: "0",
  },
  listItem: {
    padding: "0px",
    marginTop: "0%",
    marginLeft: "25px",
    marginBottom: "0%",
  },
});

export const DeviceManagement = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <ListItem className={classes.listItem}>
      <ListItemIcon>
        <SettingsRemoteOutlinedIcon />
      </ListItemIcon>
      <ListItemText primary={t("VIEW_PROJECT.COMPONENT_SIDEBAR.DEVICE_MANAGEMENT")} />
    </ListItem>
  );
};

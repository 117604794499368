import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/AuthProvider";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@mui/material/Accordion";
import PrincipalConfigAndGestionRol from "./PrincipalConfigAndGestionRol";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import { FormControlLabel } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  nameRole: {
    display: "flex",
    width: "100%",
    marginBottom: "10px",
  },
  compPaper: {
    display: "flex",
    justifyContent: "space-between",
    margin: "2em",
    width: "100%",
  },
  compPer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  accord: {
    elevation: 5,
    width: "90%",
    borderRadius: ".4em",
  },
  detailsRown: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cards: {
    display: "flex",
    backgroundColor: "#EBEBEB",
    padding: ".5em",
    borderRadius: ".5em",
    margin: "1em",
  },
  title: {
    display: "flex",
    fontWeight: 700,
    alignItems: "center",
  },
}));

const AccordionsPrincipal = ({
  components,
  name,
  label,
  action,
  component,
  data,
}) => {
  const classes = useStyles();
  const [checkedArea, setCheckedArea] = React.useState(false);
  const [expanded, setExpanded] = React.useState(true);
  const { permissionsRole, setPermissionsRoles, createRole, editRole } =
    useContext(AuthContext);
  const handleChange = () => {
    setExpanded(!expanded);
  };

  const handleChangePrueba = (e) => {
    const value = e.target.checked;
    setPermissionsRoles({
      ...permissionsRole,
      [name]: value,
    });
  };

  return (
    <>
      <div className={classes.compPaper}>
        <div className={classes.compPer}>
          <Accordion
            onChange={handleChange}
            className={classes.accord}
            expanded={true}
          >
            <AccordionSummary
              color="succes"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{
                backgroundColor: "rgba(31, 137, 229, 0.2)",
                height: "2em",
                marginBottom: "0.5em",
                borderStartEndRadius: "0.5em",
                borderStartStartRadius: "0.5em",
              }}
            >
              <div className={classes.title}>
                <Checkbox
                  onChange={handleChangePrueba}
                  name={name}
                  checked={permissionsRole[data.name]}
                ></Checkbox>
                {label.toUpperCase()}
              </div>
            </AccordionSummary>

            <AccordionDetails>
              {permissionsRole[name] ? (
                component.map((i, key) => (
                  <div className={classes.cards}>
                    <PrincipalConfigAndGestionRol key={key} {...i} state />
                  </div>
                ))
              ) : (
                <></>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default AccordionsPrincipal;

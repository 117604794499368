import React, { useContext } from 'react';
import { ListItemIcon, ListItem, ListItemText } from '@material-ui/core';
import { AuthContext } from '../../context/AuthProvider';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { makeStyles } from '@material-ui/core/styles';

//multilanguage
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    margin: '0',
  },
  listItem: {
    padding: '0px',
    marginTop: '0%',
    marginLeft: '25px',
    marginBottom: '0%',
  },
});

export const Managements = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { permissionsRoleUser } = useContext(AuthContext);
  return (
    <ListItem className={classes.listItem}>
      <ListItemIcon>
        <ManageAccountsIcon />
      </ListItemIcon>
      <ListItemText primary={t("VIEW_PROJECT.COMPONENT_SIDEBAR.USER_MANAGEMENT")} />
    </ListItem>
  );
};
export default Managements;

import React from "react";

export default function Loading_devices() {
  return (
    <>
        <lottie-player
            style={{
            width: "75%",
            }}
            src="https://assets9.lottiefiles.com/packages/lf20_8bvvWE.json"
            background="transparent"
            speed="1"
            loop
            autoplay
        ></lottie-player>
    </>
  );
}
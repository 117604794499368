import { useEffect, useState, useContext } from "react";
import fanCoolImage from "../../../../../../assets/fant.png";
import { AuthContext } from "../../../../../../context/AuthProvider";
import { getEventEmitter } from "../../../helperDevices";
import Loading_devices from "../../../../../../components/loading/Loading_devices";

const Vrf = ({ vrf, sendMessage, serial, refetch }) => {
  const { alias, variables } = vrf;
  const { setSuccessMessage, setErrorMessage, permissionsRoleUser } =
    useContext(AuthContext);
  const [setPoint, setSetpoint] = useState(
    variables?.items
      ?.find((i) => i.name === "thermostat_setpoint_cooling")
      .status?.value.toFixed(0)
  );
  const [tem, setTem] = useState(
    variables?.items?.find((e) => e?.name === "temp")?.status?.value?.toFixed(0)
  );
  const [cool, setCool] = useState(
    variables?.items?.find((e) =>
      (e?.name === "thermostat_status")?.status === "ON" ? true : false
    )
  );
  const [offCool, setOffCool] = useState(
    variables?.items?.find((e) =>
      (e?.name === "thermostat_status")?.status === "OFF" ? true : false
    )
  );
  const [fanCoolStatus, setFanCoolStatus] = useState(
    variables?.items.find((i) => i.name === "thermostat_fan_mode")?.status
  );
  const fanCool = ["LOW", "MEDIUM", "HIGH", "AUTO"];
  const [count, setCount] = useState(0);
  const [heat, setHeat] = useState(
    variables?.items?.find((e) =>
      (e?.name === "thermostat_status")?.status === "HeatOn" ? true : false
    )
  );
  const [auto, setAuto] = useState(
    variables?.items?.find((e) =>
      (e?.name === "thermostat_status")?.status === "AutoChangeOver"
        ? true
        : false
    )
  );
  const idModeUpdated = variables?.items?.find(
    (i) => i.name === "thermostat_mode"
  );
  const idFandUpdated = variables?.items?.find(
    (i) => i.name === "thermostat_setpoint_cooling"
  );
  const idTermosthatFanMode = variables?.items?.find(
    (i) => i.name === "thermostat_fan_mode"
  );
  const [load, setLoad] = useState(false);
  const [timer, setTimer] = useState(null);
  const minTemperature = 15;
  const maxTemperature = 30;
  const delay = 1500;

  useEffect(() => {
    const eventEmitter = getEventEmitter();
    const handleUpdateUnit = (unitId, message) => {
      if (variables?.items[0]?.item_id === unitId) {
        setTem(message.ambientTemperature.toFixed(0));
        setSetpoint(message.setpoint);
        setFanCoolStatus(message.fan);

        if (message.operationStatus === "OFF") {
          setOffCool(true);
          setCool(false);
          setHeat(false);
          setAuto(false);
        } else {
          setOffCool(false);
          setCool(message.operationMode === "ON");
          setHeat(message.operationMode === "HeatOn");
          setAuto(message.operationMode === "AutoChangeOver");
        }
      }
    };

    eventEmitter.on("updateUnit", handleUpdateUnit);

    return () => {
      eventEmitter.off("updateUnit", handleUpdateUnit); // Limpia el listener cuando el componente se desmonte
    };
  }, [variables?.items[0]?.item_id]);

  const on = async () => {
    setCool(true);
    setOffCool(false);
    setHeat(false);
    setAuto(false);
    const message = JSON.stringify({
      serial,
      id: idModeUpdated.item_id,
      eventType: "mode_updated",
      value: "Cool",
      projectId: parseInt(localStorage.getItem("id")),
    });
    setLoad(true);
    setTimeout(() => {
      sendMessage.send(message);
      setLoad(false);
    }, delay);
  };
  const heatMode = async () => {
    setCool(false);
    setOffCool(false);
    setHeat(true);
    setAuto(false);
    const message = JSON.stringify({
      serial,
      id: idModeUpdated.item_id,
      eventType: "mode_updated",
      value: "Heat",
      projectId: parseInt(localStorage.getItem("id")),
    });
    setLoad(true);
    setTimeout(() => {
      sendMessage.send(message);
      setLoad(false);
    }, delay);
  };
  const autoMode = async () => {
    setCool(false);
    setOffCool(false);
    setHeat(false);
    setAuto(true);
    const message = JSON.stringify({
      serial,
      id: idModeUpdated.item_id,
      eventType: "mode_updated",
      value: "Auto",
      projectId: parseInt(localStorage.getItem("id")),
    });
    setLoad(true);
    setTimeout(() => {
      sendMessage.send(message);
      setLoad(false);
    }, delay);
  };
  const off = async () => {
    setCool(false);
    setOffCool(true);
    setHeat(false);
    setAuto(false);
    const message = JSON.stringify({
      serial,
      id: idModeUpdated.item_id,
      eventType: "mode_updated",
      value: "Off",
      projectId: parseInt(localStorage.getItem("id")),
    });
    setLoad(true);
    setTimeout(() => {
      sendMessage.send(message);
      setLoad(false);
    }, delay);
  };
  const upSetpoint = () => {
    setSetpoint(parseInt(setPoint) + 1);
    const message = JSON.stringify({
      serial,
      id: idFandUpdated.item_id,
      eventType: "setpoint_updated",
      value: parseInt(setPoint) + 1,
      projectId: parseInt(localStorage.getItem("id")),
    });
    setLoad(true);
    setTimeout(() => {
      sendMessage.send(message);
      setLoad(false);
    }, delay);
  };

  const downSetPoint = () => {
    setSetpoint(parseInt(setPoint) - 1);
    const message = JSON.stringify({
      serial,
      id: idFandUpdated.item_id,
      eventType: "setpoint_updated",
      value: parseInt(setPoint) - 1,
      projectId: parseInt(localStorage.getItem("id")),
    });
    setLoad(true);
    setTimeout(() => {
      sendMessage.send(message);
      setLoad(false);
    }, delay);
  };

  const fanModeChange = () => {
    setCount(count + 1);
    if (count > 3) {
      setCount(0);
    }
    const prueba = fanCool[count];
    setFanCoolStatus(prueba);
    const message = JSON.stringify({
      serial,
      id: idTermosthatFanMode.item_id,
      eventType: "fan_updated",
      value: prueba,
      projectId: parseInt(localStorage.getItem("id")),
    });

    setTimeout(() => {
      sendMessage.send(message);
      setLoad(true);
    }, delay);
    setTimeout(() => {
      setLoad(false);
    }, 3000);
  };

  return (
    <>
      <div className={`_weather_container_new`}>
        <div className={`_weather_container_title_status_new `}>
          <div className="_weather__title_new">
            <span>{alias}</span>
          </div>

          <div className="_weather__status_new">
            <span
              className={
                variables.onLine
                  ? `${"__icon_wifi_online"}`
                  : `${"__icon_wifi_offline"}`
              }
            >
              <i class="fas fa-wifi"></i>
            </span>
          </div>
        </div>

        <div
          className={`_weather_container_temperature_new ${
            load ? "__loading_weather_other_new" : false
          }`}
        >
          <div
            className={`${
              cool || heat || fanCoolStatus
                ? ""
                : "__weather_temperatur_off_new"
            } _weather_temperature_new`}
          >
            <span
              className={`${
                cool || heat || fanCoolStatus ? "" : "__temperatur_off_new"
              } __temperature_new`}
            >
              {setPoint}
              <span
                style={{
                  display: "flex",
                  fontSize: "35px",
                  alignItems: "start",
                  marginTop: "10px",
                }}
              >
                °c
              </span>
            </span>
            <div className="_indoor_weather_new">
              <span className="__indoor_new">
                <span
                  style={{
                    display: "flex",
                    fontSize: "20px",
                    marginLeft: "20px",
                  }}
                >
                  {tem}°c
                </span>
              </span>
            </div>
          </div>

          <div className="__container_fan_new" id={idTermosthatFanMode.item_id}>
            <div className="fan_and_velocity_container">
              <div className="__color_new">
                <img
                  className={
                    fanCoolStatus === "high"
                      ? "prueba"
                      : fanCoolStatus === "medium"
                      ? "prueba2"
                      : "prueba3"
                  }
                  src={fanCoolImage}
                  onClick={
                    permissionsRoleUser?.permissionsRole?.AreaDispMan
                      ? fanModeChange
                      : false
                  }
                  id={idTermosthatFanMode.item_id}
                  alt="fan"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {fanCoolStatus === "HIGH" ? (
                  <>
                    <div
                      className={`icon_bar ${
                        fanCoolStatus === "LOW" ||
                        fanCoolStatus === "MEDIUM" ||
                        fanCoolStatus === "HIGH"
                          ? "low"
                          : "bar1"
                      }`}
                    >
                      {" "}
                    </div>
                    <div
                      className={`icon_bar ${
                        fanCoolStatus === "MEDIUM" || fanCoolStatus === "HIGH"
                          ? "medium"
                          : "bar2"
                      }`}
                    ></div>
                    <div
                      className={`icon_bar ${
                        fanCoolStatus === "HIGH" ? "high" : "bar3"
                      }`}
                    ></div>
                  </>
                ) : fanCoolStatus === "MEDIUM" ? (
                  <>
                    <div
                      className={`icon_bar ${
                        fanCoolStatus === "LOW" ||
                        fanCoolStatus === "MEDIUM" ||
                        fanCoolStatus === "HIGH"
                          ? "low"
                          : "bar1"
                      }`}
                    ></div>
                    <div
                      className={`icon_bar ${
                        fanCoolStatus === "MEDIUM" || fanCoolStatus === "HIGH"
                          ? "medium"
                          : "bar2"
                      }`}
                    ></div>
                    <div
                      className={`icon_bar ${
                        fanCoolStatus === "HIGH" ? "high" : "bar3"
                      }`}
                    ></div>
                  </>
                ) : fanCoolStatus === "LOW" ? (
                  <>
                    <div
                      className={`icon_bar ${
                        fanCoolStatus === "LOW" ? "low" : "bar1"
                      }`}
                    ></div>
                    <div
                      className={`icon_bar ${
                        fanCoolStatus === "MEDIUM" || fanCoolStatus === "HIGH"
                          ? "medium"
                          : "bar2"
                      }`}
                    ></div>
                    <div
                      className={`icon_bar ${
                        fanCoolStatus === "HIGH" ? "high" : "bar3"
                      }`}
                    ></div>
                  </>
                ) : fanCoolStatus === "AUTO" ? (
                  <>
                    <div
                      className={`icon_bar ${
                        fanCoolStatus === "AUTO" ? "low_now" : "bar1"
                      }`}
                    ></div>
                    <div
                      className={`icon_bar ${
                        fanCoolStatus === "AUTO" ||
                        fanCoolStatus === "MEDIUM" ||
                        fanCoolStatus === "HIGH"
                          ? "medium_now"
                          : "bar2"
                      }`}
                    ></div>
                    <div
                      className={`icon_bar ${
                        fanCoolStatus === "AUTO" ? "high_now" : "bar3"
                      }`}
                    ></div>
                  </>
                ) : null}
              </div>
            </div>

            <div
              className={`_indoor_weather_new ${
                load ? "__loading_weather_other_new" : false
              }`}
            >
              <span className="__indoor_new_name">{fanCoolStatus}</span>
            </div>
          </div>
        </div>

        <div className="__loading_weather">
          {load ? <Loading_devices /> : false}
        </div>

        {/* status */}
        <div
          className={`_weather_container_mode_new ${
            load ? "__loading_weather_other_new" : false
          }`}
        >
          <div className="_weather_container_up_down_new">
            <div
              id={idFandUpdated.item_id}
              className="_weather_btn_up_down_new"
              onClick={(e) =>
                permissionsRoleUser?.permissionsRole?.AreaDispMan
                  ? upSetpoint()
                  : false
              }
            >
              <span id={idFandUpdated.item_id} className="__btn_up_new">
                <i id={idFandUpdated.item_id} class="fas fa-chevron-up"></i>
              </span>
            </div>
            <div
              id={idFandUpdated.item_id}
              className="_weather_btn_up_down_new"
              onClick={(e) =>
                permissionsRoleUser?.permissionsRole?.AreaDispMan
                  ? downSetPoint()
                  : false
              }
            >
              <span id={idFandUpdated.item_id} className="__btn_down_new">
                <i id={idFandUpdated.item_id} class="fas fa-chevron-down"></i>
              </span>
            </div>
          </div>
          <div className="container_options_new">
            <div
              id={idModeUpdated.item_id}
              className={
                cool ? "_weather_mode_cool_bg_new" : "_weather_mode_cool_new"
              }
              onClick={() =>
                permissionsRoleUser?.permissionsRole?.AreaDispMan ? on() : false
              }
            >
              COOL
            </div>
            <div
              id={idModeUpdated.item_id}
              className={
                heat ? "_weather_mode_heat_bg_new" : "_weather_mode_heat_new"
              }
              onClick={() =>
                permissionsRoleUser?.permissionsRole?.AreaDispMan
                  ? heatMode()
                  : false
              }
            >
              HEAT
            </div>
            <div
              id={idModeUpdated.item_id}
              className={
                auto ? "_weather_mode_auto_bg_new" : "_weather_mode_auto_new"
              }
              onClick={() =>
                permissionsRoleUser?.permissionsRole?.AreaDispMan
                  ? autoMode()
                  : false
              }
            >
              AUTO
            </div>
            <div
              id={idModeUpdated.item_id}
              className={
                offCool ? "_weather_mode_off_new" : "_weather_mode_off_bg_new"
              }
              onClick={() =>
                permissionsRoleUser?.permissionsRole?.AreaDispMan
                  ? off()
                  : false
              }
            >
              OFF
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Vrf;

import React, { useState, useEffect, useContext, useRef } from 'react';
import SemiCircleProgressBar from 'react-progressbar-semicircle';
import { Dialog, AppBar, Typography, Slide, IconButton, Toolbar, makeStyles } from '@material-ui/core';
import { calc_percent_watts, cacl_color_graph_measurer } from '../../utils/helpers';
import CloseIcon from '@material-ui/icons/Close';
import { AuthContext } from '../../context/AuthProvider';
import { addDays } from 'date-fns';
import MeasurerDetails from '../../components/highcharts/MeasurerDetails';
//query
import { useQuery } from '@apollo/client';
import { GET_METTER_AND_METTER_HISTORIES } from '../../controllers/measurersController';
const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#3f9948',
    position: 'fixed',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

//Meters-{id proyecto}

const Measurer = ({ serial, name, timeZone, alias }) => {
  
  const { data, loading } = useQuery(GET_METTER_AND_METTER_HISTORIES, {
    variables: { serial },
    fetchPolicy: 'no-cache',
  });
  const { setDateReport, setactiveNextBack, setSerial, setZoneP, setDateMacrometers, setDateMacrometersTwo, setValueDatepicker, permissionsRoleUser } = useContext(AuthContext);
  const [openMeasurer, setOpenMeasurer] = useState(false);
  const [openNewMeasurer, setOpenNewMeasurer] = useState(false);
  const [totalPhases, setTotalphases] = useState(false);
  const classes = useStyles();
  const childRef = useRef();
  const handleClose = () => {
    setOpenMeasurer(false);
    setactiveNextBack(false);
    setDateMacrometers(0);
    setDateMacrometersTwo(0);
    setValueDatepicker(Date.parse(new Date()));
  };
 

  const percentageValue = (value) => {
    if (value.length < 2) {
      return (parseInt(value) / 10) * 100;
    } else if (value.length < 3) {
      return (parseInt(value) / 100) * 100;
    } else if (value.length < 4) {
      return (parseInt(value) / 1000) * 100;
    } else if (value.length < 5) {
      return (parseInt(value) / 10000) * 100;
    } else if (value.length < 6) {
      return (parseInt(value) / 100000) * 100;
    } else if (value.length < 7) {
      return (parseInt(value) / 1000000) * 100;
    } else if (value.length < 8) {
      return (parseInt(value) / 10000000) * 100;
    } else if (value.length < 9) {
      return (parseInt(value) / 100000000) * 100;
    }
  };

  const handleOpenReportPhases = () => {
    setDateMacrometers(0);
    setZoneP(timeZone);
    setSerial(serial);
    setOpenMeasurer(true);
    setactiveNextBack(true);
    setDateReport([
      {
        startDate: new Date(),
        endDate: addDays(new Date(), 0),
        key: 'selection',
      },
    ]);
  };
  if (loading || !data) {
    return <></>;
  }
  return (
    <>
      <div
        onClick={permissionsRoleUser?.permissionsRole?.MedMacVer ? handleOpenReportPhases : false}
        className="_measurer_container_"
      >
        <div className="_measurer_title_container_">
          <div className="_measurer__title_">
            <span>Macromedidor - {alias === '' || null ? serial : alias}</span>
          </div>
        </div>
        <div className="_measurer_container_kwh_">
          <div className="_measurer_data_container_">
            <span className="_measurer_data_number_">{data.getMetterAndMetterHistories.lecturaActual.toFixed(0)}</span>
            <span className="_measurer_data_kwh_">kWh</span>
          </div>
        </div>

        <div className="_measurer_container_watts_">
          <SemiCircleProgressBar
            diameter={150}
            strokeWidth={15}
            stroke="rgba(162, 99, 239, 1)"
            background="rgba(162, 99, 239, 0.3)"
            percentage={`${percentageValue(
              (data.getMetterAndMetterHistories.dataMetters.variables.EIFA.value + data.getMetterAndMetterHistories.dataMetters.variables.EIFB.value + data.getMetterAndMetterHistories.dataMetters.variables.EIFC.value).toString()
            )}`}
          />
        </div>
        <div className="_measurer_container_range_">
          <span className="_measurer__range_">0</span>
          <span className="_measurer__range_">100000</span>
        </div>
        <div className="_measurer_container_value_">
          {data.getMetterAndMetterHistories.dataMetters.variables.EIFA.value + data.getMetterAndMetterHistories.dataMetters.variables.EIFB.value + data.getMetterAndMetterHistories.dataMetters.variables.EIFC.value} Watts
        </div>
      </div>
      <Dialog
        fullScreen
        open={openMeasurer}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h6"
              className={classes.title}
            >
              Macromedidor {alias} con serial {serial}
            </Typography>
          </Toolbar>
        </AppBar>

        <MeasurerDetails
          //projectID={data.getProjectByIdHeader[0].id}
          timeZone={timeZone}
          serial={serial}
        />
      </Dialog>
    </>
  );
};

export default Measurer;
